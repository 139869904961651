.share-dialog {
  background-color: var(--bg-primary);
  color: var(--text-primary-active);
  padding-bottom: 0;
  overflow: visible;
  width: unset;
  margin-top: 3rem;
  &.at-dialog {
    width: 64rem;
    &.share-as-post,
    &.share-as-message {
      width: 32rem;
      .link-box {
        max-width: 85%;
        width: 24rem;
      }
    }
  }
  &.at-dialog.share-as-post {
    div#messageArea {
      display: none;
    }
  }

  &.at-dialog.share-as-message {
    div#socialMediaArea {
      display: none;
    }
  }
}

.rugshare-title {
  height: 2em;
  margin: auto;
  width: 7em;
  font-size: 2em;
  position: absolute;
  top: -2em;
  left: 0;
  right: 0;
  z-index: 500;
  background: red;
  background: #ad201c;
  border-radius: 8px 8px 0px 0px;
  line-height: 2em;
  color: white;
}
.share-area {
  position: relative;
  text-align: center;
  max-height: 60rem;
  vertical-align: middle;
  font-size: 0.8125rem;
  & > img {
    vertical-align: middle;
    border-style: none;
  }
}
.options-section {
  margin-left: 0;
  margin-right: 0;
  display: flex;
}
.section {
  max-width: 32rem;
  display: inline-block;
  padding: 1em 2em;
  border-right: 2px solid var(--bg-secondary);
  vertical-align: top;
  min-height: 30rem;
}
div#messageArea {
  //border-top: 2px solid #f0c987;
}
#hoverInfo {
  position: absolute;
  background-color: var(--bg-secondary);
  width: 29em;
  left: auto;
  right: 0;
  font-weight: normal;
  text-transform: none;
  padding: 0.5em;
  line-height: 1.5;
  cursor: pointer;
}

div#socialMediaArea {
  //border-top: 2px solid #ad201c;
  border-right: none;
  & .preview-title-main {
    color: #1d2129;
    color: var(--text-primary-active);
    font-weight: bold;
    text-decoration: none;
    font-weight: bold;
    font-family: Segoe UI;
  }
}

div#printArea {
  border-top: 2px solid #89bd9e;
  display: none;
}
.section-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: var(--text-primary-active);
  position: relative;
  & > h5 {
    font-size: 1em;
  }
  & > * {
    display: inline-block;
  }
  // color: #00070e;
}
span#appsInfo {
  margin: 0 0.5em;
  cursor: pointer;
  & svg {
    height: 1.5em;
    vertical-align: sub;
  }
}
.section-desc {
  font-weight: normal;
  text-align: center;
  line-height: 1.5em;
  color: var(--text-primary-active);
}
.platforms-area {
  margin: 1em 0;
}
.platform {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.6em;
  cursor: pointer;
  &.selected {
    & > .platform-icon-area {
      box-shadow: 0px 6px 24px rgba(76, 51, 255, 0.5);
    }
    & > .platform-title {
      font-weight: bold;
    }
  }
  & > .platform-icon-area {
    border-radius: 8px;
    padding: 0.5em;
    width: 3em;
    height: 3em;
    margin: auto;
    background-color: var(--bg-primary);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

    & > img {
      width: 100%;
    }
  }
  & > .platform-title {
    font-size: 0.8em;
    margin: 0.5em 0;
  }
}
.preview-box {
  width: 19em;
  margin: auto;
}
.platform-preview-box {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: var(--bg-primary-active);
  border: 2px solid #ccc;
  box-sizing: border-box;
  border-radius: 0px 10px 8px 8px;
  position: relative;
  z-index: 1;
  & > .preview-thumb {
    height: 11em;
    overflow: hidden;
    border-radius: 0px 8px 0px 0px;
    position: relative;
    z-index: 21;
    background-color: var(--bg-secondary);
    & > img {
      width: 100%;
      margin-top: -15%;
    }
  }
  & > .preview-title {
    text-align: left;
    padding: 0.5em;

    & > .preview-title-main {
      font-size: 0.85em;
      line-height: 1.3em;
      text-decoration-line: underline;
      color: #2b95f0;
    }
    & > .preview-subtitle {
      font-size: 0.7em;
      line-height: 1.2em;
      color: #999;
    }
    & > .preview-time {
      text-align: right;
      color: #999999;
      font-size: 0.7em;
      line-height: 1em;
    }
  }
  & > .preview-link {
    display: block;
    font-size: 0.8em;
    color: #a7b3bd;
    text-align: left;
    margin: 0.3em 0.5em 0.5em;
  }
  & > .preview-link.fbPreviewLink {
    color: #616770;
    text-transform: uppercase;
  }
  & > .box-arrow {
    position: absolute;
    width: 16px;
    height: 16px;
    left: calc(8%);
    top: -8px;
    border-radius: 2px;
    transform: rotate(45deg);
    z-index: -1;
  }
}
.platform-preview-title {
  font-weight: bold;
  text-align: center;
  font-size: 0.82em;
  line-height: 1.8em;
  margin: 0.5em 0 1em 0;
  color: #808080;
}
$rugshare-button-border-color: #ad201c;
$rugshare-button-border: 2px solid $rugshare-button-border-color;
.rugshare-sec-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;
  border: $rugshare-button-border;
  box-sizing: border-box;
  border-radius: 8px;
  color: black;
  color: var(--text-primary);
  width: 10em;
  width: fit-content;
  margin: auto;
  padding: 0.2em 0.5em;
  text-decoration: none;
  display: inline-block;
  user-select: none;
  &:hover {
    background-color: $rugshare-button-border-color;
    cursor: pointer;
    color: white;
  }
  & > a {
    color: black;
    color: var(--text-primary-active);
    text-decoration: none;
    user-select: none;
  }
  &:hover > a {
    color: white;
  }
  & > span {
    vertical-align: middle;
  }
}
.share-url {
  display: inline-block;
  width: calc(100% - 8em);
  border: 2px solid var(--bg-secondary);

  & > input {
    padding-right: 0 !important;
  }
}
.link-section {
  background-color: var(--bg-primary-active);
  padding: 0.5rem;
}
.link-box {
  display: inline-block;
  max-width: 48%;
  margin: 0.5rem;
  color: #7f7f7f;
  color: var(--text-primary-active);
  vertical-align: top;
  text-align: left;
  width: 26em;
  position: relative;
  min-height: 8em;
}
#qrArea {
  & > div {
    display: inline-block;
    width: calc(100% - 7.5em);
    padding-left: 1em;

    & > div {
      font-size: 0.9em;
    }
  }
  & label {
    display: inline-block;
    margin-left: 1em;
  }
}
.qr-checkbox {
  vertical-align: top;
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.15);
  margin: 0 0.5em;
}
.QR-checkbox-area {
  display: none;
}
.text-overlay {
  position: absolute;
  width: 32px;
  height: 30px;
  background: var(--utility-input-fade);
  right: 0;
  top: 0;
}
#copy-link-area {
  vertical-align: middle;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: fit-content;
  margin: auto;
  & > div {
    margin: 10px 0;
  }
  & .at-button {
    text-transform: capitalize;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }
  & .at-button:hover {
    background: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
  }
}
.qr-code-image {
  width: 7.5em;
  display: inline-block;
  vertical-align: top;
  outline: 1px solid white;
}

.QRtext {
  font-size: 0.9em;
  padding-bottom: 0.8em;
}

div#WhatsAppPreview {
  border-radius: 5px;
  text-align: left;
  background: #e2ffc7;
}

#WhatsAppPreview > .preview-title {
  width: calc(100% - 5em);
  background: #daf7c1;
  height: 5em;
}

div#WhatsAppPreview .preview-title-main {
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

#WhatsAppPreview .preview-link {
  display: block;
  font-size: 0.8em;
  color: #18a0ae;
  line-height: 2em;
}

#WhatsAppPreview .preview-time {
  position: absolute;
  bottom: 5px;
  right: 1em;
}

#WhatsAppPreview > div {
  display: inline-block;
  vertical-align: top;
}

#WhatsAppPreview > .preview-thumb {
  width: 5em;
  height: 5em;
  border-radius: 4px 0 0 4px;
}
@media screen and (max-width: 1500px) {
  .link-section > div {
    width: 85%;
    margin: auto;
  }
}
@media screen and (max-width: 900px) {
  .section {
    min-height: auto;
  }
  .share-dialog-wrapper {
    max-height: 80vh;
    overflow: auto;
  }
  .at-dialog.share-dialog {
    width: 32rem;
  }
  .options-section .col-md {
    width: 100%;
    display: block;
  }
  .share-area {
    min-width: 30em;
  }
  .link-section > div {
    width: 80%;
  }
  .preview-box,
  div#printArea,
  div#qrArea {
    display: none;
  }

  .link-box {
    width: 100%;
    max-width: 100%;
  }
  div.share-url {
    width: calc(100% - 8em);
  }
  .share-url .bp3-icon {
    width: 71%;
  }
  .share-dialog {
    width: auto;
  }
  .options-section {
    flex-direction: column;
  }
}
.at-catalog-header {
  margin: 0.5rem;
}
.at-printing-layout {
  width: 100%;
  height: 20em;
  min-width: 15em;
  position: relative;
  padding: 2em 1em;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.at-catalog-image {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  overflow: hidden;
}
.at-catalog-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  & > div {
    cursor: pointer;
  }
}
.at-close-rugshare-button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 25;
}
