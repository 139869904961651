@import "./components/DataControlSettings/datacontrolsettings";
@import "./components/BottomController/bottomcontroller";
@import "./components/InfoBubble/infobubble";
@import "./components/Checkbox/checkbox";

.cinematic-main {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: black;

  &.isIdle .hideOnIdle {
    opacity: 0;
    transition: opacity 0.4s;
    pointer-events: none;
  }
}

.topPanel {
  position: absolute;
  background: var(--bg-primary);
  z-index: 60;
  right: 0;
  margin: 0.5rem;
  top: -3rem;
  transition: top 0.4s;

  &.show {
    top: 0;
  }
}

.at-cine-music-slider {
  opacity: 0.8;
  // &.hidden {
  //   visibility: hidden;
  // }
  // & .bp3-popover-content {
  // }
}

.at-volume-slider {
  background-color: var(--bg-primary);
  width: 10rem;
  height: 2rem;
  // position: absolute;
  /* top: 6.5rem;
  left: calc(100% - 7.05rem); */
  opacity: 0.8;
  animation: fadeIn 0.5s forwards;
  // left: 100%;
  // top: -6rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  visibility: visible;

  &.hidden {
    visibility: hidden;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}