.design-browser {
  display: flex;
  z-index: 50;
  position: absolute;
  &__browsing-assistant.sidebar {
    position: relative;
    height: 100vh;
    flex-shrink: 0.1;
  }
  &__thumbs-area {
    height: 100vh;
    background-color: var(--bg-primary);
    flex: 1 1 auto;
    padding: 2.5rem;
    overflow: auto;
    &__title {
      margin-left: 0.25rem;
      & > .bp3-heading {
        line-height: 1.5rem;
      }
    }

    &__container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      & .designThumb-template0.thumb-item {
        width: 10rem;
      }
    }
  }
}
.design-browser-tree {
  height: 80vh;
  overflow: auto;
}

.design-browser-v2 {
  display: flex;
  z-index: 50;
  position: absolute;
  flex-direction: column;
  margin: 2rem;
  &__logo-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    & > .logo-image {
      height: 3rem;
    }
  }
  &__search-area {
    display: flex;
    justify-content: center;
    align-items: center;
    & > .at-input-group {
      max-width: 24rem;
    }
  }
  &__thumbs-area {
    height: 100vh;
    background-color: var(--bg-primary);
    flex: 1 1 auto;
    padding: 2rem;
    overflow: auto;
    &__title {
      margin-left: 0.25rem;
      & > .bp3-heading {
        line-height: 1.5rem;
        & > span {
          color: var(--text-primary-active);
        }
      }
    }

    &__container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      & > .thumb-item {
        width: 10rem;
      }
    }
  }
}
.design-browser-tree {
  height: 80vh;
  overflow: auto;
}

.design-browser__browsing-assistant {
  &.slide {
    transition: margin-left 0.4s;
  }
}
