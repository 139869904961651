$tree-icon-spacing:7px;
.at-collapse-toggle{
  float: right;
  // @include pt-icon-colors();
  transform: rotate(0deg);
  cursor: pointer;
  padding: $tree-icon-spacing;
  transition: transform ($pt-transition-duration * 2) $pt-transition-ease;
  margin-right: 0.25rem;

  &.open {
    transform: rotate(-180deg);
  }

  // CSS API support
  // &.#{$ns}-icon-standard::before {
  //   content: $pt-icon-chevron-up;
  // }
}

.at-section-container{
  // overflow: hidden;
  // scrollbar-width: thin;
  // &:hover{
  //   overflow:auto;
  //   overflow:overlay;

  // }
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2rem;
    // background:var(--utility-fade);
    // background-image:linear-gradient(180deg, rgba(247, 248, 248, 0) 0%, #1C1F22 100%);
    // background-image:-webkit-linear-gradient(top,rgba(247, 248, 248, 0), black);
    pointer-events: none;
  }
}