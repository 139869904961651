.stage-container {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: var(--bg-secondary);
  // z-index: 10;
  &.design-view {
    margin: 6rem;
  }
  &.transparent-stage {
    opacity: 0;
  }

  &.opaque-stage {
    transition: opacity 500ms ease-out;
    opacity: 1;
  }
  & .rightBarPortal-stageContainer {
    left: auto;
    right: 0;
    z-index: 10;
  }
  & .stage-container-text {
    position: absolute;
    bottom: 2rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--bg-secondary);
    z-index: 4;
    font-size: 0.8rem;
  }
}
.design-image {
  // height:calc(100% - 6rem);
  // top:3rem;
  // width:auto;
  // left: 50%;
  // transform: translateX(-50%);
  // width: 100%;
  // position: absolute;
  transition: transform 500ms;
  &.fit {
    // box-shadow: $layer-4-popout;
    filter: drop-shadow(0px 12px 16px rgba(16, 22, 26, 0.3));
  }
  &.rotated {
    transition: transform 500ms;
    transform: rotateZ(90deg);
  }
}
.design-container {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.room-image {
  width: auto;
}
.at-btn-sound-toggle {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.5s ease-in-out;
  padding: 0.5rem;
  z-index: 50;
  pointer-events: all;
  background: var(--bg-primary) !important;
  .at-icon {
    margin: 0;
  }
  h6 {
    opacity: 0;
    transition: opacity 0.4s, width 0.6s;
    height: 1rem;
    width: 0;
    overflow: hidden;
  }
  &:hover h6 {
    opacity: 1;
    width: 10.5rem;
    margin-left: 0.25rem;
  }
}

.at-btn-sound-toggle-myrugStudio{
  position: absolute;
  top: 1%;
  left: 2%;
  transform: translateX(-50%);
  transition: width 0.5s ease-in-out;
  padding: 0.5rem;
  z-index: 50;
  pointer-events: all;
  border-radius: 50%;
  background: #f7f8f8d4 !important;
  .at-icon {
    margin: 0;
  }
  h6 {
    opacity: 0;
    transition: opacity 0.4s, width 0.6s;
    height: 1rem;
    width: 0;
    overflow: hidden;
  }
  &:hover h6 {
    opacity: 1;
    width: 10.5rem;
    margin-left: 0.25rem;
  }
  &:hover {
    border-radius: 0%;
  }
}
.at-custo-assistant-scout {
  // width:13rem;
  pointer-events: all;
  box-shadow: $layer-3-temp-nav;
  color: var(--text-primary);
  & > .area-swatch {
    width: 100%;
  }
  background-color: var(--bg-primary);

  display: flex;
  border-radius: 0.25rem;
  & > .at-icon {
    padding: 0.25rem;
    cursor: pointer;
  }
}
.at-element-pan {
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: default !important;
}
.at-illustration-popups-container {
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.at-illustration-popup {
  max-width: 30vw;
  max-height: 50vh;
  overflow: auto;
  background: var(--bg-primary);
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  box-shadow: $layer-4-popout;
  border-radius: $btn-border-radius;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:focus {
    outline: none !important;
  }
  $spacing: 0.5rem 1rem;
  & > .bp3-heading {
    position: sticky;
    top: 0;
    z-index: 1;
    background: inherit;
    padding: $spacing;
  }
  & > .thumb-item {
    width: 15rem;
    margin: $spacing;
    margin-bottom: 0;
    & .thumb-image-container.landscape:before {
      padding-top: 100%;
    }
  }
}
.custom-design-mode .at-illu-options-button, .custom-design-mode .at-saveicon-customdesign{
z-index: 999;
background-color: var(--bg-primary);
}
.at-illu-options-button.at-button {
  position: absolute;
  right: 100%;
  margin-right: 1.5rem;
  bottom: 0;
  margin-bottom: 1rem;
  box-shadow: $layer-2-raised !important;
  background-color: var(--bg-primary);
}
.at-illu-options-portal {
  bottom: 0;
  top: unset;
}
.at-illu-options-menu {
  position: absolute;
  right: 100%;
  margin-right: 1.5rem;
  bottom: 0;
  margin-bottom: 4rem;
  border-radius: $btn-border-radius;
  border: 1px solid var(--bg-primary-hover);
  box-shadow: $layer-2-raised;
}
.at-illu-option-item-image {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  border: 1px var(--text-primary-active) solid;
  margin: 0.25rem;
}
.at-illu-option-item {
  display: flex;
  align-items: center;
}
.at-carpet-option {
  cursor: default;
  &:hover {
    background-color: transparent;
    cursor: default;
  }
  &:active {
    background-color: transparent;
    cursor: default;
  }
}
.at-carpet-option-rotation-controls, .at-carpet-option-scale-controls {
  & > .bp3-popover-wrapper {
    margin: 0.25rem 0.25rem 0 0;
  }
}
.py-0{
  padding-bottom: 0;
  padding-top: 0;
}
// .at-carpet-option{
//   padding-top:0;
// }
.catalog-canvas {
  height: 65%;
  box-shadow: $layer-2-raised;
}

.at-saveicon-customdesign{
  position: absolute;
  right: 5%;
  bottom: 0;
  margin-bottom: 1rem;
  box-shadow: $layer-2-raised !important;
  background-color: var(--bg-primary);
}

@media only screen and (max-width: 600px) {
  .at-saveicon-customdesign{
    right: 16% ;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1100px)  {
  .at-saveicon-customdesign{
    right: 9% ;
  }
}