.ellipseDiv {
  &.exit:before {
    animation: hideBubble 1.5s forwards;
    @keyframes hideBubble {
      0% {
        opacity: 0.8;
        clip-path: circle(50% at 50% 50%);
      }
      100% {
        opacity: 0;
        clip-path: circle(49% at 48% 52%);
      }
    }
  }
  &.entry:before {
    animation: showBubble 1.5s forwards;
    @keyframes showBubble {
      0% {
        opacity: 0;
        clip-path: circle(49% at 48% 52%);
      }
      100% {
        opacity: 0.8;
        clip-path: circle(50% at 50% 50%);
      }
    }
  }
}
