.at-vis-slider {
}
.at-vis-spinner {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: var(--bg-secondary);
  box-shadow: $layer-3-temp-nav;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  & > div {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  & > .spinner-border {
    border-color: var(--text-primary);
    border-right-color: transparent;
  }
  &--hidden {
    display: none;
  }
}
.tile-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jpeg-canvasrender {
  -webkit-transition: -webkit-height 0.25s cubic-bezier(0, 0, 0.25, 1),
    -webkit-width 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-height 0.25s cubic-bezier(0, 0, 0.25, 1),
    -moz-width 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: height 0.25s cubic-bezier(0, 0, 0.25, 1), width 0.25s cubic-bezier(0, 0, 0.25, 1);
  // width: auto;
}

canvas.jpeg-canvasrender {
  &.TOPLEFT {
    position: absolute;
    top: 50px;
    left: 50px;
  }
  &.TOPRIGHT {
    position: absolute;
    top: 50px;
    right: 50px;
  }

  &.BOTTOMRIGHT {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }

  &.BOTTOMLEFT {
    position: absolute;
    bottom: 50px;
    left: 50px;
  }
}
