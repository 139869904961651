.at-design-search-container {
  display: flex;
  &-text {
    flex: 1 1;
    margin: 0 0.5rem;
  }
}
button#at-wordCloud img {
  width: 5rem;
}
.bp3-tag.bp3-intent-primary {
  background: var(--brand3);
  color: #fff;
}
