.at-divider{
  $width:2px;
  &.at-divider-horizontal{
    border-top-style: solid;
    border-top-width: $width;
    
    border-top-color: var(--bg-secondary);
    
    width:100%
  }
  &.at-divider-vertical{
    flex:0;
    height:100%;
    border-left-style: solid;
    border-left-width: $width;
    border-left-color: var(--bg-secondary);
  }
}