.at-input-group.at-search-bar {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  & > .at-icon {
    margin-left: 0.5rem;
  }

  > .at-button {
    display: none;
    &:hover,
    &:active {
      background-color: transparent;
    }
  }
  &.focused > .at-button {
    display: block;
  }
}
.ic-searchbar {
  margin-right: 0.5rem;
}
.input-searchbar {
  display: inline-block;
  padding: initial;
  &.search-icons {
    float: right;
  }
  > input {
    background-color: transparent;
    box-shadow: none !important;
  }
  > input:focus {
    box-shadow: none !important;
  }
}
