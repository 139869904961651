.circular-button{
  width:  4rem;
  height:4rem;
  margin-left:0.25rem;
  margin-right: 0.25rem;
  display: inline-block;
  border-radius: 50%;
  position:relative;
  border:none;
  cursor: pointer;
  &.small{
    width:2rem;
    height:2rem;
  }
  >span{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  background-color:var(--bg-primary);

  &:hover{
    background-color:var(--bg-primary-hover);
  }

}