.tree-content-folders{
  cursor: pointer;
  &.base-folder{
    text-transform: uppercase;
  }
  &.selected {
  border: var(--collection-border-width) solid var(--collection-border-color);
  border-bottom: var(--collection-border-width) solid var(--bg-primary);
  background: var(--bg-primary);
  // &:before {
  //   background-color: var(--collection-border-color);
  //   content: ".";
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   height: var(--collection-border-width);
  //   z-index: -1;
  //   left: 0;
  // }
}
}