.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: var(--brand3);
}
.bp3-control .bp3-control-indicator {
  background-color: transparent;
  background-image: none;
  border: none;
  font-size: 1rem;
  &.checked {
    box-shadow: none;
  }
  &:before {
    content: none;
  }
}
.bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
  box-shadow: none;
  background: transparent;
}
.bp3-control:hover input:checked ~ .bp3-control-indicator {
  background-color: transparent;
  box-shadow: none;
}
.bp3-control input:focus ~ .bp3-control-indicator {
  outline: none;
}
.checkbox-icon {
  stroke: var(--brand3);
}
