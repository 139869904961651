.at-sidebar {
  &__togglebtn {
    position: absolute;
    // padding: 0.25rem 0.5rem;
    top: 1rem;

    &:focus {
      outline: none !important;
    }

    &.togglebtn-left {
      left: 99%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $btn-border-radius;
      border-bottom-right-radius: $btn-border-radius;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
    }

    &.togglebtn-right {
      right: 99%;
      border-top-left-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }

  &__content {
    $logo-height: 5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;

    & > * {
      flex: 0 0 auto;
    }

    &__logo {
      height: $logo-height;
      max-width: 100%;
      text-align: center;
      position: relative;
      flex: 0 0 auto;
      margin-bottom: 1rem;
      &.at-rightsidebar__logo {
        flex-grow: 1;
        max-width: 100%;
        display: none;
        & > img {
          max-width: 50%;
          height: auto;
          top: auto;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          transform: none;
        }
      }
      & > .logo-image {
        max-height: calc(100% - 0.5rem);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        max-width: 100%;
        height: auto;
      }

      & > .logo-tagline {
        position: absolute;
        bottom: 0;
        height: 1rem;
        text-align: center;
        left: 0;
        margin: auto;
        right: 0;
        margin-top: 0.5rem;
        display: none;
      }
    }

    &__tabs {
      height: 100%;
    }
  }

  &__container {
    flex-direction: column;
    height: calc(100% - 4rem);
    flex: 1 1 auto;
    display: flex;
    @extend.at-scroll-on-hover;
  }

  &__footer {
    flex: 1 1 auto;
    height: fit-content;
    height: -moz-fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > * {
      margin: 0.25rem;
    }
  }

  &__action-btns {
    background-color: var(--bg-primary);
    position: absolute;
    right: calc(100% + 3rem);
    top: 1rem;
    box-shadow: $layer-1-raised;
    & > .at-back-to-design {
      min-width: 9rem;
      border-right: 2px solid var(--bg-secondary);
    }
  }
}

#at-sidebar__footer__saveAsImage {
  display: none;
}

.at-sidebar__footer-infotext {
  display: none;

  &.shown {
    display: block;
  }
}
$border-style: 2px var(--bg-secondary) solid;
.at-sidebar-stepper.sidebar.stepper-tab-layout {
  & .at-vis-thumbs .thumb-container {
    grid-template-columns: 50% 50%;
  }
  & .bp3-tree-node .thumb-container {
    grid-template-columns: 33% 33% 33%;
  }
}
.at-sidebar-stepper.sidebar {
  padding: 0;
  & > .at-sidebar-area {
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &.stepper-tab-layout {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    // & > .at-sidebar-area {
    //  padding-left: 1rem;
    // padding-right: 1rem;
    // }
    & .at-sidebar__content {
      height: auto;
      overflow: unset;
      border-top: none;
      // min-height: calc(100% - 6rem); // * this should be adjusted with logo height accordingly
    }
    & .at-sidebar__footer {
      position: sticky;
      bottom: 0;
      background-color: var(--bg-primary);
      grid-template-rows: auto auto;
      & > * {
        margin: 0;
      }
      & > .copyright-text {
        grid-row-start: 2;
        padding-bottom: 0;
        grid-column-start: 1;
        grid-column-end: 3;
        padding-top: 0.25rem;
        & > .copyright-description {
          display: none;
        }
      }
      & > .at-footer-buttons-area {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: 3;
        justify-content: center;
      }
    }
    & .at-sidebar__step-container__title {
      display: none;
    }
    & #at-ordersheet-area {
      width: 100%;
    }
  }
  & .at-sidebar {
    &__content {
      border-top: $border-style;
      flex-direction: row;

      & > .bp3-tabs {
        width: 100%;
        min-height: calc(100% + 5rem);
        & > .bp3-tab-list {
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: var(--bg-primary);
          border-bottom: $border-style;
        }
        & > .bp3-tab-panel {
          height: auto;
          overflow: unset;
        }
      }
      &__logo {
        margin-bottom: 0;
        & > .logo-image {
          padding: 1rem;
          max-width: 100%;
          height: auto;
          max-height: calc(100% - 0.5rem);
        }
      }
      & > .at-sidebar__step-container {
        display: flex;
        flex-direction: column;
        width: 33.33%;
        padding: 0 1rem;
        &.dimmed {
          opacity: 0.5;
          pointer-events: none;
        }
        & > .at-sidebar__step-container__title {
          line-height: 3rem;
          height: 3rem;
          flex: 0 0 auto;
        }
        &:not(:last-child) {
          border-right: $border-style;
        }

        & > .at-section-container {
          flex: 1 1 auto;
          overflow: auto;
        }
        & .at-vis-thumbs .thumb-container {
          grid-template-columns: 100%;
          @media only screen and (max-width: 992px) {
            grid-template-columns: 33% 33% 33%;
          }
        }
        & .bp3-tree-node .thumb-container {
          grid-template-columns: 50% 50%;
          @media only screen and (max-width: 992px) {
            grid-template-columns: 50% 50%;
          }
        }
      }

      & .area-swatch {
        width: 100%;
      }
      & .at-des-details__title__heading {
        width: 100%;
      }
      & .at-des-details__price-area__price {
        @extend h5.bp3-heading;
      }
      & > .at-customize-area {
        border-top: $border-style;
      }
      & .at-design-variations {
        border-bottom: $border-style;
      }
      & .at-color-schemes {
        grid-template-columns: 33% 33% 33%;
      }
      & #at-des-details__title-saveImageIcon {
        display: none;
      }
      & .at-section-label > .bp3-heading {
        @extend h6.bp3-heading;
      }
      & .thumb-title {
        @extend .bp3-text-small;
      }
      & .at-design-color-options {
        width: 100%;
        & > .at-button {
          @extend .bp3-text-small;
        }
      }
      & .at-ordersheet__title {
        display: none;
      }
      & #at-des-details__title-favorite {
        display: none;
      }
    }
    &__footer {
      border-top: $border-style;

      display: grid;
      grid-template-columns: 66.67% 33.33%;
      flex: 0 0 auto;
      & > .copyright-text {
        align-self: end;
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
      }
      & > .at-footer-buttons-area {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        padding: 0.25rem;
        & > .at-button {
          margin: 0.25rem;
        }
      }
    }
  }
}

.has-ordersheet-outside {
  & #at-ordersheet-designName {
    display: none;
  }
  & #at_ordersheet_buttons_cancel {
    display: none;
  }
}

.at-price-est-area {
  padding: 0.5rem;
  &__pin-inp {
    display: flex;
    &__input {
      & > .bp3-input {
        box-shadow: inset 0 0 0 1px var(--text-primary-muted);
      }
      margin: 0.5rem 0;
    }
  }

  & .bp3-callout {
    margin: 0.5rem 0;
  }
}

.at-sendToApp-area {
  padding: 0.4em 1em;
  width: auto;
  display: block;
  & &-userIdInput,
  & .at-button {
    margin: 0.5rem 0;
  }
  & > .userIdText {
    display: inline-block;
    margin: auto;
  }
  &__inp {
    display: inline-block;
    margin-left: 0.7rem;
    width: 6rem;
  }
}
button#ReturnToAppBtn {
  width: 10rem;
  text-align: center;
}

.at-sidebar__container {
  &.hide-color {
    p.at-section-allowcolortext{
      color: var(--brand3);
    }
    .at-design-color-options,
    #color-area,
    .at-summary {
      display: none;
    }
  }
  &.hide-size {
    #at-ordersheet-area,
    .at-sidebar__container__shape-example,
    .at-summary, .at-des-details__title__controls {
      display: none;
    }
  }
  

  &__steps {
    display: flex;
    justify-content: space-between;
    .stepwise-buttons {
      text-align: center;
      &-button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        & p {
          position: absolute;
          margin: auto;
        }
      }
    }
  }
  &__shape-example {
    text-align: left;
    .shape {
      border: 1px solid black;
      width: 9rem;
      height: 12rem;
      display: flex;
      position: relative;
      text-align: center;

      transition: height 300ms linear, border-radius 300ms linear;
      &.Square {
        height: 9rem;
      }
      &.Round {
        border-radius: 9rem;
        height: 9rem;
      }
      .shape-example-text {
        position: absolute;
      }
      .shape-example-text.text-height {
        transform: rotateZ(-90deg);
        align-self: center;
        margin-left: -0.7rem;
      }

      .shape-example-text.text-width {
        justify-self: center;
        position: absolute;
        bottom: 0;
        margin-left: 3rem;
      }
    }
  }
  .at-summary {
    &-content {
      margin: 1rem 0;
    }
    &-title {
      font-weight: bold;
    }
  }
  &.summary {
    .at-sidebar__container__shape-example,
    p.at-section-allowcolortext,
    .at-design-color-options {
      display: none;
    }
    & .at-ordersheet__container .at-section-wrapper {
      display: none;
    }

    &-title {
      font-weight: bold;
    }

    &-content {
      margin: 1rem 0;
    }
  }
}
