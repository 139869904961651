.initial-logopage {
  & img {
   display: none;
    &.show {
      display: block;
    }
  }
  .bp3-progress-bar .bp3-progress-meter{
    width: 30%;
    animation: linear-progress-bar-stripes 2s linear infinite;
  }
}

@keyframes linear-progress-bar-stripes {
  0% {
    left:-30%;
  }
  to {
    left: 110%
  }
}
