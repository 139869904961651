.at-scrolling-horizbar{
    display: flex;
    width:100%;
    color:white;
    justify-content: center;
    margin: 0.5rem 0;
}
.rear-placeholders, .front-placeholders{
    pointer-events: none;
    visibility: hidden;
}