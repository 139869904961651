.animatesubjectimg {
  opacity: 1;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}
.animatesubjectimg.backup,
.animateddiv.backup {
  display: none;
}
// .animatesubjectimg.standby.active {
//   // display: inline-block;
//   opacity: 1;
//   z-index: 6;
// }
.animateddiv {
  opacity: 0;
}
.animateddiv.active {
  z-index: 5;
  opacity: 1;
}
.animateddiv.standby {
  z-index: 4;
  opacity: 1;
}
.animatesubjectimg.active {
  opacity: 1;
  z-index: 5;

  // animation: 8s ease-out slide forwards;
  // @keyframes slide {
  //   0% {
  //     transform: translateX(100px);
  //     opacity: 1;
  //   }
  //   90% {
  //     opacity: 1;
  //   }
  //   100% {
  //     transform: translateX(500px);
  //     opacity: 0;
  //   }
  // }
}
.animateddiv .blur {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
}
.animatesubjectimg.standby {
  z-index: 4;
  opacity: 1;
  // animation: 1s ease-in 7s standbyslide forwards;
  // @keyframes standbyslide {
  //   0% {
  //     transform: translateX(0px);
  //     opacity: 1;
  //   }
  //   100% {
  //     transform: translateX(100px);
  //     opacity: 1;
  //   }
  // }
}

// @keyframes zoomout {
//   0% {
//     // transform: scale(1.02, 1.02);
//     transform: scale(1, 1);
//     opacity: 1;
//   }
//   95% {
//     opacity: 1;
//   }
//   100% {
//     transform: scale(1.2, 1.2);
//     opacity: 0;
//   }
// }

// @keyframes zoomoutstandby {
//   0% {
//     // transform: scale(1, 1);
//     opacity: 1;
//   }

//   100% {
//     opacity: 1;
//     // transform: scale(1.02, 1.02);
//   }
// }
