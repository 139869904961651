.at-color-schemes {
  display: grid;
  overflow-x: auto;
}
.at-design-variations-heading {
  & > .at-button {
    display: inherit;
    &.has-3-children:not(.has-morethan-3-children) {
      display: none;
    }
  }
}

.at-color-schemes {
  grid-template-columns: 33% 33% 33%;

  > .thumb-item:nth-child(n + 4) {
    display: inherit;
  }
  &.collapsed {
    > .thumb-item:nth-child(n + 4) {
      display: none;
    }
  }
}
