@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --right-column-width: Max(20%, 20rem);
  --cyr-roomthumbs-maxheight: 15rem;
  --thumb-min-width: 12rem;
  --collection-border-width: 1px;
  --collection-border-color: var(--brand3);
}

body.create-your-rug {
  overflow: hidden;
  overflow-x: hidden;
  margin: 0;
  padding: 0;

  * {
    scroll-behavior: smooth;
  }
}

.create-your-rug {
  #app-main {
    animation: fadeIn 5s;
    width: 100%;
    display: grid;
    grid-template-areas:
      "cyr-iframe"
      "cyr-1-roomview"
      "cyr-1-roomthumb"
      "cyr-2"
      "cyr-3"
      "cyr-4"
      "cyr-5"
      "cyr-6"
      "cyr-7";
    // grid-template-rows: 1fr 30px;
    grid-template-columns: 100%;
    grid-template-rows: auto 75dvh 25dvh auto auto auto;

    &.detailViewOnTop {
      grid-template-areas:
        "cyr-iframe cyr-iframe"
        "cyr-2 cyr-1-right"
        "cyr-2 ."
        "cyr-1-roomview  cyr-1-roomview"
        "cyr-1-roomthumb cyr-1-roomthumb"
        "cyr-3 cyr-3"
        "cyr-4 cyr-4"
        "cyr-5 cyr-5"
        "cyr-6 cyr-6"
        "cyr-7 cyr-7";
      grid-template-rows: auto auto Min(120vh, 100rem) 14rem auto auto auto auto;
      grid-gap: 1rem;
    }
  }

  .searchBarDiv{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: -1rem;
  }

  .at-search .thumb-container{
    flex-wrap: wrap;
  }

  .cyr-iframeContainer {
    grid-area: cyr-iframe;
    margin-bottom: 1rem;
    height: 100vh;
  }

  .cyr-roomViewContainer {
    grid-area: cyr-1-roomview;
    position: relative;
    overflow: hidden;
  }

  .illustration-wrapper {
    grid-area: cyr-1-roomthumb;
  }

  .right-column {
    grid-area: cyr-1-right;
  }

  #cyr-collection {
    grid-area: cyr-5;
  }

  #cyr-designvariations {
    grid-area: cyr-4;
  }

  .services-section {
    grid-area: cyr-6;
  }

  .cyr-detailView {
    grid-area: cyr-2;
  }

  #cyr-designviews {
    grid-area: cyr-3;
  }

  .cyr-copyright {
    grid-area: cyr-7;
  }

  #cyr-designvariations:empty {
    display: none;
  }

  .full-height {
    height: 100%;
  }

  .full-width {
    width: 100%;
  }

  .at-sidebar__action-btns {
    right: 5rem;
    z-index: 5;
  }

  .thumb-image.fit-width {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  #fadeindelayCYR{
    position: fixed;
    overflow: hidden;
  }

  .cyr-thumbs-container .thumb-image.fit-width {
    width: auto;
  }

  #room-view-container {
    height: 100%;
  }

  .at-design-variations-heading.at-design-variations-shapes{
    margin-top: 4rem;
  }

  .at-color-schemes.at-design-variations-shapes{
    margin:0 1rem; 
  }

  .selectColorInSimilarColorsTab{
    background-color: #bebebe66;
    flex-direction: column;
  }

  .plusIconDiv{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customizeColorDiv{
    font-size: 12px;
  }

  .thumb-container,
  .at-color-schemes {
    display: flex;

    >div {
      flex: 0 0 Min(12%, 15rem);
      flex-direction: column;
      display: flex;
      min-width: var(--thumb-min-width, 12rem);
    }
  }

  .at-color-schemes {
    gap: 4rem;
    justify-content: flex-start;

    &> :first-child {
      margin-left: auto;
    }

    &> :last-child {
      margin-right: auto;
    }
  }

  .thumb-image-container {
    width: fit-content;
    padding: 1rem;
    margin: auto;
    // height: 80%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 10rem;
    max-height: 25rem;
    // .thumb-image {
    //   position: relative;
    // }
    // &:before {
    //   display: none;
    // }
  }

  .cyr-roomViewContainer {
    .myroom-controls.topLeft {
      left: 2rem;
      z-index: 1;
      top: 21rem;
      margin: auto;
      bottom: 0;
      transform: none;
      height: fit-content;
    }

    .myroom-controls.bottomBar {
      left: 0;
      z-index: 1;
    }
  }

  button.at-collapse-button {
    display: none;
  }

  .cyr-section {
    padding: 4rem 0;
    position: relative;

    .copyright-text {
      text-align: center;
    }
  }

  .cyr-section-1 {
    margin-top: 1rem;
    display: flex;
    position: relative;
    height: calc(96vh + var(--cyr-roomthumbs-maxheight));
    display: grid;
    grid-template-columns: calc(100% - var(--right-column-width)) var(--right-column-width);
  }

  .cyr-section-1>div {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 100vw;
  }

  .at-sidebar__togglebtn.togglebtn-right {
    display: none;
  }

  .right-column-backdrop {
    display: none;
  }

  .right-column {
    min-width: 20%;
    flex: 0 0;
    flex-basis: var(--right-column-width, 20rem);
    height: auto;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    overflow: auto;
  }

  .design-view-main-container{
    display: grid;
    grid-template-columns: auto 20rem 20rem;
  }
  .design-view-main-container-jpeg{
    display: grid;
    grid-template-columns: auto 20rem;
  }

  .at-sidebar__content__logo {
    height: 8rem;
    max-width: 20rem;
    display: flex;
    align-self: center;
    width: 100%;
    margin: auto;
    position: absolute;
    right: 12px;
    top: 4rem;
  }

  .bp3-portal.myroom-controls-portal {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .stage-wrapper {
    .stage-container {
      max-width: 100%;
    }

    .bp3-portal.at-illu-options-portal {
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      left: auto;
      z-index: 100;
    }
  }

  .cyr-dimension-div{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: -8px
  }

  .sizeInputDiv{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;

    #sizes{
      margin-bottom: 0.5rem;
      height: 28px;
      width: 100%;
      cursor: pointer;
      border-radius: 3px;
    }
  }

  .sizeInputText{
    font-size: 14px;
    font-weight: 500;
    color: #58595B;
    margin-bottom: 0;
  }
  

  .sizeInputWidthLengthInput{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    p{
      font-size: 13px;
      font-weight: 600;
      margin: 0;
    }
  }

  .dflex{
    display: flex;
    gap: 0.5rem;
  }
  
  .sizeInput{
    width: 100%;
    height: 28px;
    border: none;
    border: 1px solid #767676;
    border-radius: 3px;
  }

  #cyr-sizeDropdown{
    padding: 1rem;
  }

  .sizeConfirmButton{
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
  }

  .cyr-detailView-designname {
    font-size: 1.5rem;
    line-height: 2;
  }

  .at-section-heading-area.color-area-swatch,
  .at-ordersheet__title {
    display: none;
  }

  #at_ordersheet_buttons_cancel{
    display: none;
  }

  .illustration-wrapper {
    position: relative;
    padding: 0rem;

    .at-section-heading-area {
      display: none;
    }
  }

  .cyr-color-area {
    margin: 1rem;
    flex: 1 1 40%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    min-height: 15rem;
  }

  div#color-area {
    flex: 1;
    overflow: auto;
  }

  .cyr-socialmediashare-area {
    flex: 0 0 auto;
    margin: 0;
    padding: 0.5rem;
    // margin-top: 0;

    .cyr-heading {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .cyr-long-buttons-area {
    display: flex;
    flex-direction: column;
  }

  .at-button.cyr-long-buttons {
    width: 90%;
    margin: auto;
    margin-bottom: 0.5rem;

    &>.at-button-text {
      font-size: 1.5rem;
      line-height: 2rem;
      text-transform: capitalize;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
    }
  }

  .cyr-sheet-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0.5rem 0rem;
  }

  .at-section-heading-area {
    height: auto;

    .at-section-label {
      height: auto;
      justify-content: center;
    }
  }

  #cyr-DesignName{
    cursor: pointer;
    font-size: 1rem;
    margin: 0.5rem 0rem;
    font-weight: 800;
  }

  .horizontalLine{
    border-bottom: 3px solid rgb(80, 80, 78);
    margin: 1rem;
  }

  .cyr-heading,
  .at-section-heading-area .bp3-heading {
    font-family: "Cinzel", serif;
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    text-transform: uppercase;
    font-weight: normal;
  }

  .cyr-collection-section .thumb-container {
    position: absolute;
  }

  .color-area-container>.area-swatch {
    width: Max(50%, 20rem);
  }

  .stage-wrapper.deepzoom {
    position: relative;
    width: Min(100vw, 100%);
    height: 80vh;
    min-height: 25rem;
  }

  .design-controller__thumbs-area__container {
    display: flex;
    gap: 2rem;
    max-width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    // align-items: flex-end;
    justify-content: flex-start;

    &>div {
      flex: 0 0 Min(15%, 20rem);
      min-width: var(--thumb-min-width, 12rem);
      height: 100%;

      .thumb-item {
        height: 100%;
      }
    }

    &> :first-child {
      margin-left: auto;
    }

    &> :last-child {
      margin-right: auto;
    }
  }

  ul.tree-content-folders-container {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;

    &:before {
      background-color: var(--collection-border-color);
      content: ".";
      width: 100%;
      position: absolute;
      bottom: 0;
      height: var(--collection-border-width);
      z-index: -1;
      left: 0;
    }
  }

  ul.tree-content-folders-container .tree-content-folders {
    padding: 1rem;
    line-height: 1.5rem;
  }

  .at-vis-thumbs {
    max-width: 100%;
    overflow: auto;

    .thumb-container {
      display: flex;
      max-height: var(--cyr-roomthumbs-maxheight);
    }
  }

  .at-section-container::after {
    display: none;
  }

  button#at_ordersheet_btn {
    display: flex;
    margin: auto;
  }

  .cyr-large-card-wrapper {
    height: auto;
    cursor: pointer;
  }

  .double-column-section {
    display: grid;
    grid-template-columns: 50%;
    grid-auto-flow: column;
    margin: auto;
    width: Max(80%, 25rem);
  }

  .myroom-card {
    width: 50% !important;
  }

  #singleCYRCard{
    grid-template-columns: none;
  }

  .double-row-section {
    display: grid;
    grid-template-rows: auto;
    margin: auto;
    padding: 1rem;
    width: 100%;
  }

  .cyr-image-wrapper {
    &>img, &>video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cyr-card-body {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    flex: 1 1 auto;
    min-height: 1px;
    font-size: 1rem;
    padding: 1.25rem;
  }

  .cyr-card-body-title {
    font-size: 1.5rem;
    color: var(--text-primary-active);
  }

  .cyr-card-body-text {
    text-align: justify !important;
    font-size: 1rem;
  }

  .cyr-thumbs-container {
    position: relative;
  }

  span.bp3-icon.at-icon.navigator-arrows {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 5rem;
    width: 5rem;
    z-index: 8;
    left: 1rem;

    &.right {
      left: auto;
      right: 1rem;
    }

    &>svg {
      width: 100%;
      height: 100%;
      stroke-width: 0.5px;
      stroke: #eee;
      filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
      fill: var(--brand3);

      &:hover {
        filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.8));
        cursor: pointer;
        stroke: white;
      }
    }
  }

  .appview {
    .cyr-socialmediashare-area {
      display: none;
    }
  }

  .saveImages{
    display: flex;
    justify-content: space-around;
  }

  .at-design-views-similardesigns{
    overflow-y: hidden;
  }

  .ecat-mode{
    .at-perspective-thumb {
      &>.thumb-image-container {
        &>img {
          -webkit-transform: rotateX(70deg) rotateY(1deg) rotate(46deg) translateX(-28px);
          transform: rotateX(70deg) rotateY(1deg) rotate(46deg) translateX(-28px);
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) and (orientation: portrait) {
  .cyr-roomViewContainer {
    min-height: 30rem;
  }
}

@media only screen and (max-width: 1300px) {
  .create-your-rug {
    #app-main {
      grid-template-areas:
        "cyr-iframe"
        "cyr-1-roomview"
        "cyr-1-roomthumb"
        // "cyr-1-right"
        "cyr-2"
        "cyr-3"
        "cyr-4"
        "cyr-5"
        "cyr-6"
        "cyr-7";
      grid-template-columns: 100%;
    }

    .design-view-main-container{
      grid-template-columns: auto 15rem 15rem;
    }
    .design-view-main-container-jpeg{
      grid-template-columns: auto 15rem;
    }
    .at-sidebar__togglebtn.togglebtn-right {
      display: inline-flex;
      right: 0;
      z-index: 10;
      transition: right 300ms linear;

      &.toLeft {
        right: 24rem;
      }
    }

    .right-column-backdrop.show {
      display: grid;
      width: 100%;
      height: 100%;
      grid-row: 1/-1;
      grid-column: 1/-1;
      z-index: 9;
    }

    .right-collapsible {
      position: absolute;
      right: 0;
      z-index: 10;
      background: var(--bg-primary);
      width: 0;
      padding: 0;
      min-width: 0;
      overflow: hidden;
      // height: auto;
      // max-height: 100vh;
      transition: width 300ms ease-in;

      &.show {
        // left: calc(100% - 24rem);
        width: 24rem;
        overflow: auto;
      }
    }
  }

  // @media only screen and (orientation: landscape) {
  //   .cyr-roomViewContainer {
  //     min-height: 90vh;
  //   }
  // }
}

// @media only screen and (max-width: 1000px) {
//   .create-your-rug {
//     .cyr-section-1 {
//       // grid-template-columns: auto;
//       // grid-template-rows: 30rem 30rem;
//       display: flex;
//       flex-direction: column;
//       height: auto;
//       .stage-wrapper {
//         flex: 1 1 25rem;
//       }
//     }
//   }
// }

@media only screen and (max-width: 800px) {
  .create-your-rug {
    .at-myroomicebreaker-contents {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
      justify-content: center;
      align-items: start;
    }
    .design-view-main-container{
      grid-template-columns: 50% 50% !important;
      grid-template-areas:
      "cyr-a cyr-a"
      "cyr-b cyr-c";
    }
    .design-view-main-container-jpeg{
      grid-template-columns: auto 10rem !important;
      grid-template-areas:
      "cyr-a cyr-c"
    }
    .at-sidebar__content__logo{
      display: none;
    }
    #design-view-container{
      grid-area: cyr-a;
    }
    .cyr-color-area {
      grid-area: cyr-b
    }
    .right-column1 {
      grid-area: cyr-c
    }
    .double-column-section {
      grid-auto-flow: row;
      grid-template-columns: 100%;
    }

    .myroom-card {
      width: 100% !important;
    }
    
    .double-row-section {
      display: flex;
      flex-direction: column;
    }

    .right-column {
      // width: Min(35rem, 100vw);
      display: grid;
      justify-self: center;
    }
  }
}

// @media only screen and (max-width: 1000px) and (min-width: 500px) {
//   .create-your-rug .color-area-container>.area-swatch {
//     width: 50%;
//   }
// }

@media only screen and (orientation: portrait) {
  .cyr-iframeContainer {
    height: 30vh !important;
  }
}
