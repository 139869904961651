.app-main {
  width: 100%;
  height: 100%;
}

.app-container {
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: opacity 500ms ease-out;

  &.transparent {
    opacity: 0;
  }

  &.opaque-stage {
    opacity: 1;
  }
  &.isFelt {
    .color-details-material,
    .color-details-pileheight, #at-masterCarving {
      display: none;
    }
  }
}

.panel-title {
  display: inline;
  margin: auto 1em;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;

  > .at-container {
    height: 100%;
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
  }
}

.panel-toggle {
  $panel-padding: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;

  &.left {
    right: $panel-padding;
  }

  &.right {
    left: $panel-padding;
  }
}

.copyright-text {
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  width: 100%;
  padding-top: 1rem;
  flex: 0 0 auto;
}

.logo-main {
  background-color: var(--bg-primary);
  cursor: pointer;
  $border-rad: 0.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.75rem 1.25rem;
  border-bottom-left-radius: $border-rad;
  border-bottom-right-radius: $border-rad;
  box-shadow: $layer-4-popout;

  & > .logo-image {
    height: 2rem;
  }

  z-index: 16;
}

.at-size-dropdown .at-select{
  background-color: white ;
}

.control-buttons {
  position: absolute;
  right: calc(100% + 1rem);
  top: 1rem;
  display: inline-flex;
  box-shadow: $layer-2-raised;
  background-color: var(--bg-primary);
  border-radius: 0.25rem;

  & > button {
    padding: 0.5rem 1rem;
  }
}
.custom-design-mode .left.sidebar{
  display: none;
}

.side-panel-buttons-area {
  // position: absolute;
  // bottom:1rem;
  // width:100%;
  flex: 1 1 auto;
  height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-button {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.order-sheet-buttons {
  position: absolute;
  bottom: 1rem;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;

  > .at-button {
    margin: 0.25rem;
  }
}

.at-design-views-container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.fullscreen-button {
  position: absolute;
  right: calc(100% + 1rem);
  top: 1rem;
  box-shadow: $layer-2-raised;
}

.fab-button {
  position: absolute;
  right: calc(100% + 1rem);
  bottom: 1rem;
  background-color: var(--bg-primary);

  box-shadow: $layer-2-raised;
}

.at-container-gradient-area {
  position: sticky;
  bottom: 0;
  height: 4rem;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  pointer-events: none;
}

.at-designlist-area {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: column;

  & > .favorites-area {
    flex: 0 0 auto;
  }
}

.at-designlist-area {
  & > .at-section-wrapper {
    height: 100%;
  }
}

.at-tab-section {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.inactive {
    display: none;
  }

  & > .at-section-heading-area {
    flex: 0 0 auto;
  }

  & > .at-section-container {
    flex: 1 1 auto;

    &.noscroll {
      flex-shrink: 0;
    }
  }
}

.at-fullheight-container {
  height: calc(100% - 3.5rem);
}

.at-loading-overlay {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bg-primary);
  color: var(--text-primary);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 500ms;

  &.loading {
    opacity: 1;
  }

  & > div {
    margin: 0.75rem;
  }

  & > .bp3-progress-bar {
    box-shadow: $layer-3-temp-nav;

    & > .bp3-progress-meter {
      background-color: var(--brand3);
    }
  }
}

.at-initial-loading-bar {
  width: 20rem;
}

.at-loading-logo {
  width: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-image {
    border-radius: 0.5rem;
    // max-height: 4.5rem;
    max-width: 16rem;
    width: unset;
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .copyright-text {
    padding-top: 0;
  }
}

.at-container-stepper {
  display: inline-flex;
  width: 100vw;
  height: 100%;
  & > .stage-container {
    position: absolute;
    height: inherit;
    left: 50%;
    width: 50%;
    flex: 1 1 auto;
    @media only screen and (max-width: 992px) and (orientation: portrait) {
      left: 0;
      width: 100%;
    }
    &.panel-hidden {
      width: 100%;
      left: 0;
    }
  }
  & > .sidebar.left {
    width: 50%;
    flex: 0 0 auto;
    position: absolute;
    left: 0;
    @media only screen and (max-width: 992px) and (orientation: portrait) {
      width: 75%;
    }
  }
  & > .at-sidebar__action-btns {
    right: 1.5rem;
    top: 1.5rem;
    z-index: 100;
    box-shadow: $layer-3-temp-nav;
  }
}
