
$gray1: #676F75;
$gray2: #7C858B;
$gray3: #939A9F;
$gray4: #B0B5B9;
$gray5: #C7CBCE;

$light-gray1:#D3D8DB;
$light-gray2:#DDE0E3;
$light-gray3:#E5E7E9;
$light-gray4:#EFF0F2;
$light-gray5:#F7F8F8;

$dark-gray1:#1C1F22;
$dark-gray2:#252A2E;
$dark-gray3:#30363B;
$dark-gray4:#383F45;
$dark-gray5:#424A50;


$success1: #047164;
$success2: #068e7e;
$success3: #07ae9a;
$success4: #08c9b2;
$success5: #09e7cc;


$warning1: #a66321;
$warning2: #bf7326;
$warning3: #d9822b;
$warning4: #d9822b;
$warning5: #d9822b;

$danger1: #A82A2A;
$danger2: #C23030;
$danger3: #DB3737;
$danger4: #F55656;
$danger5: #FF7373;   

$secondary1:#2A91B3;
$secondary2:#25A6D0;
$secondary3:#15B1E4;
$secondary4:#55C5E9;
$secondary5:#80D9F6;

:root {
  --brand1: #00855D ;
  --brand2: #00AB78 ;
  --brand3: #00D092 ;
  --brand4: #2ED8A5 ;
  --brand5: #5CE1B9 ; 
  

  --bg-primary:#F7F8F8;
  --bg-primary-hover:#EFF0F2;
  --bg-primary-active:#E5E7E9;
  --bg-secondary:#EEF0F1;
  --text-primary:#585858;//#939A9F;
  --text-primary-muted:#C7CBCE;
  --text-primary-active:#30363B;
  --text-primary-hover:#7C858B;
  --utility-fade:	linear-gradient(180deg, rgba(247, 248, 248, 0) 0%, var(--bg-primary) 100%);
  --utility-input-fade: linear-gradient(270deg, var(--bg-secondary) 0%, rgba(196, 196, 196, 0) 100%);

  
}