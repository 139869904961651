.studiocontainer {
  width: 100%;
  height: 100%;
  position: absolute;
}
.studiocontainer--wand {
  cursor: url("https://v3dev.explorug.com/build/wand.cur"), auto;
}
.studiocontainer__canvas {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.studiocontainer__threecanvas {
  pointer-events: all;
}
.studiocontainer__shadowcanvas {
  opacity: 1;
  transition: opacity 1s;
}
.studiocontainer__shadowcanvas--fadeCross {
  opacity: 0;
}
.studiocontainer__objcontainer {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.objcontainer__objcanvas {
  position: absolute;
}
.objcontainer__objcanvas {
  pointer-events: all;
}
.objcontainer__objcanvas--trans {
  opacity: 0.5 !important;
}
.studiocontainer__transitioncanvas {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  // transition: opacity 0.4s;
}
.studiocontainer__transitioncanvas--fadeCross {
  opacity: 1;
}
.transitioncanvas__renderfromsavedstate {
  opacity: 1 !important;
  display: block !important;
}
.studiocontainer__inputcontainer {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.studiocontainer__optionscontainer {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 60;
}

.optionscontainer__pseudoWrapper {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.color-area-popover {
  pointer-events: all;
}
.optionscontainer__shotteleporter {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  &:hover {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.circular-button {
  background-color: transparent !important;
  opacity: 0.8;
  color: rgba(158, 150, 150, 0.5);
  margin: 0;
}
.optionscontainer__activetrigger {
  border-radius: 50%;
  background: transparent;
  opacity: 0.6;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2),
    0px 0px 0px rgba(16, 22, 26, 0.1);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  position: absolute;
  pointer-events: all;
  cursor: pointer;

  span {
    vertical-align: bottom;
  }
  &:hover {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid #ffffff;
    .activetrigger__inelem {
      width: 2rem;
      height: 2rem;
    }
  }
}

.activetrigger__inelem {
  background: #d6ccc0;
  border: 1px solid #808080;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2),
    0px 0px 0px rgba(16, 22, 26, 0.1);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.daybtn {
  z-index: 100;
}

.optionscontainer__lightSwitch {
  position: absolute;

  background-position: center;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  opacity: 0.2;

  &.mysticsource {
    display: none;
  }

  &__on {
    background-image: url("/build/LightOff.svg");
  }

  &__off {
    background-image: url("/build/LightOn.svg");
  }

  &:hover {
    opacity: 0.9;
  }
  &:active {
    width: 2.5rem;
    height: 2.5rem;
  }
}
