$label-height: 1.5rem;
$thumb-title-margin: 0.5rem;

.thumb-item {
  margin: 0.25rem;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  position: relative;

  &.active {
    & > .thumb-image-container {
      border: 2px solid $pt-intent-primary;
    }

    & > .thumb-title {
      color: $pt-intent-primary;
    }
  }
}

.close-button {
  position: absolute;
  bottom: $label-height + $thumb-title-margin;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: $layer-3-temp-nav;
}

.thumb-title {
  margin-top: $thumb-title-margin;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  max-width: calc(100% - #{$grid-gutter-width});
  white-space: nowrap;
  & p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.thumb-image-container {
  // height:calc(100% - #{$label-height});
  height: auto;
  // min-height: 10rem;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;

  // background-color: $light-gray4;
  background-color: var(--bg-secondary);
  border: 2px solid transparent;
  background-position: center;
  background-repeat: repeat-y;

  &:before {
    content: " ";
    display: block;
  }

  &.portrait:before {
    width: 100%;
    padding-top: 133.33%;
  }

  &.landscape:before {
    width: 100%;
    padding-top: 66.66%;
  }
}

.thumb-image {
  max-height: calc(100% - #{$grid-gutter-width});
  vertical-align: middle;
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));

  // box-shadow: 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 4px 16px rgba(0, 0, 0, 0.16), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
  &.fit-height {
    max-height: calc(100% - #{$grid-gutter-width});
    width: auto;
  }

  &.fit-width {
    max-width: calc(100% - #{$grid-gutter-width});
    height: auto;
  }

  &.rotated {
    transform: rotateZ(90deg);
  }
}
