.at-perspective-thumb {
  &>.thumb-image-container {
    position: relative;
    -webkit-perspective: 243px;
    perspective: 243px;

    &>img {
      -webkit-transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px);
      transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px);
    }
  }
}

.at-deepzoomDesign-thumb {
  &>.thumb-image-container {
    &>img {
      width: 100% !important;
      height: auto !important;
      object-fit: cover !important;
    }
  }
}

.at-folded-back-thumb {
  &>.thumb-image-container {
    position: relative;
    -webkit-perspective: 243px;
    perspective: 243px;

    &>img {
      -webkit-clip-path: polygon(-4% -4%, 104% -4%, 104% 69%, 64% 104%, -3% 104%);
      clip-path: polygon(-4% -4%, 104% -4%, 104% 69%, 64% 104%, -3% 104%);
      -webkit-transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px);
      transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px);

      // box-shadow: 8px 7px 16px -6px rgba(0, 0, 0, 0.53);
      // -webkit-box-shadow: 5px 4px 11px -7px rgba(0, 0, 0, 0.53);
      // -moz-box-shadow: 8px 7px 16px -6px rgba(0, 0, 0, 0.53);

    }

    &>img.folded-back-part {
      clip-path: polygon(56% 115%, 108% 61%, 108% 108%, 56% 110%);
      transform: rotateX(61deg) rotateY(5deg) rotate(221deg) translateX(-69%) translateY(-67%) translateZ(0px);
      // box-shadow: 8px 7px 16px -6px rgba(0, 0, 0, 0.4);
      // -webkit-box-shadow: 5px 4px 11px -7px rgba(0, 0, 0, 0.4);
      // -moz-box-shadow: 8px 7px 16px -6px rgba(0, 0, 0, 0.4);
      filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4))
    }
  }
}