@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
@charset "UTF-8";
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
:root {
  --brand1: #00855D ;
  --brand2: #00AB78 ;
  --brand3: #00D092 ;
  --brand4: #2ED8A5 ;
  --brand5: #5CE1B9 ;
  --bg-primary:#F7F8F8;
  --bg-primary-hover:#EFF0F2;
  --bg-primary-active:#E5E7E9;
  --bg-secondary:#EEF0F1;
  --text-primary:#585858;
  --text-primary-muted:#C7CBCE;
  --text-primary-active:#30363B;
  --text-primary-hover:#7C858B;
  --utility-fade:	linear-gradient(180deg, rgba(247, 248, 248, 0) 0%, var(--bg-primary) 100%);
  --utility-input-fade: linear-gradient(270deg, var(--bg-secondary) 0%, rgba(196, 196, 196, 0) 100%); }

/*

Copyright 2015-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none;
  color: #182026;
  font-family: "IBM Plex Sans"; }

p {
  margin-bottom: 10px;
  margin-top: 0; }

small {
  font-size: 12px; }

strong {
  font-weight: 600; }

::selection {
  background: rgba(125, 188, 255, 0.6); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
Headings

Markup:
<div>
  <h1 class="@ns-heading">H1 heading</h1>
  <h2 class="@ns-heading">H2 heading</h2>
  <h3 class="@ns-heading">H3 heading</h3>
  <h4 class="@ns-heading">H4 heading</h4>
  <h5 class="@ns-heading">H5 heading</h5>
  <h6 class="@ns-heading">H6 heading</h6>
</div>

Styleguide headings
*/
.bp3-heading {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0; }
  .bp3-dark .bp3-heading {
    color: #f5f8fa; }

h1.bp3-heading, .bp3-running-text h1 {
  font-size: 36px;
  line-height: 40px; }

h2.bp3-heading, .bp3-running-text h2 {
  font-size: 28px;
  line-height: 32px; }

h3.bp3-heading, .bp3-running-text h3 {
  font-size: 22px;
  line-height: 25px; }

h4.bp3-heading, .bp3-running-text h4 {
  font-size: 18px;
  line-height: 21px; }

h5.bp3-heading, .at-sidebar-stepper.sidebar .at-sidebar__content .at-des-details__price-area__price, .bp3-running-text h5 {
  font-size: 16px;
  line-height: 19px; }

h6.bp3-heading, .at-sidebar-stepper.sidebar .at-sidebar__content .at-section-label > .bp3-heading, .bp3-running-text h6 {
  font-size: 14px;
  line-height: 16px; }

/*
UI text

Markup:
<div class="{{.modifier}}">
  More than a decade ago, we set out to create products that would transform
  the way organizations use their data. Today, our products are deployed at
  the most critical government, commercial, and non-profit institutions in
  the world to solve problems we hadn’t even dreamed of back then.
</div>

.bp3-ui-text - Default Blueprint font styles, applied to the `<body>` tag and available as a class for nested resets.
.bp3-monospace-text - Use a monospace font (ideal for code).
.bp3-running-text - Increase line height ideal for longform text. See [Running text](#core/typography.running-text) below for additional features.
.bp3-text-large - Use a larger font size.
.bp3-text-small - Use a smaller font size.
.bp3-text-muted - Change text color to a gentler gray.
.bp3-text-disabled - Change text color to a transparent, faded gray.
.bp3-text-overflow-ellipsis - Truncate a single line of text with an ellipsis if it overflows its container.

Styleguide ui-text
*/
.bp3-ui-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none; }

.bp3-monospace-text {
  font-family: monospace;
  text-transform: none; }

.bp3-text-muted {
  color: #5c7080; }
  .bp3-dark .bp3-text-muted {
    color: #a7b6c2; }

.bp3-text-disabled {
  color: rgba(92, 112, 128, 0.6); }
  .bp3-dark .bp3-text-disabled {
    color: rgba(167, 182, 194, 0.6); }

.bp3-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

/*
Running text

Markup:
<div class="@ns-running-text {{.modifier}}">
  <p>
    We build products that make people better at their most important
    work — the kind of work you read about on the front page of the
    newspaper, not just the technology section.
  </p>
  <ul>
    <li>Item the <code>first</code>.</li>
    <li>Item the <strong>second</strong></code>.</li>
    <li>Item the <a href="#core/typography.running-text">third</a>.</li>
  </ul>
  <h3>Scale, Speed, Agility</h3>
  <p>
    A successful data transformation requires the whole organization — users, the IT shop, and
    leadership — to operate in lockstep. With Foundry, the enterprise comes together to
    transform the organization and turn data into a competitive advantage.
  </p>
</div>

.bp3-text-large - Use larger font size.

Styleguide running-text
*/
.bp3-running-text {
  font-size: 14px;
  line-height: 1.5; }
  .bp3-running-text h1 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h1 {
      color: #f5f8fa; }
  .bp3-running-text h2 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h2 {
      color: #f5f8fa; }
  .bp3-running-text h3 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h3 {
      color: #f5f8fa; }
  .bp3-running-text h4 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h4 {
      color: #f5f8fa; }
  .bp3-running-text h5 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h5 {
      color: #f5f8fa; }
  .bp3-running-text h6 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h6 {
      color: #f5f8fa; }
  .bp3-running-text hr {
    border: none;
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    margin: 20px 0; }
    .bp3-dark .bp3-running-text hr {
      border-color: rgba(255, 255, 255, 0.15); }
  .bp3-running-text p {
    margin: 0 0 10px;
    padding: 0; }

.bp3-text-large {
  font-size: 16px; }

.bp3-text-small, .bp3-form-group > label.bp3-label, .at-sidebar-stepper.sidebar .at-sidebar__content .thumb-title, .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-color-options > .at-button {
  font-size: 12px; }

/*
Links

Simply use an `<a href="">` tag as you normally would. No class is necessary for Blueprint styles.
Links are underlined only when hovered.

Putting an icon inside a link will cause it to inherit the link's text color.

Styleguide typography.links
*/
a {
  color: #106ba3;
  text-decoration: none; }
  a:hover {
    color: #106ba3;
    cursor: pointer;
    text-decoration: underline; }
  a .bp3-icon, a .bp3-icon-standard, a .bp3-icon-large {
    color: inherit; }
  a code,
  .bp3-dark a code {
    color: inherit; }
  .bp3-dark a,
  .bp3-dark a:hover {
    color: #48aff0; }
    .bp3-dark a .bp3-icon, .bp3-dark a .bp3-icon-standard, .bp3-dark a .bp3-icon-large,
    .bp3-dark a:hover .bp3-icon,
    .bp3-dark a:hover .bp3-icon-standard,
    .bp3-dark a:hover .bp3-icon-large {
      color: inherit; }

/*
Preformatted text

Markup:
<div>
  <p>Use the <code class="@ns-code">&lt;code></code> tag for snippets of code.</p>
  <pre class="@ns-code-block">Use the &lt;pre> tag for blocks of code.</pre>
  <pre class="@ns-code-block"><code>// code sample
export function hasModifier(
  modifiers: ts.ModifiersArray,
  ...modifierKinds: ts.SyntaxKind[],
) {
  if (modifiers == null || modifierKinds == null) {
    return false;
  }
  return modifiers.some(m => modifierKinds.some(k => m.kind === k));
}</code></pre>
</div>

Styleguide preformatted
*/
.bp3-running-text code, .bp3-code {
  font-family: monospace;
  text-transform: none;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  color: #5c7080;
  font-size: smaller;
  padding: 2px 5px; }
  .bp3-dark .bp3-running-text code, .bp3-running-text .bp3-dark code, .bp3-dark .bp3-code {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #a7b6c2; }
  .bp3-running-text a > code, a > .bp3-code {
    color: #00D092;
    color: var(--brand3); }
    .bp3-dark .bp3-running-text a > code, .bp3-running-text .bp3-dark a > code, .bp3-dark a > .bp3-code {
      color: inherit; }

.bp3-running-text pre, .bp3-code-block {
  font-family: monospace;
  text-transform: none;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  color: #182026;
  display: block;
  font-size: 13px;
  line-height: 1.4;
  margin: 10px 0;
  padding: 13px 15px 12px;
  word-break: break-all;
  word-wrap: break-word; }
  .bp3-dark .bp3-running-text pre, .bp3-running-text .bp3-dark pre, .bp3-dark .bp3-code-block {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }
  .bp3-running-text pre > code, .bp3-code-block > code {
    background: none;
    box-shadow: none;
    color: inherit;
    font-size: inherit;
    padding: 0; }

.bp3-running-text kbd, .bp3-key {
  align-items: center;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #5c7080;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 24px;
  min-width: 24px;
  padding: 3px 6px;
  vertical-align: middle; }
  .bp3-running-text kbd .bp3-icon, .bp3-key .bp3-icon, .bp3-running-text kbd .bp3-icon-standard, .bp3-key .bp3-icon-standard, .bp3-running-text kbd .bp3-icon-large, .bp3-key .bp3-icon-large {
    margin-right: 5px; }
  .bp3-dark .bp3-running-text kbd, .bp3-running-text .bp3-dark kbd, .bp3-dark .bp3-key {
    background: #424A50;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #a7b6c2; }

/*
Block quotes

Markup:
<blockquote class="@ns-blockquote">
  Premium Aerotec is a key supplier for Airbus, producing 30 million parts per year,
  which is huge complexity. Skywise helps us manage all the production steps.
  It gives Airbus much better visibility into where the product is in the supply chain,
  and it lets us immediately see our weak points so we can react on the spot.
</blockquote>

Styleguide blockquote
*/
.bp3-running-text blockquote, .bp3-blockquote {
  border-left: solid 4px rgba(176, 181, 185, 0.5);
  margin: 0 0 10px;
  padding: 0 20px; }
  .bp3-dark .bp3-running-text blockquote, .bp3-running-text .bp3-dark blockquote, .bp3-dark .bp3-blockquote {
    border-color: rgba(124, 133, 139, 0.5); }

/*
Lists

Markup:
<ol class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>
    Item the third
    <ul class="{{.modifier}}">
      <li>Item the fourth</li>
      <li>Item the fifth</li>
    </ul>
  </li>
</ol>

.bp3-list - Add a little spacing between items for readability.
.bp3-list-unstyled - Remove all list styling (including indicators) so you can add your own.

Styleguide lists
*/
.bp3-running-text ul,
.bp3-running-text ol, .bp3-list {
  margin: 10px 0;
  padding-left: 30px; }
  .bp3-running-text ul li:not(:last-child), .bp3-running-text ol li:not(:last-child), .bp3-list li:not(:last-child) {
    margin-bottom: 5px; }
  .bp3-running-text ul ol, .bp3-running-text ol ol, .bp3-list ol,
  .bp3-running-text ul ul,
  .bp3-running-text ol ul,
  .bp3-list ul {
    margin-top: 5px; }

.bp3-list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0; }
  .bp3-list-unstyled li {
    padding: 0; }

/*
Right-to-left text

Markup:
<h5 class="@ns-heading">Arabic:</h5>
<p class="@ns-rtl">
  لكل لأداء بمحاولة من. مدينة الواقعة يبق أي, وإعلان وقوعها، حول كل, حدى عجّل مشروط الخاسرة قد.
  من الذود تكبّد بين, و لها واحدة الأراضي. عل الصفحة والروسية يتم, أي للحكومة استعملت شيء. أم وصل زهاء اليا
</p>
<h5 class="@ns-heading">Hebrew:</h5>
<p class="@ns-rtl">
  כדי על עזרה יידיש הבהרה, מלא באגים טכניים דת. תנך או ברית ביולי. כתב בה הטבע למנוע, דת כלים פיסיקה החופשית זכר.
  מתן החלל מאמרשיחהצפה ב. הספרות אנציקלופדיה אם זכר, על שימושי שימושיים תאולוגיה עזה
</p>

Styleguide rtl
*/
.bp3-rtl {
  text-align: right; }

.bp3-dark {
  color: #f5f8fa; }

:focus {
  outline: rgba(19, 124, 189, 0.6) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.bp3-focus-disabled :focus {
  /* stylelint-disable declaration-no-important */
  outline: none !important; }
  .bp3-focus-disabled :focus ~ .bp3-control-indicator {
    outline: none !important; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-alert {
  max-width: 400px;
  padding: 20px; }

.bp3-alert-body {
  display: flex; }
  .bp3-alert-body .bp3-icon {
    font-size: 40px;
    margin-right: 20px;
    margin-top: 0; }

.bp3-alert-contents {
  word-break: break-word; }

.bp3-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px; }
  .bp3-alert-footer .bp3-button {
    margin-left: 10px; }

/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Breadcrumbs

Markup:
<ul class="bp3-breadcrumbs">
  <li><a class="bp3-breadcrumbs-collapsed" href="#"></a></li>
  <li><a class="bp3-breadcrumb bp3-disabled">Folder one</a></li>
  <li><a class="bp3-breadcrumb" href="#">Folder two</a></li>
  <li><a class="bp3-breadcrumb" href="#">Folder three</a></li>
  <li><span class="bp3-breadcrumb bp3-breadcrumb-current">File</span></li>
</ul>

Styleguide breadcrumbs
*/
.bp3-breadcrumbs {
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  list-style: none;
  margin: 0;
  padding: 0; }
  .bp3-breadcrumbs > li {
    align-items: center;
    display: flex; }
    .bp3-breadcrumbs > li::after {
      background: svg-icon("16px/chevron-right.svg", (path: (fill: #5c7080)));
      content: "";
      display: block;
      height: 16px;
      margin: 0 5px;
      width: 16px; }
    .bp3-breadcrumbs > li:last-of-type::after {
      display: none; }

.bp3-breadcrumb,
.bp3-breadcrumb-current,
.bp3-breadcrumbs-collapsed {
  align-items: center;
  display: inline-flex;
  font-size: 16px; }

.bp3-breadcrumb,
.bp3-breadcrumbs-collapsed {
  color: #5c7080; }

.bp3-breadcrumb:hover {
  text-decoration: none; }

.bp3-breadcrumb.bp3-disabled {
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed; }

.bp3-breadcrumb .bp3-icon {
  margin-right: 5px; }

.bp3-breadcrumb-current {
  color: inherit;
  font-weight: 600; }
  .bp3-breadcrumb-current .bp3-input {
    font-size: inherit;
    font-weight: inherit;
    vertical-align: baseline; }

.bp3-breadcrumbs-collapsed {
  background: #D3D8DB;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 2px;
  padding: 1px 5px;
  vertical-align: text-bottom; }
  .bp3-breadcrumbs-collapsed::before {
    background: svg-icon("16px/more.svg", (circle: (fill: #5c7080))) center no-repeat;
    content: "";
    display: block;
    height: 16px;
    width: 16px; }
  .bp3-breadcrumbs-collapsed:hover {
    background: #C7CBCE;
    color: #182026;
    text-decoration: none; }

.bp3-dark .bp3-breadcrumb,
.bp3-dark .bp3-breadcrumbs-collapsed {
  color: #a7b6c2; }

.bp3-dark .bp3-breadcrumbs > li::after {
  color: #a7b6c2; }

.bp3-dark .bp3-breadcrumb.bp3-disabled {
  color: rgba(167, 182, 194, 0.6); }

.bp3-dark .bp3-breadcrumb-current {
  color: #f5f8fa; }

.bp3-dark .bp3-breadcrumbs-collapsed {
  background: rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-breadcrumbs-collapsed:hover {
    background: rgba(16, 22, 26, 0.6);
    color: #f5f8fa; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button

Markup:
<a role="button" class="bp3-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="bp3-button bp3-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.bp3-active - Active appearance
.bp3-disabled - Disabled appearance
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-minimal - More subtle appearance
.bp3-outlined - Subtle yet structured appearance
.bp3-large - Larger size
.bp3-small - Smaller size
.bp3-fill - Fill parent container

Styleguide button
*/
.bp3-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px; }
  .bp3-button > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-button > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-button::before,
  .bp3-button > * {
    margin-right: 7px; }
  .bp3-button:empty::before,
  .bp3-button > :last-child {
    margin-right: 0; }
  .bp3-button:empty {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important; }
  .bp3-button:disabled, .bp3-button.bp3-disabled {
    cursor: not-allowed; }
  .bp3-button.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-button.bp3-align-right,
  .bp3-align-right .bp3-button {
    text-align: right; }
  .bp3-button.bp3-align-left,
  .bp3-align-left .bp3-button {
    text-align: left; }
  .bp3-button:not([class*="bp3-intent-"]) {
    background-color: #F7F8F8;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    color: #182026; }
    .bp3-button:not([class*="bp3-intent-"]):hover {
      background-clip: padding-box;
      background-color: #EFF0F2;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
    .bp3-button:not([class*="bp3-intent-"]):active, .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      background-color: #DDE0E3;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
      background-color: rgba(211, 216, 219, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(92, 112, 128, 0.6);
      cursor: not-allowed;
      outline: none; }
      .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active:hover, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active:hover {
        background: rgba(211, 216, 219, 0.7); }
  .bp3-button.bp3-intent-primary {
    background-color: #00D092;
    background-color: var(--brand3);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-primary:hover, .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-primary:hover {
      background-color: #106ba3;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
      background-color: #0e5a8a;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-primary:disabled, .bp3-button.bp3-intent-primary.bp3-disabled {
      background-color: rgba(#00D092, 0.5);
      background-color: rgba(var(--brand3), 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-success {
    background-color: #07ae9a;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-success:hover, .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-success:hover {
      background-color: #0d8050;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
      background-color: #0a6640;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-success:disabled, .bp3-button.bp3-intent-success.bp3-disabled {
      background-color: rgba(7, 174, 154, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-warning {
    background-color: #d9822b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-warning:hover, .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-warning:hover {
      background-color: #bf7326;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
      background-color: #a66321;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-warning:disabled, .bp3-button.bp3-intent-warning.bp3-disabled {
      background-color: rgba(217, 130, 43, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-danger {
    background-color: #DB3737;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-danger:hover, .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-danger:hover {
      background-color: #c23030;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
      background-color: #a82a2a;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-danger:disabled, .bp3-button.bp3-intent-danger.bp3-disabled {
      background-color: rgba(219, 55, 55, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
    stroke: #ffffff; }
  .bp3-button.bp3-large,
  .bp3-large .bp3-button {
    min-height: 40px;
    min-width: 40px;
    font-size: 16px;
    padding: 5px 15px; }
    .bp3-button.bp3-large::before,
    .bp3-button.bp3-large > *,
    .bp3-large .bp3-button::before,
    .bp3-large .bp3-button > * {
      margin-right: 10px; }
    .bp3-button.bp3-large:empty::before,
    .bp3-button.bp3-large > :last-child,
    .bp3-large .bp3-button:empty::before,
    .bp3-large .bp3-button > :last-child {
      margin-right: 0; }
  .bp3-button.bp3-small,
  .bp3-small .bp3-button {
    min-height: 24px;
    min-width: 24px;
    padding: 0 7px; }
  .bp3-button.bp3-loading {
    position: relative; }
    .bp3-button.bp3-loading[class*="bp3-icon-"]::before {
      visibility: hidden; }
    .bp3-button.bp3-loading .bp3-button-spinner {
      margin: 0;
      position: absolute; }
    .bp3-button.bp3-loading > :not(.bp3-button-spinner) {
      visibility: hidden; }
  .bp3-button[class*="bp3-icon-"]::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #5c7080; }
  .bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large {
    color: #5c7080; }
    .bp3-button .bp3-icon.bp3-align-right, .bp3-button .bp3-icon-standard.bp3-align-right, .bp3-button .bp3-icon-large.bp3-align-right {
      margin-left: 7px; }
  .bp3-button .bp3-icon:first-child:last-child,
  .bp3-button .bp3-spinner + .bp3-icon:last-child {
    margin: 0 -7px; }
  .bp3-dark .bp3-button:not([class*="bp3-intent-"]) {
    background-color: #424A50;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover, .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      color: #f5f8fa; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover {
      background-color: #383F45;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      background-color: #252A2E;
      background-image: none;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
      background-color: rgba(66, 74, 80, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(167, 182, 194, 0.6); }
      .bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active {
        background: rgba(66, 74, 80, 0.7); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-button-spinner .bp3-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #939A9F; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
      color: #a7b6c2; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon:not([class*="bp3-intent-"]), .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard:not([class*="bp3-intent-"]), .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large:not([class*="bp3-intent-"]) {
      color: #a7b6c2; }
  .bp3-dark .bp3-button[class*="bp3-intent-"] {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:hover {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:active, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-active {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:disabled, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-disabled {
      background-image: none;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.3); }
    .bp3-dark .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
      stroke: #939A9F; }
  .bp3-button:disabled::before,
  .bp3-button:disabled .bp3-icon, .bp3-button:disabled .bp3-icon-standard, .bp3-button:disabled .bp3-icon-large, .bp3-button.bp3-disabled::before,
  .bp3-button.bp3-disabled .bp3-icon, .bp3-button.bp3-disabled .bp3-icon-standard, .bp3-button.bp3-disabled .bp3-icon-large, .bp3-button[class*="bp3-intent-"]::before,
  .bp3-button[class*="bp3-intent-"] .bp3-icon, .bp3-button[class*="bp3-intent-"] .bp3-icon-standard, .bp3-button[class*="bp3-intent-"] .bp3-icon-large {
    /* stylelint-disable-next-line declaration-no-important */
    color: inherit !important; }
  .bp3-button.bp3-minimal {
    background: none;
    box-shadow: none; }
    .bp3-button.bp3-minimal:hover {
      background: rgba(176, 181, 185, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none; }
    .bp3-button.bp3-minimal:active, .bp3-button.bp3-minimal.bp3-active {
      background: rgba(124, 133, 139, 0.3);
      box-shadow: none;
      color: #182026; }
    .bp3-button.bp3-minimal:disabled, .bp3-button.bp3-minimal:disabled:hover, .bp3-button.bp3-minimal.bp3-disabled, .bp3-button.bp3-minimal.bp3-disabled:hover {
      background: none;
      color: rgba(92, 112, 128, 0.6);
      cursor: not-allowed; }
      .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
        background: rgba(124, 133, 139, 0.3); }
    .bp3-dark .bp3-button.bp3-minimal {
      background: none;
      box-shadow: none;
      color: inherit; }
      .bp3-dark .bp3-button.bp3-minimal:hover, .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
        background: none;
        box-shadow: none; }
      .bp3-dark .bp3-button.bp3-minimal:hover {
        background: rgba(147, 154, 159, 0.15); }
      .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
        background: rgba(147, 154, 159, 0.3);
        color: #f5f8fa; }
      .bp3-dark .bp3-button.bp3-minimal:disabled, .bp3-dark .bp3-button.bp3-minimal:disabled:hover, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover {
        background: none;
        color: rgba(167, 182, 194, 0.6);
        cursor: not-allowed; }
        .bp3-dark .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
          background: rgba(147, 154, 159, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-primary {
      color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:hover, .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
        background: none;
        box-shadow: none;
        color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #106ba3; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary {
        color: #48aff0; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(19, 124, 189, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-success {
      color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:hover, .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
        background: none;
        box-shadow: none;
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:hover, .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
        background: none;
        box-shadow: none;
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-danger {
      color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:hover, .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
        background: none;
        box-shadow: none;
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
  .bp3-button.bp3-outlined {
    background: none;
    box-shadow: none;
    border: 1px solid rgba(24, 32, 38, 0.2);
    box-sizing: border-box; }
    .bp3-button.bp3-outlined:hover {
      background: rgba(176, 181, 185, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none; }
    .bp3-button.bp3-outlined:active, .bp3-button.bp3-outlined.bp3-active {
      background: rgba(124, 133, 139, 0.3);
      box-shadow: none;
      color: #182026; }
    .bp3-button.bp3-outlined:disabled, .bp3-button.bp3-outlined:disabled:hover, .bp3-button.bp3-outlined.bp3-disabled, .bp3-button.bp3-outlined.bp3-disabled:hover {
      background: none;
      color: rgba(92, 112, 128, 0.6);
      cursor: not-allowed; }
      .bp3-button.bp3-outlined:disabled.bp3-active, .bp3-button.bp3-outlined:disabled:hover.bp3-active, .bp3-button.bp3-outlined.bp3-disabled.bp3-active, .bp3-button.bp3-outlined.bp3-disabled:hover.bp3-active {
        background: rgba(124, 133, 139, 0.3); }
    .bp3-dark .bp3-button.bp3-outlined {
      background: none;
      box-shadow: none;
      color: inherit; }
      .bp3-dark .bp3-button.bp3-outlined:hover, .bp3-dark .bp3-button.bp3-outlined:active, .bp3-dark .bp3-button.bp3-outlined.bp3-active {
        background: none;
        box-shadow: none; }
      .bp3-dark .bp3-button.bp3-outlined:hover {
        background: rgba(147, 154, 159, 0.15); }
      .bp3-dark .bp3-button.bp3-outlined:active, .bp3-dark .bp3-button.bp3-outlined.bp3-active {
        background: rgba(147, 154, 159, 0.3);
        color: #f5f8fa; }
      .bp3-dark .bp3-button.bp3-outlined:disabled, .bp3-dark .bp3-button.bp3-outlined:disabled:hover, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled:hover {
        background: none;
        color: rgba(167, 182, 194, 0.6);
        cursor: not-allowed; }
        .bp3-dark .bp3-button.bp3-outlined:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined:disabled:hover.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled:hover.bp3-active {
          background: rgba(147, 154, 159, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-primary {
      color: #106ba3; }
      .bp3-button.bp3-outlined.bp3-intent-primary:hover, .bp3-button.bp3-outlined.bp3-intent-primary:active, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-active {
        background: none;
        box-shadow: none;
        color: #106ba3; }
      .bp3-button.bp3-outlined.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .bp3-button.bp3-outlined.bp3-intent-primary:active, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-primary:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #106ba3; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary {
        color: #48aff0; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(19, 124, 189, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-success {
      color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:hover, .bp3-button.bp3-outlined.bp3-intent-success:active, .bp3-button.bp3-outlined.bp3-intent-success.bp3-active {
        background: none;
        box-shadow: none;
        color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:active, .bp3-button.bp3-outlined.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-success:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:hover, .bp3-button.bp3-outlined.bp3-intent-warning:active, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-active {
        background: none;
        box-shadow: none;
        color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:active, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-warning:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-danger {
      color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:hover, .bp3-button.bp3-outlined.bp3-intent-danger:active, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-active {
        background: none;
        box-shadow: none;
        color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:active, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-danger:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
    .bp3-button.bp3-outlined:disabled, .bp3-button.bp3-outlined.bp3-disabled, .bp3-button.bp3-outlined:disabled:hover, .bp3-button.bp3-outlined.bp3-disabled:hover {
      border-color: rgba(92, 112, 128, 0.1); }
    .bp3-dark .bp3-button.bp3-outlined {
      border-color: rgba(255, 255, 255, 0.4); }
      .bp3-dark .bp3-button.bp3-outlined:disabled, .bp3-dark .bp3-button.bp3-outlined:disabled:hover, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled:hover {
        border-color: rgba(255, 255, 255, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-primary {
      border-color: rgba(16, 107, 163, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
        border-color: rgba(16, 107, 163, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary {
        border-color: rgba(72, 175, 240, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
          border-color: rgba(72, 175, 240, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-success {
      border-color: rgba(13, 128, 80, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
        border-color: rgba(13, 128, 80, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success {
        border-color: rgba(61, 204, 145, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
          border-color: rgba(61, 204, 145, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-warning {
      border-color: rgba(191, 115, 38, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
        border-color: rgba(191, 115, 38, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning {
        border-color: rgba(255, 179, 102, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
          border-color: rgba(255, 179, 102, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-danger {
      border-color: rgba(194, 48, 48, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
        border-color: rgba(194, 48, 48, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger {
        border-color: rgba(255, 115, 115, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
          border-color: rgba(255, 115, 115, 0.2); }

a.bp3-button {
  text-align: center;
  text-decoration: none;
  transition: none; }
  a.bp3-button, a.bp3-button:hover, a.bp3-button:active {
    color: #182026; }
  a.bp3-button.bp3-disabled {
    color: rgba(92, 112, 128, 0.6); }

.bp3-button-text {
  flex: 0 1 auto; }

.bp3-button.bp3-align-left .bp3-button-text, .bp3-button.bp3-align-right .bp3-button-text,
.bp3-button-group.bp3-align-left .bp3-button-text,
.bp3-button-group.bp3-align-right .bp3-button-text {
  flex: 1 1 auto; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button groups

Markup:
<div class="bp3-button-group {{.modifier}}">
  <button type="button" class="bp3-button bp3-intent-success">Save</button>
  <button type="button" class="bp3-button bp3-intent-success bp3-icon-caret-down"></button>
</div>
<div class="bp3-button-group {{.modifier}}">
  <a class="bp3-button bp3-icon-database" tabindex="0" role="button">Queries</a>
  <a class="bp3-button bp3-icon-function" tabindex="0" role="button">Functions</a>
  <a class="bp3-button" tabindex="0" role="button">
    Options <span class="bp3-icon-standard bp3-icon-caret-down bp3-align-right"></span>
  </a>
</div>
<div class="bp3-button-group {{.modifier}}">
  <a class="bp3-button bp3-icon-chart" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-control" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-graph" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-camera" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-map" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-code" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-th" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-time" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-compressed" tabindex="0" role="button"></a>
</div>

.bp3-fill - Buttons expand equally to fill container
.bp3-large - Use large buttons
.bp3-minimal - Use minimal buttons
.bp3-vertical - Vertical layout

Styleguide button-group
*/
.bp3-button-group {
  display: inline-flex;
  /*
  Responsive

  Markup:
  <div class="bp3-button-group bp3-large bp3-fill">
    <a class="bp3-button bp3-intent-primary bp3-fixed" tabindex="0" role="button">Start</a>
    <a class="bp3-button bp3-intent-primary" tabindex="0" role="button">Left</a>
    <a class="bp3-button bp3-intent-primary bp3-active" tabindex="0" role="button">Middle</a>
    <a class="bp3-button bp3-intent-primary" tabindex="0" role="button">Right</a>
    <a class="bp3-button bp3-intent-primary bp3-fixed" tabindex="0" role="button">End</a>
  </div>
  <br />
  <div class="bp3-button-group bp3-fill">
    <button class="bp3-button bp3-icon-arrow-left"></button>
    <button class="bp3-button bp3-fill">Middle</button>
    <button class="bp3-button bp3-icon-arrow-right"></button>
  </div>

  Styleguide button-group}-fill
  */
  /*
  Vertical button groups

  Markup:
  <div class="bp3-button-group bp3-vertical">
    <a class="bp3-button bp3-icon-search-template" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-in" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-out" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-to-fit" role="button" tabindex="0"></a>
  </div>
  <div class="bp3-button-group bp3-vertical">
    <button type="button" class="bp3-button bp3-active">Home</button>
    <button type="button" class="bp3-button">Pages</button>
    <button type="button" class="bp3-button">Blog</button>
    <button type="button" class="bp3-button">Calendar</button>
  </div>
  <div class="bp3-button-group bp3-vertical bp3-align-left bp3-large">
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-document">Text</button>
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-media bp3-active">Media</button>
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-link">Sources</button>
  </div>

  Styleguide button-group-vertical
  */ }
  .bp3-button-group .bp3-button {
    flex: 0 0 auto;
    position: relative;
    z-index: 4; }
    .bp3-button-group .bp3-button:focus {
      z-index: 5; }
    .bp3-button-group .bp3-button:hover {
      z-index: 6; }
    .bp3-button-group .bp3-button:active, .bp3-button-group .bp3-button.bp3-active {
      z-index: 7; }
    .bp3-button-group .bp3-button:disabled, .bp3-button-group .bp3-button.bp3-disabled {
      z-index: 3; }
    .bp3-button-group .bp3-button[class*="bp3-intent-"] {
      z-index: 9; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:focus {
        z-index: 10; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:hover {
        z-index: 11; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:active, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-active {
        z-index: 12; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:disabled, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-disabled {
        z-index: 8; }
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:first-child) .bp3-button,
  .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px; }
  .bp3-button-group.bp3-minimal .bp3-button {
    background: none;
    box-shadow: none; }
    .bp3-button-group.bp3-minimal .bp3-button:hover {
      background: rgba(176, 181, 185, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none; }
    .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
      background: rgba(124, 133, 139, 0.3);
      box-shadow: none;
      color: #182026; }
    .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
      background: none;
      color: rgba(92, 112, 128, 0.6);
      cursor: not-allowed; }
      .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
        background: rgba(124, 133, 139, 0.3); }
    .bp3-dark .bp3-button-group.bp3-minimal .bp3-button {
      background: none;
      box-shadow: none;
      color: inherit; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
        background: none;
        box-shadow: none; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover {
        background: rgba(147, 154, 159, 0.15); }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
        background: rgba(147, 154, 159, 0.3);
        color: #f5f8fa; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
        background: none;
        color: rgba(167, 182, 194, 0.6);
        cursor: not-allowed; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
          background: rgba(147, 154, 159, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
      color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
        background: none;
        box-shadow: none;
        color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.15);
        color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #106ba3; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(16, 107, 163, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #106ba3; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
        color: #48aff0; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
          background: rgba(19, 124, 189, 0.2);
          color: #48aff0; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
          background: rgba(19, 124, 189, 0.3);
          color: #48aff0; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(72, 175, 240, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(19, 124, 189, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
      color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
        background: none;
        box-shadow: none;
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
        background: none;
        box-shadow: none;
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
      color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
        background: none;
        box-shadow: none;
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
  .bp3-button-group .bp3-popover-wrapper,
  .bp3-button-group .bp3-popover-target {
    display: flex;
    flex: 1 1 auto; }
  .bp3-button-group.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-button-group .bp3-button.bp3-fill,
  .bp3-button-group.bp3-fill .bp3-button:not(.bp3-fixed) {
    flex: 1 1 auto; }
  .bp3-button-group.bp3-vertical {
    align-items: stretch;
    flex-direction: column;
    vertical-align: top; }
    .bp3-button-group.bp3-vertical.bp3-fill {
      height: 100%;
      width: unset; }
    .bp3-button-group.bp3-vertical .bp3-button {
      margin-right: 0 !important;
      /* stylelint-disable-line declaration-no-important */
      width: 100%; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:first-child .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:first-child {
      border-radius: 3px 3px 0 0; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:last-child .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:last-child {
      border-radius: 0 0 3px 3px; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:not(:last-child) {
      margin-bottom: -1px; }
  .bp3-button-group.bp3-align-left .bp3-button {
    text-align: left; }
  .bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    margin-right: 1px; }
  .bp3-dark .bp3-button-group.bp3-vertical > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-dark .bp3-button-group.bp3-vertical > .bp3-button:not(:last-child) {
    margin-bottom: 1px; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Callout

Markup:
<div class="bp3-callout {{.modifier}}">
  <h4 class="bp3-heading">Callout Heading</h4>
  It's dangerous to go alone! Take this.
</div>

.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger  - Danger intent
.bp3-icon-info-sign - With an icon

Styleguide callout
*/
.bp3-callout {
  font-size: 14px;
  line-height: 1.5;
  background-color: rgba(147, 154, 159, 0.15);
  border-radius: 3px;
  padding: 10px 12px 9px;
  position: relative;
  width: 100%; }
  .bp3-callout[class*="bp3-icon-"] {
    padding-left: 40px; }
    .bp3-callout[class*="bp3-icon-"]::before {
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      color: #5c7080;
      left: 10px;
      position: absolute;
      top: 10px; }
  .bp3-callout.bp3-callout-icon {
    padding-left: 40px; }
    .bp3-callout.bp3-callout-icon > .bp3-icon:first-child {
      color: #5c7080;
      left: 10px;
      position: absolute;
      top: 10px; }
  .bp3-callout .bp3-heading {
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 0; }
    .bp3-callout .bp3-heading:last-child {
      margin-bottom: 0; }
  .bp3-dark .bp3-callout {
    background-color: rgba(147, 154, 159, 0.2); }
    .bp3-dark .bp3-callout[class*="bp3-icon-"]::before {
      color: #a7b6c2; }
  .bp3-callout.bp3-intent-primary {
    background-color: rgba(19, 124, 189, 0.15); }
    .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-primary .bp3-heading {
      color: #106ba3; }
    .bp3-dark .bp3-callout.bp3-intent-primary {
      background-color: rgba(19, 124, 189, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-primary .bp3-heading {
        color: #48aff0; }
  .bp3-callout.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15); }
    .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-success .bp3-heading {
      color: #0d8050; }
    .bp3-dark .bp3-callout.bp3-intent-success {
      background-color: rgba(15, 153, 96, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-success .bp3-heading {
        color: #3dcc91; }
  .bp3-callout.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15); }
    .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-warning .bp3-heading {
      color: #bf7326; }
    .bp3-dark .bp3-callout.bp3-intent-warning {
      background-color: rgba(217, 130, 43, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-warning .bp3-heading {
        color: #ffb366; }
  .bp3-callout.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15); }
    .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-danger .bp3-heading {
      color: #c23030; }
    .bp3-dark .bp3-callout.bp3-intent-danger {
      background-color: rgba(219, 55, 55, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-danger .bp3-heading {
        color: #ff7373; }
  .bp3-running-text .bp3-callout {
    margin: 20px 0; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Cards

Markup:
<div class="bp3-card {{.modifier}}">
  We build products that make people better at their most important work.
</div>

.bp3-elevation-0 - Ground floor. This level provides just a gentle border shadow.
.bp3-elevation-1 - First. Subtle drop shadow intended for static containers.
.bp3-elevation-2 - Second. An even stronger shadow, moving on up.
.bp3-elevation-3 - Third. For containers overlaying content temporarily.
.bp3-elevation-4 - Fourth. The strongest shadow, usually for overlay containers on top of backdrops.
.bp3-interactive - On hover, increase elevation and use pointer cursor.

Styleguide card
*/
.bp3-card {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  padding: 20px;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-card.bp3-dark,
  .bp3-dark .bp3-card {
    background-color: #383F45;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.bp3-elevation-0 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }
  .bp3-elevation-0.bp3-dark,
  .bp3-dark .bp3-elevation-0 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.bp3-elevation-1 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-1.bp3-dark,
  .bp3-dark .bp3-elevation-1 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-elevation-2 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-2.bp3-dark,
  .bp3-dark .bp3-elevation-2 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4); }

.bp3-elevation-3 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-3.bp3-dark,
  .bp3-dark .bp3-elevation-3 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-elevation-4 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-4.bp3-dark,
  .bp3-dark .bp3-elevation-4 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4); }

.bp3-card.bp3-interactive:hover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  cursor: pointer; }
  .bp3-card.bp3-interactive:hover.bp3-dark,
  .bp3-dark .bp3-card.bp3-interactive:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-card.bp3-interactive:active {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  opacity: 0.9;
  transition-duration: 0; }
  .bp3-card.bp3-interactive:active.bp3-dark,
  .bp3-dark .bp3-card.bp3-interactive:active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-collapse {
  height: 0;
  overflow-y: hidden;
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-collapse .bp3-collapse-body {
    transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-collapse .bp3-collapse-body[aria-hidden="true"] {
      display: none; }

.bp3-context-menu .bp3-popover-target {
  display: block; }

.bp3-context-menu-popover-target {
  position: fixed; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-divider {
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  margin: 5px; }
  .bp3-dark .bp3-divider {
    border-color: rgba(16, 22, 26, 0.4); }

/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Dialog

Markup:
<!-- this container element fills its parent and centers the .bp3-dialog within it -->
<div class="bp3-dialog-container">
  <div class="bp3-dialog">
    <div class="bp3-dialog-header">
      <span class="bp3-icon-large bp3-icon-inbox"></span>
      <h4 class="bp3-heading">Dialog header</h4>
      <button aria-label="Close" class="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"></button>
    </div>
    <div class="bp3-dialog-body">
      This dialog hasn't been wired up with any open or close interactions.
      It's just an example of markup and styles.
    </div>
    <div class="bp3-dialog-footer">
      <div class="bp3-dialog-footer-actions">
        <button type="button" class="bp3-button">Secondary button</button>
        <button type="submit" class="bp3-button bp3-intent-primary">Primary button</button>
      </div>
    </div>
  </div>
</div>

Styleguide dialog
*/
.bp3-dialog-container {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%; }
  .bp3-dialog-container.bp3-overlay-enter > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear > .bp3-dialog {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  .bp3-dialog-container.bp3-overlay-enter-active > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear-active > .bp3-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-dialog-container.bp3-overlay-exit > .bp3-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  .bp3-dialog-container.bp3-overlay-exit-active > .bp3-dialog {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }

.bp3-dialog {
  background: #EFF0F2;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
  pointer-events: all;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
  width: 500px; }
  .bp3-dialog:focus {
    outline: 0; }
  .bp3-dialog.bp3-dark,
  .bp3-dark .bp3-dialog {
    background: #293742;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }

.bp3-dialog-header {
  align-items: center;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  display: flex;
  flex: 0 0 auto;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 5px;
  z-index: 0; }
  .bp3-dialog-header .bp3-icon-large,
  .bp3-dialog-header .bp3-icon {
    color: #5c7080;
    flex: 0 0 auto;
    margin-right: 10px; }
  .bp3-dialog-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0; }
    .bp3-dialog-header .bp3-heading:last-child {
      margin-right: 20px; }
  .bp3-dark .bp3-dialog-header {
    background: #383F45;
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-dialog-header .bp3-icon-large,
    .bp3-dark .bp3-dialog-header .bp3-icon {
      color: #a7b6c2; }

.bp3-dialog-body {
  flex: 1 1 auto;
  line-height: 18px;
  margin: 20px; }

.bp3-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px; }

.bp3-dialog-footer-actions {
  display: flex;
  justify-content: flex-end; }
  .bp3-dialog-footer-actions .bp3-button {
    margin-left: 10px; }

/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-multistep-dialog-panels {
  display: flex; }
  .bp3-multistep-dialog-panels:first-child .bp3-dialog-step-container:first-child {
    border-radius: 6px 0 0 0; }
  .bp3-multistep-dialog-panels:first-child .bp3-multistep-dialog-right-panel {
    border-top-right-radius: 6px; }

.bp3-multistep-dialog-left-panel {
  display: flex;
  flex: 1 1;
  flex-direction: column; }
  .bp3-dark .bp3-multistep-dialog-left-panel {
    background: #252A2E; }

.bp3-multistep-dialog-right-panel {
  background-color: #F7F8F8;
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  border-radius: 0 0 6px 0;
  flex: 3 1;
  min-width: 0; }
  .bp3-dark .bp3-multistep-dialog-right-panel {
    background-color: #30363B;
    border-left: 1px solid rgba(16, 22, 26, 0.4); }

.bp3-multistep-dialog-footer {
  background-color: #ffffff;
  border-radius: 0 0 6px 0;
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 10px; }
  .bp3-dark .bp3-multistep-dialog-footer {
    background: #383F45;
    border-top: 1px solid rgba(16, 22, 26, 0.4); }
  .bp3-multistep-dialog-footer .bp3-dialog-footer-actions {
    flex-grow: 1; }

.bp3-dialog-step-container {
  background-color: #F7F8F8;
  border-bottom: 1px solid rgba(16, 22, 26, 0.15); }
  .bp3-dark .bp3-dialog-step-container {
    background: #30363B;
    border-bottom: 1px solid rgba(16, 22, 26, 0.4); }
  .bp3-dialog-step-container.bp3-dialog-step-viewed {
    background-color: #ffffff; }
    .bp3-dark .bp3-dialog-step-container.bp3-dialog-step-viewed {
      background: #383F45; }

.bp3-dialog-step {
  align-items: center;
  background-color: #F7F8F8;
  border-radius: 6px;
  cursor: not-allowed;
  display: flex;
  margin: 4px;
  padding: 6px 14px; }
  .bp3-dark .bp3-dialog-step {
    background: #30363B; }
  .bp3-dialog-step-viewed .bp3-dialog-step {
    background-color: #ffffff;
    cursor: pointer; }
    .bp3-dark .bp3-dialog-step-viewed .bp3-dialog-step {
      background: #383F45; }
  .bp3-dialog-step:hover {
    background-color: #F7F8F8; }
    .bp3-dark .bp3-dialog-step:hover {
      background: #30363B; }

.bp3-dialog-step-icon {
  align-items: center;
  background-color: rgba(92, 112, 128, 0.6);
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px; }
  .bp3-dark .bp3-dialog-step-icon {
    background-color: rgba(167, 182, 194, 0.6); }
  .bp3-active.bp3-dialog-step-viewed .bp3-dialog-step-icon {
    background-color: #2b95d6; }
  .bp3-dialog-step-viewed .bp3-dialog-step-icon {
    background-color: #939A9F; }

.bp3-dialog-step-title {
  color: rgba(92, 112, 128, 0.6);
  flex: 1 1;
  padding-left: 10px; }
  .bp3-dark .bp3-dialog-step-title {
    color: rgba(167, 182, 194, 0.6); }
  .bp3-active.bp3-dialog-step-viewed .bp3-dialog-step-title {
    color: #2b95d6; }
  .bp3-dialog-step-viewed:not(.bp3-active) .bp3-dialog-step-title {
    color: #182026; }
    .bp3-dark .bp3-dialog-step-viewed:not(.bp3-active) .bp3-dialog-step-title {
      color: #f5f8fa; }

/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-drawer {
  background: #ffffff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0; }
  .bp3-drawer:focus {
    outline: 0; }
  .bp3-drawer.bp3-position-top {
    height: 50%;
    left: 0;
    right: 0;
    top: 0; }
    .bp3-drawer.bp3-position-top.bp3-overlay-enter, .bp3-drawer.bp3-position-top.bp3-overlay-appear {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
    .bp3-drawer.bp3-position-top.bp3-overlay-enter-active, .bp3-drawer.bp3-position-top.bp3-overlay-appear-active {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-top.bp3-overlay-exit {
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    .bp3-drawer.bp3-position-top.bp3-overlay-exit-active {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-position-bottom {
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0; }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-enter, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear {
      -webkit-transform: translateY(100%);
              transform: translateY(100%); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-enter-active, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear-active {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-exit {
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-exit-active {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-position-left {
    bottom: 0;
    left: 0;
    top: 0;
    width: 50%; }
    .bp3-drawer.bp3-position-left.bp3-overlay-enter, .bp3-drawer.bp3-position-left.bp3-overlay-appear {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
    .bp3-drawer.bp3-position-left.bp3-overlay-enter-active, .bp3-drawer.bp3-position-left.bp3-overlay-appear-active {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-left.bp3-overlay-exit {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .bp3-drawer.bp3-position-left.bp3-overlay-exit-active {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-position-right {
    bottom: 0;
    right: 0;
    top: 0;
    width: 50%; }
    .bp3-drawer.bp3-position-right.bp3-overlay-enter, .bp3-drawer.bp3-position-right.bp3-overlay-appear {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
    .bp3-drawer.bp3-position-right.bp3-overlay-enter-active, .bp3-drawer.bp3-position-right.bp3-overlay-appear-active {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-right.bp3-overlay-exit {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .bp3-drawer.bp3-position-right.bp3-overlay-exit-active {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
  .bp3-position-right):not(.bp3-vertical) {
    bottom: 0;
    right: 0;
    top: 0;
    width: 50%; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-appear {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-appear-active {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-exit {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-exit-active {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
  .bp3-position-right).bp3-vertical {
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-appear {
      -webkit-transform: translateY(100%);
              transform: translateY(100%); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-appear-active {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-exit {
      -webkit-transform: translateY(0);
              transform: translateY(0); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-exit-active {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-dark,
  .bp3-dark .bp3-drawer {
    background: #383F45;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }

.bp3-drawer-header {
  align-items: center;
  border-radius: 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  display: flex;
  flex: 0 0 auto;
  min-height: 40px;
  padding: 5px;
  padding-left: 20px;
  position: relative; }
  .bp3-drawer-header .bp3-icon-large,
  .bp3-drawer-header .bp3-icon {
    color: #5c7080;
    flex: 0 0 auto;
    margin-right: 10px; }
  .bp3-drawer-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0; }
    .bp3-drawer-header .bp3-heading:last-child {
      margin-right: 20px; }
  .bp3-dark .bp3-drawer-header {
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-drawer-header .bp3-icon-large,
    .bp3-dark .bp3-drawer-header .bp3-icon {
      color: #a7b6c2; }

.bp3-drawer-body {
  flex: 1 1 auto;
  line-height: 18px;
  overflow: auto; }

.bp3-drawer-footer {
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  flex: 0 0 auto;
  padding: 10px 20px;
  position: relative; }
  .bp3-dark .bp3-drawer-footer {
    box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-editable-text {
  cursor: text;
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  white-space: nowrap; }
  .bp3-editable-text::before {
    bottom: -3px;
    left: -3px;
    position: absolute;
    right: -3px;
    top: -3px;
    border-radius: 3px;
    content: "";
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15);
    box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .bp3-editable-text.bp3-editable-text-editing::before {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-disabled::before {
    box-shadow: none; }
  .bp3-editable-text.bp3-intent-primary .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
    color: #137cbd; }
  .bp3-editable-text.bp3-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(19, 124, 189, 0.4); }
  .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-intent-success .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
    color: #0f9960; }
  .bp3-editable-text.bp3-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }
  .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-intent-warning .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
    color: #d9822b; }
  .bp3-editable-text.bp3-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }
  .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-intent-danger .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
    color: #db3737; }
  .bp3-editable-text.bp3-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }
  .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-dark .bp3-editable-text.bp3-editable-text-editing::before {
    background-color: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-disabled::before {
    box-shadow: none; }
  .bp3-dark .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
    color: #48aff0; }
  .bp3-dark .bp3-editable-text.bp3-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(72, 175, 240, 0), 0 0 0 0 rgba(72, 175, 240, 0), inset 0 0 0 1px rgba(72, 175, 240, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #48aff0, 0 0 0 3px rgba(72, 175, 240, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
    color: #3dcc91; }
  .bp3-dark .bp3-editable-text.bp3-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
    color: #ffb366; }
  .bp3-dark .bp3-editable-text.bp3-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
    color: #ff7373; }
  .bp3-dark .bp3-editable-text.bp3-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-editable-text-input,
.bp3-editable-text-content {
  color: inherit;
  display: inherit;
  font: inherit;
  letter-spacing: inherit;
  max-width: inherit;
  min-width: inherit;
  position: relative;
  resize: none;
  text-transform: inherit;
  vertical-align: top; }

.bp3-editable-text-input {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  white-space: pre-wrap;
  width: 100%; }
  .bp3-editable-text-input::-webkit-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-editable-text-input:-ms-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-editable-text-input::placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-editable-text-input:focus {
    outline: none; }
  .bp3-editable-text-input::-ms-clear {
    display: none; }

.bp3-editable-text-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }
  .bp3-editable-text-editing > .bp3-editable-text-content {
    left: 0;
    position: absolute;
    visibility: hidden; }
  .bp3-editable-text-placeholder > .bp3-editable-text-content {
    color: rgba(92, 112, 128, 0.6); }
    .bp3-dark .bp3-editable-text-placeholder > .bp3-editable-text-content {
      color: rgba(167, 182, 194, 0.6); }

.bp3-editable-text.bp3-multiline {
  display: block; }
  .bp3-editable-text.bp3-multiline .bp3-editable-text-content {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word; }

/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-divider {
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  margin: 5px; }
  .bp3-dark .bp3-divider {
    border-color: rgba(16, 22, 26, 0.4); }

/*
Control groups

Markup:
<div class="bp3-control-group">
  <button class="bp3-button bp3-icon-filter">Filter</button>
  <input type="text" class="bp3-input" placeholder="Find filters..." />
</div>
<div class="bp3-control-group">
  <div class="bp3-select">
    <select>
      <option selected>Filter...</option>
      <option value="1">Issues</option>
      <option value="2">Requests</option>
      <option value="3">Projects</option>
    </select>
  </div>
  <div class="bp3-input-group">
    <span class="bp3-icon bp3-icon-search"></span>
    <input type="text" class="bp3-input" value="from:ggray to:allorca" />
  </div>
</div>
<div class="bp3-control-group">
  <div class="bp3-input-group">
    <span class="bp3-icon bp3-icon-people"></span>
    <input type="text" class="bp3-input" placeholder="Find collaborators..." style="padding-right:94px" />
    <div class="bp3-input-action">
      <button class="bp3-button bp3-minimal bp3-intent-primary">
        can view<span class="bp3-icon-standard bp3-icon-caret-down bp3-align-right"></span>
      </button>
    </div>
  </div>
  <button class="bp3-button bp3-intent-primary">Add</button>
</div>
<div class="bp3-control-group">
  <div class="bp3-select">
    <select>
      <option selected value="dollar">$</option>
      <option value="euro">€</option>
    </select>
  </div>
  <div class="bp3-control-group bp3-numeric-input">
    <div class="bp3-input-group">
      <input type="text" autocomplete="off" class="bp3-input" style="padding-right: 10px;" value="1">
    </div>
    <div class="bp3-button-group bp3-vertical bp3-fixed">
      <button type="button" class="bp3-button">
        <span icon="chevron-up" class="bp3-icon bp3-icon-chevron-up">
          <svg data-icon="chevron-up" width="16" height="16" viewBox="0 0 16 16"><desc>chevron-up</desc><path d="M12.71 9.29l-4-4C8.53 5.11 8.28 5 8 5s-.53.11-.71.29l-4 4a1.003 1.003 0 001.42 1.42L8 7.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71z" fill-rule="evenodd"></path></svg>
        </span>
      </button>
      <button type="button" class="bp3-button">
        <span icon="chevron-down" class="bp3-icon bp3-icon-chevron-down">
          <svg data-icon="chevron-down" width="16" height="16" viewBox="0 0 16 16"><desc>chevron-down</desc><path d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z" fill-rule="evenodd"></path></svg>
        </span>
      </button>
    </div>
  </div>
</div>

Styleguide control-group
*/
.bp3-control-group {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*
  Responsive control groups

  Markup:
  <div class="bp3-control-group">
    <div class="bp3-input-group bp3-fill">
      <span class="bp3-icon bp3-icon-people"></span>
      <input type="text" class="bp3-input" placeholder="Find collaborators..." />
    </div>
    <button class="bp3-button bp3-intent-primary">Add</button>
  </div>
  <div class="bp3-control-group bp3-fill">
    <button class="bp3-button bp3-icon-minus bp3-fixed"></button>
    <input type="text" class="bp3-input" placeholder="Enter a value..." />
    <button class="bp3-button bp3-icon-plus bp3-fixed"></button>
  </div>

  Styleguide control-group-fill
  */
  /*
  Vertical control groups

  Markup:
  <div class="bp3-control-group bp3-vertical" style="width: 300px;">
    <div class="bp3-input-group bp3-large">
      <span class="bp3-icon bp3-icon-person"></span>
      <input type="text" class="bp3-input" placeholder="Username" />
    </div>
    <div class="bp3-input-group bp3-large">
      <span class="bp3-icon bp3-icon-lock"></span>
      <input type="password" class="bp3-input" placeholder="Password" />
    </div>
    <button class="bp3-button bp3-large bp3-intent-primary">Login</button>
  </div>

  Styleguide control-group-vertical
  */ }
  .bp3-control-group > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-control-group > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-control-group .bp3-button,
  .bp3-control-group .bp3-html-select,
  .bp3-control-group .bp3-input,
  .bp3-control-group .bp3-select {
    position: relative; }
  .bp3-control-group .bp3-input {
    border-radius: inherit;
    z-index: 2; }
    .bp3-control-group .bp3-input:focus {
      border-radius: 3px;
      z-index: 14; }
    .bp3-control-group .bp3-input[class*="bp3-intent"] {
      z-index: 13; }
      .bp3-control-group .bp3-input[class*="bp3-intent"]:focus {
        z-index: 15; }
    .bp3-control-group .bp3-input[readonly], .bp3-control-group .bp3-input:disabled, .bp3-control-group .bp3-input.bp3-disabled {
      z-index: 1; }
  .bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input {
    z-index: 13; }
    .bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input:focus {
      z-index: 15; }
  .bp3-control-group .bp3-button,
  .bp3-control-group .bp3-html-select select,
  .bp3-control-group .bp3-select select {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    border-radius: inherit;
    z-index: 4; }
    .bp3-control-group .bp3-button:focus,
    .bp3-control-group .bp3-html-select select:focus,
    .bp3-control-group .bp3-select select:focus {
      z-index: 5; }
    .bp3-control-group .bp3-button:hover,
    .bp3-control-group .bp3-html-select select:hover,
    .bp3-control-group .bp3-select select:hover {
      z-index: 6; }
    .bp3-control-group .bp3-button:active,
    .bp3-control-group .bp3-html-select select:active,
    .bp3-control-group .bp3-select select:active {
      z-index: 7; }
    .bp3-control-group .bp3-button[readonly], .bp3-control-group .bp3-button:disabled, .bp3-control-group .bp3-button.bp3-disabled,
    .bp3-control-group .bp3-html-select select[readonly],
    .bp3-control-group .bp3-html-select select:disabled,
    .bp3-control-group .bp3-html-select select.bp3-disabled,
    .bp3-control-group .bp3-select select[readonly],
    .bp3-control-group .bp3-select select:disabled,
    .bp3-control-group .bp3-select select.bp3-disabled {
      z-index: 3; }
    .bp3-control-group .bp3-button[class*="bp3-intent"],
    .bp3-control-group .bp3-html-select select[class*="bp3-intent"],
    .bp3-control-group .bp3-select select[class*="bp3-intent"] {
      z-index: 9; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:focus,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:focus,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:focus {
        z-index: 10; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:hover,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:hover,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:hover {
        z-index: 11; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:active,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:active,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:active {
        z-index: 12; }
      .bp3-control-group .bp3-button[class*="bp3-intent"][readonly], .bp3-control-group .bp3-button[class*="bp3-intent"]:disabled, .bp3-control-group .bp3-button[class*="bp3-intent"].bp3-disabled,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"][readonly],
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:disabled,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"].bp3-disabled,
      .bp3-control-group .bp3-select select[class*="bp3-intent"][readonly],
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:disabled,
      .bp3-control-group .bp3-select select[class*="bp3-intent"].bp3-disabled {
        z-index: 8; }
  .bp3-control-group .bp3-input-group > .bp3-icon,
  .bp3-control-group .bp3-input-group > .bp3-button,
  .bp3-control-group .bp3-input-group > .bp3-input-left-container,
  .bp3-control-group .bp3-input-group > .bp3-input-action {
    z-index: 16; }
  .bp3-control-group .bp3-select::after,
  .bp3-control-group .bp3-html-select::after,
  .bp3-control-group .bp3-select > .bp3-icon,
  .bp3-control-group .bp3-html-select > .bp3-icon {
    z-index: 17; }
  .bp3-control-group .bp3-select:focus-within {
    z-index: 5; }
  .bp3-control-group:not(.bp3-vertical) > *:not(.bp3-divider) {
    margin-right: -1px; }
  .bp3-control-group:not(.bp3-vertical) > .bp3-divider:not(:first-child) {
    margin-left: 6px; }
  .bp3-dark .bp3-control-group:not(.bp3-vertical) > *:not(.bp3-divider) {
    margin-right: 0; }
  .bp3-dark .bp3-control-group:not(.bp3-vertical) > .bp3-button + .bp3-button {
    margin-left: 1px; }
  .bp3-control-group .bp3-popover-wrapper,
  .bp3-control-group .bp3-popover-target {
    border-radius: inherit; }
  .bp3-control-group > :first-child {
    border-radius: 3px 0 0 3px; }
  .bp3-control-group > :last-child {
    border-radius: 0 3px 3px 0;
    margin-right: 0; }
  .bp3-control-group > :only-child {
    border-radius: 3px;
    margin-right: 0; }
  .bp3-control-group .bp3-input-group .bp3-button {
    border-radius: 3px; }
  .bp3-control-group .bp3-numeric-input:not(:first-child) .bp3-input-group {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .bp3-control-group.bp3-fill {
    width: 100%; }
  .bp3-control-group > .bp3-fill {
    flex: 1 1 auto; }
  .bp3-control-group.bp3-fill > *:not(.bp3-fixed) {
    flex: 1 1 auto; }
  .bp3-control-group.bp3-vertical {
    flex-direction: column; }
    .bp3-control-group.bp3-vertical > * {
      margin-top: -1px; }
    .bp3-control-group.bp3-vertical > :first-child {
      border-radius: 3px 3px 0 0;
      margin-top: 0; }
    .bp3-control-group.bp3-vertical > :last-child {
      border-radius: 0 0 3px 3px; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-control {
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  position: relative;
  text-transform: none;
  /*
  Checkbox

  Markup:
  <label class="bp3-control bp3-checkbox {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Checkbox
  </label>

  :checked  - Checked
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  :indeterminate - Indeterminate. Note that this style can only be achieved via JavaScript
                   <code>input.indeterminate = true</code>.
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide checkbox
  */
  /*
  Radio

  Markup:
  <label class="bp3-control bp3-radio {{.modifier}}">
    <input type="radio" name="docs-radio-regular" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Radio
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide radio
  */
  /*
  Switch

  Markup:
  <label class="bp3-control bp3-switch {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Switch
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide switch
  */
  /* stylelint-disable-next-line order/order */
  /*
  Inline labels

  Markup:
  <div>
    <label class="bp3-label">A group of related options</label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      First
    </label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      Second
    </label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      Third
    </label>
  </div>

  Styleguide checkbox-inline
  */ }
  .bp3-control input:checked ~ .bp3-control-indicator {
    background-color: #00D092;
    background-color: var(--brand3);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
  .bp3-control:hover input:checked ~ .bp3-control-indicator {
    background-color: #106ba3;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
  .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
    background: #0e5a8a;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    background: rgba(#00D092, 0.5);
    background: rgba(var(--brand3), 0.5);
    box-shadow: none; }
  .bp3-dark .bp3-control input:checked ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control:hover input:checked ~ .bp3-control-indicator {
    background-color: #106ba3;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
    background-color: #0e5a8a;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    background: rgba(14, 90, 138, 0.5);
    box-shadow: none; }
  .bp3-control:not(.bp3-align-right) {
    padding-left: 26px; }
    .bp3-control:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -26px; }
  .bp3-control.bp3-align-right {
    padding-right: 26px; }
    .bp3-control.bp3-align-right .bp3-control-indicator {
      margin-right: -26px; }
  .bp3-control.bp3-disabled {
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed; }
  .bp3-control.bp3-inline {
    display: inline-block;
    margin-right: 20px; }
  .bp3-control input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .bp3-control .bp3-control-indicator {
    background-clip: padding-box;
    background-color: #F7F8F8;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    border: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    height: 1em;
    margin-right: 10px;
    margin-top: -3px;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    vertical-align: middle;
    width: 1em; }
    .bp3-control .bp3-control-indicator::before {
      content: "";
      display: block;
      height: 1em;
      width: 1em; }
  .bp3-control:hover .bp3-control-indicator {
    background-color: #EFF0F2; }
  .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
    background: #DDE0E3;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-control input:disabled ~ .bp3-control-indicator {
    background: rgba(211, 216, 219, 0.5);
    box-shadow: none;
    cursor: not-allowed; }
  .bp3-control input:focus ~ .bp3-control-indicator {
    outline: rgba(19, 124, 189, 0.6) auto 2px;
    outline-offset: 2px;
    -moz-outline-radius: 6px; }
  .bp3-control.bp3-align-right .bp3-control-indicator {
    float: right;
    margin-left: 10px;
    margin-top: 1px; }
  .bp3-control.bp3-large {
    font-size: 16px; }
    .bp3-control.bp3-large:not(.bp3-align-right) {
      padding-left: 30px; }
      .bp3-control.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -30px; }
    .bp3-control.bp3-large.bp3-align-right {
      padding-right: 30px; }
      .bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
        margin-right: -30px; }
    .bp3-control.bp3-large .bp3-control-indicator {
      font-size: 20px; }
    .bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
      margin-top: 0; }
  .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    background-color: #00D092;
    background-color: var(--brand3);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
  .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: #106ba3;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
  .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
    background: #0e5a8a;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
    background: rgba(#00D092, 0.5);
    background: rgba(var(--brand3), 0.5);
    box-shadow: none; }
  .bp3-dark .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: #106ba3;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
    background-color: #0e5a8a;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
    background: rgba(14, 90, 138, 0.5);
    box-shadow: none; }
  .bp3-control.bp3-checkbox .bp3-control-indicator {
    border-radius: 3px; }
  .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
    background-image: svg-icon("16px/small-tick.svg", (path: (fill: #ffffff))); }
  .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator::before {
    background-image: svg-icon("16px/small-minus.svg", (path: (fill: #ffffff))); }
  .bp3-control.bp3-radio .bp3-control-indicator {
    border-radius: 50%; }
  .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
    background-image: radial-gradient(#ffffff, #ffffff 28%, transparent 32%); }
  .bp3-control.bp3-radio input:checked:disabled ~ .bp3-control-indicator::before {
    opacity: 0.5; }
  .bp3-control.bp3-radio input:focus ~ .bp3-control-indicator {
    -moz-outline-radius: 16px; }
  .bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: rgba(176, 181, 185, 0.5); }
  .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
    background: rgba(124, 133, 139, 0.5); }
  .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
    background: rgba(103, 111, 117, 0.5); }
  .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
    background: rgba(211, 216, 219, 0.5); }
    .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator::before {
      background: rgba(255, 255, 255, 0.8); }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #00D092;
    background: var(--brand3); }
  .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #106ba3; }
  .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
    background: #0e5a8a; }
  .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
    background: rgba(19, 124, 189, 0.5); }
    .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator::before {
      background: rgba(255, 255, 255, 0.8); }
  .bp3-control.bp3-switch:not(.bp3-align-right) {
    padding-left: 38px; }
    .bp3-control.bp3-switch:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -38px; }
  .bp3-control.bp3-switch.bp3-align-right {
    padding-right: 38px; }
    .bp3-control.bp3-switch.bp3-align-right .bp3-control-indicator {
      margin-right: -38px; }
  .bp3-control.bp3-switch .bp3-control-indicator {
    border: none;
    border-radius: 1.75em;
    /* stylelint-disable-next-line declaration-no-important */
    box-shadow: none !important;
    min-width: 1.75em;
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
    width: auto; }
    .bp3-control.bp3-switch .bp3-control-indicator::before {
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
      height: calc(1em - 4px);
      left: 0;
      margin: 2px;
      position: absolute;
      transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
      width: calc(1em - 4px); }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    left: calc(100% - 1em); }
  .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) {
    padding-left: 45px; }
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -45px; }
  .bp3-control.bp3-switch.bp3-large.bp3-align-right {
    padding-right: 45px; }
    .bp3-control.bp3-switch.bp3-large.bp3-align-right .bp3-control-indicator {
      margin-right: -45px; }
  .bp3-dark .bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: rgba(16, 22, 26, 0.5); }
  .bp3-dark .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
    background: rgba(16, 22, 26, 0.7); }
  .bp3-dark .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
    background: rgba(16, 22, 26, 0.9); }
  .bp3-dark .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
    background: rgba(66, 74, 80, 0.5); }
    .bp3-dark .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator::before {
      background: rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #00D092;
    background: var(--brand3); }
  .bp3-dark .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #106ba3; }
  .bp3-dark .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
    background: #0e5a8a; }
  .bp3-dark .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
    background: rgba(14, 90, 138, 0.5); }
    .bp3-dark .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator::before {
      background: rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-switch .bp3-control-indicator::before {
    background: #424A50;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-control.bp3-switch .bp3-switch-inner-text {
    font-size: 0.7em;
    text-align: center; }
  .bp3-control.bp3-switch .bp3-control-indicator-child:first-child {
    line-height: 0;
    margin-left: 0.5em;
    margin-right: 1.2em;
    visibility: hidden; }
  .bp3-control.bp3-switch .bp3-control-indicator-child:last-child {
    line-height: 1em;
    margin-left: 1.2em;
    margin-right: 0.5em;
    visibility: visible; }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:first-child {
    line-height: 1em;
    visibility: visible; }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:last-child {
    line-height: 0;
    visibility: hidden; }
  .bp3-dark .bp3-control {
    color: #f5f8fa; }
    .bp3-dark .bp3-control.bp3-disabled {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-control .bp3-control-indicator {
      background-color: #424A50;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-control:hover .bp3-control-indicator {
      background-color: #383F45; }
    .bp3-dark .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
      background: #252A2E;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-control input:disabled ~ .bp3-control-indicator {
      background: rgba(66, 74, 80, 0.5);
      box-shadow: none;
      cursor: not-allowed; }
    .bp3-dark .bp3-control.bp3-checkbox input:disabled:checked ~ .bp3-control-indicator, .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
      color: rgba(167, 182, 194, 0.6); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/*
File input

Markup:
<label class="bp3-file-input {{.modifier}}">
  <input type="file" {{:modifier}}/>
  <span class="bp3-file-upload-input">Choose file...</span>
</label>

:disabled - Disabled
.bp3-large - Larger size
.bp3-fill - Take up full width of parent element
.bp3-file-input-has-selection - User has made a selection

Styleguide file-input
*/
.bp3-file-input {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative; }
  .bp3-file-input input {
    margin: 0;
    min-width: 200px;
    opacity: 0; }
    .bp3-file-input input:disabled + .bp3-file-upload-input,
    .bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
      background: rgba(211, 216, 219, 0.5);
      box-shadow: none;
      color: rgba(92, 112, 128, 0.6);
      cursor: not-allowed;
      resize: none; }
      .bp3-file-input input:disabled + .bp3-file-upload-input::after,
      .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
        background-color: rgba(211, 216, 219, 0.5);
        background-image: none;
        box-shadow: none;
        color: rgba(92, 112, 128, 0.6);
        cursor: not-allowed;
        outline: none; }
        .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active:hover,
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active,
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active:hover {
          background: rgba(211, 216, 219, 0.7); }
      .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input, .bp3-dark
      .bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
        background: rgba(66, 74, 80, 0.5);
        box-shadow: none;
        color: rgba(167, 182, 194, 0.6); }
        .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after, .bp3-dark
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
          background-color: rgba(66, 74, 80, 0.5);
          background-image: none;
          box-shadow: none;
          color: rgba(167, 182, 194, 0.6); }
          .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-dark
          .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active {
            background: rgba(66, 74, 80, 0.7); }
  .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
    color: #182026; }
  .bp3-dark .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
    color: #f5f8fa; }
  .bp3-file-input.bp3-fill {
    width: 100%; }
  .bp3-file-input.bp3-large,
  .bp3-large .bp3-file-input {
    height: 40px; }
  .bp3-file-input .bp3-file-upload-input-custom-text::after {
    content: attr(bp3-button-text); }

.bp3-file-upload-input {
  -webkit-appearance: none;
          appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: rgba(92, 112, 128, 0.6);
  left: 0;
  padding-right: 80px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bp3-file-upload-input::-webkit-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-file-upload-input:-ms-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-file-upload-input::placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-file-upload-input:focus, .bp3-file-upload-input.bp3-active {
    box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-file-upload-input[type="search"], .bp3-file-upload-input.bp3-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .bp3-file-upload-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .bp3-file-upload-input:disabled, .bp3-file-upload-input.bp3-disabled {
    background: rgba(211, 216, 219, 0.5);
    box-shadow: none;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
    resize: none; }
  .bp3-file-upload-input::after {
    background-color: #F7F8F8;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    color: #182026;
    min-height: 24px;
    min-width: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    border-radius: 3px;
    content: "Browse";
    line-height: 24px;
    margin: 3px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px; }
    .bp3-file-upload-input::after:hover {
      background-clip: padding-box;
      background-color: #EFF0F2;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
    .bp3-file-upload-input::after:active, .bp3-file-upload-input::after.bp3-active {
      background-color: #DDE0E3;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-file-upload-input::after:disabled, .bp3-file-upload-input::after.bp3-disabled {
      background-color: rgba(211, 216, 219, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(92, 112, 128, 0.6);
      cursor: not-allowed;
      outline: none; }
      .bp3-file-upload-input::after:disabled.bp3-active, .bp3-file-upload-input::after:disabled.bp3-active:hover, .bp3-file-upload-input::after.bp3-disabled.bp3-active, .bp3-file-upload-input::after.bp3-disabled.bp3-active:hover {
        background: rgba(211, 216, 219, 0.7); }
  .bp3-file-upload-input:hover::after {
    background-clip: padding-box;
    background-color: #EFF0F2;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
  .bp3-file-upload-input:active::after {
    background-color: #DDE0E3;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-large .bp3-file-upload-input {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding-right: 95px; }
    .bp3-large .bp3-file-upload-input[type="search"], .bp3-large .bp3-file-upload-input.bp3-round {
      padding: 0 15px; }
    .bp3-large .bp3-file-upload-input::after {
      min-height: 30px;
      min-width: 30px;
      line-height: 30px;
      margin: 5px;
      width: 85px; }
  .bp3-dark .bp3-file-upload-input {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #f5f8fa;
    color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-file-upload-input::-webkit-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-file-upload-input:-ms-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-file-upload-input::placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-file-upload-input:focus {
      box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
      box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-file-upload-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-file-upload-input:disabled, .bp3-dark .bp3-file-upload-input.bp3-disabled {
      background: rgba(66, 74, 80, 0.5);
      box-shadow: none;
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-file-upload-input::after {
      background-color: #424A50;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      color: #f5f8fa; }
      .bp3-dark .bp3-file-upload-input::after:hover, .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
        color: #f5f8fa; }
      .bp3-dark .bp3-file-upload-input::after:hover {
        background-color: #383F45;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
        background-color: #252A2E;
        background-image: none;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
      .bp3-dark .bp3-file-upload-input::after:disabled, .bp3-dark .bp3-file-upload-input::after.bp3-disabled {
        background-color: rgba(66, 74, 80, 0.5);
        background-image: none;
        box-shadow: none;
        color: rgba(167, 182, 194, 0.6); }
        .bp3-dark .bp3-file-upload-input::after:disabled.bp3-active, .bp3-dark .bp3-file-upload-input::after.bp3-disabled.bp3-active {
          background: rgba(66, 74, 80, 0.7); }
      .bp3-dark .bp3-file-upload-input::after .bp3-button-spinner .bp3-spinner-head {
        background: rgba(16, 22, 26, 0.5);
        stroke: #939A9F; }
    .bp3-dark .bp3-file-upload-input:hover::after {
      background-color: #383F45;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-file-upload-input:active::after {
      background-color: #252A2E;
      background-image: none;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }

/* stylelint-disable-next-line no-duplicate-selectors */
.bp3-file-upload-input::after {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Form groups

Markup:
<div class="bp3-form-group {{.modifier}}">
  <label class="bp3-label" for="form-group-input">
    Label
    <span class="bp3-text-muted">(required)</span>
  </label>
  <div class="bp3-form-content">
    <div class="bp3-input-group {{.modifier}}">
      <span class="bp3-icon bp3-icon-calendar"></span>
      <input id="form-group-input" type="text" {{:modifier}} class="bp3-input"
             style="width: 200px;" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="bp3-form-helper-text">Please enter a value</div>
  </div>
</div>

:disabled - Disable the input.
.bp3-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp3-inline - Label and content appear side by side.
.bp3-intent-primary - Apply intent to form group and input separately.

Styleguide form-group
*/
.bp3-form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
  /* stylelint-disable-next-line order/declaration-block-order */ }
  .bp3-form-group label.bp3-label {
    margin-bottom: 5px; }
  .bp3-form-group .bp3-control {
    margin-top: 7px; }
  .bp3-form-group .bp3-form-group-sub-label,
  .bp3-form-group .bp3-form-helper-text {
    color: #5c7080;
    font-size: 12px; }
  .bp3-form-group .bp3-form-group-sub-label {
    margin-bottom: 5px; }
  .bp3-form-group .bp3-form-helper-text {
    margin-top: 5px; }
  .bp3-form-group.bp3-intent-primary .bp3-form-group-sub-label,
  .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: #106ba3; }
  .bp3-form-group.bp3-intent-success .bp3-form-group-sub-label,
  .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
    color: #0d8050; }
  .bp3-form-group.bp3-intent-warning .bp3-form-group-sub-label,
  .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
    color: #bf7326; }
  .bp3-form-group.bp3-intent-danger .bp3-form-group-sub-label,
  .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: #c23030; }
  .bp3-form-group.bp3-inline {
    align-items: flex-start;
    flex-direction: row; }
    .bp3-form-group.bp3-inline.bp3-large label.bp3-label {
      line-height: 40px;
      margin: 0 10px 0 0; }
    .bp3-form-group.bp3-inline label.bp3-label {
      line-height: 30px;
      margin: 0 10px 0 0; }
  .bp3-form-group.bp3-disabled .bp3-label,
  .bp3-form-group.bp3-disabled .bp3-text-muted,
  .bp3-form-group.bp3-disabled .bp3-form-group-sub-label,
  .bp3-form-group.bp3-disabled .bp3-form-helper-text {
    /* stylelint-disable-next-line declaration-no-important */
    color: rgba(92, 112, 128, 0.6) !important; }
  .bp3-dark .bp3-form-group.bp3-intent-primary .bp3-form-group-sub-label,
  .bp3-dark .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: #48aff0; }
  .bp3-dark .bp3-form-group.bp3-intent-success .bp3-form-group-sub-label,
  .bp3-dark .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
    color: #3dcc91; }
  .bp3-dark .bp3-form-group.bp3-intent-warning .bp3-form-group-sub-label,
  .bp3-dark .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
    color: #ffb366; }
  .bp3-dark .bp3-form-group.bp3-intent-danger .bp3-form-group-sub-label,
  .bp3-dark .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: #ff7373; }
  .bp3-dark .bp3-form-group .bp3-form-helper-text {
    color: #a7b6c2; }
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-label,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-text-muted,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-form-group-sub-label,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-form-helper-text {
    /* stylelint-disable-next-line declaration-no-important */
    color: rgba(167, 182, 194, 0.6) !important; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Input groups

Markup:
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-filter"></span>
  <input type="text" class="bp3-input" {{:modifier}} placeholder="Filter histogram..." />
</div>
<div class="bp3-input-group {{.modifier}}">
  <input type="password" class="bp3-input" {{:modifier}} placeholder="Enter your password..." />
  <button class="bp3-button bp3-minimal bp3-intent-warning bp3-icon-lock" {{:modifier}}></button>
</div>
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-search"></span>
  <input type="text" class="bp3-input" {{:modifier}} placeholder="Search" />
  <button class="bp3-button bp3-minimal bp3-intent-primary bp3-icon-arrow-right" {{:modifier}}></button>
</div>

:disabled - Disabled input. Must be added separately to the <code>&#60;input&#62;</code> and <code>&#60;button&#62;</code>. Also add <code>.bp3-disabled</code> to <code>.bp3-input-group</code> for icon coloring (not shown below).
.bp3-round - Rounded caps. Button will also be rounded.
.bp3-large - Large group. Children will adjust size accordingly.
.bp3-small - Small group. Children will adjust size accordingly.
.bp3-intent-primary - Primary intent. (All 4 intents are supported.)
.bp3-fill - Take up full width of parent element.

Styleguide input-group
*/
.bp3-input-group {
  display: block;
  position: relative; }
  .bp3-input-group .bp3-input {
    position: relative;
    width: 100%; }
    .bp3-input-group .bp3-input:not(:first-child) {
      padding-left: 30px; }
    .bp3-input-group .bp3-input:not(:last-child) {
      padding-right: 30px; }
  .bp3-input-group .bp3-input-action,
  .bp3-input-group > .bp3-input-left-container,
  .bp3-input-group > .bp3-button,
  .bp3-input-group > .bp3-icon {
    position: absolute;
    top: 0; }
    .bp3-input-group .bp3-input-action:first-child,
    .bp3-input-group > .bp3-input-left-container:first-child,
    .bp3-input-group > .bp3-button:first-child,
    .bp3-input-group > .bp3-icon:first-child {
      left: 0; }
    .bp3-input-group .bp3-input-action:last-child,
    .bp3-input-group > .bp3-input-left-container:last-child,
    .bp3-input-group > .bp3-button:last-child,
    .bp3-input-group > .bp3-icon:last-child {
      right: 0; }
  .bp3-input-group .bp3-button {
    min-height: 24px;
    min-width: 24px;
    margin: 3px;
    padding: 0 7px; }
    .bp3-input-group .bp3-button:empty {
      padding: 0; }
  .bp3-input-group > .bp3-input-left-container,
  .bp3-input-group > .bp3-icon {
    z-index: 1; }
  .bp3-input-group > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group > .bp3-icon {
    color: #5c7080; }
    .bp3-input-group > .bp3-input-left-container > .bp3-icon:empty,
    .bp3-input-group > .bp3-icon:empty {
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased; }
  .bp3-input-group > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group > .bp3-icon,
  .bp3-input-group .bp3-input-action > .bp3-spinner {
    margin: 7px; }
  .bp3-input-group .bp3-tag {
    margin: 5px; }
  .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus),
  .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
    color: #5c7080;
    /* stylelint-disable-next-line selector-max-compound-selectors */ }
    .bp3-dark .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus), .bp3-dark
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
      color: #a7b6c2; }
    .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large {
      color: #5c7080; }
  .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled,
  .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled {
    /* stylelint-disable declaration-no-important */
    color: rgba(92, 112, 128, 0.6) !important; }
    .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-large,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-standard,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-large {
      color: rgba(92, 112, 128, 0.6) !important; }
  .bp3-input-group.bp3-disabled {
    cursor: not-allowed; }
    .bp3-input-group.bp3-disabled .bp3-icon {
      color: rgba(92, 112, 128, 0.6); }
  .bp3-input-group.bp3-large .bp3-button {
    min-height: 30px;
    min-width: 30px;
    margin: 5px; }
  .bp3-input-group.bp3-large > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group.bp3-large > .bp3-icon,
  .bp3-input-group.bp3-large .bp3-input-action > .bp3-spinner {
    margin: 12px; }
  .bp3-input-group.bp3-large .bp3-input {
    font-size: 16px;
    height: 40px;
    line-height: 40px; }
    .bp3-input-group.bp3-large .bp3-input[type="search"], .bp3-input-group.bp3-large .bp3-input.bp3-round {
      padding: 0 15px; }
    .bp3-input-group.bp3-large .bp3-input:not(:first-child) {
      padding-left: 40px; }
    .bp3-input-group.bp3-large .bp3-input:not(:last-child) {
      padding-right: 40px; }
  .bp3-input-group.bp3-small .bp3-button {
    min-height: 20px;
    min-width: 20px;
    margin: 2px; }
  .bp3-input-group.bp3-small .bp3-tag {
    min-height: 20px;
    min-width: 20px;
    margin: 2px; }
  .bp3-input-group.bp3-small > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group.bp3-small > .bp3-icon,
  .bp3-input-group.bp3-small .bp3-input-action > .bp3-spinner {
    margin: 4px; }
  .bp3-input-group.bp3-small .bp3-input {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px; }
    .bp3-input-group.bp3-small .bp3-input[type="search"], .bp3-input-group.bp3-small .bp3-input.bp3-round {
      padding: 0 12px; }
    .bp3-input-group.bp3-small .bp3-input:not(:first-child) {
      padding-left: 24px; }
    .bp3-input-group.bp3-small .bp3-input:not(:last-child) {
      padding-right: 24px; }
  .bp3-input-group.bp3-fill {
    flex: 1 1 auto;
    width: 100%; }
  .bp3-input-group.bp3-round .bp3-button,
  .bp3-input-group.bp3-round .bp3-input,
  .bp3-input-group.bp3-round .bp3-tag {
    border-radius: 30px; }
  .bp3-dark .bp3-input-group .bp3-icon {
    color: #a7b6c2; }
  .bp3-dark .bp3-input-group.bp3-disabled .bp3-icon {
    color: rgba(167, 182, 194, 0.6); }
  .bp3-input-group.bp3-intent-primary .bp3-input {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-primary .bp3-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-primary .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .bp3-input-group.bp3-intent-primary .bp3-input:disabled, .bp3-input-group.bp3-intent-primary .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-primary > .bp3-icon {
    color: #106ba3; }
    .bp3-dark .bp3-input-group.bp3-intent-primary > .bp3-icon {
      color: #48aff0; }
  .bp3-input-group.bp3-intent-success .bp3-input {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-success .bp3-input:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-success .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .bp3-input-group.bp3-intent-success .bp3-input:disabled, .bp3-input-group.bp3-intent-success .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-success > .bp3-icon {
    color: #0d8050; }
    .bp3-dark .bp3-input-group.bp3-intent-success > .bp3-icon {
      color: #3dcc91; }
  .bp3-input-group.bp3-intent-warning .bp3-input {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-warning .bp3-input:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-warning .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .bp3-input-group.bp3-intent-warning .bp3-input:disabled, .bp3-input-group.bp3-intent-warning .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-warning > .bp3-icon {
    color: #bf7326; }
    .bp3-dark .bp3-input-group.bp3-intent-warning > .bp3-icon {
      color: #ffb366; }
  .bp3-input-group.bp3-intent-danger .bp3-input {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-danger .bp3-input:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-danger .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .bp3-input-group.bp3-intent-danger .bp3-input:disabled, .bp3-input-group.bp3-intent-danger .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-danger > .bp3-icon {
    color: #c23030; }
    .bp3-dark .bp3-input-group.bp3-intent-danger > .bp3-icon {
      color: #ff7373; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Text inputs

Markup:
<input class="bp3-input {{.modifier}}" {{:modifier}} type="text" placeholder="Text input" dir="auto" />

:disabled - Disabled
:readonly - Readonly
.bp3-round - Rounded ends
.bp3-large - Larger size
.bp3-small - Small size
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-fill - Take up full width of parent element

Styleguide input
*/
.bp3-input {
  -webkit-appearance: none;
          appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle; }
  .bp3-input::-webkit-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-input:-ms-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-input::placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-input:focus, .bp3-input.bp3-active {
    box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-input[type="search"], .bp3-input.bp3-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .bp3-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .bp3-input:disabled, .bp3-input.bp3-disabled {
    background: rgba(211, 216, 219, 0.5);
    box-shadow: none;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
    resize: none; }
  .bp3-input.bp3-large {
    font-size: 16px;
    height: 40px;
    line-height: 40px; }
    .bp3-input.bp3-large[type="search"], .bp3-input.bp3-large.bp3-round {
      padding: 0 15px; }
  .bp3-input.bp3-small {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px; }
    .bp3-input.bp3-small[type="search"], .bp3-input.bp3-small.bp3-round {
      padding: 0 12px; }
  .bp3-input.bp3-fill {
    flex: 1 1 auto;
    width: 100%; }
  .bp3-dark .bp3-input {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }
    .bp3-dark .bp3-input::-webkit-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-input:-ms-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-input::placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-input:focus {
      box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
      box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-input:disabled, .bp3-dark .bp3-input.bp3-disabled {
      background: rgba(66, 74, 80, 0.5);
      box-shadow: none;
      color: rgba(167, 182, 194, 0.6); }
  .bp3-input.bp3-intent-primary {
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-primary:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-primary[readonly] {
      box-shadow: inset 0 0 0 1px #137cbd; }
    .bp3-input.bp3-intent-primary:disabled, .bp3-input.bp3-intent-primary.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-primary {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px #137cbd, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-primary:focus {
        box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-primary[readonly] {
        box-shadow: inset 0 0 0 1px #137cbd; }
      .bp3-dark .bp3-input.bp3-intent-primary:disabled, .bp3-dark .bp3-input.bp3-intent-primary.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-success {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-success:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-success[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .bp3-input.bp3-intent-success:disabled, .bp3-input.bp3-intent-success.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-success {
      box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-success:focus {
        box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-success[readonly] {
        box-shadow: inset 0 0 0 1px #0f9960; }
      .bp3-dark .bp3-input.bp3-intent-success:disabled, .bp3-dark .bp3-input.bp3-intent-success.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-warning {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-warning:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-warning[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .bp3-input.bp3-intent-warning:disabled, .bp3-input.bp3-intent-warning.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-warning {
      box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-warning:focus {
        box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-warning[readonly] {
        box-shadow: inset 0 0 0 1px #d9822b; }
      .bp3-dark .bp3-input.bp3-intent-warning:disabled, .bp3-dark .bp3-input.bp3-intent-warning.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-danger {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-danger:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-danger[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .bp3-input.bp3-intent-danger:disabled, .bp3-input.bp3-intent-danger.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-danger {
      box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-danger:focus {
        box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-danger[readonly] {
        box-shadow: inset 0 0 0 1px #db3737; }
      .bp3-dark .bp3-input.bp3-intent-danger:disabled, .bp3-dark .bp3-input.bp3-intent-danger.bp3-disabled {
        box-shadow: none; }
  .bp3-input::-ms-clear {
    display: none; }

/*
Search inputs

Markup:
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-search"></span>
  <input class="bp3-input" {{:modifier}} type="search" placeholder="Search input" dir="auto" />
</div>

:disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-input-group</code> for icon coloring (not shown below).
.bp3-large - Large
.bp3-small - Small

Styleguide input-search
*/
/*
Textareas

Markup:
<textarea class="bp3-input {{.modifier}}" {{:modifier}} dir="auto"></textarea>

:disabled - Disabled
:readonly - Readonly
.bp3-large - Larger font size
.bp3-small - Small font size
.bp3-intent-primary - Primary intent
.bp3-intent-danger  - Danger intent
.bp3-fill  - Take up full width of parent element

Styleguide textarea
*/
/* stylelint-disable-next-line selector-no-qualifying-type */
textarea.bp3-input {
  max-width: 100%;
  padding: 10px; }
  textarea.bp3-input, textarea.bp3-input.bp3-large, textarea.bp3-input.bp3-small {
    height: auto;
    line-height: inherit; }
  textarea.bp3-input.bp3-small {
    padding: 8px; }
  .bp3-dark textarea.bp3-input {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), 0 0 0 0 rgba(#00D092, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), 0 0 0 0 rgba(var(--brand3), 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }
    .bp3-dark textarea.bp3-input::-webkit-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark textarea.bp3-input:-ms-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark textarea.bp3-input::placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark textarea.bp3-input:focus {
      box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
      box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark textarea.bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark textarea.bp3-input:disabled, .bp3-dark textarea.bp3-input.bp3-disabled {
      background: rgba(66, 74, 80, 0.5);
      box-shadow: none;
      color: rgba(167, 182, 194, 0.6); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Labels

Markup:
<div>
  <label class="bp3-label {{.modifier}}">
    Label A
    <span class="bp3-text-muted">(optional)</span>
    <input {{:modifier}} class="bp3-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
  </label>
  <label class="bp3-label {{.modifier}}">
    Label B
    <div class="bp3-input-group {{.modifier}}">
      <span class="bp3-icon bp3-icon-calendar"></span>
      <input {{:modifier}} class="bp3-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
    </div>
  </label>
  <label class="bp3-label {{.modifier}}">
    Label C
    <div class="bp3-select {{.modifier}}">
      <select {{:modifier}}>
        <option selected>Choose an item...</option>
        <option value="1">One</option>
      </select>
    </div>
  </label>
</div>

:disabled - Disable the input.
.bp3-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp3-inline - Label and content appear side by side.

Styleguide label
*/
label.bp3-label {
  display: block;
  margin-bottom: 15px;
  margin-top: 0; }
  label.bp3-label .bp3-html-select,
  label.bp3-label .bp3-input,
  label.bp3-label .bp3-select,
  label.bp3-label .bp3-slider,
  label.bp3-label .bp3-popover-wrapper {
    display: block;
    margin-top: 5px;
    text-transform: none; }
  label.bp3-label .bp3-button-group {
    margin-top: 5px; }
  label.bp3-label .bp3-select select,
  label.bp3-label .bp3-html-select select {
    font-weight: 400;
    vertical-align: top;
    width: 100%; }
  label.bp3-label.bp3-disabled,
  label.bp3-label.bp3-disabled .bp3-text-muted {
    color: rgba(92, 112, 128, 0.6); }
  label.bp3-label.bp3-inline {
    line-height: 30px; }
    label.bp3-label.bp3-inline .bp3-html-select,
    label.bp3-label.bp3-inline .bp3-input,
    label.bp3-label.bp3-inline .bp3-input-group,
    label.bp3-label.bp3-inline .bp3-select,
    label.bp3-label.bp3-inline .bp3-popover-wrapper {
      display: inline-block;
      margin: 0 0 0 5px;
      vertical-align: top; }
    label.bp3-label.bp3-inline .bp3-button-group {
      margin: 0 0 0 5px; }
    label.bp3-label.bp3-inline .bp3-input-group .bp3-input {
      margin-left: 0; }
    label.bp3-label.bp3-inline.bp3-large {
      line-height: 40px; }
  label.bp3-label:not(.bp3-inline) .bp3-popover-target {
    display: block; }
  .bp3-dark label.bp3-label {
    color: #f5f8fa; }
    .bp3-dark label.bp3-label.bp3-disabled,
    .bp3-dark label.bp3-label.bp3-disabled .bp3-text-muted {
      color: rgba(167, 182, 194, 0.6); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button {
  flex: 1 1 14px;
  min-height: 0;
  padding: 0;
  width: 30px; }
  .bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:first-child {
    border-radius: 0 3px 0 0; }
  .bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:last-child {
    border-radius: 0 0 3px 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:first-child {
  border-radius: 3px 0 0 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:last-child {
  border-radius: 0 0 0 3px; }

.bp3-numeric-input.bp3-large .bp3-button-group.bp3-vertical > .bp3-button {
  width: 40px; }

form {
  display: block; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-html-select select,
.bp3-select select {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  background-color: #F7F8F8;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  color: #182026;
  /* stylelint-disable property-no-vendor-prefix */
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px;
  width: 100%;
  /* stylelint-enable property-no-vendor-prefix */ }
  .bp3-html-select select > *, .bp3-select select > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-html-select select > .bp3-fill, .bp3-select select > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-html-select select::before,
  .bp3-select select::before, .bp3-html-select select > *, .bp3-select select > * {
    margin-right: 7px; }
  .bp3-html-select select:empty::before,
  .bp3-select select:empty::before,
  .bp3-html-select select > :last-child,
  .bp3-select select > :last-child {
    margin-right: 0; }
  .bp3-html-select select:hover,
  .bp3-select select:hover {
    background-clip: padding-box;
    background-color: #EFF0F2;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
  .bp3-html-select select:active,
  .bp3-select select:active, .bp3-html-select select.bp3-active,
  .bp3-select select.bp3-active {
    background-color: #DDE0E3;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-html-select select:disabled,
  .bp3-select select:disabled, .bp3-html-select select.bp3-disabled,
  .bp3-select select.bp3-disabled {
    background-color: rgba(211, 216, 219, 0.5);
    background-image: none;
    box-shadow: none;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
    outline: none; }
    .bp3-html-select select:disabled.bp3-active,
    .bp3-select select:disabled.bp3-active, .bp3-html-select select:disabled.bp3-active:hover,
    .bp3-select select:disabled.bp3-active:hover, .bp3-html-select select.bp3-disabled.bp3-active,
    .bp3-select select.bp3-disabled.bp3-active, .bp3-html-select select.bp3-disabled.bp3-active:hover,
    .bp3-select select.bp3-disabled.bp3-active:hover {
      background: rgba(211, 216, 219, 0.7); }

.bp3-html-select.bp3-minimal select,
.bp3-select.bp3-minimal select {
  background: none;
  box-shadow: none; }
  .bp3-html-select.bp3-minimal select:hover,
  .bp3-select.bp3-minimal select:hover {
    background: rgba(176, 181, 185, 0.3);
    box-shadow: none;
    color: #182026;
    text-decoration: none; }
  .bp3-html-select.bp3-minimal select:active,
  .bp3-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal select.bp3-active,
  .bp3-select.bp3-minimal select.bp3-active {
    background: rgba(124, 133, 139, 0.3);
    box-shadow: none;
    color: #182026; }
  .bp3-html-select.bp3-minimal select:disabled,
  .bp3-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal select:disabled:hover,
  .bp3-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal select.bp3-disabled,
  .bp3-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal select.bp3-disabled:hover,
  .bp3-select.bp3-minimal select.bp3-disabled:hover {
    background: none;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed; }
    .bp3-html-select.bp3-minimal select:disabled.bp3-active,
    .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active,
    .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active,
    .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active,
    .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active {
      background: rgba(124, 133, 139, 0.3); }
  .bp3-dark .bp3-html-select.bp3-minimal select, .bp3-html-select.bp3-minimal .bp3-dark select,
  .bp3-dark .bp3-select.bp3-minimal select, .bp3-select.bp3-minimal .bp3-dark select {
    background: none;
    box-shadow: none;
    color: inherit; }
    .bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
    .bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover, .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
    .bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
      background: none;
      box-shadow: none; }
    .bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
    .bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover {
      background: rgba(147, 154, 159, 0.15); }
    .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
    .bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
      background: rgba(147, 154, 159, 0.3);
      color: #f5f8fa; }
    .bp3-dark .bp3-html-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal .bp3-dark select:disabled,
    .bp3-dark .bp3-select.bp3-minimal select:disabled, .bp3-select.bp3-minimal .bp3-dark select:disabled, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover,
    .bp3-dark .bp3-select.bp3-minimal select:disabled:hover, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover {
      background: none;
      color: rgba(167, 182, 194, 0.6);
      cursor: not-allowed; }
      .bp3-dark .bp3-html-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active {
        background: rgba(147, 154, 159, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-primary,
  .bp3-select.bp3-minimal select.bp3-intent-primary {
    color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
    .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
    .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
      background: none;
      box-shadow: none;
      color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
    .bp3-select.bp3-minimal select.bp3-intent-primary:hover {
      background: rgba(19, 124, 189, 0.15);
      color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
    .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
      background: rgba(19, 124, 189, 0.3);
      color: #106ba3; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled {
      background: none;
      color: rgba(16, 107, 163, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active {
        background: rgba(19, 124, 189, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
      stroke: #106ba3; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary {
      color: #48aff0; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover {
        background: rgba(19, 124, 189, 0.2);
        color: #48aff0; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active {
        background: rgba(19, 124, 189, 0.3);
        color: #48aff0; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(72, 175, 240, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(19, 124, 189, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-success,
  .bp3-select.bp3-minimal select.bp3-intent-success {
    color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
    .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
    .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
      background: none;
      box-shadow: none;
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
    .bp3-select.bp3-minimal select.bp3-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
    .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active {
        background: rgba(15, 153, 96, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
      stroke: #0d8050; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success {
      color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-warning,
  .bp3-select.bp3-minimal select.bp3-intent-warning {
    color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
    .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
    .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
      background: none;
      box-shadow: none;
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
    .bp3-select.bp3-minimal select.bp3-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
    .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active {
        background: rgba(217, 130, 43, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
      stroke: #bf7326; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning {
      color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-danger,
  .bp3-select.bp3-minimal select.bp3-intent-danger {
    color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
    .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
    .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
      background: none;
      box-shadow: none;
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
    .bp3-select.bp3-minimal select.bp3-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
    .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active {
        background: rgba(219, 55, 55, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
      stroke: #c23030; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger {
      color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }

.bp3-html-select.bp3-large select,
.bp3-select.bp3-large select {
  font-size: 16px;
  height: 40px;
  padding-right: 35px; }

.bp3-dark .bp3-html-select select, .bp3-dark .bp3-select select {
  background-color: #424A50;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  color: #f5f8fa; }
  .bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover, .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
    color: #f5f8fa; }
  .bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover {
    background-color: #383F45;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
    background-color: #252A2E;
    background-image: none;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-html-select select:disabled, .bp3-dark .bp3-select select:disabled, .bp3-dark .bp3-html-select select.bp3-disabled, .bp3-dark .bp3-select select.bp3-disabled {
    background-color: rgba(66, 74, 80, 0.5);
    background-image: none;
    box-shadow: none;
    color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-html-select select:disabled.bp3-active, .bp3-dark .bp3-select select:disabled.bp3-active, .bp3-dark .bp3-html-select select.bp3-disabled.bp3-active, .bp3-dark .bp3-select select.bp3-disabled.bp3-active {
      background: rgba(66, 74, 80, 0.7); }
  .bp3-dark .bp3-html-select select .bp3-button-spinner .bp3-spinner-head, .bp3-dark .bp3-select select .bp3-button-spinner .bp3-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #939A9F; }

.bp3-html-select select:disabled,
.bp3-select select:disabled {
  background-color: rgba(211, 216, 219, 0.5);
  box-shadow: none;
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed; }

.bp3-html-select .bp3-icon,
.bp3-select .bp3-icon, .bp3-select::after {
  color: #5c7080;
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 7px; }
  .bp3-html-select .bp3-disabled.bp3-icon,
  .bp3-select .bp3-disabled.bp3-icon, .bp3-disabled.bp3-select::after {
    color: rgba(92, 112, 128, 0.6); }

/*
HTML select

Markup:
<div class="bp3-html-select {{.modifier}}">
  <select {{:modifier}}>
    <option selected>Choose an item...</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
  <span class="bp3-icon bp3-icon-double-caret-vertical"></span>
</div>

:disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-select</code> for icon coloring (not shown below).
.bp3-fill - Expand to fill parent container
.bp3-large - Large size
.bp3-minimal - Minimal appearance

Styleguide select
*/
.bp3-html-select,
.bp3-select {
  display: inline-block;
  letter-spacing: normal;
  position: relative;
  vertical-align: middle; }
  .bp3-html-select select::-ms-expand,
  .bp3-select select::-ms-expand {
    display: none; }
  .bp3-html-select .bp3-icon,
  .bp3-select .bp3-icon {
    color: #5c7080; }
    .bp3-html-select .bp3-icon:hover,
    .bp3-select .bp3-icon:hover {
      color: #182026; }
    .bp3-dark .bp3-html-select .bp3-icon, .bp3-dark
    .bp3-select .bp3-icon {
      color: #a7b6c2; }
      .bp3-dark .bp3-html-select .bp3-icon:hover, .bp3-dark
      .bp3-select .bp3-icon:hover {
        color: #f5f8fa; }
  .bp3-html-select.bp3-large::after,
  .bp3-html-select.bp3-large .bp3-icon,
  .bp3-select.bp3-large::after,
  .bp3-select.bp3-large .bp3-icon {
    right: 12px;
    top: 12px; }
  .bp3-html-select.bp3-fill,
  .bp3-html-select.bp3-fill select,
  .bp3-select.bp3-fill,
  .bp3-select.bp3-fill select {
    width: 100%; }
  .bp3-dark .bp3-html-select option, .bp3-dark
  .bp3-select option {
    background-color: #383F45;
    color: #f5f8fa; }
  .bp3-dark .bp3-html-select option:disabled, .bp3-dark
  .bp3-select option:disabled {
    color: rgba(167, 182, 194, 0.6); }
  .bp3-dark .bp3-html-select::after, .bp3-dark
  .bp3-select::after {
    color: #a7b6c2; }

.bp3-select::after {
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: ""; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Tables

Markup:
<table class="bp3-html-table {{.modifier}}">
  <thead>
    <tr>
      <th>Project</th>
      <th>Description</th>
      <th>Technologies</th>
      <th>Contributors</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Blueprint</td>
      <td>CSS framework and UI toolkit</td>
      <td>Sass, TypeScript, React</td>
      <td>268</td>
    </tr>
    <tr>
      <td>TSLint</td>
      <td>Static analysis linter for TypeScript</td>
      <td>TypeScript</td>
      <td>403</td>
    </tr>
    <tr>
      <td>Plottable</td>
      <td>Composable charting library built on top of D3</td>
      <td>SVG, TypeScript, D3</td>
      <td>737</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan=3>Total</td>
      <td>1408</td>
    </tr>
  </tfoot>
</table>

.bp3-html-table-bordered - Bordered appearance
.bp3-html-table-condensed - Condensed smaller appearance
.bp3-html-table-striped - Striped appearance
.bp3-interactive - Enables hover styles on rows
.bp3-small - Small, condensed appearance for this element _and all child elements_

Styleguide html-table
*/
.bp3-running-text table, table.bp3-html-table {
  border-spacing: 0;
  font-size: 14px;
  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-enable selector-max-compound-selectors */ }
  .bp3-running-text table th, table.bp3-html-table th,
  .bp3-running-text table td,
  table.bp3-html-table td {
    padding: 11px;
    text-align: left;
    vertical-align: top; }
  .bp3-running-text table th, table.bp3-html-table th {
    color: #182026;
    font-weight: 600; }
  
  .bp3-running-text table td,
  table.bp3-html-table td {
    color: #182026; }
  .bp3-running-text table tbody tr:first-child th, table.bp3-html-table tbody tr:first-child th,
  .bp3-running-text table tbody tr:first-child td,
  table.bp3-html-table tbody tr:first-child td,
  .bp3-running-text table tfoot tr:first-child th,
  table.bp3-html-table tfoot tr:first-child th,
  .bp3-running-text table tfoot tr:first-child td,
  table.bp3-html-table tfoot tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp3-dark .bp3-running-text table th, .bp3-running-text .bp3-dark table th, .bp3-dark table.bp3-html-table th {
    color: #f5f8fa; }
  .bp3-dark .bp3-running-text table td, .bp3-running-text .bp3-dark table td, .bp3-dark table.bp3-html-table td {
    color: #f5f8fa; }
  .bp3-dark .bp3-running-text table tbody tr:first-child th, .bp3-running-text .bp3-dark table tbody tr:first-child th, .bp3-dark table.bp3-html-table tbody tr:first-child th,
  .bp3-dark .bp3-running-text table tbody tr:first-child td,
  .bp3-running-text .bp3-dark table tbody tr:first-child td,
  .bp3-dark table.bp3-html-table tbody tr:first-child td,
  .bp3-dark .bp3-running-text table tfoot tr:first-child th,
  .bp3-running-text .bp3-dark table tfoot tr:first-child th,
  .bp3-dark table.bp3-html-table tfoot tr:first-child th,
  .bp3-dark .bp3-running-text table tfoot tr:first-child td,
  .bp3-running-text .bp3-dark table tfoot tr:first-child td,
  .bp3-dark table.bp3-html-table tfoot tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }

table.bp3-html-table.bp3-html-table-condensed th,
table.bp3-html-table.bp3-html-table-condensed td, table.bp3-html-table.bp3-small th,
table.bp3-html-table.bp3-small td {
  padding-bottom: 6px;
  padding-top: 6px; }

table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(199, 203, 206, 0.15); }

table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered tbody tr td,
table.bp3-html-table.bp3-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
  table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child),
  table.bp3-html-table.bp3-html-table-bordered tfoot tr td:not(:first-child) {
    box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: none; }
  table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(199, 203, 206, 0.3);
  cursor: pointer; }

table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(199, 203, 206, 0.4); }

.bp3-dark table.bp3-html-table {
  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-enable selector-max-compound-selectors */ }
  .bp3-dark table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
    background: rgba(103, 111, 117, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td,
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered tfoot tr td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
    .bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child),
    .bp3-dark table.bp3-html-table.bp3-html-table-bordered tfoot tr td:not(:first-child) {
      box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
    box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15);
    /* stylelint-disable max-nesting-depth */ }
    .bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:first-child {
      box-shadow: none; }
  .bp3-dark table.bp3-html-table.bp3-interactive tbody tr:hover td {
    background-color: rgba(103, 111, 117, 0.3);
    cursor: pointer; }
  .bp3-dark table.bp3-html-table.bp3-interactive tbody tr:active td {
    background-color: rgba(103, 111, 117, 0.4); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
.bp3-key-combo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .bp3-key-combo > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-key-combo > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-key-combo::before,
  .bp3-key-combo > * {
    margin-right: 5px; }
  .bp3-key-combo:empty::before,
  .bp3-key-combo > :last-child {
    margin-right: 0; }

.bp3-hotkey-dialog {
  padding-bottom: 0;
  top: 40px; }
  .bp3-hotkey-dialog .bp3-dialog-body {
    margin: 0;
    padding: 0; }
  .bp3-hotkey-dialog .bp3-hotkey-label {
    flex-grow: 1; }

.bp3-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px; }
  .bp3-hotkey-column .bp3-heading {
    margin-bottom: 20px; }
    .bp3-hotkey-column .bp3-heading:not(:first-child) {
      margin-top: 40px; }

.bp3-hotkey {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0; }
  .bp3-hotkey:not(:last-child) {
    margin-bottom: 10px; }

/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-icon {
  display: inline-block;
  flex: 0 0 auto;
  vertical-align: text-bottom; }
  .bp3-icon:not(:empty)::before {
    /* stylelint-disable */
    content: "" !important;
    content: unset !important;
    /* stylelint-enable */ }
  .bp3-icon > svg {
    display: block; }
    .bp3-icon > svg:not([fill]) {
      fill: currentColor; }

.bp3-icon.bp3-intent-primary, .bp3-icon-standard.bp3-intent-primary, .bp3-icon-large.bp3-intent-primary {
  color: #106ba3; }
  .bp3-dark .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-icon-large.bp3-intent-primary {
    color: #48aff0; }

.bp3-icon.bp3-intent-success, .bp3-icon-standard.bp3-intent-success, .bp3-icon-large.bp3-intent-success {
  color: #0d8050; }
  .bp3-dark .bp3-icon.bp3-intent-success, .bp3-dark .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-icon-large.bp3-intent-success {
    color: #3dcc91; }

.bp3-icon.bp3-intent-warning, .bp3-icon-standard.bp3-intent-warning, .bp3-icon-large.bp3-intent-warning {
  color: #bf7326; }
  .bp3-dark .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-icon-large.bp3-intent-warning {
    color: #ffb366; }

.bp3-icon.bp3-intent-danger, .bp3-icon-standard.bp3-intent-danger, .bp3-icon-large.bp3-intent-danger {
  color: #c23030; }
  .bp3-dark .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-icon-large.bp3-intent-danger {
    color: #ff7373; }

span.bp3-icon-standard {
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon-large {
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon:empty {
  font-family: "Icons20";
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1; }
  span.bp3-icon:empty::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

.bp3-icon-add::before {
  content: ""; }

.bp3-icon-add-column-left::before {
  content: ""; }

.bp3-icon-add-column-right::before {
  content: ""; }

.bp3-icon-add-row-bottom::before {
  content: ""; }

.bp3-icon-add-row-top::before {
  content: ""; }

.bp3-icon-add-to-artifact::before {
  content: ""; }

.bp3-icon-add-to-folder::before {
  content: ""; }

.bp3-icon-airplane::before {
  content: ""; }

.bp3-icon-align-center::before {
  content: ""; }

.bp3-icon-align-justify::before {
  content: ""; }

.bp3-icon-align-left::before {
  content: ""; }

.bp3-icon-align-right::before {
  content: ""; }

.bp3-icon-alignment-bottom::before {
  content: ""; }

.bp3-icon-alignment-horizontal-center::before {
  content: ""; }

.bp3-icon-alignment-left::before {
  content: ""; }

.bp3-icon-alignment-right::before {
  content: ""; }

.bp3-icon-alignment-top::before {
  content: ""; }

.bp3-icon-alignment-vertical-center::before {
  content: ""; }

.bp3-icon-annotation::before {
  content: ""; }

.bp3-icon-application::before {
  content: ""; }

.bp3-icon-applications::before {
  content: ""; }

.bp3-icon-archive::before {
  content: ""; }

.bp3-icon-arrow-bottom-left::before {
  content: "↙"; }

.bp3-icon-arrow-bottom-right::before {
  content: "↘"; }

.bp3-icon-arrow-down::before {
  content: "↓"; }

.bp3-icon-arrow-left::before {
  content: "←"; }

.bp3-icon-arrow-right::before {
  content: "→"; }

.bp3-icon-arrow-top-left::before {
  content: "↖"; }

.bp3-icon-arrow-top-right::before {
  content: "↗"; }

.bp3-icon-arrow-up::before {
  content: "↑"; }

.bp3-icon-arrows-horizontal::before {
  content: "↔"; }

.bp3-icon-arrows-vertical::before {
  content: "↕"; }

.bp3-icon-asterisk::before {
  content: "*"; }

.bp3-icon-automatic-updates::before {
  content: ""; }

.bp3-icon-badge::before {
  content: ""; }

.bp3-icon-ban-circle::before {
  content: ""; }

.bp3-icon-bank-account::before {
  content: ""; }

.bp3-icon-barcode::before {
  content: ""; }

.bp3-icon-blank::before {
  content: ""; }

.bp3-icon-blocked-person::before {
  content: ""; }

.bp3-icon-bold::before {
  content: ""; }

.bp3-icon-book::before {
  content: ""; }

.bp3-icon-bookmark::before {
  content: ""; }

.bp3-icon-box::before {
  content: ""; }

.bp3-icon-briefcase::before {
  content: ""; }

.bp3-icon-bring-data::before {
  content: ""; }

.bp3-icon-build::before {
  content: ""; }

.bp3-icon-calculator::before {
  content: ""; }

.bp3-icon-calendar::before {
  content: ""; }

.bp3-icon-camera::before {
  content: ""; }

.bp3-icon-caret-down::before {
  content: "⌄"; }

.bp3-icon-caret-left::before {
  content: "〈"; }

.bp3-icon-caret-right::before {
  content: "〉"; }

.bp3-icon-caret-up::before {
  content: "⌃"; }

.bp3-icon-cell-tower::before {
  content: ""; }

.bp3-icon-changes::before {
  content: ""; }

.bp3-icon-chart::before {
  content: ""; }

.bp3-icon-chat::before {
  content: ""; }

.bp3-icon-chevron-backward::before {
  content: ""; }

.bp3-icon-chevron-down::before {
  content: ""; }

.bp3-icon-chevron-forward::before {
  content: ""; }

.bp3-icon-chevron-left::before {
  content: ""; }

.bp3-icon-chevron-right::before {
  content: ""; }

.bp3-icon-chevron-up::before {
  content: ""; }

.bp3-icon-circle::before {
  content: ""; }

.bp3-icon-circle-arrow-down::before {
  content: ""; }

.bp3-icon-circle-arrow-left::before {
  content: ""; }

.bp3-icon-circle-arrow-right::before {
  content: ""; }

.bp3-icon-circle-arrow-up::before {
  content: ""; }

.bp3-icon-citation::before {
  content: ""; }

.bp3-icon-clean::before {
  content: ""; }

.bp3-icon-clipboard::before {
  content: ""; }

.bp3-icon-cloud::before {
  content: "☁"; }

.bp3-icon-cloud-download::before {
  content: ""; }

.bp3-icon-cloud-upload::before {
  content: ""; }

.bp3-icon-code::before {
  content: ""; }

.bp3-icon-code-block::before {
  content: ""; }

.bp3-icon-cog::before {
  content: ""; }

.bp3-icon-collapse-all::before {
  content: ""; }

.bp3-icon-column-layout::before {
  content: ""; }

.bp3-icon-comment::before {
  content: ""; }

.bp3-icon-comparison::before {
  content: ""; }

.bp3-icon-compass::before {
  content: ""; }

.bp3-icon-compressed::before {
  content: ""; }

.bp3-icon-confirm::before {
  content: ""; }

.bp3-icon-console::before {
  content: ""; }

.bp3-icon-contrast::before {
  content: ""; }

.bp3-icon-control::before {
  content: ""; }

.bp3-icon-credit-card::before {
  content: ""; }

.bp3-icon-cross::before {
  content: "✗"; }

.bp3-icon-crown::before {
  content: ""; }

.bp3-icon-cube::before {
  content: ""; }

.bp3-icon-cube-add::before {
  content: ""; }

.bp3-icon-cube-remove::before {
  content: ""; }

.bp3-icon-curved-range-chart::before {
  content: ""; }

.bp3-icon-cut::before {
  content: ""; }

.bp3-icon-dashboard::before {
  content: ""; }

.bp3-icon-data-lineage::before {
  content: ""; }

.bp3-icon-database::before {
  content: ""; }

.bp3-icon-delete::before {
  content: ""; }

.bp3-icon-delta::before {
  content: "Δ"; }

.bp3-icon-derive-column::before {
  content: ""; }

.bp3-icon-desktop::before {
  content: ""; }

.bp3-icon-diagnosis::before {
  content: ""; }

.bp3-icon-diagram-tree::before {
  content: ""; }

.bp3-icon-direction-left::before {
  content: ""; }

.bp3-icon-direction-right::before {
  content: ""; }

.bp3-icon-disable::before {
  content: ""; }

.bp3-icon-document::before {
  content: ""; }

.bp3-icon-document-open::before {
  content: ""; }

.bp3-icon-document-share::before {
  content: ""; }

.bp3-icon-dollar::before {
  content: "$"; }

.bp3-icon-dot::before {
  content: "•"; }

.bp3-icon-double-caret-horizontal::before {
  content: ""; }

.bp3-icon-double-caret-vertical::before {
  content: ""; }

.bp3-icon-double-chevron-down::before {
  content: ""; }

.bp3-icon-double-chevron-left::before {
  content: ""; }

.bp3-icon-double-chevron-right::before {
  content: ""; }

.bp3-icon-double-chevron-up::before {
  content: ""; }

.bp3-icon-doughnut-chart::before {
  content: ""; }

.bp3-icon-download::before {
  content: ""; }

.bp3-icon-drag-handle-horizontal::before {
  content: ""; }

.bp3-icon-drag-handle-vertical::before {
  content: ""; }

.bp3-icon-draw::before {
  content: ""; }

.bp3-icon-drive-time::before {
  content: ""; }

.bp3-icon-duplicate::before {
  content: ""; }

.bp3-icon-edit::before {
  content: "✎"; }

.bp3-icon-eject::before {
  content: "⏏"; }

.bp3-icon-endorsed::before {
  content: ""; }

.bp3-icon-envelope::before {
  content: "✉"; }

.bp3-icon-equals::before {
  content: ""; }

.bp3-icon-eraser::before {
  content: ""; }

.bp3-icon-error::before {
  content: ""; }

.bp3-icon-euro::before {
  content: "€"; }

.bp3-icon-exchange::before {
  content: ""; }

.bp3-icon-exclude-row::before {
  content: ""; }

.bp3-icon-expand-all::before {
  content: ""; }

.bp3-icon-export::before {
  content: ""; }

.bp3-icon-eye-off::before {
  content: ""; }

.bp3-icon-eye-on::before {
  content: ""; }

.bp3-icon-eye-open::before {
  content: ""; }

.bp3-icon-fast-backward::before {
  content: ""; }

.bp3-icon-fast-forward::before {
  content: ""; }

.bp3-icon-feed::before {
  content: ""; }

.bp3-icon-feed-subscribed::before {
  content: ""; }

.bp3-icon-film::before {
  content: ""; }

.bp3-icon-filter::before {
  content: ""; }

.bp3-icon-filter-keep::before {
  content: ""; }

.bp3-icon-filter-list::before {
  content: ""; }

.bp3-icon-filter-open::before {
  content: ""; }

.bp3-icon-filter-remove::before {
  content: ""; }

.bp3-icon-flag::before {
  content: "⚑"; }

.bp3-icon-flame::before {
  content: ""; }

.bp3-icon-flash::before {
  content: ""; }

.bp3-icon-floppy-disk::before {
  content: ""; }

.bp3-icon-flow-branch::before {
  content: ""; }

.bp3-icon-flow-end::before {
  content: ""; }

.bp3-icon-flow-linear::before {
  content: ""; }

.bp3-icon-flow-review::before {
  content: ""; }

.bp3-icon-flow-review-branch::before {
  content: ""; }

.bp3-icon-flows::before {
  content: ""; }

.bp3-icon-folder-close::before {
  content: ""; }

.bp3-icon-folder-new::before {
  content: ""; }

.bp3-icon-folder-open::before {
  content: ""; }

.bp3-icon-folder-shared::before {
  content: ""; }

.bp3-icon-folder-shared-open::before {
  content: ""; }

.bp3-icon-follower::before {
  content: ""; }

.bp3-icon-following::before {
  content: ""; }

.bp3-icon-font::before {
  content: ""; }

.bp3-icon-fork::before {
  content: ""; }

.bp3-icon-form::before {
  content: ""; }

.bp3-icon-full-circle::before {
  content: ""; }

.bp3-icon-full-stacked-chart::before {
  content: ""; }

.bp3-icon-fullscreen::before {
  content: ""; }

.bp3-icon-function::before {
  content: ""; }

.bp3-icon-gantt-chart::before {
  content: ""; }

.bp3-icon-geolocation::before {
  content: ""; }

.bp3-icon-geosearch::before {
  content: ""; }

.bp3-icon-git-branch::before {
  content: ""; }

.bp3-icon-git-commit::before {
  content: ""; }

.bp3-icon-git-merge::before {
  content: ""; }

.bp3-icon-git-new-branch::before {
  content: ""; }

.bp3-icon-git-pull::before {
  content: ""; }

.bp3-icon-git-push::before {
  content: ""; }

.bp3-icon-git-repo::before {
  content: ""; }

.bp3-icon-glass::before {
  content: ""; }

.bp3-icon-globe::before {
  content: ""; }

.bp3-icon-globe-network::before {
  content: ""; }

.bp3-icon-graph::before {
  content: ""; }

.bp3-icon-graph-remove::before {
  content: ""; }

.bp3-icon-greater-than::before {
  content: ""; }

.bp3-icon-greater-than-or-equal-to::before {
  content: ""; }

.bp3-icon-grid::before {
  content: ""; }

.bp3-icon-grid-view::before {
  content: ""; }

.bp3-icon-group-objects::before {
  content: ""; }

.bp3-icon-grouped-bar-chart::before {
  content: ""; }

.bp3-icon-hand::before {
  content: ""; }

.bp3-icon-hand-down::before {
  content: ""; }

.bp3-icon-hand-left::before {
  content: ""; }

.bp3-icon-hand-right::before {
  content: ""; }

.bp3-icon-hand-up::before {
  content: ""; }

.bp3-icon-header::before {
  content: ""; }

.bp3-icon-header-one::before {
  content: ""; }

.bp3-icon-header-two::before {
  content: ""; }

.bp3-icon-headset::before {
  content: ""; }

.bp3-icon-heart::before {
  content: "♥"; }

.bp3-icon-heart-broken::before {
  content: ""; }

.bp3-icon-heat-grid::before {
  content: ""; }

.bp3-icon-heatmap::before {
  content: ""; }

.bp3-icon-help::before {
  content: "?"; }

.bp3-icon-helper-management::before {
  content: ""; }

.bp3-icon-highlight::before {
  content: ""; }

.bp3-icon-history::before {
  content: ""; }

.bp3-icon-home::before {
  content: "⌂"; }

.bp3-icon-horizontal-bar-chart::before {
  content: ""; }

.bp3-icon-horizontal-bar-chart-asc::before {
  content: ""; }

.bp3-icon-horizontal-bar-chart-desc::before {
  content: ""; }

.bp3-icon-horizontal-distribution::before {
  content: ""; }

.bp3-icon-id-number::before {
  content: ""; }

.bp3-icon-image-rotate-left::before {
  content: ""; }

.bp3-icon-image-rotate-right::before {
  content: ""; }

.bp3-icon-import::before {
  content: ""; }

.bp3-icon-inbox::before {
  content: ""; }

.bp3-icon-inbox-filtered::before {
  content: ""; }

.bp3-icon-inbox-geo::before {
  content: ""; }

.bp3-icon-inbox-search::before {
  content: ""; }

.bp3-icon-inbox-update::before {
  content: ""; }

.bp3-icon-info-sign::before {
  content: "ℹ"; }

.bp3-icon-inheritance::before {
  content: ""; }

.bp3-icon-inner-join::before {
  content: ""; }

.bp3-icon-insert::before {
  content: ""; }

.bp3-icon-intersection::before {
  content: ""; }

.bp3-icon-ip-address::before {
  content: ""; }

.bp3-icon-issue::before {
  content: ""; }

.bp3-icon-issue-closed::before {
  content: ""; }

.bp3-icon-issue-new::before {
  content: ""; }

.bp3-icon-italic::before {
  content: ""; }

.bp3-icon-join-table::before {
  content: ""; }

.bp3-icon-key::before {
  content: ""; }

.bp3-icon-key-backspace::before {
  content: ""; }

.bp3-icon-key-command::before {
  content: ""; }

.bp3-icon-key-control::before {
  content: ""; }

.bp3-icon-key-delete::before {
  content: ""; }

.bp3-icon-key-enter::before {
  content: ""; }

.bp3-icon-key-escape::before {
  content: ""; }

.bp3-icon-key-option::before {
  content: ""; }

.bp3-icon-key-shift::before {
  content: ""; }

.bp3-icon-key-tab::before {
  content: ""; }

.bp3-icon-known-vehicle::before {
  content: ""; }

.bp3-icon-lab-test::before {
  content: ""; }

.bp3-icon-label::before {
  content: ""; }

.bp3-icon-layer::before {
  content: ""; }

.bp3-icon-layers::before {
  content: ""; }

.bp3-icon-layout::before {
  content: ""; }

.bp3-icon-layout-auto::before {
  content: ""; }

.bp3-icon-layout-balloon::before {
  content: ""; }

.bp3-icon-layout-circle::before {
  content: ""; }

.bp3-icon-layout-grid::before {
  content: ""; }

.bp3-icon-layout-group-by::before {
  content: ""; }

.bp3-icon-layout-hierarchy::before {
  content: ""; }

.bp3-icon-layout-linear::before {
  content: ""; }

.bp3-icon-layout-skew-grid::before {
  content: ""; }

.bp3-icon-layout-sorted-clusters::before {
  content: ""; }

.bp3-icon-learning::before {
  content: ""; }

.bp3-icon-left-join::before {
  content: ""; }

.bp3-icon-less-than::before {
  content: ""; }

.bp3-icon-less-than-or-equal-to::before {
  content: ""; }

.bp3-icon-lifesaver::before {
  content: ""; }

.bp3-icon-lightbulb::before {
  content: ""; }

.bp3-icon-link::before {
  content: ""; }

.bp3-icon-list::before {
  content: "☰"; }

.bp3-icon-list-columns::before {
  content: ""; }

.bp3-icon-list-detail-view::before {
  content: ""; }

.bp3-icon-locate::before {
  content: ""; }

.bp3-icon-lock::before {
  content: ""; }

.bp3-icon-log-in::before {
  content: ""; }

.bp3-icon-log-out::before {
  content: ""; }

.bp3-icon-manual::before {
  content: ""; }

.bp3-icon-manually-entered-data::before {
  content: ""; }

.bp3-icon-map::before {
  content: ""; }

.bp3-icon-map-create::before {
  content: ""; }

.bp3-icon-map-marker::before {
  content: ""; }

.bp3-icon-maximize::before {
  content: ""; }

.bp3-icon-media::before {
  content: ""; }

.bp3-icon-menu::before {
  content: ""; }

.bp3-icon-menu-closed::before {
  content: ""; }

.bp3-icon-menu-open::before {
  content: ""; }

.bp3-icon-merge-columns::before {
  content: ""; }

.bp3-icon-merge-links::before {
  content: ""; }

.bp3-icon-minimize::before {
  content: ""; }

.bp3-icon-minus::before {
  content: "−"; }

.bp3-icon-mobile-phone::before {
  content: ""; }

.bp3-icon-mobile-video::before {
  content: ""; }

.bp3-icon-moon::before {
  content: ""; }

.bp3-icon-more::before {
  content: ""; }

.bp3-icon-mountain::before {
  content: ""; }

.bp3-icon-move::before {
  content: ""; }

.bp3-icon-mugshot::before {
  content: ""; }

.bp3-icon-multi-select::before {
  content: ""; }

.bp3-icon-music::before {
  content: ""; }

.bp3-icon-new-drawing::before {
  content: ""; }

.bp3-icon-new-grid-item::before {
  content: ""; }

.bp3-icon-new-layer::before {
  content: ""; }

.bp3-icon-new-layers::before {
  content: ""; }

.bp3-icon-new-link::before {
  content: ""; }

.bp3-icon-new-object::before {
  content: ""; }

.bp3-icon-new-person::before {
  content: ""; }

.bp3-icon-new-prescription::before {
  content: ""; }

.bp3-icon-new-text-box::before {
  content: ""; }

.bp3-icon-ninja::before {
  content: ""; }

.bp3-icon-not-equal-to::before {
  content: ""; }

.bp3-icon-notifications::before {
  content: ""; }

.bp3-icon-notifications-updated::before {
  content: ""; }

.bp3-icon-numbered-list::before {
  content: ""; }

.bp3-icon-numerical::before {
  content: ""; }

.bp3-icon-office::before {
  content: ""; }

.bp3-icon-offline::before {
  content: ""; }

.bp3-icon-oil-field::before {
  content: ""; }

.bp3-icon-one-column::before {
  content: ""; }

.bp3-icon-outdated::before {
  content: ""; }

.bp3-icon-page-layout::before {
  content: ""; }

.bp3-icon-panel-stats::before {
  content: ""; }

.bp3-icon-panel-table::before {
  content: ""; }

.bp3-icon-paperclip::before {
  content: ""; }

.bp3-icon-paragraph::before {
  content: ""; }

.bp3-icon-path::before {
  content: ""; }

.bp3-icon-path-search::before {
  content: ""; }

.bp3-icon-pause::before {
  content: ""; }

.bp3-icon-people::before {
  content: ""; }

.bp3-icon-percentage::before {
  content: ""; }

.bp3-icon-person::before {
  content: ""; }

.bp3-icon-phone::before {
  content: "☎"; }

.bp3-icon-pie-chart::before {
  content: ""; }

.bp3-icon-pin::before {
  content: ""; }

.bp3-icon-pivot::before {
  content: ""; }

.bp3-icon-pivot-table::before {
  content: ""; }

.bp3-icon-play::before {
  content: ""; }

.bp3-icon-plus::before {
  content: "+"; }

.bp3-icon-polygon-filter::before {
  content: ""; }

.bp3-icon-power::before {
  content: ""; }

.bp3-icon-predictive-analysis::before {
  content: ""; }

.bp3-icon-prescription::before {
  content: ""; }

.bp3-icon-presentation::before {
  content: ""; }

.bp3-icon-print::before {
  content: "⎙"; }

.bp3-icon-projects::before {
  content: ""; }

.bp3-icon-properties::before {
  content: ""; }

.bp3-icon-property::before {
  content: ""; }

.bp3-icon-publish-function::before {
  content: ""; }

.bp3-icon-pulse::before {
  content: ""; }

.bp3-icon-random::before {
  content: ""; }

.bp3-icon-record::before {
  content: ""; }

.bp3-icon-redo::before {
  content: ""; }

.bp3-icon-refresh::before {
  content: ""; }

.bp3-icon-regression-chart::before {
  content: ""; }

.bp3-icon-remove::before {
  content: ""; }

.bp3-icon-remove-column::before {
  content: ""; }

.bp3-icon-remove-column-left::before {
  content: ""; }

.bp3-icon-remove-column-right::before {
  content: ""; }

.bp3-icon-remove-row-bottom::before {
  content: ""; }

.bp3-icon-remove-row-top::before {
  content: ""; }

.bp3-icon-repeat::before {
  content: ""; }

.bp3-icon-reset::before {
  content: ""; }

.bp3-icon-resolve::before {
  content: ""; }

.bp3-icon-rig::before {
  content: ""; }

.bp3-icon-right-join::before {
  content: ""; }

.bp3-icon-ring::before {
  content: ""; }

.bp3-icon-rotate-document::before {
  content: ""; }

.bp3-icon-rotate-page::before {
  content: ""; }

.bp3-icon-satellite::before {
  content: ""; }

.bp3-icon-saved::before {
  content: ""; }

.bp3-icon-scatter-plot::before {
  content: ""; }

.bp3-icon-search::before {
  content: ""; }

.bp3-icon-search-around::before {
  content: ""; }

.bp3-icon-search-template::before {
  content: ""; }

.bp3-icon-search-text::before {
  content: ""; }

.bp3-icon-segmented-control::before {
  content: ""; }

.bp3-icon-select::before {
  content: ""; }

.bp3-icon-selection::before {
  content: "⦿"; }

.bp3-icon-send-to::before {
  content: ""; }

.bp3-icon-send-to-graph::before {
  content: ""; }

.bp3-icon-send-to-map::before {
  content: ""; }

.bp3-icon-series-add::before {
  content: ""; }

.bp3-icon-series-configuration::before {
  content: ""; }

.bp3-icon-series-derived::before {
  content: ""; }

.bp3-icon-series-filtered::before {
  content: ""; }

.bp3-icon-series-search::before {
  content: ""; }

.bp3-icon-settings::before {
  content: ""; }

.bp3-icon-share::before {
  content: ""; }

.bp3-icon-shield::before {
  content: ""; }

.bp3-icon-shop::before {
  content: ""; }

.bp3-icon-shopping-cart::before {
  content: ""; }

.bp3-icon-signal-search::before {
  content: ""; }

.bp3-icon-sim-card::before {
  content: ""; }

.bp3-icon-slash::before {
  content: ""; }

.bp3-icon-small-cross::before {
  content: ""; }

.bp3-icon-small-minus::before {
  content: ""; }

.bp3-icon-small-plus::before {
  content: ""; }

.bp3-icon-small-tick::before {
  content: ""; }

.bp3-icon-snowflake::before {
  content: ""; }

.bp3-icon-social-media::before {
  content: ""; }

.bp3-icon-sort::before {
  content: ""; }

.bp3-icon-sort-alphabetical::before {
  content: ""; }

.bp3-icon-sort-alphabetical-desc::before {
  content: ""; }

.bp3-icon-sort-asc::before {
  content: ""; }

.bp3-icon-sort-desc::before {
  content: ""; }

.bp3-icon-sort-numerical::before {
  content: ""; }

.bp3-icon-sort-numerical-desc::before {
  content: ""; }

.bp3-icon-split-columns::before {
  content: ""; }

.bp3-icon-square::before {
  content: ""; }

.bp3-icon-stacked-chart::before {
  content: ""; }

.bp3-icon-star::before {
  content: "★"; }

.bp3-icon-star-empty::before {
  content: "☆"; }

.bp3-icon-step-backward::before {
  content: ""; }

.bp3-icon-step-chart::before {
  content: ""; }

.bp3-icon-step-forward::before {
  content: ""; }

.bp3-icon-stop::before {
  content: ""; }

.bp3-icon-stopwatch::before {
  content: ""; }

.bp3-icon-strikethrough::before {
  content: ""; }

.bp3-icon-style::before {
  content: ""; }

.bp3-icon-swap-horizontal::before {
  content: ""; }

.bp3-icon-swap-vertical::before {
  content: ""; }

.bp3-icon-symbol-circle::before {
  content: ""; }

.bp3-icon-symbol-cross::before {
  content: ""; }

.bp3-icon-symbol-diamond::before {
  content: ""; }

.bp3-icon-symbol-square::before {
  content: ""; }

.bp3-icon-symbol-triangle-down::before {
  content: ""; }

.bp3-icon-symbol-triangle-up::before {
  content: ""; }

.bp3-icon-tag::before {
  content: ""; }

.bp3-icon-take-action::before {
  content: ""; }

.bp3-icon-taxi::before {
  content: ""; }

.bp3-icon-text-highlight::before {
  content: ""; }

.bp3-icon-th::before {
  content: ""; }

.bp3-icon-th-derived::before {
  content: ""; }

.bp3-icon-th-disconnect::before {
  content: ""; }

.bp3-icon-th-filtered::before {
  content: ""; }

.bp3-icon-th-list::before {
  content: ""; }

.bp3-icon-thumbs-down::before {
  content: ""; }

.bp3-icon-thumbs-up::before {
  content: ""; }

.bp3-icon-tick::before {
  content: "✓"; }

.bp3-icon-tick-circle::before {
  content: ""; }

.bp3-icon-time::before {
  content: "⏲"; }

.bp3-icon-timeline-area-chart::before {
  content: ""; }

.bp3-icon-timeline-bar-chart::before {
  content: ""; }

.bp3-icon-timeline-events::before {
  content: ""; }

.bp3-icon-timeline-line-chart::before {
  content: ""; }

.bp3-icon-tint::before {
  content: ""; }

.bp3-icon-torch::before {
  content: ""; }

.bp3-icon-tractor::before {
  content: ""; }

.bp3-icon-train::before {
  content: ""; }

.bp3-icon-translate::before {
  content: ""; }

.bp3-icon-trash::before {
  content: ""; }

.bp3-icon-tree::before {
  content: ""; }

.bp3-icon-trending-down::before {
  content: ""; }

.bp3-icon-trending-up::before {
  content: ""; }

.bp3-icon-truck::before {
  content: ""; }

.bp3-icon-two-columns::before {
  content: ""; }

.bp3-icon-unarchive::before {
  content: ""; }

.bp3-icon-underline::before {
  content: "⎁"; }

.bp3-icon-undo::before {
  content: "⎌"; }

.bp3-icon-ungroup-objects::before {
  content: ""; }

.bp3-icon-unknown-vehicle::before {
  content: ""; }

.bp3-icon-unlock::before {
  content: ""; }

.bp3-icon-unpin::before {
  content: ""; }

.bp3-icon-unresolve::before {
  content: ""; }

.bp3-icon-updated::before {
  content: ""; }

.bp3-icon-upload::before {
  content: ""; }

.bp3-icon-user::before {
  content: ""; }

.bp3-icon-variable::before {
  content: ""; }

.bp3-icon-vertical-bar-chart-asc::before {
  content: ""; }

.bp3-icon-vertical-bar-chart-desc::before {
  content: ""; }

.bp3-icon-vertical-distribution::before {
  content: ""; }

.bp3-icon-video::before {
  content: ""; }

.bp3-icon-volume-down::before {
  content: ""; }

.bp3-icon-volume-off::before {
  content: ""; }

.bp3-icon-volume-up::before {
  content: ""; }

.bp3-icon-walk::before {
  content: ""; }

.bp3-icon-warning-sign::before {
  content: ""; }

.bp3-icon-waterfall-chart::before {
  content: ""; }

.bp3-icon-widget::before {
  content: ""; }

.bp3-icon-widget-button::before {
  content: ""; }

.bp3-icon-widget-footer::before {
  content: ""; }

.bp3-icon-widget-header::before {
  content: ""; }

.bp3-icon-wrench::before {
  content: ""; }

.bp3-icon-zoom-in::before {
  content: ""; }

.bp3-icon-zoom-out::before {
  content: ""; }

.bp3-icon-zoom-to-fit::before {
  content: ""; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-submenu > .bp3-popover-wrapper {
  display: block; }

.bp3-submenu .bp3-popover-target {
  display: block; }
  .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */ }

.bp3-submenu.bp3-popover {
  box-shadow: none;
  padding: 0 5px; }
  .bp3-submenu.bp3-popover > .bp3-popover-content {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-submenu.bp3-popover, .bp3-submenu.bp3-popover.bp3-dark {
    box-shadow: none; }
    .bp3-dark .bp3-submenu.bp3-popover > .bp3-popover-content, .bp3-submenu.bp3-popover.bp3-dark > .bp3-popover-content {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

/*
Menus

Markup:
<ul class="bp3-menu {{.modifier}} bp3-elevation-1">
  <li>
    <a class="bp3-menu-item bp3-icon-people" tabindex="0">Share...</a>
  </li>
  <li>
    <a class="bp3-menu-item bp3-icon-circle-arrow-right" tabindex="0">Move...</a>
  </li>
  <li>
    <a class="bp3-menu-item bp3-icon-edit" tabindex="0">Rename</a>
  </li>
  <li class="bp3-menu-divider"></li>
  <li>
    <a class="bp3-menu-item bp3-icon-trash bp3-intent-danger" tabindex="0">Delete</a>
  </li>
</ul>

.bp3-large - Large size (only supported on <code>.bp3-menu</code>)

Styleguide menu
*/
.bp3-menu {
  background: #ffffff;
  border-radius: 3px;
  color: #182026;
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 5px;
  text-align: left; }

.bp3-menu-divider {
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  display: block;
  margin: 5px; }
  .bp3-dark .bp3-menu-divider {
    border-top-color: rgba(255, 255, 255, 0.15); }

.bp3-menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 2px;
  color: inherit;
  line-height: 20px;
  padding: 5px 7px;
  text-decoration: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */ }
  .bp3-menu-item > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-menu-item > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-menu-item::before,
  .bp3-menu-item > * {
    margin-right: 7px; }
  .bp3-menu-item:empty::before,
  .bp3-menu-item > :last-child {
    margin-right: 0; }
  .bp3-menu-item > .bp3-fill {
    word-break: break-word; }
  .bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    background-color: rgba(176, 181, 185, 0.3);
    cursor: pointer;
    text-decoration: none; }
  .bp3-menu-item.bp3-disabled {
    background-color: inherit;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed; }
  .bp3-dark .bp3-menu-item {
    color: inherit; }
    .bp3-dark .bp3-menu-item:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
      background-color: rgba(147, 154, 159, 0.15);
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-disabled {
      background-color: inherit;
      color: rgba(167, 182, 194, 0.6); }
  .bp3-menu-item.bp3-intent-primary {
    color: #106ba3; }
    .bp3-menu-item.bp3-intent-primary .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-primary::before, .bp3-menu-item.bp3-intent-primary::after,
    .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
      color: #106ba3; }
    .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary.bp3-active {
      background-color: #00D092;
      background-color: var(--brand3); }
    .bp3-menu-item.bp3-intent-primary:active {
      background-color: #106ba3; }
    .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary:active, .bp3-menu-item.bp3-intent-primary:active::before, .bp3-menu-item.bp3-intent-primary:active::after,
    .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-menu-item.bp3-intent-primary.bp3-active::after,
    .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item.bp3-intent-success {
    color: #0d8050; }
    .bp3-menu-item.bp3-intent-success .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-success::before, .bp3-menu-item.bp3-intent-success::after,
    .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
      color: #0d8050; }
    .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success.bp3-active {
      background-color: #07ae9a; }
    .bp3-menu-item.bp3-intent-success:active {
      background-color: #0d8050; }
    .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-menu-item.bp3-intent-success:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success:active, .bp3-menu-item.bp3-intent-success:active::before, .bp3-menu-item.bp3-intent-success:active::after,
    .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-menu-item.bp3-intent-success.bp3-active::after,
    .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item.bp3-intent-warning {
    color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-warning::before, .bp3-menu-item.bp3-intent-warning::after,
    .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
      color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning.bp3-active {
      background-color: #d9822b; }
    .bp3-menu-item.bp3-intent-warning:active {
      background-color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning:active, .bp3-menu-item.bp3-intent-warning:active::before, .bp3-menu-item.bp3-intent-warning:active::after,
    .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-menu-item.bp3-intent-warning.bp3-active::after,
    .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item.bp3-intent-danger {
    color: #c23030; }
    .bp3-menu-item.bp3-intent-danger .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-danger::before, .bp3-menu-item.bp3-intent-danger::after,
    .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
      color: #c23030; }
    .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger.bp3-active {
      background-color: #DB3737; }
    .bp3-menu-item.bp3-intent-danger:active {
      background-color: #c23030; }
    .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger:active, .bp3-menu-item.bp3-intent-danger:active::before, .bp3-menu-item.bp3-intent-danger:active::after,
    .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-menu-item.bp3-intent-danger.bp3-active::after,
    .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin-right: 7px; }
  .bp3-menu-item::before,
  .bp3-menu-item > .bp3-icon {
    color: #5c7080;
    margin-top: 2px; }
  .bp3-menu-item .bp3-menu-item-label {
    color: #5c7080; }
  .bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    color: inherit; }
  .bp3-menu-item.bp3-active, .bp3-menu-item:active {
    background-color: rgba(124, 133, 139, 0.3); }
  .bp3-menu-item.bp3-disabled {
    background-color: inherit !important;
    color: rgba(92, 112, 128, 0.6) !important;
    cursor: not-allowed !important;
    outline: none !important; }
    .bp3-menu-item.bp3-disabled::before,
    .bp3-menu-item.bp3-disabled > .bp3-icon,
    .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
      color: rgba(92, 112, 128, 0.6) !important; }
  .bp3-large .bp3-menu-item {
    font-size: 16px;
    line-height: 22px;
    padding: 9px 7px; }
    .bp3-large .bp3-menu-item .bp3-icon {
      margin-top: 3px; }
    .bp3-large .bp3-menu-item::before {
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-right: 10px;
      margin-top: 1px; }

button.bp3-menu-item {
  background: none;
  border: none;
  text-align: left;
  width: 100%; }

/*
Menu headers

Markup:
<ul class="bp3-menu bp3-elevation-1">
  <li class="bp3-menu-header"><h6 class="bp3-heading">Layouts</h6></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-auto">Auto</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-circle">Circle</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-grid">Grid</button></li>
  <li class="bp3-menu-header"><h6 class="bp3-heading">Views</h6></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-history">History</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-star">Favorites</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-envelope">Messages</button></li>
</ul>

Styleguide menu-header
*/
.bp3-menu-header {
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  display: block;
  margin: 5px;
  cursor: default;
  padding-left: 2px; }
  .bp3-dark .bp3-menu-header {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .bp3-menu-header:first-of-type {
    border-top: none; }
  .bp3-menu-header > h6 {
    color: #182026;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    line-height: 17px;
    margin: 0;
    padding: 10px 7px 0 1px; }
    .bp3-dark .bp3-menu-header > h6 {
      color: #f5f8fa; }
  .bp3-menu-header:first-of-type > h6 {
    padding-top: 0; }
  .bp3-large .bp3-menu-header > h6 {
    font-size: 18px;
    padding-bottom: 5px;
    padding-top: 15px; }
  .bp3-large .bp3-menu-header:first-of-type > h6 {
    padding-top: 0; }

.bp3-dark .bp3-menu {
  background: #383F45;
  color: #f5f8fa; }

.bp3-dark .bp3-menu-item {
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */ }
  .bp3-dark .bp3-menu-item.bp3-intent-primary {
    color: #48aff0; }
    .bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-icon {
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-intent-primary::before, .bp3-dark .bp3-menu-item.bp3-intent-primary::after,
    .bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
      color: #48aff0; }
    .bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active {
      background-color: #00D092;
      background-color: var(--brand3); }
    .bp3-dark .bp3-menu-item.bp3-intent-primary:active {
      background-color: #106ba3; }
    .bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
    .bp3-dark .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
    .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label,
    .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary:active, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-dark .bp3-menu-item.bp3-intent-success {
    color: #3dcc91; }
    .bp3-dark .bp3-menu-item.bp3-intent-success .bp3-icon {
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-intent-success::before, .bp3-dark .bp3-menu-item.bp3-intent-success::after,
    .bp3-dark .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
      color: #3dcc91; }
    .bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active {
      background-color: #07ae9a; }
    .bp3-dark .bp3-menu-item.bp3-intent-success:active {
      background-color: #0d8050; }
    .bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
    .bp3-dark .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
    .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label,
    .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success:active, .bp3-dark .bp3-menu-item.bp3-intent-success:active::before, .bp3-dark .bp3-menu-item.bp3-intent-success:active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning {
    color: #ffb366; }
    .bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-icon {
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-intent-warning::before, .bp3-dark .bp3-menu-item.bp3-intent-warning::after,
    .bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
      color: #ffb366; }
    .bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active {
      background-color: #d9822b; }
    .bp3-dark .bp3-menu-item.bp3-intent-warning:active {
      background-color: #bf7326; }
    .bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
    .bp3-dark .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
    .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label,
    .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning:active, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger {
    color: #ff7373; }
    .bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-icon {
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-intent-danger::before, .bp3-dark .bp3-menu-item.bp3-intent-danger::after,
    .bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
      color: #ff7373; }
    .bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active {
      background-color: #DB3737; }
    .bp3-dark .bp3-menu-item.bp3-intent-danger:active {
      background-color: #c23030; }
    .bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
    .bp3-dark .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
    .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label,
    .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger:active, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::after,
    .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-dark .bp3-menu-item::before,
  .bp3-dark .bp3-menu-item > .bp3-icon {
    color: #a7b6c2; }
  .bp3-dark .bp3-menu-item .bp3-menu-item-label {
    color: #a7b6c2; }
  .bp3-dark .bp3-menu-item.bp3-active, .bp3-dark .bp3-menu-item:active {
    background-color: rgba(147, 154, 159, 0.3); }
  .bp3-dark .bp3-menu-item.bp3-disabled {
    color: rgba(167, 182, 194, 0.6) !important; }
    .bp3-dark .bp3-menu-item.bp3-disabled::before,
    .bp3-dark .bp3-menu-item.bp3-disabled > .bp3-icon,
    .bp3-dark .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
      color: rgba(167, 182, 194, 0.6) !important; }

.bp3-dark .bp3-menu-divider,
.bp3-dark .bp3-menu-header {
  border-color: rgba(255, 255, 255, 0.15); }

.bp3-dark .bp3-menu-header > h6 {
  color: #f5f8fa; }

.bp3-label .bp3-menu {
  margin-top: 5px; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Navbars

Markup:
<nav class="bp3-navbar {{.modifier}}">
  <div class="bp3-navbar-group bp3-align-left">
    <div class="bp3-navbar-heading">Blueprint</div>
    <input class="bp3-input" placeholder="Search files..." type="text" />
  </div>
  <div class="bp3-navbar-group bp3-align-right">
    <button class="bp3-button bp3-minimal bp3-icon-home">Home</button>
    <button class="bp3-button bp3-minimal bp3-icon-document">Files</button>
    <span class="bp3-navbar-divider"></span>
    <button class="bp3-button bp3-minimal bp3-icon-user"></button>
    <button class="bp3-button bp3-minimal bp3-icon-notifications"></button>
    <button class="bp3-button bp3-minimal bp3-icon-cog"></button>
  </div>
</nav>

.bp3-dark - Dark theme

Styleguide navbar
*/
.bp3-navbar {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  height: 50px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 10; }
  .bp3-navbar.bp3-dark,
  .bp3-dark .bp3-navbar {
    background-color: #424A50; }
  .bp3-navbar.bp3-dark {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-navbar {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-navbar.bp3-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    top: 0; }

.bp3-navbar-heading {
  font-size: 16px;
  margin-right: 15px; }

.bp3-navbar-group {
  align-items: center;
  display: flex;
  height: 50px; }
  .bp3-navbar-group.bp3-align-left {
    float: left; }
  .bp3-navbar-group.bp3-align-right {
    float: right; }

.bp3-navbar-divider {
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  height: 20px;
  margin: 0 10px; }
  .bp3-dark .bp3-navbar-divider {
    border-left-color: rgba(255, 255, 255, 0.15); }

/*
Fixed width

Markup:
<nav class="bp3-navbar bp3-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="bp3-navbar-group bp3-align-left">
      <div class="bp3-navbar-heading">Blueprint</div>
    </div>
    <div class="bp3-navbar-group bp3-align-right">
      <button class="bp3-button bp3-minimal bp3-icon-home">Home</button>
      <button class="bp3-button bp3-minimal bp3-icon-document">Files</button>
      <span class="bp3-navbar-divider"></span>
      <button class="bp3-button bp3-minimal bp3-icon-user"></button>
      <button class="bp3-button bp3-minimal bp3-icon-notifications"></button>
      <button class="bp3-button bp3-minimal bp3-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide navbar-container
*/
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Non-ideal state

Markup:
<div class="bp3-non-ideal-state">
  <div class="bp3-non-ideal-state-visual">
    <span class="bp3-icon bp3-icon-folder-open"></span>
  </div>
  <h4 class="bp3-heading">This folder is empty</h4>
  <div>Create a new file to populate the folder.</div>
  <button class="bp3-button bp3-intent-primary">Create</button>
</div>

Styleguide non-ideal-state
*/
.bp3-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%; }
  .bp3-non-ideal-state > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-non-ideal-state > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-non-ideal-state::before,
  .bp3-non-ideal-state > * {
    margin-bottom: 20px; }
  .bp3-non-ideal-state:empty::before,
  .bp3-non-ideal-state > :last-child {
    margin-bottom: 0; }
  .bp3-non-ideal-state > * {
    max-width: 400px; }

.bp3-non-ideal-state-visual {
  color: rgba(92, 112, 128, 0.6);
  font-size: 60px; }
  .bp3-dark .bp3-non-ideal-state-visual {
    color: rgba(167, 182, 194, 0.6); }

.bp3-overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0; }

.bp3-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
body.bp3-overlay-open {
  overflow: hidden; }

.bp3-overlay {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
  top: 0;
  z-index: 20; }
  .bp3-overlay:not(.bp3-overlay-open) {
    pointer-events: none; }
  .bp3-overlay.bp3-overlay-container {
    overflow: hidden;
    position: fixed; }
    .bp3-overlay.bp3-overlay-container.bp3-overlay-inline {
      position: absolute; }
  .bp3-overlay.bp3-overlay-scroll-container {
    overflow: auto;
    position: fixed; }
    .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {
      position: absolute; }
  .bp3-overlay.bp3-overlay-inline {
    display: inline;
    overflow: visible; }

.bp3-overlay-content {
  position: fixed;
  z-index: 20; }
  .bp3-overlay-inline .bp3-overlay-content,
  .bp3-overlay-scroll-container .bp3-overlay-content {
    position: absolute; }

.bp3-overlay-backdrop {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 1;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 20; }
  .bp3-overlay-backdrop.bp3-overlay-enter, .bp3-overlay-backdrop.bp3-overlay-appear {
    opacity: 0; }
  .bp3-overlay-backdrop.bp3-overlay-enter-active, .bp3-overlay-backdrop.bp3-overlay-appear-active {
    opacity: 1;
    transition-delay: 0;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-overlay-backdrop.bp3-overlay-exit {
    opacity: 1; }
  .bp3-overlay-backdrop.bp3-overlay-exit-active {
    opacity: 0;
    transition-delay: 0;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-overlay-backdrop:focus {
    outline: none; }
  .bp3-overlay-inline .bp3-overlay-backdrop {
    position: absolute; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-panel-stack {
  overflow: hidden;
  position: relative; }

.bp3-panel-stack-header {
  align-items: center;
  box-shadow: 0 1px rgba(16, 22, 26, 0.15);
  display: flex;
  flex-shrink: 0;
  height: 30px;
  z-index: 1; }
  .bp3-dark .bp3-panel-stack-header {
    box-shadow: 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-panel-stack-header > span {
    align-items: stretch;
    display: flex;
    flex: 1 1; }
  .bp3-panel-stack-header .bp3-heading {
    margin: 0 5px; }

.bp3-button.bp3-panel-stack-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap; }
  .bp3-button.bp3-panel-stack-header-back .bp3-icon {
    margin: 0 2px; }

.bp3-panel-stack-view {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  overflow-y: auto;
  z-index: 1; }
  .bp3-dark .bp3-panel-stack-view {
    background-color: #383F45; }
  .bp3-panel-stack-view:nth-last-child(n + 4) {
    display: none; }

.bp3-panel-stack-push .bp3-panel-stack-enter, .bp3-panel-stack-push .bp3-panel-stack-appear {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0; }

.bp3-panel-stack-push .bp3-panel-stack-enter-active, .bp3-panel-stack-push .bp3-panel-stack-appear-active {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

.bp3-panel-stack-push .bp3-panel-stack-exit {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-push .bp3-panel-stack-exit-active {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

.bp3-panel-stack-pop .bp3-panel-stack-enter, .bp3-panel-stack-pop .bp3-panel-stack-appear {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-enter-active, .bp3-panel-stack-pop .bp3-panel-stack-appear-active {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

.bp3-panel-stack-pop .bp3-panel-stack-exit {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-pop .bp3-panel-stack-exit-active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-panel-stack2 {
  overflow: hidden;
  position: relative; }

.bp3-panel-stack2-header {
  align-items: center;
  box-shadow: 0 1px rgba(16, 22, 26, 0.15);
  display: flex;
  flex-shrink: 0;
  height: 30px;
  z-index: 1; }
  .bp3-dark .bp3-panel-stack2-header {
    box-shadow: 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-panel-stack2-header > span {
    align-items: stretch;
    display: flex;
    flex: 1 1; }
  .bp3-panel-stack2-header .bp3-heading {
    margin: 0 5px; }

.bp3-button.bp3-panel-stack2-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap; }
  .bp3-button.bp3-panel-stack2-header-back .bp3-icon {
    margin: 0 2px; }

.bp3-panel-stack2-view {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  overflow-y: auto;
  z-index: 1; }
  .bp3-dark .bp3-panel-stack2-view {
    background-color: #383F45; }
  .bp3-panel-stack2-view:nth-last-child(n + 4) {
    display: none; }

.bp3-panel-stack2-push .bp3-panel-stack2-enter, .bp3-panel-stack2-push .bp3-panel-stack2-appear {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0; }

.bp3-panel-stack2-push .bp3-panel-stack2-enter-active, .bp3-panel-stack2-push .bp3-panel-stack2-appear-active {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

.bp3-panel-stack2-push .bp3-panel-stack2-exit {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack2-push .bp3-panel-stack2-exit-active {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

.bp3-panel-stack2-pop .bp3-panel-stack2-enter, .bp3-panel-stack2-pop .bp3-panel-stack2-appear {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0; }

.bp3-panel-stack2-pop .bp3-panel-stack2-enter-active, .bp3-panel-stack2-pop .bp3-panel-stack2-appear-active {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

.bp3-panel-stack2-pop .bp3-panel-stack2-exit {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack2-pop .bp3-panel-stack2-exit-active {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-timing-function: ease; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-popover {
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 3px;
  display: inline-block;
  z-index: 20; }
  .bp3-popover .bp3-popover-arrow {
    height: 30px;
    position: absolute;
    width: 30px; }
    .bp3-popover .bp3-popover-arrow::before {
      height: 20px;
      margin: 5px;
      width: 20px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover {
    margin-bottom: 17px;
    margin-top: -17px; }
    .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
      bottom: -11px; }
      .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow svg {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg); }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover {
    margin-left: 17px; }
    .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
      left: -11px; }
      .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow svg {
        -webkit-transform: rotate(0);
                transform: rotate(0); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover {
    margin-top: 17px; }
    .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
      top: -11px; }
      .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow svg {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover {
    margin-left: -17px;
    margin-right: 17px; }
    .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
      right: -11px; }
      .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
  .bp3-tether-element-attached-middle > .bp3-popover > .bp3-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .bp3-tether-element-attached-center > .bp3-popover > .bp3-popover-arrow {
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
    top: -0.3934px; }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
    right: -0.3934px; }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
    left: -0.3934px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
    bottom: -0.3934px; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-popover {
    -webkit-transform-origin: top left;
            transform-origin: top left; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-popover {
    -webkit-transform-origin: top center;
            transform-origin: top center; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-popover {
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-popover {
    -webkit-transform-origin: center left;
            transform-origin: center left; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-popover {
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-popover {
    -webkit-transform-origin: center right;
            transform-origin: center right; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-popover {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-popover {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-popover {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
  .bp3-popover .bp3-popover-content {
    background: #ffffff; }
  .bp3-popover .bp3-popover-content,
  .bp3-popover .bp3-heading {
    color: inherit; }
  .bp3-popover .bp3-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .bp3-popover .bp3-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .bp3-popover .bp3-popover-arrow-fill {
    fill: #ffffff; }
  .bp3-popover-enter > .bp3-popover, .bp3-popover-appear > .bp3-popover {
    -webkit-transform: scale(0.3);
            transform: scale(0.3); }
  .bp3-popover-enter-active > .bp3-popover, .bp3-popover-appear-active > .bp3-popover {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-popover-exit > .bp3-popover {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .bp3-popover-exit-active > .bp3-popover {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-popover .bp3-popover-content {
    border-radius: 3px;
    position: relative; }
  .bp3-popover.bp3-popover-content-sizing .bp3-popover-content {
    max-width: 350px;
    padding: 20px; }
  .bp3-popover-target + .bp3-overlay .bp3-popover.bp3-popover-content-sizing {
    width: 350px; }
  .bp3-popover.bp3-minimal {
    margin: 0 !important;
    /* stylelint-disable-line declaration-no-important */ }
    .bp3-popover.bp3-minimal .bp3-popover-arrow {
      display: none; }
    .bp3-popover.bp3-minimal.bp3-popover {
      -webkit-transform: scale(1);
              transform: scale(1); }
      .bp3-popover-enter > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear > .bp3-popover.bp3-minimal.bp3-popover {
        -webkit-transform: scale(1);
                transform: scale(1); }
      .bp3-popover-enter-active > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear-active > .bp3-popover.bp3-minimal.bp3-popover {
        -webkit-transform: scale(1);
                transform: scale(1);
        transition-delay: 0;
        transition-duration: 100ms;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
      .bp3-popover-exit > .bp3-popover.bp3-minimal.bp3-popover {
        -webkit-transform: scale(1);
                transform: scale(1); }
      .bp3-popover-exit-active > .bp3-popover.bp3-minimal.bp3-popover {
        -webkit-transform: scale(1);
                transform: scale(1);
        transition-delay: 0;
        transition-duration: 100ms;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover.bp3-dark,
  .bp3-dark .bp3-popover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-popover.bp3-dark .bp3-popover-content,
    .bp3-dark .bp3-popover .bp3-popover-content {
      background: #383F45; }
    .bp3-popover.bp3-dark .bp3-popover-content,
    .bp3-popover.bp3-dark .bp3-heading,
    .bp3-dark .bp3-popover .bp3-popover-content,
    .bp3-dark .bp3-popover .bp3-heading {
      color: inherit; }
    .bp3-popover.bp3-dark .bp3-popover-arrow::before,
    .bp3-dark .bp3-popover .bp3-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .bp3-popover.bp3-dark .bp3-popover-arrow-border,
    .bp3-dark .bp3-popover .bp3-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .bp3-popover.bp3-dark .bp3-popover-arrow-fill,
    .bp3-dark .bp3-popover .bp3-popover-arrow-fill {
      fill: #383F45; }

.bp3-popover-arrow::before {
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.bp3-tether-pinned .bp3-popover-arrow {
  display: none; }

.bp3-popover-backdrop {
  background: rgba(255, 255, 255, 0); }

.bp3-transition-container {
  opacity: 1;
  display: flex;
  z-index: 20; }
  .bp3-transition-container.bp3-popover-enter, .bp3-transition-container.bp3-popover-appear {
    opacity: 0; }
  .bp3-transition-container.bp3-popover-enter-active, .bp3-transition-container.bp3-popover-appear-active {
    opacity: 1;
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-transition-container.bp3-popover-exit {
    opacity: 1; }
  .bp3-transition-container.bp3-popover-exit-active {
    opacity: 0;
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-transition-container:focus {
    outline: none; }
  .bp3-transition-container.bp3-popover-leave .bp3-popover-content {
    pointer-events: none; }
  .bp3-transition-container[data-x-out-of-boundaries] {
    display: none; }

span.bp3-popover-target {
  display: inline-block; }

.bp3-popover-wrapper.bp3-fill {
  width: 100%; }

.bp3-portal {
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Progress bars

Markup:
<div class="bp3-progress-bar {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 25%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-primary {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 50%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-success {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 75%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-danger {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 100%"></div>
</div>

.bp3-no-stripes   - No stripes
.bp3-no-animation - No animation

Styleguide progress-bar
*/
@-webkit-keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }
@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.bp3-progress-bar {
  background: rgba(103, 111, 117, 0.2);
  border-radius: 40px;
  display: block;
  height: 8px;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .bp3-progress-bar .bp3-progress-meter {
    background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
    background-color: gray;
    background-size: 30px 30px;
    border-radius: 40px;
    height: 100%;
    position: absolute;
    transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
    width: 100%; }
  .bp3-progress-bar:not(.bp3-no-animation):not(.bp3-no-stripes) .bp3-progress-meter {
    animation: linear-progress-bar-stripes 300ms linear infinite reverse; }
  .bp3-progress-bar.bp3-no-stripes .bp3-progress-meter {
    background-image: none; }

.bp3-dark .bp3-progress-bar {
  background: rgba(16, 22, 26, 0.5); }
  .bp3-dark .bp3-progress-bar .bp3-progress-meter {
    background-color: #939A9F; }

.bp3-progress-bar.bp3-intent-primary .bp3-progress-meter {
  background-color: #137cbd; }

.bp3-progress-bar.bp3-intent-success .bp3-progress-meter {
  background-color: #0f9960; }

.bp3-progress-bar.bp3-intent-warning .bp3-progress-meter {
  background-color: #d9822b; }

.bp3-progress-bar.bp3-intent-danger .bp3-progress-meter {
  background-color: #db3737; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Skeletons

Markup:
<div class="bp3-card">
  <h5 class="bp3-heading"><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="bp3-button bp3-icon-add {{.modifier}}" tabindex="-1">Submit</button>
</div>

.bp3-skeleton - Render this element as a skeleton, an outline of its true self.

Styleguide skeleton
*/
@-webkit-keyframes skeleton-glow {
  from {
    background: rgba(211, 216, 219, 0.2);
    border-color: rgba(211, 216, 219, 0.2); }
  to {
    background: rgba(103, 111, 117, 0.2);
    border-color: rgba(103, 111, 117, 0.2); } }
@keyframes skeleton-glow {
  from {
    background: rgba(211, 216, 219, 0.2);
    border-color: rgba(211, 216, 219, 0.2); }
  to {
    background: rgba(103, 111, 117, 0.2);
    border-color: rgba(103, 111, 117, 0.2); } }

/* stylelint-disable declaration-no-important */
.bp3-skeleton {
  -webkit-animation: 1000ms linear infinite alternate skeleton-glow;
          animation: 1000ms linear infinite alternate skeleton-glow;
  background: rgba(211, 216, 219, 0.2);
  background-clip: padding-box !important;
  border-color: rgba(211, 216, 219, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bp3-skeleton::before, .bp3-skeleton::after,
  .bp3-skeleton * {
    visibility: hidden !important; }

/* stylelint-enable declaration-no-important */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-slider {
  height: 40px;
  min-width: 150px;
  width: 100%;
  cursor: default;
  outline: none;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bp3-slider:hover {
    cursor: pointer; }
  .bp3-slider:active {
    cursor: grabbing; }
  .bp3-slider.bp3-disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .bp3-slider.bp3-slider-unlabeled {
    height: 16px; }

.bp3-slider-track,
.bp3-slider-progress {
  height: 6px;
  left: 0;
  right: 0;
  top: 5px;
  position: absolute; }

.bp3-slider-track {
  border-radius: 3px;
  overflow: hidden; }

.bp3-slider-progress {
  background: rgba(103, 111, 117, 0.2); }
  .bp3-dark .bp3-slider-progress {
    background: rgba(16, 22, 26, 0.5); }
  .bp3-slider-progress.bp3-intent-primary {
    background-color: #137cbd; }
  .bp3-slider-progress.bp3-intent-success {
    background-color: #0f9960; }
  .bp3-slider-progress.bp3-intent-warning {
    background-color: #d9822b; }
  .bp3-slider-progress.bp3-intent-danger {
    background-color: #db3737; }

.bp3-slider-handle {
  background-color: #F7F8F8;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  color: #182026;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .bp3-slider-handle:hover {
    background-clip: padding-box;
    background-color: #EFF0F2;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
  .bp3-slider-handle:active, .bp3-slider-handle.bp3-active {
    background-color: #DDE0E3;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-slider-handle:disabled, .bp3-slider-handle.bp3-disabled {
    background-color: rgba(211, 216, 219, 0.5);
    background-image: none;
    box-shadow: none;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
    outline: none; }
    .bp3-slider-handle:disabled.bp3-active, .bp3-slider-handle:disabled.bp3-active:hover, .bp3-slider-handle.bp3-disabled.bp3-active, .bp3-slider-handle.bp3-disabled.bp3-active:hover {
      background: rgba(211, 216, 219, 0.7); }
  .bp3-slider-handle:focus {
    z-index: 1; }
  .bp3-slider-handle:hover {
    background-clip: padding-box;
    background-color: #EFF0F2;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
    cursor: grab;
    z-index: 2; }
  .bp3-slider-handle.bp3-active {
    background-color: #DDE0E3;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 1px rgba(16, 22, 26, 0.1);
    cursor: grabbing; }
  .bp3-disabled .bp3-slider-handle {
    background: #C7CBCE;
    box-shadow: none;
    pointer-events: none; }
  .bp3-dark .bp3-slider-handle {
    background-color: #424A50;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #f5f8fa; }
    .bp3-dark .bp3-slider-handle:hover, .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
      color: #f5f8fa; }
    .bp3-dark .bp3-slider-handle:hover {
      background-color: #383F45;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
      background-color: #252A2E;
      background-image: none;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-slider-handle:disabled, .bp3-dark .bp3-slider-handle.bp3-disabled {
      background-color: rgba(66, 74, 80, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(167, 182, 194, 0.6); }
      .bp3-dark .bp3-slider-handle:disabled.bp3-active, .bp3-dark .bp3-slider-handle.bp3-disabled.bp3-active {
        background: rgba(66, 74, 80, 0.7); }
    .bp3-dark .bp3-slider-handle .bp3-button-spinner .bp3-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #939A9F; }
    .bp3-dark .bp3-slider-handle, .bp3-dark .bp3-slider-handle:hover {
      background-color: #424A50; }
    .bp3-dark .bp3-slider-handle.bp3-active {
      background-color: #30363B; }
  .bp3-dark .bp3-disabled .bp3-slider-handle {
    background: #676F75;
    border-color: #676F75;
    box-shadow: none; }
  .bp3-slider-handle .bp3-slider-label {
    background: #424A50;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
    color: #F7F8F8;
    margin-left: 8px; }
    .bp3-dark .bp3-slider-handle .bp3-slider-label {
      background: #E5E7E9;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
      color: #424A50; }
    .bp3-disabled .bp3-slider-handle .bp3-slider-label {
      box-shadow: none; }
  .bp3-slider-handle.bp3-start, .bp3-slider-handle.bp3-end {
    width: 8px; }
  .bp3-slider-handle.bp3-start {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .bp3-slider-handle.bp3-end {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 8px; }
    .bp3-slider-handle.bp3-end .bp3-slider-label {
      margin-left: 0; }

.bp3-slider-label {
  -webkit-transform: translate(-50%, 20px);
          transform: translate(-50%, 20px);
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 2px 5px;
  position: absolute;
  vertical-align: top; }

.bp3-slider.bp3-vertical {
  height: 150px;
  min-width: 40px;
  width: 40px; }
  .bp3-slider.bp3-vertical .bp3-slider-track,
  .bp3-slider.bp3-vertical .bp3-slider-progress {
    bottom: 0;
    height: auto;
    left: 5px;
    top: 0;
    width: 6px; }
  .bp3-slider.bp3-vertical .bp3-slider-progress {
    top: auto; }
  .bp3-slider.bp3-vertical .bp3-slider-label {
    -webkit-transform: translate(20px, 50%);
            transform: translate(20px, 50%); }
  .bp3-slider.bp3-vertical .bp3-slider-handle {
    top: auto; }
    .bp3-slider.bp3-vertical .bp3-slider-handle .bp3-slider-label {
      margin-left: 0;
      margin-top: -8px; }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end, .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
      height: 8px;
      margin-left: 0;
      width: 16px; }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0; }
      .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start .bp3-slider-label {
        -webkit-transform: translate(20px);
                transform: translate(20px); }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 3px;
      margin-bottom: 8px; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
@-webkit-keyframes pt-spinner-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes pt-spinner-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.bp3-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: visible;
  vertical-align: middle; }
  .bp3-spinner svg {
    display: block; }
  .bp3-spinner path {
    fill-opacity: 0; }
  .bp3-spinner .bp3-spinner-head {
    stroke: gray;
    stroke-linecap: round;
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-spinner .bp3-spinner-track {
    stroke: rgba(103, 111, 117, 0.2); }

.bp3-spinner-animation {
  -webkit-animation: pt-spinner-animation 500ms linear infinite;
          animation: pt-spinner-animation 500ms linear infinite; }
  .bp3-no-spin > .bp3-spinner-animation {
    -webkit-animation: none;
            animation: none; }

.bp3-dark .bp3-spinner .bp3-spinner-head {
  stroke: #939A9F; }

.bp3-dark .bp3-spinner .bp3-spinner-track {
  stroke: rgba(16, 22, 26, 0.5); }

.bp3-spinner.bp3-intent-primary .bp3-spinner-head {
  stroke: #137cbd; }

.bp3-spinner.bp3-intent-success .bp3-spinner-head {
  stroke: #0f9960; }

.bp3-spinner.bp3-intent-warning .bp3-spinner-head {
  stroke: #d9822b; }

.bp3-spinner.bp3-intent-danger .bp3-spinner-head {
  stroke: #db3737; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Tabs

Markup:
<div class="bp3-tabs">
    <ul class="bp3-tab-list {{.modifier}}" role="tablist">
        <li class="bp3-tab" role="tab" aria-selected="true">Selected tab</li>
        <li class="bp3-tab" role="tab">Another tab</li>
        <li class="bp3-tab" role="tab" aria-disabled="true">Disabled tab</li>
    </ul>
    <div class="bp3-tab-panel" role="tabpanel">Selected panel</div>
    <div class="bp3-tab-panel" role="tabpanel" aria-hidden="true">Another panel</div>
    <div class="bp3-tab-panel" role="tabpanel" aria-hidden="true">Disabled panel</div>
</div>

.bp3-large - Large tabs

Styleguide tabs
*/
.bp3-tabs.bp3-vertical {
  display: flex; }
  .bp3-tabs.bp3-vertical > .bp3-tab-list {
    align-items: flex-start;
    flex-direction: column; }
    .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab {
      border-radius: 3px;
      padding: 0 10px;
      width: 100%; }
      .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"] {
        background-color: rgba(#00D092, 0.2);
        background-color: rgba(var(--brand3), 0.2);
        box-shadow: none; }
    .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab-indicator-wrapper .bp3-tab-indicator {
      background-color: rgba(#00D092, 0.2);
      background-color: rgba(var(--brand3), 0.2);
      border-radius: 3px;
      bottom: 0;
      height: auto;
      left: 0;
      right: 0;
      top: 0; }
  .bp3-tabs.bp3-vertical > .bp3-tab-panel {
    margin-top: 0;
    padding-left: 20px; }

.bp3-tab-list {
  align-items: flex-end;
  border: none;
  display: flex;
  flex: 0 0 auto;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  /* stylelint-disable-next-line selector-no-universal */ }
  .bp3-tab-list > *:not(:last-child) {
    margin-right: 20px; }

.bp3-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #182026;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 30px;
  max-width: 100%;
  position: relative;
  vertical-align: top; }
  .bp3-tab a {
    color: inherit;
    display: block;
    text-decoration: none; }
  .bp3-tab-indicator-wrapper ~ .bp3-tab {
    /* stylelint-disable declaration-no-important */
    background-color: transparent !important;
    box-shadow: none !important;
    /* stylelint-enable declaration-no-important */ }
  .bp3-tab[aria-disabled="true"] {
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed; }
  .bp3-tab[aria-selected="true"] {
    border-radius: 0;
    box-shadow: inset 0 -3px 0 #106ba3; }
  .bp3-tab[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
    color: #106ba3; }
  .bp3-tab:focus {
    -moz-outline-radius: 0; }
  .bp3-large > .bp3-tab {
    font-size: 16px;
    line-height: 40px; }

.bp3-tab-panel {
  margin-top: 20px; }
  .bp3-tab-panel[aria-hidden="true"] {
    display: none; }

.bp3-tab-indicator-wrapper {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(0), translateY(0);
          transform: translateX(0), translateY(0);
  transition: height, width, -webkit-transform;
  transition: height, transform, width;
  transition: height, transform, width, -webkit-transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: #106ba3;
    bottom: 0;
    height: 3px;
    left: 0;
    position: absolute;
    right: 0; }
  .bp3-tab-indicator-wrapper.bp3-no-animation {
    transition: none; }

.bp3-dark .bp3-tab {
  color: #f5f8fa; }
  .bp3-dark .bp3-tab[aria-disabled="true"] {
    color: rgba(167, 182, 194, 0.6); }
  .bp3-dark .bp3-tab[aria-selected="true"] {
    box-shadow: inset 0 -3px 0 #48aff0; }
  .bp3-dark .bp3-tab[aria-selected="true"], .bp3-dark .bp3-tab:not([aria-disabled="true"]):hover {
    color: #48aff0; }

.bp3-dark .bp3-tab-indicator {
  background-color: #48aff0; }

.bp3-flex-expander {
  flex: 1 1; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
Tags

Markup:
<span class="bp3-tag {{.modifier}}">125</span>
<span class="bp3-tag {{.modifier}}">Done</span>
<span class="bp3-tag {{.modifier}}">
  Tracking
  <button class="bp3-tag-remove"></button>
</span>
<span class="bp3-tag {{.modifier}}">
  Crushed
  <button class="bp3-tag-remove"></button>
</span>
<span class="bp3-tag {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="bp3-tag-remove"></button>
</span>

.bp3-large - Large
.bp3-minimal - Minimal appearance
.bp3-round - Rounded corners, ideal for badges
.bp3-interactive - Hover and active effects
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger  - Danger intent

Styleguide tag
*/
.bp3-tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: #676F75;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  color: #f5f8fa;
  font-size: 12px;
  line-height: 16px;
  max-width: 100%;
  min-height: 20px;
  min-width: 20px;
  padding: 2px 6px;
  position: relative; }
  .bp3-tag.bp3-interactive {
    cursor: pointer; }
    .bp3-tag.bp3-interactive:hover {
      background-color: rgba(103, 111, 117, 0.85); }
    .bp3-tag.bp3-interactive.bp3-active, .bp3-tag.bp3-interactive:active {
      background-color: rgba(103, 111, 117, 0.7); }
  .bp3-tag > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-tag > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-tag::before,
  .bp3-tag > * {
    margin-right: 4px; }
  .bp3-tag:empty::before,
  .bp3-tag > :last-child {
    margin-right: 0; }
  .bp3-tag:focus {
    outline: rgba(19, 124, 189, 0.6) auto 2px;
    outline-offset: 0;
    -moz-outline-radius: 6px; }
  .bp3-tag.bp3-round {
    border-radius: 30px;
    padding-left: 8px;
    padding-right: 8px; }
  .bp3-dark .bp3-tag {
    background-color: #C7CBCE;
    color: #182026; }
    .bp3-dark .bp3-tag.bp3-interactive {
      cursor: pointer; }
      .bp3-dark .bp3-tag.bp3-interactive:hover {
        background-color: rgba(199, 203, 206, 0.85); }
      .bp3-dark .bp3-tag.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-interactive:active {
        background-color: rgba(199, 203, 206, 0.7); }
    .bp3-dark .bp3-tag > .bp3-icon, .bp3-dark .bp3-tag .bp3-icon-standard, .bp3-dark .bp3-tag .bp3-icon-large {
      fill: currentColor; }
  .bp3-tag > .bp3-icon, .bp3-tag .bp3-icon-standard, .bp3-tag .bp3-icon-large {
    fill: #ffffff; }
  .bp3-tag.bp3-large,
  .bp3-large .bp3-tag {
    font-size: 14px;
    line-height: 20px;
    min-height: 30px;
    min-width: 30px;
    padding: 5px 10px; }
    .bp3-tag.bp3-large::before,
    .bp3-tag.bp3-large > *,
    .bp3-large .bp3-tag::before,
    .bp3-large .bp3-tag > * {
      margin-right: 7px; }
    .bp3-tag.bp3-large:empty::before,
    .bp3-tag.bp3-large > :last-child,
    .bp3-large .bp3-tag:empty::before,
    .bp3-large .bp3-tag > :last-child {
      margin-right: 0; }
    .bp3-tag.bp3-large.bp3-round,
    .bp3-large .bp3-tag.bp3-round {
      padding-left: 12px;
      padding-right: 12px; }
  .bp3-tag.bp3-intent-primary {
    background: #137cbd;
    color: #ffffff; }
    .bp3-tag.bp3-intent-primary.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-primary.bp3-interactive:hover {
        background-color: rgba(19, 124, 189, 0.85); }
      .bp3-tag.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-primary.bp3-interactive:active {
        background-color: rgba(19, 124, 189, 0.7); }
  .bp3-tag.bp3-intent-success {
    background: #0f9960;
    color: #ffffff; }
    .bp3-tag.bp3-intent-success.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-success.bp3-interactive:hover {
        background-color: rgba(15, 153, 96, 0.85); }
      .bp3-tag.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-success.bp3-interactive:active {
        background-color: rgba(15, 153, 96, 0.7); }
  .bp3-tag.bp3-intent-warning {
    background: #d9822b;
    color: #ffffff; }
    .bp3-tag.bp3-intent-warning.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-warning.bp3-interactive:hover {
        background-color: rgba(217, 130, 43, 0.85); }
      .bp3-tag.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-warning.bp3-interactive:active {
        background-color: rgba(217, 130, 43, 0.7); }
  .bp3-tag.bp3-intent-danger {
    background: #db3737;
    color: #ffffff; }
    .bp3-tag.bp3-intent-danger.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-danger.bp3-interactive:hover {
        background-color: rgba(219, 55, 55, 0.85); }
      .bp3-tag.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-danger.bp3-interactive:active {
        background-color: rgba(219, 55, 55, 0.7); }
  .bp3-tag.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-tag.bp3-minimal > .bp3-icon, .bp3-tag.bp3-minimal .bp3-icon-standard, .bp3-tag.bp3-minimal .bp3-icon-large {
    fill: #5c7080; }
  .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
    background-color: rgba(147, 154, 159, 0.2);
    color: #182026; }
    .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
        background-color: rgba(103, 111, 117, 0.3); }
      .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
        background-color: rgba(103, 111, 117, 0.4); }
    .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
      color: #f5f8fa; }
      .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
          background-color: rgba(199, 203, 206, 0.3); }
        .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
          background-color: rgba(199, 203, 206, 0.4); }
      .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) > .bp3-icon, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-large {
        fill: #a7b6c2; }
  .bp3-tag.bp3-minimal.bp3-intent-primary {
    background-color: rgba(19, 124, 189, 0.15);
    color: #106ba3; }
    .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
        background-color: rgba(19, 124, 189, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
        background-color: rgba(19, 124, 189, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-primary > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-large {
      fill: #137cbd; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary {
      background-color: rgba(19, 124, 189, 0.25);
      color: #48aff0; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
          background-color: rgba(19, 124, 189, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
          background-color: rgba(19, 124, 189, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15);
    color: #0d8050; }
    .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
        background-color: rgba(15, 153, 96, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
        background-color: rgba(15, 153, 96, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-success > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-large {
      fill: #0f9960; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success {
      background-color: rgba(15, 153, 96, 0.25);
      color: #3dcc91; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
          background-color: rgba(15, 153, 96, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
          background-color: rgba(15, 153, 96, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15);
    color: #bf7326; }
    .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
        background-color: rgba(217, 130, 43, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
        background-color: rgba(217, 130, 43, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-warning > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-large {
      fill: #d9822b; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning {
      background-color: rgba(217, 130, 43, 0.25);
      color: #ffb366; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
          background-color: rgba(217, 130, 43, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
          background-color: rgba(217, 130, 43, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15);
    color: #c23030; }
    .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
        background-color: rgba(219, 55, 55, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
        background-color: rgba(219, 55, 55, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-danger > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-large {
      fill: #db3737; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger {
      background-color: rgba(219, 55, 55, 0.25);
      color: #ff7373; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
          background-color: rgba(219, 55, 55, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
          background-color: rgba(219, 55, 55, 0.45); }

.bp3-tag-remove {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  margin-bottom: -2px;
  /* stylelint-disable-next-line declaration-no-important */
  margin-right: -6px !important;
  margin-top: -2px;
  opacity: 0.5;
  padding: 2px;
  padding-left: 0; }
  .bp3-tag-remove:hover {
    background: none;
    opacity: 0.8;
    text-decoration: none; }
  .bp3-tag-remove:active {
    opacity: 1; }
  .bp3-tag-remove:empty::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: ""; }
  .bp3-large .bp3-tag-remove {
    /* stylelint-disable-next-line declaration-no-important */
    margin-right: -10px !important;
    padding: 0 5px 0 0; }
    .bp3-large .bp3-tag-remove:empty::before {
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-tag-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: text;
  height: auto;
  line-height: inherit;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 0; }
  .bp3-tag-input > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-tag-input > .bp3-tag-input-values {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-tag-input .bp3-tag-input-icon {
    color: #5c7080;
    margin-left: 2px;
    margin-right: 7px;
    margin-top: 7px; }
  .bp3-tag-input .bp3-tag-input-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    margin-right: 7px;
    margin-top: 5px;
    min-width: 0; }
    .bp3-tag-input .bp3-tag-input-values > * {
      flex-grow: 0;
      flex-shrink: 0; }
    .bp3-tag-input .bp3-tag-input-values > .bp3-fill {
      flex-grow: 1;
      flex-shrink: 1; }
    .bp3-tag-input .bp3-tag-input-values::before,
    .bp3-tag-input .bp3-tag-input-values > * {
      margin-right: 5px; }
    .bp3-tag-input .bp3-tag-input-values:empty::before,
    .bp3-tag-input .bp3-tag-input-values > :last-child {
      margin-right: 0; }
    .bp3-tag-input .bp3-tag-input-values:first-child .bp3-input-ghost:first-child {
      padding-left: 5px; }
    .bp3-tag-input .bp3-tag-input-values > * {
      margin-bottom: 5px; }
  .bp3-tag-input .bp3-tag {
    overflow-wrap: break-word; }
    .bp3-tag-input .bp3-tag.bp3-active {
      outline: rgba(19, 124, 189, 0.6) auto 2px;
      outline-offset: 0;
      -moz-outline-radius: 6px; }
  .bp3-tag-input .bp3-input-ghost {
    flex: 1 1 auto;
    line-height: 20px;
    width: 80px; }
    .bp3-tag-input .bp3-input-ghost:disabled, .bp3-tag-input .bp3-input-ghost.bp3-disabled {
      cursor: not-allowed; }
  .bp3-tag-input .bp3-button,
  .bp3-tag-input .bp3-spinner {
    margin: 3px;
    margin-left: 0; }
  .bp3-tag-input .bp3-button {
    min-height: 24px;
    min-width: 24px;
    padding: 0 7px; }
  .bp3-tag-input.bp3-large {
    height: auto;
    min-height: 40px; }
    .bp3-tag-input.bp3-large::before,
    .bp3-tag-input.bp3-large > * {
      margin-right: 10px; }
    .bp3-tag-input.bp3-large:empty::before,
    .bp3-tag-input.bp3-large > :last-child {
      margin-right: 0; }
    .bp3-tag-input.bp3-large .bp3-tag-input-icon {
      margin-left: 5px;
      margin-top: 10px; }
    .bp3-tag-input.bp3-large .bp3-input-ghost {
      line-height: 30px; }
    .bp3-tag-input.bp3-large .bp3-button {
      min-height: 30px;
      min-width: 30px;
      padding: 5px 10px;
      margin: 5px;
      margin-left: 0; }
    .bp3-tag-input.bp3-large .bp3-spinner {
      margin: 8px;
      margin-left: 0; }
  .bp3-tag-input.bp3-active {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-primary {
      box-shadow: 0 0 0 1px #106ba3, 0 0 0 3px rgba(16, 107, 163, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-success {
      box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-warning {
      box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-danger {
      box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-tag-input .bp3-tag-input-icon, .bp3-tag-input.bp3-dark .bp3-tag-input-icon {
    color: #a7b6c2; }
  .bp3-dark .bp3-tag-input .bp3-input-ghost, .bp3-tag-input.bp3-dark .bp3-input-ghost {
    color: #f5f8fa; }
    .bp3-dark .bp3-tag-input .bp3-input-ghost::-webkit-input-placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::-webkit-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-tag-input .bp3-input-ghost:-ms-input-placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost:-ms-input-placeholder {
      color: rgba(167, 182, 194, 0.6); }
    .bp3-dark .bp3-tag-input .bp3-input-ghost::placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::placeholder {
      color: rgba(167, 182, 194, 0.6); }
  .bp3-dark .bp3-tag-input.bp3-active, .bp3-tag-input.bp3-dark.bp3-active {
    background-color: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 1px rgba(#00D092, 1), 0 0 0 1px rgba(#00D092, 1), 0 0 0 3px rgba(#00D092, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 1px rgba(var(--brand3), 1), 0 0 0 3px rgba(var(--brand3), 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-primary, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-primary {
      box-shadow: 0 0 0 1px #106ba3, 0 0 0 3px rgba(16, 107, 163, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-success, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-success {
      box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-warning, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-warning {
      box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-danger, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-danger {
      box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-input-ghost {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0; }
  .bp3-input-ghost::-webkit-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-input-ghost:-ms-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-input-ghost::placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1; }
  .bp3-input-ghost:focus {
    /* stylelint-disable-next-line declaration-no-important */
    outline: none !important; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-toast {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  display: flex;
  margin: 20px 0 0;
  max-width: 500px;
  min-width: 300px;
  pointer-events: all;
  /* stylelint-disable-next-line declaration-no-important */
  position: relative !important;
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */ }
  .bp3-toast.bp3-toast-enter, .bp3-toast.bp3-toast-appear {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  .bp3-toast.bp3-toast-enter-active, .bp3-toast.bp3-toast-appear-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-toast.bp3-toast-enter ~ .bp3-toast, .bp3-toast.bp3-toast-appear ~ .bp3-toast {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px); }
  .bp3-toast.bp3-toast-enter-active ~ .bp3-toast, .bp3-toast.bp3-toast-appear-active ~ .bp3-toast {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-toast.bp3-toast-exit {
    opacity: 1;
    -webkit-filter: blur(0);
            filter: blur(0); }
  .bp3-toast.bp3-toast-exit-active {
    opacity: 0;
    -webkit-filter: blur(10px);
            filter: blur(10px);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-toast.bp3-toast-exit ~ .bp3-toast {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .bp3-toast.bp3-toast-exit-active ~ .bp3-toast {
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
    transition-delay: 50ms;
    transition-duration: 100ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-toast .bp3-button-group {
    flex: 0 0 auto;
    padding: 5px;
    padding-left: 0; }
  .bp3-toast > .bp3-icon {
    color: #5c7080;
    margin: 12px;
    margin-right: 0; }
  .bp3-toast.bp3-dark,
  .bp3-dark .bp3-toast {
    background-color: #424A50;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-toast.bp3-dark > .bp3-icon,
    .bp3-dark .bp3-toast > .bp3-icon {
      color: #a7b6c2; }
  .bp3-toast[class*="bp3-intent-"] a {
    color: rgba(255, 255, 255, 0.7); }
    .bp3-toast[class*="bp3-intent-"] a:hover {
      color: #ffffff; }
  .bp3-toast[class*="bp3-intent-"] > .bp3-icon {
    color: #ffffff; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button, .bp3-toast[class*="bp3-intent-"] .bp3-button::before,
  .bp3-toast[class*="bp3-intent-"] .bp3-button .bp3-icon, .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
    color: rgba(255, 255, 255, 0.7) !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:focus {
    outline-color: rgba(255, 255, 255, 0.5); }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: #ffffff !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: #ffffff !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button::after {
    background: rgba(255, 255, 255, 0.3) !important; }
  .bp3-toast.bp3-intent-primary {
    background-color: #137cbd;
    color: #ffffff; }
  .bp3-toast.bp3-intent-success {
    background-color: #0f9960;
    color: #ffffff; }
  .bp3-toast.bp3-intent-warning {
    background-color: #d9822b;
    color: #ffffff; }
  .bp3-toast.bp3-intent-danger {
    background-color: #db3737;
    color: #ffffff; }

.bp3-toast-message {
  flex: 1 1 auto;
  padding: 11px;
  word-break: break-word; }

.bp3-toast-container {
  align-items: center;
  /* stylelint-disable-next-line declaration-no-important */
  display: flex !important;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 0 20px 20px;
  pointer-events: none;
  right: 0;
  z-index: 40; }
  .bp3-toast-container.bp3-toast-container-in-portal {
    position: fixed; }
  .bp3-toast-container.bp3-toast-container-inline {
    position: absolute; }
  .bp3-toast-container.bp3-toast-container-top {
    top: 0; }
  .bp3-toast-container.bp3-toast-container-bottom {
    bottom: 0;
    flex-direction: column-reverse;
    top: auto; }
  .bp3-toast-container.bp3-toast-container-left {
    align-items: flex-start; }
  .bp3-toast-container.bp3-toast-container-right {
    align-items: flex-end; }

.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active),
.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active) ~ .bp3-toast, .bp3-toast-container-bottom .bp3-toast.bp3-toast-appear:not(.bp3-toast-appear-active),
.bp3-toast-container-bottom .bp3-toast.bp3-toast-appear:not(.bp3-toast-appear-active) ~ .bp3-toast,
.bp3-toast-container-bottom .bp3-toast.bp3-toast-exit-active ~ .bp3-toast,
.bp3-toast-container-bottom .bp3-toast.bp3-toast-leave-active ~ .bp3-toast {
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-tooltip {
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  /* stylelint-disable-next-line max-line-length */
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  -webkit-transform: scale(1);
          transform: scale(1); }
  .bp3-tooltip .bp3-popover-arrow {
    height: 22px;
    position: absolute;
    width: 22px; }
    .bp3-tooltip .bp3-popover-arrow::before {
      height: 14px;
      margin: 4px;
      width: 14px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip {
    margin-bottom: 11px;
    margin-top: -11px; }
    .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
      bottom: -8px; }
      .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow svg {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg); }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip {
    margin-left: 11px; }
    .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
      left: -8px; }
      .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow svg {
        -webkit-transform: rotate(0);
                transform: rotate(0); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip {
    margin-top: 11px; }
    .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
      top: -8px; }
      .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow svg {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip {
    margin-left: -11px;
    margin-right: 11px; }
    .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
      right: -8px; }
      .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
  .bp3-tether-element-attached-middle > .bp3-tooltip > .bp3-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .bp3-tether-element-attached-center > .bp3-tooltip > .bp3-popover-arrow {
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
    top: -0.22183px; }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
    right: -0.22183px; }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
    left: -0.22183px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
    bottom: -0.22183px; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-tooltip {
    -webkit-transform-origin: top left;
            transform-origin: top left; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-tooltip {
    -webkit-transform-origin: top center;
            transform-origin: top center; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-tooltip {
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-tooltip {
    -webkit-transform-origin: center left;
            transform-origin: center left; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-tooltip {
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-tooltip {
    -webkit-transform-origin: center right;
            transform-origin: center right; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-tooltip {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-tooltip {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-tooltip {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
  .bp3-tooltip .bp3-popover-content {
    background: #424A50; }
  .bp3-tooltip .bp3-popover-content,
  .bp3-tooltip .bp3-heading {
    color: #F7F8F8; }
  .bp3-tooltip .bp3-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .bp3-tooltip .bp3-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .bp3-tooltip .bp3-popover-arrow-fill {
    fill: #424A50; }
  .bp3-popover-enter > .bp3-tooltip, .bp3-popover-appear > .bp3-tooltip {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  .bp3-popover-enter-active > .bp3-tooltip, .bp3-popover-appear-active > .bp3-tooltip {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover-exit > .bp3-tooltip {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .bp3-popover-exit-active > .bp3-tooltip {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tooltip .bp3-popover-content {
    padding: 10px 12px; }
  .bp3-tooltip.bp3-dark,
  .bp3-dark .bp3-tooltip {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-tooltip.bp3-dark .bp3-popover-content,
    .bp3-dark .bp3-tooltip .bp3-popover-content {
      background: #E5E7E9; }
    .bp3-tooltip.bp3-dark .bp3-popover-content,
    .bp3-tooltip.bp3-dark .bp3-heading,
    .bp3-dark .bp3-tooltip .bp3-popover-content,
    .bp3-dark .bp3-tooltip .bp3-heading {
      color: #424A50; }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow::before,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow-border,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow-fill,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow-fill {
      fill: #E5E7E9; }
  .bp3-tooltip.bp3-intent-primary .bp3-popover-content {
    background: #137cbd;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-primary .bp3-popover-arrow-fill {
    fill: #137cbd; }
  .bp3-tooltip.bp3-intent-success .bp3-popover-content {
    background: #0f9960;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-success .bp3-popover-arrow-fill {
    fill: #0f9960; }
  .bp3-tooltip.bp3-intent-warning .bp3-popover-content {
    background: #d9822b;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-warning .bp3-popover-arrow-fill {
    fill: #d9822b; }
  .bp3-tooltip.bp3-intent-danger .bp3-popover-content {
    background: #db3737;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-danger .bp3-popover-arrow-fill {
    fill: #db3737; }

.bp3-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help; }

/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/* stylelint-disable @blueprintjs/no-color-literal */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2021 Palantir Technologies, Inc. All rights reserved.
 */
/*
Trees

Markup:
<div class="bp3-tree bp3-elevation-0">
  <ul class="bp3-tree-node-list bp3-tree-root">
    <li class="bp3-tree-node bp3-tree-node-expanded">
      <div class="bp3-tree-node-content">
        <span class="bp3-tree-node-caret bp3-tree-node-caret-open bp3-icon-standard"></span>
        <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-folder-close"></span>
        <span class="bp3-tree-node-label">Label</span>
        <span class="bp3-tree-node-secondary-label">Secondary label</span>
      </div>
      <ul class="bp3-tree-node-list">
        <li class="bp3-tree-node">
          <div class="bp3-tree-node-content">
            <span class="bp3-tree-node-caret-none bp3-icon-standard"></span>
            <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-document"></span>
          <span class="bp3-tree-node-label">A Document</span>
          </div>
        </li>
        <li class="bp3-tree-node">
          <div class="bp3-tree-node-content">
            <span class="bp3-tree-node-caret-none bp3-icon-standard"></span>
            <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-document"></span>
            <span class="bp3-tree-node-label">Another Document</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

Styleguide tree
*/
.bp3-tree .bp3-icon, .bp3-tree .bp3-icon-standard, .bp3-tree .bp3-icon-large {
  color: #5c7080; }
  .bp3-tree .bp3-icon.bp3-intent-primary, .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-tree .bp3-icon-large.bp3-intent-primary {
    color: #137cbd; }
  .bp3-tree .bp3-icon.bp3-intent-success, .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-tree .bp3-icon-large.bp3-intent-success {
    color: #0f9960; }
  .bp3-tree .bp3-icon.bp3-intent-warning, .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-tree .bp3-icon-large.bp3-intent-warning {
    color: #d9822b; }
  .bp3-tree .bp3-icon.bp3-intent-danger, .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-tree .bp3-icon-large.bp3-intent-danger {
    color: #db3737; }

.bp3-tree-node-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.bp3-tree-root {
  background-color: transparent;
  cursor: default;
  padding-left: 0;
  position: relative; }

.bp3-tree-node-content-0 {
  padding-left: 0px; }

.bp3-tree-node-content-1 {
  padding-left: 23px; }

.bp3-tree-node-content-2 {
  padding-left: 46px; }

.bp3-tree-node-content-3 {
  padding-left: 69px; }

.bp3-tree-node-content-4 {
  padding-left: 92px; }

.bp3-tree-node-content-5 {
  padding-left: 115px; }

.bp3-tree-node-content-6 {
  padding-left: 138px; }

.bp3-tree-node-content-7 {
  padding-left: 161px; }

.bp3-tree-node-content-8 {
  padding-left: 184px; }

.bp3-tree-node-content-9 {
  padding-left: 207px; }

.bp3-tree-node-content-10 {
  padding-left: 230px; }

.bp3-tree-node-content-11 {
  padding-left: 253px; }

.bp3-tree-node-content-12 {
  padding-left: 276px; }

.bp3-tree-node-content-13 {
  padding-left: 299px; }

.bp3-tree-node-content-14 {
  padding-left: 322px; }

.bp3-tree-node-content-15 {
  padding-left: 345px; }

.bp3-tree-node-content-16 {
  padding-left: 368px; }

.bp3-tree-node-content-17 {
  padding-left: 391px; }

.bp3-tree-node-content-18 {
  padding-left: 414px; }

.bp3-tree-node-content-19 {
  padding-left: 437px; }

.bp3-tree-node-content-20 {
  padding-left: 460px; }

.bp3-tree-node-content {
  align-items: center;
  display: flex;
  height: 30px;
  padding-right: 5px;
  width: 100%; }
  .bp3-tree-node-content:hover {
    background-color: rgba(199, 203, 206, 0.4); }

.bp3-tree-node-caret,
.bp3-tree-node-caret-none {
  min-width: 30px; }

.bp3-tree-node-caret {
  color: #5c7080;
  cursor: pointer;
  padding: 7px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tree-node-caret:hover {
    color: #182026; }
  .bp3-dark .bp3-tree-node-caret {
    color: #a7b6c2; }
    .bp3-dark .bp3-tree-node-caret:hover {
      color: #f5f8fa; }
  .bp3-tree-node-caret.bp3-tree-node-caret-open {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .bp3-tree-node-caret.bp3-icon-standard::before {
    content: ""; }

.bp3-tree-node-icon {
  margin-right: 7px;
  position: relative; }

.bp3-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bp3-tree-node-label span {
    display: inline; }

.bp3-tree-node-secondary-label {
  padding: 0 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bp3-tree-node-secondary-label .bp3-popover-wrapper,
  .bp3-tree-node-secondary-label .bp3-popover-target {
    align-items: center;
    display: flex; }

.bp3-tree-node.bp3-disabled .bp3-tree-node-content {
  background-color: inherit;
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed; }

.bp3-tree-node.bp3-disabled .bp3-tree-node-caret,
.bp3-tree-node.bp3-disabled .bp3-tree-node-icon {
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed; }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #00D092;
  background-color: var(--brand3); }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-standard, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-large {
    color: #ffffff; }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret::before {
    color: rgba(255, 255, 255, 0.7); }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret:hover::before {
    color: #ffffff; }

.bp3-dark .bp3-tree-node-content:hover {
  background-color: rgba(103, 111, 117, 0.3); }

.bp3-dark .bp3-tree .bp3-icon, .bp3-dark .bp3-tree .bp3-icon-standard, .bp3-dark .bp3-tree .bp3-icon-large {
  color: #a7b6c2; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-primary {
    color: #137cbd; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-success, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-success {
    color: #0f9960; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-warning {
    color: #d9822b; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-danger {
    color: #db3737; }

.bp3-dark .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #00D092;
  background-color: var(--brand3); }

.bp3-button {
  background-image: none !important; }

.bp3-toast .bp3-button-group {
  align-self: center; }

.bp3-input-group {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center; }

.bp3-input-group .bp3-input-action:first-child,
.bp3-input-group > .bp3-button:first-child,
.bp3-input-group > .bp3-icon:first-child {
  margin: 0.25rem 0 0 0; }

.bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large {
  color: #585858;
  color: var(--text-primary); }

.bp3-input-group .bp3-input:not(:last-child) {
  padding-right: 36px; }

.bp3-input-group .bp3-input:not(:first-child) {
  padding-left: 36px; }

.bp3-input {
  box-shadow: inset 0 0 0 2px #EEF0F1;
  box-shadow: inset 0 0 0 2px var(--bg-secondary);
  background-color: transparent;
  color: #585858;
  color: var(--text-primary); }
  .bp3-input::-webkit-input-placeholder {
    color: #C7CBCE;
    color: var(--text-primary-muted); }
  .bp3-input:-ms-input-placeholder {
    color: #C7CBCE;
    color: var(--text-primary-muted); }
  .bp3-input::placeholder {
    color: #C7CBCE;
    color: var(--text-primary-muted); }
  .bp3-input:focus {
    box-shadow: inset 0 0 1px 2px #5CE1B9;
    box-shadow: inset 0 0 1px 2px var(--brand5);
    outline: none; }

.bp3-editable-text.bp3-editable-text-editing::before {
  background-color: transparent; }

.bp3-editable-text-placeholder > .bp3-editable-text-content {
  color: #C7CBCE;
  color: var(--text-primary-muted); }

.ordersheetDivForFtAndInch {
  display: flex;
  flex-direction: row; }

.ordersheetSecondDivForFtAndInch {
  display: flex;
  align-items: center;
  margin-right: 10px; }

.bp3-control {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  text-transform: none; }
  .bp3-control.bp3-switch .bp3-control-indicator::before {
    background: #F7F8F8;
    background: var(--bg-primary); }

.bp3-popover-wrapper.bp3-fill > .bp3-popover-target {
  width: 100%; }

.bp3-form-group {
  margin: 0 0 1rem; }
  .bp3-form-group > label.bp3-label {
    margin-bottom: 0.5rem; }
  .bp3-form-group .bp3-form-helper-text {
    color: #C7CBCE;
    color: var(--text-primary-muted); }

.bp3-input-group > .bp3-icon,
.bp3-input-group .bp3-input-action > .bp3-spinner {
  margin: 3px; }

.bp3-heading {
  margin: 0;
  color: inherit;
  font-weight: 600; }

.bp3-text-muted {
  color: #C7CBCE;
  color: var(--text-primary-muted); }

.bp3-menu {
  min-width: 8rem;
  color: #585858;
  color: var(--text-primary);
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }

.bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item,
.bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
  background-color: #EFF0F2;
  background-color: var(--bg-primary-hover); }

.bp3-menu-item.bp3-active, .bp3-menu-item:active {
  background-color: #E5E7E9;
  background-color: var(--bg-primary-active); }

.bp3-menu-header {
  color: #585858;
  color: var(--text-primary); }

.bp3-button.bp3-minimal {
  color: #585858;
  color: var(--text-primary);
  background-color: transparent; }
  .bp3-button.bp3-minimal.bp3-disabled {
    color: #C7CBCE;
    color: var(--text-primary-muted); }
    .bp3-button.bp3-minimal.bp3-disabled:hover {
      color: #C7CBCE;
      color: var(--text-primary-muted); }
  .bp3-button.bp3-minimal:hover {
    background-color: #EFF0F2;
    background-color: var(--bg-primary-hover);
    color: #7C858B;
    color: var(--text-primary-hover); }
  .bp3-button.bp3-minimal:active {
    background-color: #E5E7E9;
    background-color: var(--bg-primary-active);
    color: #30363B;
    color: var(--text-primary-active); }

.bp3-button-group {
  border-radius: 0.25rem; }
  .bp3-button-group > button {
    border-radius: 0; }
  .bp3-button-group:not(.bp3-vertical) > button:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .bp3-button-group:not(.bp3-vertical) > button:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  .bp3-button-group.bp3-vertical > button:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .bp3-button-group.bp3-vertical > button:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }

.at-sidebar-tabs {
  display: flex;
  flex-direction: column; }
  .at-sidebar-tabs > .bp3-tab-panel {
    margin-top: 0.5rem;
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
    flex-direction: column; }
    .at-sidebar-tabs > .bp3-tab-panel[aria-hidden="false"] {
      display: flex; }
  .at-sidebar-tabs .at-sidebar-tabs__view-container {
    flex: 1 1;
    flex-direction: column;
    height: calc(100% - 3.5rem); }

#at-zoom-buttons {
  position: absolute;
  top: 4rem;
  flex-direction: column;
  right: 99%; }

.bp3-menu-header > h6 {
  color: #585858;
  color: var(--text-primary); }

.bp3-popover .bp3-popover-content {
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  color: inherit; }

.bp3-tab {
  color: inherit; }
  .bp3-tab > .bp3-heading {
    line-height: 2.5rem; }
    .bp3-tab > .bp3-heading > .bp3-icon {
      color: #585858;
      color: var(--text-primary);
      margin-left: 0.25rem; }
      .bp3-tab > .bp3-heading > .bp3-icon:hover {
        background-color: #EFF0F2;
        background-color: var(--bg-primary-hover); }
      .bp3-tab > .bp3-heading > .bp3-icon > svg {
        width: 1.25rem; }

.bp3-tab[aria-selected="false"] > .bp3-heading > .bp3-icon {
  visibility: hidden; }

.bp3-tab-list {
  justify-content: center; }
  .bp3-tab-list > .bp3-tab {
    flex: 1 1 auto;
    text-align: center; }

.bp3-tab-list > *:not(:last-child) {
  margin-right: 0.25rem; }

.bp3-control:hover input:checked ~ .bp3-control-indicator {
  background-color: #00AB78;
  background-color: var(--brand2); }

.bp3-tab[aria-selected="true"] {
  color: #30363B;
  color: var(--text-primary-active); }
  .bp3-tab[aria-selected="true"]:not([aria-disabled="true"]):hover {
    color: #30363B;
    color: var(--text-primary-active); }

.bp3-tab:not([aria-disabled="true"]):hover {
  color: #7C858B;
  color: var(--text-primary-hover); }

.bp3-tab-indicator-wrapper .bp3-tab-indicator {
  background-color: #00D092;
  background-color: var(--brand3);
  height: 2px; }

.bp3-popover {
  box-shadow: 0px 18px 46px rgba(16, 22, 26, 0.2), 0px 4px 8px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  border: 1px solid #EFF0F2;
  border: 1px solid var(--bg-primary-hover); }

.bp3-callout {
  padding: 0.5rem; }

.bp3-spinner-animation {
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s; }

.circular-button {
  width: 4rem;
  height: 4rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  border: none;
  cursor: pointer;
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }
  .circular-button.small {
    width: 2rem;
    height: 2rem; }
  .circular-button > span {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .circular-button:hover {
    background-color: #EFF0F2;
    background-color: var(--bg-primary-hover); }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Button

Markup:
<a role="button" class="bp3-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="bp3-button bp3-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.bp3-active - Active appearance
.bp3-disabled - Disabled appearance
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-minimal - More subtle appearance
.bp3-large - Larger size
.bp3-small - Smaller size
.bp3-fill - Fill parent container

Styleguide button
*/
.at-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 2rem;
  min-height: 2rem; }
  .at-button > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .at-button > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .at-button::before,
  .at-button > * {
    margin-right: 7px; }
  .at-button:empty::before,
  .at-button > :last-child {
    margin-right: 0; }
  .at-button:empty {
    padding: 0 !important; }
  .at-button:disabled, .at-button.at-disabled {
    cursor: not-allowed; }
  .at-button.at-fill {
    display: flex;
    width: 100%; }
  .at-button.at-align-right,
  .at-align-right .at-button {
    text-align: right; }
  .at-button.at-align-left,
  .at-align-left .at-button {
    text-align: left; }
  .at-button:not([class*="at-intent-"]) {
    background-color: inherit;
    box-shadow: inset 0 0 0 1px #585858, inset 0 -1px 0 #585858;
    box-shadow: inset 0 0 0 1px var(--text-primary), inset 0 -1px 0 var(--text-primary);
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    color: #585858;
    color: var(--text-primary); }
    .at-button:not([class*="at-intent-"]):hover {
      background-color: #EFF0F2;
      background-color: var(--bg-primary-hover);
      color: #7C858B;
      color: var(--text-primary-hover); }
    .at-button:not([class*="at-intent-"]):active, .at-button:not([class*="at-intent-"]).at-active {
      background-color: #E5E7E9;
      background-color: var(--bg-primary-active);
      color: #30363B;
      color: var(--text-primary-active); }
    .at-button:not([class*="at-intent-"]):disabled, .at-button:not([class*="at-intent-"]).at-disabled {
      outline: none;
      box-shadow: none;
      cursor: not-allowed;
      color: #C7CBCE;
      color: var(--text-primary-muted); }
  .at-button.at-intent-primary {
    box-shadow: inset 0 0 0 1px #00D092, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
    box-shadow: inset 0 0 0 1px var(--brand3), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
    background-color: #00D092;
    background-color: var(--brand3);
    color: #ffffff; }
    .at-button.at-intent-primary > .at-button-text {
      color: #ffffff;
      opacity: 1; }
    .at-button.at-intent-primary:hover, .at-button.at-intent-primary:active, .at-button.at-intent-primary.at-active {
      color: #ffffff; }
      .at-button.at-intent-primary:hover > .at-button-text, .at-button.at-intent-primary:active > .at-button-text, .at-button.at-intent-primary.at-active > .at-button-text {
        color: #ffffff; }
    .at-button.at-intent-primary:hover {
      box-shadow: inset 0 0 0 1px #00AB78, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      box-shadow: inset 0 0 0 1px var(--brand2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #00AB78;
      background-color: var(--brand2); }
    .at-button.at-intent-primary:active, .at-button.at-intent-primary.at-active {
      box-shadow: inset 0 0 0 1px #00855D 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      box-shadow: inset 0 0 0 1px var(--brand1) 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #00855D;
      background-color: var(--brand1); }
    .at-button.at-intent-primary:disabled, .at-button.at-intent-primary.at-disabled {
      border-color: transparent;
      box-shadow: none;
      opacity: 0.5; }
      .at-button.at-intent-primary:disabled:hover, .at-button.at-intent-primary.at-disabled:hover {
        box-shadow: inset 0 0 0 1px #00D092, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        box-shadow: inset 0 0 0 1px var(--brand3), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #00D092;
        background-color: var(--brand3); }
    .at-button.at-intent-primary.at-tertiary {
      box-shadow: inset 0 0 0 1px #00D092;
      box-shadow: inset 0 0 0 1px var(--brand3);
      background-color: transparent;
      color: #00D092;
      color: var(--brand3); }
      .at-button.at-intent-primary.at-tertiary > .at-button-text {
        color: #00D092;
        color: var(--brand3); }
      .at-button.at-intent-primary.at-tertiary:active, .at-button.at-intent-primary.at-tertiary.at-active {
        box-shadow: inset 0 0 0 1px #00855D, 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        box-shadow: inset 0 0 0 1px var(--brand1), 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #00855D;
        background-color: var(--brand1); }
      .at-button.at-intent-primary.at-tertiary:hover {
        box-shadow: inset 0 0 0 1px #00AB78, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        box-shadow: inset 0 0 0 1px var(--brand2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #00AB78;
        background-color: var(--brand2);
        color: #ffffff; }
        .at-button.at-intent-primary.at-tertiary:hover > .at-button-text {
          color: #ffffff; }
  .at-button.at-intent-success {
    box-shadow: inset 0 0 0 1px #07ae9a, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
    background-color: #07ae9a;
    color: #ffffff; }
    .at-button.at-intent-success > .at-button-text {
      color: #ffffff;
      opacity: 1; }
    .at-button.at-intent-success:hover, .at-button.at-intent-success:active, .at-button.at-intent-success.at-active {
      color: #ffffff; }
      .at-button.at-intent-success:hover > .at-button-text, .at-button.at-intent-success:active > .at-button-text, .at-button.at-intent-success.at-active > .at-button-text {
        color: #ffffff; }
    .at-button.at-intent-success:hover {
      box-shadow: inset 0 0 0 1px #068e7e, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #068e7e; }
    .at-button.at-intent-success:active, .at-button.at-intent-success.at-active {
      box-shadow: inset 0 0 0 1px #047164 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #047164; }
    .at-button.at-intent-success:disabled, .at-button.at-intent-success.at-disabled {
      border-color: transparent;
      box-shadow: none;
      opacity: 0.5; }
      .at-button.at-intent-success:disabled:hover, .at-button.at-intent-success.at-disabled:hover {
        box-shadow: inset 0 0 0 1px #07ae9a, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #07ae9a; }
    .at-button.at-intent-success.at-tertiary {
      box-shadow: inset 0 0 0 1px #07ae9a;
      background-color: transparent;
      color: #07ae9a; }
      .at-button.at-intent-success.at-tertiary > .at-button-text {
        color: #07ae9a; }
      .at-button.at-intent-success.at-tertiary:active, .at-button.at-intent-success.at-tertiary.at-active {
        box-shadow: inset 0 0 0 1px #047164, 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #047164; }
      .at-button.at-intent-success.at-tertiary:hover {
        box-shadow: inset 0 0 0 1px #068e7e, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #068e7e;
        color: #ffffff; }
        .at-button.at-intent-success.at-tertiary:hover > .at-button-text {
          color: #ffffff; }
  .at-button.at-intent-warning {
    box-shadow: inset 0 0 0 1px #d9822b, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
    background-color: #d9822b;
    color: #ffffff; }
    .at-button.at-intent-warning > .at-button-text {
      color: #ffffff;
      opacity: 1; }
    .at-button.at-intent-warning:hover, .at-button.at-intent-warning:active, .at-button.at-intent-warning.at-active {
      color: #ffffff; }
      .at-button.at-intent-warning:hover > .at-button-text, .at-button.at-intent-warning:active > .at-button-text, .at-button.at-intent-warning.at-active > .at-button-text {
        color: #ffffff; }
    .at-button.at-intent-warning:hover {
      box-shadow: inset 0 0 0 1px #bf7326, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #bf7326; }
    .at-button.at-intent-warning:active, .at-button.at-intent-warning.at-active {
      box-shadow: inset 0 0 0 1px #a66321 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #a66321; }
    .at-button.at-intent-warning:disabled, .at-button.at-intent-warning.at-disabled {
      border-color: transparent;
      box-shadow: none;
      opacity: 0.5; }
      .at-button.at-intent-warning:disabled:hover, .at-button.at-intent-warning.at-disabled:hover {
        box-shadow: inset 0 0 0 1px #d9822b, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #d9822b; }
    .at-button.at-intent-warning.at-tertiary {
      box-shadow: inset 0 0 0 1px #d9822b;
      background-color: transparent;
      color: #d9822b; }
      .at-button.at-intent-warning.at-tertiary > .at-button-text {
        color: #d9822b; }
      .at-button.at-intent-warning.at-tertiary:active, .at-button.at-intent-warning.at-tertiary.at-active {
        box-shadow: inset 0 0 0 1px #a66321, 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #a66321; }
      .at-button.at-intent-warning.at-tertiary:hover {
        box-shadow: inset 0 0 0 1px #bf7326, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #bf7326;
        color: #ffffff; }
        .at-button.at-intent-warning.at-tertiary:hover > .at-button-text {
          color: #ffffff; }
  .at-button.at-intent-danger {
    box-shadow: inset 0 0 0 1px #DB3737, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
    background-color: #DB3737;
    color: #ffffff; }
    .at-button.at-intent-danger > .at-button-text {
      color: #ffffff;
      opacity: 1; }
    .at-button.at-intent-danger:hover, .at-button.at-intent-danger:active, .at-button.at-intent-danger.at-active {
      color: #ffffff; }
      .at-button.at-intent-danger:hover > .at-button-text, .at-button.at-intent-danger:active > .at-button-text, .at-button.at-intent-danger.at-active > .at-button-text {
        color: #ffffff; }
    .at-button.at-intent-danger:hover {
      box-shadow: inset 0 0 0 1px #C23030, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #C23030; }
    .at-button.at-intent-danger:active, .at-button.at-intent-danger.at-active {
      box-shadow: inset 0 0 0 1px #A82A2A 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
      background-color: #A82A2A; }
    .at-button.at-intent-danger:disabled, .at-button.at-intent-danger.at-disabled {
      border-color: transparent;
      box-shadow: none;
      opacity: 0.5; }
      .at-button.at-intent-danger:disabled:hover, .at-button.at-intent-danger.at-disabled:hover {
        box-shadow: inset 0 0 0 1px #DB3737, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #DB3737; }
    .at-button.at-intent-danger.at-tertiary {
      box-shadow: inset 0 0 0 1px #DB3737;
      background-color: transparent;
      color: #DB3737; }
      .at-button.at-intent-danger.at-tertiary > .at-button-text {
        color: #DB3737; }
      .at-button.at-intent-danger.at-tertiary:active, .at-button.at-intent-danger.at-tertiary.at-active {
        box-shadow: inset 0 0 0 1px #A82A2A, 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #A82A2A; }
      .at-button.at-intent-danger.at-tertiary:hover {
        box-shadow: inset 0 0 0 1px #C23030, 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
        background-color: #C23030;
        color: #ffffff; }
        .at-button.at-intent-danger.at-tertiary:hover > .at-button-text {
          color: #ffffff; }
  .at-button[class*="at-intent-"] .at-button-spinner .at-spinner-head {
    stroke: #ffffff; }
  .at-button.at-large,
  .at-large .at-button {
    min-width: 40px;
    min-height: 40px;
    padding: 5px 15px;
    font-size: 16px; }
    .at-button.at-large::before,
    .at-button.at-large > *,
    .at-large .at-button::before,
    .at-large .at-button > * {
      margin-right: 10px; }
    .at-button.at-large:empty::before,
    .at-button.at-large > :last-child,
    .at-large .at-button:empty::before,
    .at-large .at-button > :last-child {
      margin-right: 0; }
  .at-button.at-small,
  .at-small .at-button {
    min-width: 24px;
    min-height: 24px;
    font-size: 0.875rem;
    padding: 0 7px; }
  .at-button.at-loading {
    position: relative; }
    .at-button.at-loading[class*="bp3-icon-"]::before {
      visibility: hidden; }
    .at-button.at-loading .at-button-spinner {
      position: absolute;
      margin: 0; }
    .at-button.at-loading > :not(.at-button-spinner) {
      visibility: hidden; }
  .at-button .at-icon:first-child:last-child,
  .at-button .at-spinner + .at-icon:last-child {
    margin: 0 -0.5rem; }
  .at-button.at-minimal {
    box-shadow: none;
    color: #585858;
    color: var(--text-primary);
    background-color: transparent; }
    .at-button.at-minimal:hover {
      background-color: #EFF0F2;
      background-color: var(--bg-primary-hover);
      color: #7C858B;
      color: var(--text-primary-hover);
      box-shadow: none;
      text-decoration: none; }
    .at-button.at-minimal:active, .at-button.at-minimal.at-active {
      background-color: #E5E7E9;
      background-color: var(--bg-primary-active);
      color: #30363B;
      color: var(--text-primary-active); }
    .at-button.at-minimal:disabled, .at-button.at-minimal:disabled:hover, .at-button.at-minimal.at-disabled, .at-button.at-minimal.at-disabled:hover {
      background: none;
      cursor: not-allowed;
      color: #C7CBCE;
      color: var(--text-primary-muted); }
      .at-button.at-minimal:disabled.at-active, .at-button.at-minimal:disabled:hover.at-active, .at-button.at-minimal.at-disabled.at-active, .at-button.at-minimal.at-disabled:hover.at-active {
        background-color: #E5E7E9;
        background-color: var(--bg-primary-active);
        color: #30363B;
        color: var(--text-primary-active); }
    .at-button.at-minimal > .bp3-heading {
      opacity: 1; }
    .at-button.at-minimal.at-intent-primary {
      color: #00D092;
      color: var(--brand3); }
      .at-button.at-minimal.at-intent-primary:hover, .at-button.at-minimal.at-intent-primary:active, .at-button.at-minimal.at-intent-primary.at-active {
        box-shadow: none;
        background: none;
        color: #00D092;
        color: var(--brand3); }
      .at-button.at-minimal.at-intent-primary:hover {
        color: #00AB78;
        color: var(--brand2);
        background-color: #EFF0F2;
        background-color: var(--bg-primary-hover); }
      .at-button.at-minimal.at-intent-primary:active, .at-button.at-minimal.at-intent-primary.at-active {
        color: #00855D;
        color: var(--brand1);
        background-color: #E5E7E9;
        background-color: var(--bg-primary-active); }
      .at-button.at-minimal.at-intent-primary:disabled, .at-button.at-minimal.at-intent-primary.at-disabled {
        background: none; }
      .at-button.at-minimal.at-intent-primary .at-button-spinner .at-spinner-head {
        stroke: #00D092;
        stroke: var(--brand3); }
    .at-button.at-minimal.at-intent-success {
      color: #07ae9a; }
      .at-button.at-minimal.at-intent-success:hover, .at-button.at-minimal.at-intent-success:active, .at-button.at-minimal.at-intent-success.at-active {
        box-shadow: none;
        background: none;
        color: #07ae9a; }
      .at-button.at-minimal.at-intent-success:hover {
        color: #068e7e;
        background-color: #EFF0F2;
        background-color: var(--bg-primary-hover); }
      .at-button.at-minimal.at-intent-success:active, .at-button.at-minimal.at-intent-success.at-active {
        color: #047164;
        background-color: #E5E7E9;
        background-color: var(--bg-primary-active); }
      .at-button.at-minimal.at-intent-success:disabled, .at-button.at-minimal.at-intent-success.at-disabled {
        background: none; }
      .at-button.at-minimal.at-intent-success .at-button-spinner .at-spinner-head {
        stroke: #07ae9a; }
    .at-button.at-minimal.at-intent-warning {
      color: #d9822b; }
      .at-button.at-minimal.at-intent-warning:hover, .at-button.at-minimal.at-intent-warning:active, .at-button.at-minimal.at-intent-warning.at-active {
        box-shadow: none;
        background: none;
        color: #d9822b; }
      .at-button.at-minimal.at-intent-warning:hover {
        color: #bf7326;
        background-color: #EFF0F2;
        background-color: var(--bg-primary-hover); }
      .at-button.at-minimal.at-intent-warning:active, .at-button.at-minimal.at-intent-warning.at-active {
        color: #a66321;
        background-color: #E5E7E9;
        background-color: var(--bg-primary-active); }
      .at-button.at-minimal.at-intent-warning:disabled, .at-button.at-minimal.at-intent-warning.at-disabled {
        background: none; }
      .at-button.at-minimal.at-intent-warning .at-button-spinner .at-spinner-head {
        stroke: #d9822b; }
    .at-button.at-minimal.at-intent-danger {
      color: #DB3737; }
      .at-button.at-minimal.at-intent-danger:hover, .at-button.at-minimal.at-intent-danger:active, .at-button.at-minimal.at-intent-danger.at-active {
        box-shadow: none;
        background: none;
        color: #DB3737; }
      .at-button.at-minimal.at-intent-danger:hover {
        color: #C23030;
        background-color: #EFF0F2;
        background-color: var(--bg-primary-hover); }
      .at-button.at-minimal.at-intent-danger:active, .at-button.at-minimal.at-intent-danger.at-active {
        color: #A82A2A;
        background-color: #E5E7E9;
        background-color: var(--bg-primary-active); }
      .at-button.at-minimal.at-intent-danger:disabled, .at-button.at-minimal.at-intent-danger.at-disabled {
        background: none; }
      .at-button.at-minimal.at-intent-danger .at-button-spinner .at-spinner-head {
        stroke: #DB3737; }

a.at-button {
  text-align: center;
  text-decoration: none;
  transition: none; }
  a.at-button, a.at-button:hover, a.at-button:active {
    color: #182026; }

.at-button-text {
  flex: 0 1 auto; }

.at-button.at-align-left .at-button-text, .at-button.at-align-right .at-button-text,
.at-button-group.at-align-left .at-button-text,
.at-button-group.at-align-right .at-button-text {
  flex: 1 1 auto; }

.disable {
  pointer-events: none; }

.at-input-btn-label {
  position: relative;
  padding: 0 1rem; }
  .at-input-btn-label > button {
    padding: 0; }
  .at-input-btn-label:after {
    cursor: pointer;
    position: absolute;
    content: "";
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .at-input-btn-label:hover {
    background-color: #EFF0F2;
    background-color: var(--bg-primary-hover);
    color: #7C858B;
    color: var(--text-primary-hover);
    box-shadow: none;
    text-decoration: none;
    border-radius: 0.25rem; }
  .at-input-btn-label:active {
    background-color: #E5E7E9;
    background-color: var(--bg-primary-active);
    color: #30363B;
    color: var(--text-primary-active); }
  .at-input-btn-label:disabled, .at-input-btn-label:disabled:hover {
    background: none;
    cursor: not-allowed;
    color: #C7CBCE;
    color: var(--text-primary-muted); }

.at-radiogroup {
  margin: 0.75rem 0; }

.at-radio input[type="radio"] {
  display: none; }

.radio-icon--checked {
  color: #00D092;
  color: var(--brand3); }

.branding {
  width: 100%;
  height: 4rem;
  position: absolute;
  box-shadow: 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }
  .branding.fill {
    height: 100vh; }

.logo-area {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.brand-progress-bar {
  width: 18rem; }
  .brand-progress-bar > .bp3-progress-meter {
    background: linear-gradient(90deg, #0BAADE 0%, #00D092 100%) !important; }

.popover-container > .bp3-popover-target {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 3rem; }

.dropdown-container {
  display: inline-flex;
  width: 20rem;
  border: 2px solid #ccc;
  border-radius: 0.25rem;
  justify-content: space-between;
  align-items: center; }

.dropdown-menu {
  width: 20rem; }

.bp3-select::after {
  display: none; }

.bp3-select {
  color: #585858;
  color: var(--text-primary); }
  .bp3-select.at-select {
    width: 100%; }
    .bp3-select.at-select > .at-icon {
      top: 0;
      bottom: 0;
      margin: auto;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      color: inherit; }
  .bp3-select.minimal > select {
    box-shadow: none; }
  .bp3-select > select {
    box-shadow: inset 0 0 0 2px #EEF0F1;
    box-shadow: inset 0 0 0 2px var(--bg-secondary);
    background-color: transparent;
    color: #585858;
    color: var(--text-primary);
    background-image: none; }
    .bp3-select > select:hover {
      opacity: 1;
      background-color: #EFF0F2;
      background-color: var(--bg-primary-hover);
      box-shadow: 0 0 0 2px #EFF0F2;
      box-shadow: 0 0 0 2px var(--bg-primary-hover);
      background-image: none; }
    .bp3-select > select:active {
      opacity: 1;
      background-color: #E5E7E9;
      background-color: var(--bg-primary-active);
      box-shadow: 0 0 0 2px #E5E7E9;
      box-shadow: 0 0 0 2px var(--bg-primary-active);
      background-image: none; }
    .bp3-select > select > option {
      background-color: transparent; }

.at-option {
  color: #585858;
  color: var(--text-primary); }

.at-icon {
  color: inherit; }

.at-ruler-container {
  overflow: hidden; }

.at-spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 17; }
  .at-spinner-overlay > .spinner-border {
    z-index: 20; }

.spinner-border {
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
          animation: spinner-border 0.75s linear infinite;
  border-color: #30363B;
  border-color: var(--text-primary-active);
  border-right-color: transparent;
  border-style: solid; }
  .spinner-border-xs {
    width: 1rem;
    height: 1rem;
    border-width: 0.2rem; }
  .spinner-border-sm {
    width: 1.5rem;
    height: 1.5rem;
    border-width: 0.2rem; }
  .spinner-border-md {
    width: 2rem;
    height: 2rem;
    border-width: 0.2rem; }
  .spinner-border-lg {
    width: 3rem;
    height: 3rem;
    border-width: 0.25rem; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.at-input-group {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center; }
  .at-input-group .at-input {
    position: relative;
    width: 100%;
    height: 2.25rem; }
    .at-input-group .at-input:not(:first-child) {
      padding-left: 2.25rem; }
    .at-input-group .at-input:not(:last-child) {
      padding-right: 2.25rem; }
  .at-input-group .at-input-action,
  .at-input-group > .at-button,
  .at-input-group > .at-icon {
    position: absolute; }
    .at-input-group .at-input-action:first-child,
    .at-input-group > .at-button:first-child,
    .at-input-group > .at-icon:first-child {
      left: 0; }
    .at-input-group .at-input-action:last-child,
    .at-input-group > .at-button:last-child,
    .at-input-group > .at-icon:last-child {
      right: 0; }
  .at-input-group > .bp3-icon {
    z-index: 1;
    color: #585858;
    color: var(--text-primary); }

.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #00D092;
  color: var(--brand3); }

.bp3-control .bp3-control-indicator {
  background-color: transparent;
  background-image: none;
  border: none;
  font-size: 1rem; }
  .bp3-control .bp3-control-indicator.checked {
    box-shadow: none; }
  .bp3-control .bp3-control-indicator:before {
    content: none; }

.bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
  box-shadow: none;
  background: transparent; }

.bp3-control:hover input:checked ~ .bp3-control-indicator {
  background-color: transparent;
  box-shadow: none; }

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: none; }

.checkbox-icon {
  stroke: #00D092;
  stroke: var(--brand3); }

.at-divider.at-divider-horizontal {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: #EEF0F1;
  border-top-color: var(--bg-secondary);
  width: 100%; }

.at-divider.at-divider-vertical {
  flex: 0 1;
  height: 100%;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: #EEF0F1;
  border-left-color: var(--bg-secondary); }

.at-input-group.at-search-bar {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }
  .at-input-group.at-search-bar > .at-icon {
    margin-left: 0.5rem; }
  .at-input-group.at-search-bar > .at-button {
    display: none; }
    .at-input-group.at-search-bar > .at-button:hover, .at-input-group.at-search-bar > .at-button:active {
      background-color: transparent; }
  .at-input-group.at-search-bar.focused > .at-button {
    display: block; }

.ic-searchbar {
  margin-right: 0.5rem; }

.input-searchbar {
  display: inline-block;
  padding: 0;
  padding: initial; }
  .input-searchbar.search-icons {
    float: right; }
  .input-searchbar > input {
    background-color: transparent;
    box-shadow: none !important; }
  .input-searchbar > input:focus {
    box-shadow: none !important; }

.thumb-container {
  padding: 0.5rem;
  display: inline-grid;
  grid-template-columns: 33% 33% 33%;
  width: 100%; }

.panel-btn-grp {
  position: absolute;
  top: 50%;
  display: inline-flex;
  -webkit-transform-origin: center;
          transform-origin: center;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem 0.5rem 0 0; }
  .panel-btn-grp.left {
    left: calc(100% + 1.25em - 1px);
    -webkit-transform: translate(-50%, -50%) rotateZ(90deg);
            transform: translate(-50%, -50%) rotateZ(90deg); }
  .panel-btn-grp.right {
    right: calc(100% + 1.25em - 1px);
    -webkit-transform: translate(50%, -50%) rotateZ(-90deg);
            transform: translate(50%, -50%) rotateZ(-90deg); }
  .panel-btn-grp span:first-child {
    border-top-left-radius: 0.25rem; }
  .panel-btn-grp span:last-child {
    border-top-right-radius: 0.25rem; }

.panel-btn {
  height: 2.5em;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  display: inline-flex;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.04);
  background-color: #EEF0F1;
  background-color: var(--bg-secondary); }
  .panel-btn.active {
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    color: #00D092;
    color: var(--brand3); }

.at-collapse-toggle {
  float: right;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
  padding: 7px;
  transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  margin-right: 0.25rem; }
  .at-collapse-toggle.open {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }

.at-section-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2rem;
  pointer-events: none; }

.color-pie {
  min-width: 3.5rem;
  height: 3.5rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 0 0 3.5rem; }
  .color-pie__color {
    height: 3.5rem;
    width: 3.5rem; }
  .color-pie__percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    pointer-events: none; }
    .color-pie__percentage.atBottom {
      top: auto;
      bottom: 0.25rem;
      -webkit-transform: unset;
              transform: unset;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center; }

.color-pie__color.no-background {
  background-color: transparent !important;
  border: none !important; }

.color-pie__color.hide-color-pom img {
  visibility: hidden; }

img.color-pom-image {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.color-index {
  flex: 0 0 1.4rem;
  color: #585858;
  color: var(--text-primary);
  width: 1.4rem;
  overflow: hidden;
  text-align: center;
  margin-top: 0.25rem; }
  .color-index.color-pie__colorindex {
    position: absolute;
    width: auto; }

.color-area-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto; }
  .color-area-container:last-child {
    margin-bottom: 1rem; }
  .color-area-container > .area-swatch {
    width: 100%; }

.area-swatch {
  border-radius: 0.25rem;
  display: inline-flex;
  padding: 0.5rem 0;
  color: #585858;
  color: var(--text-primary); }
  .area-swatch.active {
    background-color: #E5E7E9;
    background-color: var(--bg-primary-active);
    color: #585858;
    color: var(--text-primary); }

.color-details-area {
  align-self: center;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-around;
  height: 100%; }
  .color-details-area > div {
    display: flex;
    align-items: center;
    width: 100%; }

.color-details-name {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline; }
  .color-details-name__title {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.25rem; }

.color-area-popover > .bp3-popover-target {
  width: 100%; }

.at-section__title__controls {
  margin-left: -0.6rem; }

.at-design-color-options {
  display: inline-flex;
  align-items: center;
  transition: opacity 100ms ease-out;
  flex: 0 0 auto;
  width: calc(100% - 4px);
  border: 2px solid #EEF0F1;
  border: 2px solid var(--bg-secondary);
  box-sizing: border-box;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: space-evenly; }
  .at-design-color-options.invisible {
    opacity: 0;
    overflow: hidden;
    width: 0; }
  .at-design-color-options.collapsed {
    display: none; }
  .at-design-color-options > * {
    display: flex;
    justify-content: center; }
  .at-design-color-options > .colortools-divider {
    display: none; }
  .at-design-color-options > button.at-button.at-small {
    min-width: 3rem;
    font-size: 0.75rem; }

#at-section-tools-autocolor,
#at-design-color-options-shufflecolors {
  display: none; }

.at-design-color-options-colorpalette {
  font-size: 0.75rem !important;
  box-shadow: none !important; }

.at-section-container.collapsed {
  display: none; }

label.bp3-control.bp3-checkbox.at-carving-checkbox {
  margin: 1rem 0;
  text-transform: uppercase; }

.at-colorboxTooltipmsg {
  background: #333;
  border: 1px solid #666;
  color: #f5f5f5;
  padding: 4px 6px;
  font-size: 13px;
  text-transform: uppercase; }

.at-masterCarving-Fade {
  background-color: #EFF0F2 !important;
  background-color: var(--bg-primary-hover) !important;
  color: #7C858B !important;
  color: var(--text-primary-hover) !important; }

@media screen and (max-width: 500px) {
  .at-design-color-options {
    display: flex;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    flex-wrap: wrap; } }

#at-design-color-options-autocolor {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content; }

.bp3-popover.bp3-minimal.autocolor-popover {
  width: 11rem;
  border: none;
  margin-top: 0.25rem !important; }

ul.bp3-menu.autocolor-menu {
  padding: 0;
  color: white;
  line-height: 2rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-shadow: 0px 0px 0px rgba(16, 22, 26, 0.32), 0px 1px 1px rgba(16, 22, 26, 0.32); }
  ul.bp3-menu.autocolor-menu > li:hover {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
    ul.bp3-menu.autocolor-menu > li:hover div {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg); }

ul.bp3-menu.autocolor-menu a {
  line-height: 2rem;
  font-size: 0.875rem;
  border-radius: 0; }

ul.bp3-menu.autocolor-menu li:first-child a {
  border-radius: 0.25rem 0.25rem 0 0; }

ul.bp3-menu.autocolor-menu li:last-child a {
  border-radius: 0 0 0.25rem 0.25rem; }

/*
Dialog

Markup:
<!-- this container element fills its parent and centers the .bp3-dialog within it -->
<div class="bp3-dialog-container">
  <div class="bp3-dialog">
    <div class="bp3-dialog-header">
      <span class="bp3-icon-large bp3-icon-inbox"></span>
      <h4 class="bp3-heading">Dialog header</h4>
      <button aria-label="Close" class="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"></button>
    </div>
    <div class="bp3-dialog-body">
      This dialog hasn't been wired up with any open or close interactions.
      It's just an example of markup and styles.
    </div>
    <div class="bp3-dialog-footer">
      <div class="bp3-dialog-footer-actions">
        <button type="button" class="bp3-button">Secondary button</button>
        <button type="submit" class="bp3-button bp3-intent-primary">Primary button</button>
      </div>
    </div>
  </div>
</div>

Styleguide dialog
*/
.at-dialog-container {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .at-dialog-container.at-overlay-enter > .at-dialog, .at-dialog-container.at-overlay-appear > .at-dialog {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  .at-dialog-container.at-overlay-enter-active > .at-dialog, .at-dialog-container.at-overlay-appear-active > .at-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .at-dialog-container.at-overlay-exit > .at-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  .at-dialog-container.at-overlay-exit-active > .at-dialog {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }

.at-dialog {
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  pointer-events: all;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text; }
  .at-dialog:focus {
    outline: 0; }
  .at-dialog.at-dialog-lg {
    width: 85%; }
  .at-dialog.at-dialog-md {
    width: 50%; }
  .at-dialog.at-dialog-sm {
    width: 33.33%; }
  .at-dialog.at-dialog-xs {
    width: 20%; }

.at-dialog-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  background: #ffffff;
  min-height: 40px;
  padding-right: 5px;
  padding-left: 20px; }
  .at-dialog-header .at-icon-large,
  .at-dialog-header .at-icon {
    flex: 0 0 auto;
    margin-right: 10px;
    color: #5c7080; }
  .at-dialog-header .at-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    margin: 0;
    line-height: inherit; }
    .at-dialog-header .at-heading:last-child {
      margin-right: 20px; }

.at-dialog-body {
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px; }

.at-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px; }

.at-dialog-footer-actions {
  display: flex;
  justify-content: flex-end; }
  .at-dialog-footer-actions .at-button {
    margin-left: 10px; }

.thumb-item {
  margin: 0.25rem;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  position: relative; }
  .thumb-item.active > .thumb-image-container {
    border: 2px solid #00D092;
    border: 2px solid var(--brand3); }
  .thumb-item.active > .thumb-title {
    color: #00D092;
    color: var(--brand3); }

.close-button {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }

.thumb-title {
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  max-width: calc(100% - 1rem);
  white-space: nowrap; }
  .thumb-title p {
    text-overflow: ellipsis;
    overflow: hidden; }

.thumb-image-container {
  height: auto;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #EEF0F1;
  background-color: var(--bg-secondary);
  border: 2px solid transparent;
  background-position: center;
  background-repeat: repeat-y; }
  .thumb-image-container:before {
    content: " ";
    display: block; }
  .thumb-image-container.portrait:before {
    width: 100%;
    padding-top: 133.33%; }
  .thumb-image-container.landscape:before {
    width: 100%;
    padding-top: 66.66%; }

.thumb-image {
  max-height: calc(100% - 1rem);
  vertical-align: middle;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2)); }
  .thumb-image.fit-height {
    max-height: calc(100% - 1rem);
    width: auto; }
  .thumb-image.fit-width {
    max-width: calc(100% - 1rem);
    height: auto; }
  .thumb-image.rotated {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }

.newTag {
  background: var(--new-tag-color);
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15);
  border-radius: 4px;
  position: absolute;
  color: #E5E7E9;
  color: var(--bg-primary-active);
  padding: 0.2rem 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem; }

#designslist .variations, #designslist .variationThumb {
  display: none; }

.variationThumb {
  width: 15%;
  min-width: 2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  background: #EEF0F1;
  background: var(--bg-secondary);
  margin: 0.25rem;
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px rgba(16, 22, 26, 0.1), 0px 1px 1px rgba(16, 22, 26, 0.2);
  border: 2px solid transparent;
  max-height: 4rem;
  display: flex; }
  .variationThumb.fixedSize {
    width: 3rem;
    height: 3rem; }
  .variationThumb.active, .variationThumb:hover {
    border: 2px solid #00D092;
    border: 2px solid var(--brand3); }

.thumb-item .active {
  border: 2px solid #00D092;
  border: 2px solid var(--brand3); }

img.variationImgThumb {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto; }

.mainThumbArea {
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  border-radius: 4px;
  position: relative; }

.hideOnHover {
  opacity: 1;
  transition: opacity 0.5s linear; }

.mainThumbArea:hover ~ .hideOnHover {
  opacity: 0; }

.design-browser__thumbs-area__container.templateGrid {
  grid-template-columns: 25% 25% 25% 25%;
  display: grid; }

@media only screen and (max-width: 1200px) {
  .variationThumb {
    width: 20%;
    min-width: 1.5rem;
    padding: 0.2rem; }
  .design-browser__thumbs-area__container.templateGrid {
    grid-template-columns: 33% 33% 33%;
    display: grid; } }

.variations {
  display: inline-flex;
  width: 100%; }

.variationThumbHolder {
  width: 100%;
  height: 100%; }

.designThumb-template1 .imageThumb {
  background: #EEF0F1;
  background: var(--bg-secondary);
  padding: 0.3rem 1.5rem;
  position: relative; }

.designThumb-template1 .newTag {
  top: 0.5rem;
  right: 0.5rem; }

.designThumb-template1-designName {
  font-size: 1.375rem;
  font-weight: bold;
  color: #585858;
  color: var(--text-primary); }

.designThumb-template1 .variations {
  justify-content: center; }

.designThumb-template2 {
  position: relative;
  width: 86%;
  margin-left: 7%;
  display: inline-block;
  margin: auto; }
  .designThumb-template2 .mainThumbArea {
    padding: 0; }
  .designThumb-template2 .imageThumb {
    padding: 0.25rem; }
  .designThumb-template2 .newTag {
    top: 0.5rem;
    left: 0.5rem; }
  .designThumb-template2 .detailsContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    background-color: #252a2e;
    color: #30363B;
    color: var(--text-primary-active);
    padding: 0.5rem 0 0.5rem 0.75rem;
    text-align: left;
    margin: auto; }
  .designThumb-template2 .designName {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
    text-transform: uppercase; }
  .designThumb-template2 .sizeInfo {
    font-size: 0.75rem;
    font-family: IBM Plex sans;
    font-weight: 400;
    color: #30363B;
    color: var(--text-primary-active); }
  .designThumb-template2 .variations {
    position: absolute;
    bottom: 3.5rem;
    width: 100%;
    background-color: rgba(28, 31, 34, 0.75);
    text-align: left;
    padding-left: 0.75rem;
    left: 0.25rem; }
  .designThumb-template2 .variationThumb {
    background-color: #1c1f22;
    text-align: center;
    box-sizing: border-box; }

.designThumb-template3 {
  position: relative;
  width: 92%;
  margin-left: 4%;
  display: inline-block;
  margin: auto;
  margin-bottom: 1rem; }
  .designThumb-template3 .mainThumbArea {
    padding: 0; }
  .designThumb-template3 .imageThumb {
    padding: 0.25rem;
    margin: 0.25rem; }
  .designThumb-template3 .newTag {
    top: 0.5rem;
    left: 0.5rem; }
  .designThumb-template3 .detailsContainer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 0.4rem);
    height: 3.5rem;
    background-color: #252a2e;
    color: #30363B;
    color: var(--text-primary-active);
    padding: 0.5rem 0 0.5rem 0.75rem;
    text-align: left;
    margin: auto;
    width: 100%; }
  .designThumb-template3 .designName {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
    text-transform: uppercase; }
  .designThumb-template3 .sizeInfo {
    font-size: 0.75rem;
    font-family: IBM Plex sans;
    font-weight: 400;
    color: #30363B;
    color: var(--text-primary-active); }
  .designThumb-template3 .variations {
    bottom: 3.5rem;
    width: 100%;
    justify-content: center;
    padding: 0; }
  .designThumb-template3 .variationThumb {
    background-color: #EEF0F1;
    background-color: var(--bg-secondary);
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box; }
  .designThumb-template3 .mainThumbArea:hover .hideOnHover {
    opacity: 0; }

.designThumb-template4 {
  position: relative;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  display: inline-block;
  margin: auto; }
  .designThumb-template4 .mainThumbArea {
    padding: 0.5rem 1.5rem;
    background-color: #EEF0F1;
    background-color: var(--bg-secondary); }
  .designThumb-template4 .imageThumb {
    padding: 0.25rem; }
  .designThumb-template4 .newTag {
    top: 0.5rem;
    left: 0.5rem; }
  .designThumb-template4 .detailsContainer {
    bottom: 0;
    height: 3.5rem;
    color: #585858;
    color: var(--text-primary);
    text-align: left;
    justify-content: space-between;
    display: flex;
    margin: 1rem 0.5rem; }
  .designThumb-template4 .designName {
    font-size: 1.375rem;
    line-height: 1.5rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
    text-transform: uppercase;
    color: #30363B;
    color: var(--text-primary-active); }
  .designThumb-template4 .sizeInfo {
    font-size: 0.875rem;
    color: #30363B;
    color: var(--text-primary-active);
    font-family: IBM Plex sans;
    font-weight: 400;
    line-height: 1.5rem; }

#left-sidebar .designThumb-template4 .detailsContainer {
  display: block; }

#left-sidebar .designThumb-template4 .designName {
  font-size: 0.875rem; }

#left-sidebar .designThumb-template4 .sizeInfo {
  font-size: 0.75rem; }

.sliderContainer {
  position: absolute;
  bottom: 3.75rem;
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  left: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  padding: 1rem 1.5rem 0.75rem 1rem;
  border-radius: 0.25rem;
  width: 22.5rem;
  z-index: 100;
  background-color: #EEF0F1;
  background-color: var(--bg-secondary);
  color: #30363B;
  color: var(--text-primary-active);
  opacity: 0.8;
  transition: opacity 0.5s; }
  .sliderContainer.hasRuler {
    padding: 0 1.5rem 0.75rem 1rem; }
  .sliderContainer:hover {
    opacity: 1; }

.bp3-slider-handle {
  border-radius: 50%;
  outline: none;
  width: 1rem;
  margin-left: -0.5rem; }
  .bp3-slider-handle:hover {
    cursor: pointer; }
  .bp3-slider-handle:active {
    cursor: pointer; }

.bp3-slider-axis,
.bp3-slider-label {
  display: none; }

.SliderLabelsContainer {
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  color: #585858;
  color: var(--text-primary); }

.visualizationSlider {
  height: 1.5rem;
  margin: 0.25rem; }
  .visualizationSlider .bp3-slider-progress.bp3-intent-primary {
    background-color: #00D092;
    background-color: var(--brand3); }
  .visualizationSlider .bp3-slider-progress {
    background: #C7CBCE;
    background: var(--text-primary-muted); }
  .visualizationSlider .bp3-slider-handle {
    background: #00D092;
    background: var(--brand3); }
    .visualizationSlider .bp3-slider-handle:focus {
      outline: none;
      background-color: #00855D;
      background-color: var(--brand1); }
    .visualizationSlider .bp3-slider-handle:hover {
      background-color: #00AB78;
      background-color: var(--brand2); }

.opening-image-area {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%; }

img.opening-image {
  width: 100%;
  margin: auto;
  display: flex; }

.opening-image-container {
  margin: auto; }

.at-accountlinks {
  justify-content: space-evenly;
  display: flex; }

.bp3-tree-node-content {
  height: 2.5rem !important; }
  .bp3-tree-node-content:hover {
    background-color: #EFF0F2;
    background-color: var(--bg-primary-hover); }
  .bp3-tree-node-content.active {
    background-color: #E5E7E9;
    background-color: var(--bg-primary-active); }

.tree-node-content-inside .at-folder-story-icon {
  opacity: 0; }

.tree-node-content-inside.active > span {
  color: #30363B;
  color: var(--text-primary-active); }
  .tree-node-content-inside.active > span > h5 {
    color: #30363B;
    color: var(--text-primary-active); }

.tree-node-content-inside:hover > .bp3-tree-node-caret {
  opacity: 1; }

.tree-node-content-inside:hover > .at-folder-story-icon {
  opacity: 1; }

.bp3-tree-node-content-0 {
  padding-left: 0.75rem !important; }
  .bp3-tree-node-content-0 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-1 {
  padding-left: 1.5rem !important; }
  .bp3-tree-node-content-1 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-2 {
  padding-left: 2.25rem !important; }
  .bp3-tree-node-content-2 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-3 {
  padding-left: 3rem !important; }
  .bp3-tree-node-content-3 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-4 {
  padding-left: 3.75rem !important; }
  .bp3-tree-node-content-4 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-5 {
  padding-left: 4.5rem !important; }
  .bp3-tree-node-content-5 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-6 {
  padding-left: 5.25rem !important; }
  .bp3-tree-node-content-6 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-7 {
  padding-left: 6rem !important; }
  .bp3-tree-node-content-7 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-8 {
  padding-left: 6.75rem !important; }
  .bp3-tree-node-content-8 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-9 {
  padding-left: 7.5rem !important; }
  .bp3-tree-node-content-9 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-10 {
  padding-left: 8.25rem !important; }
  .bp3-tree-node-content-10 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-11 {
  padding-left: 9rem !important; }
  .bp3-tree-node-content-11 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-12 {
  padding-left: 9.75rem !important; }
  .bp3-tree-node-content-12 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-13 {
  padding-left: 10.5rem !important; }
  .bp3-tree-node-content-13 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-14 {
  padding-left: 11.25rem !important; }
  .bp3-tree-node-content-14 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-15 {
  padding-left: 12rem !important; }
  .bp3-tree-node-content-15 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-16 {
  padding-left: 12.75rem !important; }
  .bp3-tree-node-content-16 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-17 {
  padding-left: 13.5rem !important; }
  .bp3-tree-node-content-17 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-18 {
  padding-left: 14.25rem !important; }
  .bp3-tree-node-content-18 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-19 {
  padding-left: 15rem !important; }
  .bp3-tree-node-content-19 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-content-20 {
  padding-left: 15.75rem !important; }
  .bp3-tree-node-content-20 .tree-node-content-inside {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%; }

.bp3-tree-node-caret {
  opacity: 0;
  color: #5c7080;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  cursor: pointer;
  padding: 7px;
  margin-right: 0.25rem;
  transition: -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tree-node-caret:hover {
    color: #182026; }
  .bp3-dark .bp3-tree-node-caret {
    color: #a7b6c2; }
    .bp3-dark .bp3-tree-node-caret:hover {
      color: #f5f8fa; }
  .bp3-tree-node-caret.bp3-tree-node-caret-open {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .bp3-tree-node-caret.bp3-icon-standard::before {
    content: ""; }

.color-selection-box {
  color: #585858;
  color: var(--text-primary);
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  min-width: 20rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  box-shadow: 0px 18px 46px rgba(16, 22, 26, 0.2), 0px 4px 8px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  overflow: auto;
  position: relative; }
  .color-selection-box.dialog .color-pie {
    pointer-events: none; }
  .color-selection-box > div {
    margin: 0.5rem 1rem; }
  .color-selection-box .at-color-search-area {
    margin: 0.5rem 2rem 0.5rem 1rem; }
  .color-selection-box > .color-palette-dropdown {
    margin-left: 0;
    margin-right: 0;
    padding: 0.25rem 1rem;
    margin-bottom: 0;
    background-color: #EEF0F1;
    background-color: var(--bg-secondary);
    box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }
  .color-selection-box > .color-palette-area {
    display: flex;
    flex-direction: column; }
    .color-selection-box > .color-palette-area > div {
      display: flex;
      flex-direction: row; }
    .color-selection-box > .color-palette-area .color-palette-custom-select {
      text-align: center;
      margin-top: 0.5rem; }

.at-close-button {
  position: absolute;
  top: 0.6rem;
  right: 0.25rem;
  z-index: 25; }

.color-palette-area-colorLabel {
  text-align: center;
  display: none; }
  .color-palette-area-colorLabel.shown {
    display: flex;
    flex-direction: column; }

.color-palette-area-colorLabels {
  padding: 0.15rem; }

.labelTemplate-flipo {
  flex-direction: column;
  display: flex;
  flex: 1 1;
  height: 3rem;
  margin: 0.15rem; }
  .labelTemplate-flipo__titletext {
    font-size: 0.8rem;
    border-top: 1px solid #cccccc; }
  .labelTemplate-flipo__text {
    font-size: 1rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.at-color-palette-options {
  display: flex;
  align-items: flex-end; }

.at-sorting-options {
  flex: 1 1 auto;
  align-self: center; }

.color-swatch {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  box-shadow: 0px 0px 0 2px #F7F8F8;
  box-shadow: 0px 0px 0 2px var(--bg-primary);
  cursor: pointer; }
  .color-swatch.no-background {
    background-color: transparent !important;
    border: none !important; }
  .color-swatch.hide-color-pom img {
    visibility: hidden; }
  .color-swatch.active {
    outline: 1px solid #F7F8F8;
    outline: 1px solid var(--bg-primary);
    box-shadow: 0px 0px 0 3px #30363B;
    box-shadow: 0px 0px 0 3px var(--text-primary-active);
    z-index: 1; }
  .color-swatch--disabled {
    pointer-events: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    cursor: default; }
    .color-swatch--disabled.active {
      box-shadow: none;
      outline: none;
      background-color: transparent; }
  .color-swatch > span {
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    font-size: 8px;
    text-align: center;
    width: 100%;
    height: 10px;
    line-height: 10px;
    color: #30363B;
    color: var(--text-primary-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.pauligSwatchBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 1rem -1rem 0rem; }

.pauligSwatchBoxText {
  font-size: 9.5px;
  margin-top: 4px;
  text-transform: capitalize; }

.at-similar-color-content {
  display: flex;
  overflow: auto;
  margin-top: 0.5rem; }

.at-texture-sample-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 18px 46px rgba(16, 22, 26, 0.2), 0px 4px 8px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  padding: 0.25rem;
  width: 5.5rem;
  position: absolute;
  color: #585858;
  color: var(--text-primary);
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }
  .at-texture-sample-area > .at-texture-sample-box {
    width: 5rem;
    height: 5rem;
    margin-bottom: 0.25rem; }
  .at-texture-sample-area > .at-texture-sample-colorname {
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    width: 100%; }

.stage-container {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #EEF0F1;
  background-color: var(--bg-secondary); }
  .stage-container.design-view {
    margin: 6rem; }
  .stage-container.transparent-stage {
    opacity: 0; }
  .stage-container.opaque-stage {
    transition: opacity 500ms ease-out;
    opacity: 1; }
  .stage-container .rightBarPortal-stageContainer {
    left: auto;
    right: 0;
    z-index: 10; }
  .stage-container .stage-container-text {
    position: absolute;
    bottom: 2rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: #EEF0F1;
    background-color: var(--bg-secondary);
    z-index: 4;
    font-size: 0.8rem; }

.design-image {
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms; }
  .design-image.fit {
    -webkit-filter: drop-shadow(0px 12px 16px rgba(16, 22, 26, 0.3));
            filter: drop-shadow(0px 12px 16px rgba(16, 22, 26, 0.3)); }
  .design-image.rotated {
    transition: -webkit-transform 500ms;
    transition: transform 500ms;
    transition: transform 500ms, -webkit-transform 500ms;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }

.design-container {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative; }

.room-image {
  width: auto; }

.at-btn-sound-toggle {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: width 0.5s ease-in-out;
  padding: 0.5rem;
  z-index: 50;
  pointer-events: all;
  background: #F7F8F8 !important;
  background: var(--bg-primary) !important; }
  .at-btn-sound-toggle .at-icon {
    margin: 0; }
  .at-btn-sound-toggle h6 {
    opacity: 0;
    transition: opacity 0.4s, width 0.6s;
    height: 1rem;
    width: 0;
    overflow: hidden; }
  .at-btn-sound-toggle:hover h6 {
    opacity: 1;
    width: 10.5rem;
    margin-left: 0.25rem; }

.at-btn-sound-toggle-myrugStudio {
  position: absolute;
  top: 1%;
  left: 2%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: width 0.5s ease-in-out;
  padding: 0.5rem;
  z-index: 50;
  pointer-events: all;
  border-radius: 50%;
  background: #f7f8f8d4 !important; }
  .at-btn-sound-toggle-myrugStudio .at-icon {
    margin: 0; }
  .at-btn-sound-toggle-myrugStudio h6 {
    opacity: 0;
    transition: opacity 0.4s, width 0.6s;
    height: 1rem;
    width: 0;
    overflow: hidden; }
  .at-btn-sound-toggle-myrugStudio:hover h6 {
    opacity: 1;
    width: 10.5rem;
    margin-left: 0.25rem; }
  .at-btn-sound-toggle-myrugStudio:hover {
    border-radius: 0%; }

.at-custo-assistant-scout {
  pointer-events: all;
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  color: #585858;
  color: var(--text-primary);
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  display: flex;
  border-radius: 0.25rem; }
  .at-custo-assistant-scout > .area-swatch {
    width: 100%; }
  .at-custo-assistant-scout > .at-icon {
    padding: 0.25rem;
    cursor: pointer; }

.at-element-pan {
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: default !important; }

.at-illustration-popups-container {
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.at-illustration-popup {
  max-width: 30vw;
  max-height: 50vh;
  overflow: auto;
  background: #F7F8F8;
  background: var(--bg-primary);
  color: #585858;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 18px 46px rgba(16, 22, 26, 0.2), 0px 4px 8px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .at-illustration-popup:focus {
    outline: none !important; }
  .at-illustration-popup > .bp3-heading {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background: inherit;
    padding: 0.5rem 1rem; }
  .at-illustration-popup > .thumb-item {
    width: 15rem;
    margin: 0.5rem 1rem;
    margin-bottom: 0; }
    .at-illustration-popup > .thumb-item .thumb-image-container.landscape:before {
      padding-top: 100%; }

.custom-design-mode .at-illu-options-button, .custom-design-mode .at-saveicon-customdesign {
  z-index: 999;
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }

.at-illu-options-button.at-button {
  position: absolute;
  right: 100%;
  margin-right: 1.5rem;
  bottom: 0;
  margin-bottom: 1rem;
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1) !important;
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }

.at-illu-options-portal {
  bottom: 0;
  top: unset; }

.at-illu-options-menu {
  position: absolute;
  right: 100%;
  margin-right: 1.5rem;
  bottom: 0;
  margin-bottom: 4rem;
  border-radius: 0.25rem;
  border: 1px solid #EFF0F2;
  border: 1px solid var(--bg-primary-hover);
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }

.at-illu-option-item-image {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  border: 1px #30363B solid;
  border: 1px var(--text-primary-active) solid;
  margin: 0.25rem; }

.at-illu-option-item {
  display: flex;
  align-items: center; }

.at-carpet-option {
  cursor: default; }
  .at-carpet-option:hover {
    background-color: transparent;
    cursor: default; }
  .at-carpet-option:active {
    background-color: transparent;
    cursor: default; }

.at-carpet-option-rotation-controls > .bp3-popover-wrapper, .at-carpet-option-scale-controls > .bp3-popover-wrapper {
  margin: 0.25rem 0.25rem 0 0; }

.py-0 {
  padding-bottom: 0;
  padding-top: 0; }

.catalog-canvas {
  height: 65%;
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }

.at-saveicon-customdesign {
  position: absolute;
  right: 5%;
  bottom: 0;
  margin-bottom: 1rem;
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1) !important;
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }

@media only screen and (max-width: 600px) {
  .at-saveicon-customdesign {
    right: 16%; } }

@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .at-saveicon-customdesign {
    right: 9%; } }

.favorites-area {
  margin-bottom: 0.5rem; }

.lonely-area {
  text-align: center;
  padding: 1rem;
  color: var(--bg-text-primary-muted);
  background-color: #EEF0F1;
  background-color: var(--bg-secondary); }
  .lonely-area > div {
    margin-bottom: 0.5rem; }

.at-dialog.at-fav-dialog,
.at-dialog.at-confirmation-dialog {
  min-width: 17rem; }

.at-dialog.at-dialog-xs.at-confirmation-dialog.info-dialog {
  width: 15%; }

.at-fav-dialog-area.at-dialog-area > .bp3-heading,
.at-confirmation-dialog-area.at-dialog-area > .bp3-heading {
  text-align: left; }

.at-fav-dialog-area.at-dialog-area > .at-dialog-content,
.at-confirmation-dialog-area.at-dialog-area > .at-dialog-content {
  align-items: flex-start; }
  .at-fav-dialog-area.at-dialog-area > .at-dialog-content > .at-dialog-content-text,
  .at-confirmation-dialog-area.at-dialog-area > .at-dialog-content > .at-dialog-content-text {
    margin-bottom: 1rem; }
  .at-fav-dialog-area.at-dialog-area > .at-dialog-content > .bp3-form-group,
  .at-confirmation-dialog-area.at-dialog-area > .at-dialog-content > .bp3-form-group {
    width: calc(100% - 1rem); }

.at-close-dialog-button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 25; }

.at-fav-btns-area > button,
.at-confirmation-btns-area > button {
  margin-right: 0.25rem; }

.at-color-schemes {
  display: grid;
  overflow-x: auto; }

.at-design-variations-heading > .at-button {
  display: inherit; }
  .at-design-variations-heading > .at-button.has-3-children:not(.has-morethan-3-children) {
    display: none; }

.at-color-schemes {
  grid-template-columns: 33% 33% 33%; }
  .at-color-schemes > .thumb-item:nth-child(n + 4) {
    display: inherit; }
  .at-color-schemes.collapsed > .thumb-item:nth-child(n + 4) {
    display: none; }

.at-perspective-thumb > .thumb-image-container {
  position: relative;
  -webkit-perspective: 243px;
  perspective: 243px; }
  .at-perspective-thumb > .thumb-image-container > img {
    -webkit-transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px);
    transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px); }

.at-deepzoomDesign-thumb > .thumb-image-container > img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important; }

.at-folded-back-thumb > .thumb-image-container {
  position: relative;
  -webkit-perspective: 243px;
  perspective: 243px; }
  .at-folded-back-thumb > .thumb-image-container > img {
    -webkit-clip-path: polygon(-4% -4%, 104% -4%, 104% 69%, 64% 104%, -3% 104%);
    clip-path: polygon(-4% -4%, 104% -4%, 104% 69%, 64% 104%, -3% 104%);
    -webkit-transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px);
    transform: rotateX(64deg) rotateY(0deg) rotate(45deg) translateX(7px); }
  .at-folded-back-thumb > .thumb-image-container > img.folded-back-part {
    -webkit-clip-path: polygon(56% 115%, 108% 61%, 108% 108%, 56% 110%);
            clip-path: polygon(56% 115%, 108% 61%, 108% 108%, 56% 110%);
    -webkit-transform: rotateX(61deg) rotateY(5deg) rotate(221deg) translateX(-69%) translateY(-67%) translateZ(0px);
            transform: rotateX(61deg) rotateY(5deg) rotate(221deg) translateX(-69%) translateY(-67%) translateZ(0px);
    -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4)); }

.at-design-search-container {
  display: flex; }
  .at-design-search-container-text {
    flex: 1 1;
    margin: 0 0.5rem; }

button#at-wordCloud img {
  width: 5rem; }

.bp3-tag.bp3-intent-primary {
  background: #00D092;
  background: var(--brand3);
  color: #fff; }

.at-dialog-area {
  color: #30363B;
  color: var(--text-primary-active);
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  padding: 1rem;
  position: relative; }
  .at-dialog-area > .bp3-heading {
    margin-bottom: 1rem;
    text-align: center; }
  .at-dialog-area > .at-close-dialog-button {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 25; }
  .at-dialog-area > .at-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center; }

.at-qr-description {
  text-align: center; }

.at-vr-qrcode {
  width: 8rem;
  height: 8rem;
  margin: 0.5rem;
  cursor: pointer;
  outline: 1px solid white; }

.at-des-details__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .at-des-details__title__languageDropdown .bp3-heading {
    font-size: 1.1rem;
    padding: 0.5rem; }
  .at-des-details__title__languageDropdown .bp3-menu.langoptions-menu {
    min-width: 6rem; }
  .at-des-details__title__heading {
    flex: 1 1 auto;
    max-width: 100%;
    word-break: break-word; }
  .at-des-details__title__controls {
    margin-left: -0.6rem; }
    .at-des-details__title__controls.fullWidth {
      width: 100%; }

.at-des-details____tex-sel {
  margin: 1rem 0; }

.at-des-details__price-area {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  height: 1.5rem; }
  .at-des-details__price-area__dims {
    flex: 1 0 auto;
    line-break: strict; }

.at-des-details__size-area {
  display: flex;
  margin: 0.5rem 0; }

.at-des-details__design-info {
  margin-bottom: 0.5rem; }

button#at-des-details__title-saveImageIcon img {
  width: 1.2rem; }

.at-des-details__title__heading__linebreak {
  display: flex;
  flex-direction: column;
  margin-right: 1rem; }

#show-all-designs {
  justify-content: center;
  cursor: pointer; }

.bp3-tree-node-content.active {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1; }

.at-search {
  display: flex;
  flex-direction: column; }
  .at-search__no-results {
    text-align: center; }

.at-vis-spinner {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 100;
  background-color: #EEF0F1;
  background-color: var(--bg-secondary);
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.5rem; }
  .at-vis-spinner > div {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .at-vis-spinner > .spinner-border {
    border-color: #585858;
    border-color: var(--text-primary);
    border-right-color: transparent; }
  .at-vis-spinner--hidden {
    display: none; }

.tile-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.jpeg-canvasrender {
  transition: height 0.25s cubic-bezier(0, 0, 0.25, 1), width 0.25s cubic-bezier(0, 0, 0.25, 1); }

canvas.jpeg-canvasrender.TOPLEFT {
  position: absolute;
  top: 50px;
  left: 50px; }

canvas.jpeg-canvasrender.TOPRIGHT {
  position: absolute;
  top: 50px;
  right: 50px; }

canvas.jpeg-canvasrender.BOTTOMRIGHT {
  position: absolute;
  bottom: 50px;
  right: 50px; }

canvas.jpeg-canvasrender.BOTTOMLEFT {
  position: absolute;
  bottom: 50px;
  left: 50px; }

.bp3-tooltip .bp3-popover-arrow-border {
  fill: #F7F8F8;
  fill: var(--bg-primary); }

.bp3-tooltip .bp3-popover-arrow-fill {
  fill: #F7F8F8;
  fill: var(--bg-primary); }

.three-canvas-loading {
  -webkit-animation: fadeOut 2s;
          animation: fadeOut 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.studioButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  grid-gap: 5px;
  gap: 5px; }

.studioButtonPausePlay {
  z-index: 99;
  height: 38px;
  width: 38px;
  border: 1px solid white;
  box-shadow: none;
  background-color: #454545;
  cursor: pointer;
  position: relative; }

.studiocontainer {
  width: 100%;
  height: 100%;
  position: absolute; }

.studiocontainer--wand {
  cursor: url("https://v3dev.explorug.com/build/wand.cur"), auto; }

.studiocontainer__canvas {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; }

.studiocontainer__threecanvas {
  pointer-events: all; }

.studiocontainer__shadowcanvas {
  opacity: 1;
  transition: opacity 1s; }

.studiocontainer__shadowcanvas--fadeCross {
  opacity: 0; }

.studiocontainer__objcontainer {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5; }

.objcontainer__objcanvas {
  position: absolute; }

.objcontainer__objcanvas {
  pointer-events: all; }

.objcontainer__objcanvas--trans {
  opacity: 0.5 !important; }

.studiocontainer__transitioncanvas {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0; }

.studiocontainer__transitioncanvas--fadeCross {
  opacity: 1; }

.transitioncanvas__renderfromsavedstate {
  opacity: 1 !important;
  display: block !important; }

.studiocontainer__inputcontainer {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }

.studiocontainer__optionscontainer {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 60; }

.optionscontainer__pseudoWrapper {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.color-area-popover {
  pointer-events: all; }

.optionscontainer__shotteleporter {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all; }
  .optionscontainer__shotteleporter:hover {
    width: 2.5rem;
    height: 2.5rem; }

.circular-button {
  background-color: transparent !important;
  opacity: 0.8;
  color: rgba(158, 150, 150, 0.5);
  margin: 0; }

.optionscontainer__activetrigger {
  border-radius: 50%;
  background: transparent;
  opacity: 0.6;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  pointer-events: all;
  cursor: pointer; }
  .optionscontainer__activetrigger span {
    vertical-align: bottom; }
  .optionscontainer__activetrigger:hover {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid #ffffff; }
    .optionscontainer__activetrigger:hover .activetrigger__inelem {
      width: 2rem;
      height: 2rem; }

.activetrigger__inelem {
  background: #d6ccc0;
  border: 1px solid #808080;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%; }

.daybtn {
  z-index: 100; }

.optionscontainer__lightSwitch {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  opacity: 0.2; }
  .optionscontainer__lightSwitch.mysticsource {
    display: none; }
  .optionscontainer__lightSwitch__on {
    background-image: url("/build/LightOff.svg"); }
  .optionscontainer__lightSwitch__off {
    background-image: url("/build/LightOn.svg"); }
  .optionscontainer__lightSwitch:hover {
    opacity: 0.9; }
  .optionscontainer__lightSwitch:active {
    width: 2.5rem;
    height: 2.5rem; }

.perspective-view__container {
  opacity: 1;
  transition: opacity ease-in-out 0.4s; }
  .perspective-view__container.hide {
    opacity: 0; }

.bd-wordcloud {
  margin-left: 25%;
  display: flex; }
  .bd-wordcloud > div {
    display: flex;
    align-items: center; }

#imageDropContainer {
  position: absolute;
  height: var(--drop-container-height);
  text-align: center;
  bottom: 1rem;
  left: 2rem;
  border: 2px dashed #ccc;
  border-radius: 0.5rem;
  width: 26rem;
  font-family: sans-serif;
  background: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  right: 0;
  max-width: 40vw;
  cursor: pointer;
  z-index: 100; }

#imageDropContainer.highlight {
  border-color: #00D092;
  border-color: var(--brand3); }

.inputArea {
  width: 80%; }

div#inputSubmit {
  border: 1px solid;
  border-radius: 0.25rem;
  height: 26px;
  line-height: 26px;
  cursor: pointer; }

#customFile {
  display: none; }

.custom-file-label {
  cursor: pointer;
  color: #333;
  font-size: 1.2em; }

div#errorMessage {
  font-size: 0.75em;
  color: #555; }

#uploadMessage {
  font-size: 15px;
  color: #555;
  font-weight: 400; }

.custom-file-title {
  text-transform: uppercase;
  font-weight: 400; }

.at-file-input-dialog .at-dialog-area.at-file-input-dialog-area {
  min-height: 20rem;
  display: grid;
  grid-template-rows: 2rem calc(100% - 2rem); }

.at-file-input-dialog .at-dialog-content {
  justify-content: center; }

.at-file-input-dialog .palette-from-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.at-file-input-dialog .uploaded-image,
.at-file-input-dialog .palette-colors {
  display: flex;
  justify-content: center;
  align-items: center; }

.at-file-input-dialog .uploaded-image img {
  max-width: 90%; }

.at-file-input-dialog div#imageDropContainer {
  height: 100%;
  position: unset;
  display: grid;
  grid-row: 1/-1;
  width: 100%; }
  .at-file-input-dialog div#imageDropContainer:hover {
    border-color: #00D092;
    border-color: var(--brand3); }

.at-file-input-dialog h5.bp3-heading.at-dialog-heading, .at-file-input-dialog .at-sidebar-stepper.sidebar .at-sidebar__content .at-dialog-heading.at-des-details__price-area__price, .at-sidebar-stepper.sidebar .at-sidebar__content .at-file-input-dialog .at-dialog-heading.at-des-details__price-area__price {
  margin: 0;
  height: 100%;
  line-height: 2rem; }

.at-file-input-dialog label.custom-file-label {
  display: grid;
  height: 60%;
  margin-top: 1rem;
  width: 80%; }

.at-file-input-dialog .image-input-url {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%; }
  .at-file-input-dialog .image-input-url label.bp3-label {
    margin: 0;
    margin-right: 1rem;
    flex: 0 0 9rem; }
  .at-file-input-dialog .image-input-url .bp3-input-group {
    width: calc(100% - 9rem); }

.at-file-input-dialog .after-image-load-area {
  grid-row: 1/-1;
  display: grid; }

.at-file-input-dialog .image-palette-wrapper {
  grid-row: 1/-1;
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0.5rem 0; }

.at-file-input-dialog .image-palette-footer-area {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 0.5rem; }

.tree-content-folders {
  cursor: pointer; }
  .tree-content-folders.base-folder {
    text-transform: uppercase; }
  .tree-content-folders.selected {
    border: 1px solid #00D092;
    border: var(--collection-border-width) solid var(--collection-border-color);
    border-bottom: 1px solid #F7F8F8;
    border-bottom: var(--collection-border-width) solid var(--bg-primary);
    background: #F7F8F8;
    background: var(--bg-primary); }

.cyr-socialmediashare {
  display: grid;
  grid-template-columns: Repeat(4, 1fr);
  text-align: center;
  grid-gap: 1rem; }
  .cyr-socialmediashare .cyr-heading {
    font-size: 1.75rem; }
  .cyr-socialmediashare .cyr-social-media {
    cursor: pointer; }
  .cyr-socialmediashare span.bp3-icon.at-icon svg {
    width: 100%;
    height: 100%; }
  .cyr-socialmediashare span.bp3-icon.at-icon {
    width: 100%;
    height: 3rem; }

.at-arcarpet-dialog {
  width: 25% !important;
  min-width: 30rem; }

.ar-dialog-content .scan-text {
  padding-left: 1.5rem;
  font-size: 1rem;
  line-height: 1.125rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: calc(100% - 10rem);
  word-break: break-word;
  text-align: left;
  align-self: center; }

.ar-dialog-content .no-ar-app {
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  width: 100%; }

.ar-dialog-content .ar-app-store-area {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1rem; }
  .ar-dialog-content .ar-app-store-area a {
    padding: 1rem 0;
    line-height: 2; }

.ar-dialog-content .no-ar-app-title {
  color: #585858;
  color: var(--text-primary);
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline; }
  .ar-dialog-content .no-ar-app-title > .bp3-icon {
    margin-left: 0.5rem; }

.ar-dialog-content .ar-app-store canvas,
.ar-dialog-content .ar-play-store canvas {
  width: 8rem !important;
  height: auto !important;
  display: block; }

.ar-dialog-content .no-ar-app-content {
  height: 0;
  opacity: 0;
  text-align: center;
  overflow: hidden;
  transition: all 1s ease;
  transition-property: opacity;
  transition-duration: 0.25s; }
  .ar-dialog-content .no-ar-app-content.show {
    height: auto;
    opacity: 1; }

.ar-scan-wrapper {
  width: 100%;
  top: 100%;
  background: #f7f8f8;
  border-radius: 0px 0px 4px 4px;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: none;
  z-index: 1;
  display: flex; }
  .ar-scan-wrapper .qr-code {
    width: 10rem; }
  .ar-scan-wrapper canvas {
    width: 100% !important;
    height: auto !important; }
  .ar-scan-wrapper > svg {
    width: 50%; }

@media only screen and (max-width: 800px) {
  .ar-scan-wrapper {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: unset; }
  .ar-dialog-content .scan-text {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0;
    margin: 0.5rem 0;
    text-align: center; } }

@media only screen and (max-height: 500px) {
  .ar-scan-wrapper {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: unset; }
  .ar-dialog-content .scan-text {
    padding-left: 0; } }

@media only screen and (max-width: 500px) {
  .scan-wrapper {
    flex-direction: row; }
  .scan-text {
    padding-left: 0.5rem; }
  .at-arcarpet-dialog {
    min-width: 22rem; } }

.QRDialogTopDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center; }

.QRpara {
  padding: 0rem 2.5rem;
  font-size: 1rem;
  line-height: 1.125rem;
  word-break: break-word;
  text-align: center;
  margin-bottom: 1rem; }

.QRTopRightDiv {
  margin-bottom: 1rem;
  cursor: pointer;
  border: 1px solid #C7CBCE;
  border-radius: 0.25rem;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center; }

.cyr-qrTextDiv {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.dropdown {
  width: 140px; }

.selectSizeDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2 / span 4; }

.initial-logopage img {
  display: none; }
  .initial-logopage img.show {
    display: block; }

.initial-logopage .bp3-progress-bar .bp3-progress-meter {
  width: 30%;
  -webkit-animation: linear-progress-bar-stripes 2s linear infinite;
          animation: linear-progress-bar-stripes 2s linear infinite; }

@keyframes linear-progress-bar-stripes {
  0% {
    left: -30%; }
  to {
    left: 110%; } }

.app-main {
  width: 100%;
  height: 100%; }

.app-container {
  color: #585858;
  color: var(--text-primary);
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  transition: opacity 500ms ease-out; }
  .app-container.transparent {
    opacity: 0; }
  .app-container.opaque-stage {
    opacity: 1; }
  .app-container.isFelt .color-details-material,
  .app-container.isFelt .color-details-pileheight, .app-container.isFelt #at-masterCarving {
    display: none; }

.panel-title {
  display: inline;
  margin: auto 1em; }

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0; }
  .sidebar > .at-container {
    height: 100%;
    position: relative;
    overflow: hidden;
    flex: 1 1 auto; }

.panel-toggle {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute; }
  .panel-toggle.left {
    right: 0; }
  .panel-toggle.right {
    left: 0; }

.copyright-text {
  text-align: left;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: relative;
  width: 100%;
  padding-top: 1rem;
  flex: 0 0 auto; }

.logo-main {
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0.75rem 1.25rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: 0px 18px 46px rgba(16, 22, 26, 0.2), 0px 4px 8px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  z-index: 16; }
  .logo-main > .logo-image {
    height: 2rem; }

.at-size-dropdown .at-select {
  background-color: white; }

.control-buttons {
  position: absolute;
  right: calc(100% + 1rem);
  top: 1rem;
  display: inline-flex;
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  border-radius: 0.25rem; }
  .control-buttons > button {
    padding: 0.5rem 1rem; }

.custom-design-mode .left.sidebar {
  display: none; }

.side-panel-buttons-area {
  flex: 1 1 auto;
  height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  display: flex;
  align-items: center;
  justify-content: center; }

.download-button {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.order-sheet-buttons {
  position: absolute;
  bottom: 1rem;
  align-items: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .order-sheet-buttons > .at-button {
    margin: 0.25rem; }

.at-design-views-container {
  display: grid;
  grid-template-columns: 33% 33% 33%; }

.fullscreen-button {
  position: absolute;
  right: calc(100% + 1rem);
  top: 1rem;
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }

.fab-button {
  position: absolute;
  right: calc(100% + 1rem);
  bottom: 1rem;
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }

.at-container-gradient-area {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  height: 4rem;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  pointer-events: none; }

.at-designlist-area {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: column; }
  .at-designlist-area > .favorites-area {
    flex: 0 0 auto; }

.at-designlist-area > .at-section-wrapper {
  height: 100%; }

.at-tab-section {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .at-tab-section.inactive {
    display: none; }
  .at-tab-section > .at-section-heading-area {
    flex: 0 0 auto; }
  .at-tab-section > .at-section-container {
    flex: 1 1 auto; }
    .at-tab-section > .at-section-container.noscroll {
      flex-shrink: 0; }

.at-fullheight-container {
  height: calc(100% - 3.5rem); }

.at-loading-overlay {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  color: #585858;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 500ms; }
  .at-loading-overlay.loading {
    opacity: 1; }
  .at-loading-overlay > div {
    margin: 0.75rem; }
  .at-loading-overlay > .bp3-progress-bar {
    box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }
    .at-loading-overlay > .bp3-progress-bar > .bp3-progress-meter {
      background-color: #00D092;
      background-color: var(--brand3); }

.at-initial-loading-bar {
  width: 20rem; }

.at-loading-logo {
  width: 18rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .at-loading-logo .logo-image {
    border-radius: 0.5rem;
    max-width: 16rem;
    width: unset;
    padding: 0.5rem; }

@media only screen and (max-width: 1200px) {
  .copyright-text {
    padding-top: 0; } }

.at-container-stepper {
  display: inline-flex;
  width: 100vw;
  height: 100%; }
  .at-container-stepper > .stage-container {
    position: absolute;
    height: inherit;
    left: 50%;
    width: 50%;
    flex: 1 1 auto; }
    @media only screen and (max-width: 992px) and (orientation: portrait) {
      .at-container-stepper > .stage-container {
        left: 0;
        width: 100%; } }
    .at-container-stepper > .stage-container.panel-hidden {
      width: 100%;
      left: 0; }
  .at-container-stepper > .sidebar.left {
    width: 50%;
    flex: 0 0 auto;
    position: absolute;
    left: 0; }
    @media only screen and (max-width: 992px) and (orientation: portrait) {
      .at-container-stepper > .sidebar.left {
        width: 75%; } }
  .at-container-stepper > .at-sidebar__action-btns {
    right: 1.5rem;
    top: 1.5rem;
    z-index: 100;
    box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }

.promotionalModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); }

.promotionalContainer {
  width: 46.875rem;
  height: 90vh;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 65465;
  overflow: hidden;
  padding: 1.25;
  display: grid;
  grid-template-rows: auto;
  justify-content: center; }
  .promotionalContainer .list {
    height: 19.25rem; }
  .promotionalContainer .list.isMobile {
    height: 257px; }
  .promotionalContainer .list li {
    list-style-type: none;
    width: 27.938rem;
    height: 19.25rem;
    position: absolute;
    left: 50%;
    margin-left: -13.938rem;
    border-radius: 10px; }
  .promotionalContainer .list.isMobile li {
    width: 209px;
    height: 257px;
    margin-left: 104px; }
  .promotionalContainer .list li {
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s; }
  .promotionalContainer .list li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px; }
  .promotionalContainer .hide {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    border-radius: 10px;
    opacity: 0; }
  .promotionalContainer .prev {
    -webkit-transform: translateX(-23.438rem) scale(0.525);
            transform: translateX(-23.438rem) scale(0.525); }
  .promotionalContainer .next {
    -webkit-transform: translateX(23.438rem) scale(0.525);
            transform: translateX(23.438rem) scale(0.525); }
  .promotionalContainer .act {
    z-index: 1; }
  .promotionalContainer .new-next {
    -webkit-transform: translateX(39.375rem) scale(0.2512);
            transform: translateX(39.375rem) scale(0.2512);
    opacity: 0; }
  .promotionalContainer .old-prev {
    -webkit-transform: translateX(-39.375rem) scale(0.2512);
            transform: translateX(-39.375rem) scale(0.2512);
    opacity: 0; }
  .promotionalContainer .carousel {
    /* margin-top: 3.5rem; */
    position: relative;
    width: 100%;
    align-self: center; }
  .promotionalContainer .carousel::before {
    content: "";
    display: block;
    width: 100%;
    height: -webkit-max-content;
    height: max-content; }
  .promotionalContainer .controller-button {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 100%;
    z-index: 2; }
  .promotionalContainer .controller-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.875rem;
    height: rem;
    border-radius: 50%;
    border: 2px solid white;
    background-color: rgba(217, 217, 217, 0.2);
    cursor: pointer; }
  .promotionalContainer .logo {
    width: 31.813rem;
    justify-self: center; }
  .promotionalContainer .logo.isMobile {
    width: 14.875rem;
    justify-self: center; }
  .promotionalContainer .logo img {
    width: 100%;
    height: 100%; }
  .promotionalContainer .about {
    /* margin-top: 6rem; */
    font-weight: 600;
    font-size: 2.125rem;
    line-height: 2.563rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center; }
  .promotionalContainer .about.isMobile {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px; }
  .promotionalContainer .description {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center; }
  .promotionalContainer .contact {
    /* margin-top: 3.5rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 0.8rem; }
  .promotionalContainer .contact.isMobile {
    width: 100%; }
  .promotionalContainer .upgrade {
    font-weight: 600;
    font-size: 26px;
    line-height: 15px;
    text-align: center;
    align-self: self-end; }
  .promotionalContainer .upgrade.isMobile {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.625rem;
    align-self: center; }
  .promotionalContainer .contact button {
    /* margin-top: 1.2rem; */
    background-color: #f05223;
    width: 32.938rem;
    height: 3.688rem;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;
    cursor: pointer; }
  .promotionalContainer .contact.isMobile button {
    width: 245px;
    height: 49px; }
  .promotionalContainer .contact div {
    /* margin-top: 1rem; */
    color: #f05223;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center; }
  .promotionalContainer .contact div u {
    cursor: pointer; }
  .promotionalContainer .contact.isMobile div {
    font-size: 12px; }
  .promotionalContainer .icon-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    font-size: 30px;
    color: black; }

.promotionalContainer.isMobile {
  width: 95%;
  height: 75vh; }

.at-login-screen {
  display: flex;
  flex-direction: row;
  height: 100vh; }

.at-promotionals {
  flex-basis: 0;
  flex-grow: 1;
  background: url(../../static/media/login.9b2985f7.jpg);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover; }
  .at-promotionals > .bp3-card {
    position: absolute;
    bottom: 7rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    width: 30rem;
    color: #30363B;
    color: var(--text-primary-active);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 8px 24px rgba(16, 22, 26, 0.2), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }
    .at-promotionals > .bp3-card > p {
      margin-top: 1rem;
      text-align: center; }
    .at-promotionals > .bp3-card > .at-button {
      margin-top: 2rem; }

.at-login-input .bp3-input {
  box-shadow: inset 0 0 0 2px #C7CBCE;
  box-shadow: inset 0 0 0 2px var(--text-primary-muted);
  background-color: #EEF0F1;
  background-color: var(--bg-secondary); }

.login-field {
  flex: 0 0 42%;
  height: auto;
  color: #585858;
  color: var(--text-primary);
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #30363B;
  color: var(--text-primary-active); }

.signing-spinner {
  display: inline-flex;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  vertical-align: bottom;
  opacity: 0;
  transition: opacity 0.4s; }

.signing-spinner.show {
  opacity: 1; }

.signin-span-text {
  margin-left: -1rem;
  transition: 0.5s; }

.signin-span-text.shift-right {
  margin-left: 0; }

.at-login-screen.separate-loginpage .at-promotionals {
  display: none; }

.at-login-screen.separate-loginpage .login-field {
  flex: auto; }

.privacy-policy {
  position: absolute;
  z-index: 10;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
  color: #30363B;
  color: var(--text-primary-active);
  font-size: 0.8rem; }
  .privacy-policy > a {
    display: block;
    color: #30363B;
    color: var(--text-primary-active); }

.design-browser {
  display: flex;
  z-index: 50;
  position: absolute; }
  .design-browser__browsing-assistant.sidebar {
    position: relative;
    height: 100vh;
    flex-shrink: 0.1; }
  .design-browser__thumbs-area {
    height: 100vh;
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    flex: 1 1 auto;
    padding: 2.5rem;
    overflow: auto; }
    .design-browser__thumbs-area__title {
      margin-left: 0.25rem; }
      .design-browser__thumbs-area__title > .bp3-heading {
        line-height: 1.5rem; }
    .design-browser__thumbs-area__container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap; }
      .design-browser__thumbs-area__container .designThumb-template0.thumb-item {
        width: 10rem; }

.design-browser-tree {
  height: 80vh;
  overflow: auto; }

.design-browser-v2 {
  display: flex;
  z-index: 50;
  position: absolute;
  flex-direction: column;
  margin: 2rem; }
  .design-browser-v2__logo-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem; }
    .design-browser-v2__logo-area > .logo-image {
      height: 3rem; }
  .design-browser-v2__search-area {
    display: flex;
    justify-content: center;
    align-items: center; }
    .design-browser-v2__search-area > .at-input-group {
      max-width: 24rem; }
  .design-browser-v2__thumbs-area {
    height: 100vh;
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    flex: 1 1 auto;
    padding: 2rem;
    overflow: auto; }
    .design-browser-v2__thumbs-area__title {
      margin-left: 0.25rem; }
      .design-browser-v2__thumbs-area__title > .bp3-heading {
        line-height: 1.5rem; }
        .design-browser-v2__thumbs-area__title > .bp3-heading > span {
          color: #30363B;
          color: var(--text-primary-active); }
    .design-browser-v2__thumbs-area__container {
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap; }
      .design-browser-v2__thumbs-area__container > .thumb-item {
        width: 10rem; }

.design-browser-tree {
  height: 80vh;
  overflow: auto; }

.design-browser__browsing-assistant.slide {
  transition: margin-left 0.4s; }

.colors-page {
  padding: 1rem; }

.colors-page #at_ordersheet_btn {
  display: flex;
  margin: auto; }

:root {
  --right-column-width: Max(20%, 20rem);
  --cyr-roomthumbs-maxheight: 15rem;
  --thumb-min-width: 12rem;
  --collection-border-width: 1px;
  --collection-border-color: var(--brand3); }

body.create-your-rug {
  overflow: hidden;
  overflow-x: hidden;
  margin: 0;
  padding: 0; }
  body.create-your-rug * {
    scroll-behavior: smooth; }

.create-your-rug #app-main {
  -webkit-animation: fadeIn 5s;
          animation: fadeIn 5s;
  width: 100%;
  display: grid;
  grid-template-areas: "cyr-iframe" "cyr-1-roomview" "cyr-1-roomthumb" "cyr-2" "cyr-3" "cyr-4" "cyr-5" "cyr-6" "cyr-7";
  grid-template-columns: 100%;
  grid-template-rows: auto 75dvh 25dvh auto auto auto; }
  .create-your-rug #app-main.detailViewOnTop {
    grid-template-areas: "cyr-iframe cyr-iframe" "cyr-2 cyr-1-right" "cyr-2 ." "cyr-1-roomview  cyr-1-roomview" "cyr-1-roomthumb cyr-1-roomthumb" "cyr-3 cyr-3" "cyr-4 cyr-4" "cyr-5 cyr-5" "cyr-6 cyr-6" "cyr-7 cyr-7";
    grid-template-rows: auto auto Min(120vh, 100rem) 14rem auto auto auto auto;
    grid-gap: 1rem; }

.create-your-rug .searchBarDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: -1rem; }

.create-your-rug .at-search .thumb-container {
  flex-wrap: wrap; }

.create-your-rug .cyr-iframeContainer {
  grid-area: cyr-iframe;
  margin-bottom: 1rem;
  height: 100vh; }

.create-your-rug .cyr-roomViewContainer {
  grid-area: cyr-1-roomview;
  position: relative;
  overflow: hidden; }

.create-your-rug .illustration-wrapper {
  grid-area: cyr-1-roomthumb; }

.create-your-rug .right-column {
  grid-area: cyr-1-right; }

.create-your-rug #cyr-collection {
  grid-area: cyr-5; }

.create-your-rug #cyr-designvariations {
  grid-area: cyr-4; }

.create-your-rug .services-section {
  grid-area: cyr-6; }

.create-your-rug .cyr-detailView {
  grid-area: cyr-2; }

.create-your-rug #cyr-designviews {
  grid-area: cyr-3; }

.create-your-rug .cyr-copyright {
  grid-area: cyr-7; }

.create-your-rug #cyr-designvariations:empty {
  display: none; }

.create-your-rug .full-height {
  height: 100%; }

.create-your-rug .full-width {
  width: 100%; }

.create-your-rug .at-sidebar__action-btns {
  right: 5rem;
  z-index: 5; }

.create-your-rug .thumb-image.fit-width {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.create-your-rug #fadeindelayCYR {
  position: fixed;
  overflow: hidden; }

.create-your-rug .cyr-thumbs-container .thumb-image.fit-width {
  width: auto; }

.create-your-rug #room-view-container {
  height: 100%; }

.create-your-rug .at-design-variations-heading.at-design-variations-shapes {
  margin-top: 4rem; }

.create-your-rug .at-color-schemes.at-design-variations-shapes {
  margin: 0 1rem; }

.create-your-rug .selectColorInSimilarColorsTab {
  background-color: #bebebe66;
  flex-direction: column; }

.create-your-rug .plusIconDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center; }

.create-your-rug .customizeColorDiv {
  font-size: 12px; }

.create-your-rug .thumb-container,
.create-your-rug .at-color-schemes {
  display: flex; }
  .create-your-rug .thumb-container > div,
  .create-your-rug .at-color-schemes > div {
    flex: 0 0 Min(12%, 15rem);
    flex-direction: column;
    display: flex;
    min-width: 12rem;
    min-width: var(--thumb-min-width, 12rem); }

.create-your-rug .at-color-schemes {
  grid-gap: 4rem;
  gap: 4rem;
  justify-content: flex-start; }
  .create-your-rug .at-color-schemes > :first-child {
    margin-left: auto; }
  .create-your-rug .at-color-schemes > :last-child {
    margin-right: auto; }

.create-your-rug .thumb-image-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 10rem;
  max-height: 25rem; }

.create-your-rug .cyr-roomViewContainer .myroom-controls.topLeft {
  left: 2rem;
  z-index: 1;
  top: 21rem;
  margin: auto;
  bottom: 0;
  -webkit-transform: none;
          transform: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.create-your-rug .cyr-roomViewContainer .myroom-controls.bottomBar {
  left: 0;
  z-index: 1; }

.create-your-rug button.at-collapse-button {
  display: none; }

.create-your-rug .cyr-section {
  padding: 4rem 0;
  position: relative; }
  .create-your-rug .cyr-section .copyright-text {
    text-align: center; }

.create-your-rug .cyr-section-1 {
  margin-top: 1rem;
  display: flex;
  position: relative;
  height: calc(96vh + 15rem);
  height: calc(96vh + var(--cyr-roomthumbs-maxheight));
  display: grid;
  grid-template-columns: calc(100% - Max(20%, 20rem)) Max(20%, 20rem);
  grid-template-columns: calc(100% - var(--right-column-width)) var(--right-column-width); }

.create-your-rug .cyr-section-1 > div {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 100vw; }

.create-your-rug .at-sidebar__togglebtn.togglebtn-right {
  display: none; }

.create-your-rug .right-column-backdrop {
  display: none; }

.create-your-rug .right-column {
  min-width: 20%;
  flex: 0 0;
  flex-basis: Max(20%, 20rem);
  flex-basis: var(--right-column-width, 20rem);
  height: auto;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: auto; }

.create-your-rug .design-view-main-container {
  display: grid;
  grid-template-columns: auto 20rem 20rem; }

.create-your-rug .design-view-main-container-jpeg {
  display: grid;
  grid-template-columns: auto 20rem; }

.create-your-rug .at-sidebar__content__logo {
  height: 8rem;
  max-width: 20rem;
  display: flex;
  align-self: center;
  width: 100%;
  margin: auto;
  position: absolute;
  right: 12px;
  top: 4rem; }

.create-your-rug .bp3-portal.myroom-controls-portal {
  position: relative;
  width: 100%;
  height: 100%; }

.create-your-rug .stage-wrapper .stage-container {
  max-width: 100%; }

.create-your-rug .stage-wrapper .bp3-portal.at-illu-options-portal {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  left: auto;
  z-index: 100; }

.create-your-rug .cyr-dimension-div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: -8px; }

.create-your-rug .sizeInputDiv {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0; }
  .create-your-rug .sizeInputDiv #sizes {
    margin-bottom: 0.5rem;
    height: 28px;
    width: 100%;
    cursor: pointer;
    border-radius: 3px; }

.create-your-rug .sizeInputText {
  font-size: 14px;
  font-weight: 500;
  color: #58595B;
  margin-bottom: 0; }

.create-your-rug .sizeInputWidthLengthInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem; }
  .create-your-rug .sizeInputWidthLengthInput p {
    font-size: 13px;
    font-weight: 600;
    margin: 0; }

.create-your-rug .dflex {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.create-your-rug .sizeInput {
  width: 100%;
  height: 28px;
  border: none;
  border: 1px solid #767676;
  border-radius: 3px; }

.create-your-rug #cyr-sizeDropdown {
  padding: 1rem; }

.create-your-rug .sizeConfirmButton {
  cursor: pointer;
  width: 100%;
  margin-top: 1rem; }

.create-your-rug .cyr-detailView-designname {
  font-size: 1.5rem;
  line-height: 2; }

.create-your-rug .at-section-heading-area.color-area-swatch,
.create-your-rug .at-ordersheet__title {
  display: none; }

.create-your-rug #at_ordersheet_buttons_cancel {
  display: none; }

.create-your-rug .illustration-wrapper {
  position: relative;
  padding: 0rem; }
  .create-your-rug .illustration-wrapper .at-section-heading-area {
    display: none; }

.create-your-rug .cyr-color-area {
  margin: 1rem;
  flex: 1 1 40%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 15rem; }

.create-your-rug div#color-area {
  flex: 1 1;
  overflow: auto; }

.create-your-rug .cyr-socialmediashare-area {
  flex: 0 0 auto;
  margin: 0;
  padding: 0.5rem; }
  .create-your-rug .cyr-socialmediashare-area .cyr-heading {
    font-size: 1.5rem;
    margin: 0; }

.create-your-rug .cyr-long-buttons-area {
  display: flex;
  flex-direction: column; }

.create-your-rug .at-button.cyr-long-buttons {
  width: 90%;
  margin: auto;
  margin-bottom: 0.5rem; }
  .create-your-rug .at-button.cyr-long-buttons > .at-button-text {
    font-size: 1.5rem;
    line-height: 2rem;
    text-transform: capitalize;
    font-weight: normal;
    font-family: "Poppins", sans-serif; }

.create-your-rug .cyr-sheet-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0.5rem 0rem; }

.create-your-rug .at-section-heading-area {
  height: auto; }
  .create-your-rug .at-section-heading-area .at-section-label {
    height: auto;
    justify-content: center; }

.create-your-rug #cyr-DesignName {
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem 0rem;
  font-weight: 800; }

.create-your-rug .horizontalLine {
  border-bottom: 3px solid #50504e;
  margin: 1rem; }

.create-your-rug .cyr-heading,
.create-your-rug .at-section-heading-area .bp3-heading {
  font-family: "Cinzel", serif;
  font-size: 2rem;
  text-align: center;
  margin: 2rem 0;
  text-transform: uppercase;
  font-weight: normal; }

.create-your-rug .cyr-collection-section .thumb-container {
  position: absolute; }

.create-your-rug .color-area-container > .area-swatch {
  width: Max(50%, 20rem); }

.create-your-rug .stage-wrapper.deepzoom {
  position: relative;
  width: Min(100vw, 100%);
  height: 80vh;
  min-height: 25rem; }

.create-your-rug .design-controller__thumbs-area__container {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  max-width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  justify-content: flex-start; }
  .create-your-rug .design-controller__thumbs-area__container > div {
    flex: 0 0 Min(15%, 20rem);
    min-width: 12rem;
    min-width: var(--thumb-min-width, 12rem);
    height: 100%; }
    .create-your-rug .design-controller__thumbs-area__container > div .thumb-item {
      height: 100%; }
  .create-your-rug .design-controller__thumbs-area__container > :first-child {
    margin-left: auto; }
  .create-your-rug .design-controller__thumbs-area__container > :last-child {
    margin-right: auto; }

.create-your-rug ul.tree-content-folders-container {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative; }
  .create-your-rug ul.tree-content-folders-container:before {
    background-color: #00D092;
    background-color: var(--collection-border-color);
    content: ".";
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 1px;
    height: var(--collection-border-width);
    z-index: -1;
    left: 0; }

.create-your-rug ul.tree-content-folders-container .tree-content-folders {
  padding: 1rem;
  line-height: 1.5rem; }

.create-your-rug .at-vis-thumbs {
  max-width: 100%;
  overflow: auto; }
  .create-your-rug .at-vis-thumbs .thumb-container {
    display: flex;
    max-height: 15rem;
    max-height: var(--cyr-roomthumbs-maxheight); }

.create-your-rug .at-section-container::after {
  display: none; }

.create-your-rug button#at_ordersheet_btn {
  display: flex;
  margin: auto; }

.create-your-rug .cyr-large-card-wrapper {
  height: auto;
  cursor: pointer; }

.create-your-rug .double-column-section {
  display: grid;
  grid-template-columns: 50%;
  grid-auto-flow: column;
  margin: auto;
  width: Max(80%, 25rem); }

.create-your-rug .myroom-card {
  width: 50% !important; }

.create-your-rug #singleCYRCard {
  grid-template-columns: none; }

.create-your-rug .double-row-section {
  display: grid;
  grid-template-rows: auto;
  margin: auto;
  padding: 1rem;
  width: 100%; }

.create-your-rug .cyr-image-wrapper > img, .create-your-rug .cyr-image-wrapper > video {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.create-your-rug .cyr-card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  flex: 1 1 auto;
  min-height: 1px;
  font-size: 1rem;
  padding: 1.25rem; }

.create-your-rug .cyr-card-body-title {
  font-size: 1.5rem;
  color: #30363B;
  color: var(--text-primary-active); }

.create-your-rug .cyr-card-body-text {
  text-align: justify !important;
  font-size: 1rem; }

.create-your-rug .cyr-thumbs-container {
  position: relative; }

.create-your-rug span.bp3-icon.at-icon.navigator-arrows {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 5rem;
  width: 5rem;
  z-index: 8;
  left: 1rem; }
  .create-your-rug span.bp3-icon.at-icon.navigator-arrows.right {
    left: auto;
    right: 1rem; }
  .create-your-rug span.bp3-icon.at-icon.navigator-arrows > svg {
    width: 100%;
    height: 100%;
    stroke-width: 0.5px;
    stroke: #eee;
    -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
    fill: #00D092;
    fill: var(--brand3); }
    .create-your-rug span.bp3-icon.at-icon.navigator-arrows > svg:hover {
      -webkit-filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.8));
              filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.8));
      cursor: pointer;
      stroke: white; }

.create-your-rug .appview .cyr-socialmediashare-area {
  display: none; }

.create-your-rug .saveImages {
  display: flex;
  justify-content: space-around; }

.create-your-rug .at-design-views-similardesigns {
  overflow-y: hidden; }

.create-your-rug .ecat-mode .at-perspective-thumb > .thumb-image-container > img {
  -webkit-transform: rotateX(70deg) rotateY(1deg) rotate(46deg) translateX(-28px);
  transform: rotateX(70deg) rotateY(1deg) rotate(46deg) translateX(-28px); }

@media only screen and (max-width: 1300px) and (orientation: portrait) {
  .cyr-roomViewContainer {
    min-height: 30rem; } }

@media only screen and (max-width: 1300px) {
  .create-your-rug #app-main {
    grid-template-areas: "cyr-iframe" "cyr-1-roomview" "cyr-1-roomthumb" "cyr-2" "cyr-3" "cyr-4" "cyr-5" "cyr-6" "cyr-7";
    grid-template-columns: 100%; }
  .create-your-rug .design-view-main-container {
    grid-template-columns: auto 15rem 15rem; }
  .create-your-rug .design-view-main-container-jpeg {
    grid-template-columns: auto 15rem; }
  .create-your-rug .at-sidebar__togglebtn.togglebtn-right {
    display: inline-flex;
    right: 0;
    z-index: 10;
    transition: right 300ms linear; }
    .create-your-rug .at-sidebar__togglebtn.togglebtn-right.toLeft {
      right: 24rem; }
  .create-your-rug .right-column-backdrop.show {
    display: grid;
    width: 100%;
    height: 100%;
    grid-row: 1/-1;
    grid-column: 1/-1;
    z-index: 9; }
  .create-your-rug .right-collapsible {
    position: absolute;
    right: 0;
    z-index: 10;
    background: #F7F8F8;
    background: var(--bg-primary);
    width: 0;
    padding: 0;
    min-width: 0;
    overflow: hidden;
    transition: width 300ms ease-in; }
    .create-your-rug .right-collapsible.show {
      width: 24rem;
      overflow: auto; } }

@media only screen and (max-width: 800px) {
  .create-your-rug .at-myroomicebreaker-contents {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    justify-content: center;
    align-items: start; }
  .create-your-rug .design-view-main-container {
    grid-template-columns: 50% 50% !important;
    grid-template-areas: "cyr-a cyr-a" "cyr-b cyr-c"; }
  .create-your-rug .design-view-main-container-jpeg {
    grid-template-columns: auto 10rem !important;
    grid-template-areas: "cyr-a cyr-c"; }
  .create-your-rug .at-sidebar__content__logo {
    display: none; }
  .create-your-rug #design-view-container {
    grid-area: cyr-a; }
  .create-your-rug .cyr-color-area {
    grid-area: cyr-b; }
  .create-your-rug .right-column1 {
    grid-area: cyr-c; }
  .create-your-rug .double-column-section {
    grid-auto-flow: row;
    grid-template-columns: 100%; }
  .create-your-rug .myroom-card {
    width: 100% !important; }
  .create-your-rug .double-row-section {
    display: flex;
    flex-direction: column; }
  .create-your-rug .right-column {
    display: grid;
    justify-self: center; } }

@media only screen and (orientation: portrait) {
  .cyr-iframeContainer {
    height: 30vh !important; } }

.explorug-controller {
  --bg-color: #f2b824;
  --header-height: calc(9 * 100vw / 16 + 5rem);
  background: #f2b824;
  background: var(--bg-color, #f2b824); }
  .explorug-controller .app-main {
    height: 100vh;
    overflow: auto; }
    .explorug-controller .app-main::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */ }
  .explorug-controller .exp-controller-header {
    position: relative;
    width: 100%;
    height: var(--header-height);
    background: #f2b824; }
    .explorug-controller .exp-controller-header .exp-controller-title {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      margin: 1rem; }
    .explorug-controller .exp-controller-header #room-view-container {
      position: relative;
      width: 90%;
      height: calc(100% - 4rem);
      margin: auto; }
      .explorug-controller .exp-controller-header #room-view-container .stage-container {
        height: calc(9 * 100vw / 16);
        bottom: 0;
        right: 0;
        margin: auto;
        width: 100%; }
        .explorug-controller .exp-controller-header #room-view-container .stage-container .illustration-container > div {
          background: #f2b824;
          background: var(--bg-color, #f2b824); }
  .explorug-controller .at-sidebar__content__tabs {
    background: white; }
    .explorug-controller .at-sidebar__content__tabs .bp3-tab-list {
      background: #f2b824;
      background: var(--bg-color, #f2b824); }
      .explorug-controller .at-sidebar__content__tabs .bp3-tab-list .bp3-tab {
        font-weight: 500;
        padding: 1rem 0.5rem;
        font-size: 1.2rem; }
      .explorug-controller .at-sidebar__content__tabs .bp3-tab-list .bp3-tab[aria-selected="true"] {
        color: #30363B;
        color: var(--text-primary-active);
        background: #fafafa !important;
        border-radius: 0.25rem;
        margin-bottom: -1px; }
      .explorug-controller .at-sidebar__content__tabs .bp3-tab-list .bp3-tab-indicator {
        background-color: white; }
  .explorug-controller #cyr-collection .cyr-heading {
    display: none; }
  .explorug-controller .cyr-section {
    background: white;
    max-height: 100%;
    display: flex;
    flex-direction: column; }
  .explorug-controller .thumb-title {
    font-size: 1rem; }
  .explorug-controller ul.tree-content-folders-container {
    display: flex;
    flex: 0 0 auto;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding: 1rem; }
    .explorug-controller ul.tree-content-folders-container::-webkit-scrollbar {
      display: none; }
    .explorug-controller ul.tree-content-folders-container .tree-content-folders {
      padding: 0.5rem 1rem;
      line-height: 1.5rem;
      background: #f0f0f0;
      border-radius: 4px;
      font-size: 1rem; }
      .explorug-controller ul.tree-content-folders-container .tree-content-folders.selected {
        background: #f2b824;
        background: var(--bg-color, #f2b824);
        color: #000;
        border: none; }
  .explorug-controller .cyr-thumbs-container {
    position: relative;
    max-height: 100%;
    overflow: auto;
    flex: 1 1 auto; }
  .explorug-controller .exp-controller-design-wrapper {
    max-height: 100%;
    display: flex;
    justify-content: center; }
    .explorug-controller .exp-controller-design-wrapper > img {
      object-fit: contain; }
  .explorug-controller .design-controller__thumbs-area__container {
    height: 100%;
    max-width: 100%;
    scroll-behavior: smooth;
    align-items: flex-end;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: 50% 50%; }
    .explorug-controller .design-controller__thumbs-area__container > div {
      flex: 0 0 Min(15%, 20rem);
      min-width: 12rem;
      min-width: var(--thumb-min-width, 12rem);
      height: 100%; }
      .explorug-controller .design-controller__thumbs-area__container > div .thumb-item {
        height: 100%; }
  .explorug-controller .at-vis-thumbs {
    overflow: auto;
    overflow-x: hidden; }
    .explorug-controller .at-vis-thumbs .thumb-item {
      width: Min(100%, 40rem);
      margin: auto; }
  .explorug-controller .at-designviews.at-vis-thumbs .thumb-item {
    width: Min(100%, 30rem); }
    .explorug-controller .at-designviews.at-vis-thumbs .thumb-item .thumb-image-container > img {
      width: 40%; }
  .explorug-controller .at-section-heading-area {
    display: none; }
  .explorug-controller .thumb-container {
    grid-template-columns: 100%;
    justify-items: center; }
  .explorug-controller .stage-wrapper.framePictureWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .explorug-controller .stage-wrapper.framePictureWrapper > div {
      position: absolute;
      max-width: Min(80%, 35rem);
      right: 0;
      left: 0;
      margin: auto;
      margin-top: -5rem;
      display: flex;
      justify-content: center;
      align-content: center;
      align-self: center;
      justify-self: center; }
    .explorug-controller .stage-wrapper.framePictureWrapper img {
      width: 100%;
      height: auto; }

@media only screen and (min-width: 700px) {
  .explorug-controller .design-controller__thumbs-area__container {
    grid-template-columns: 33% 33% 33%; } }

.share-dialog {
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  color: #30363B;
  color: var(--text-primary-active);
  padding-bottom: 0;
  overflow: visible;
  width: unset;
  margin-top: 3rem; }
  .share-dialog.at-dialog {
    width: 64rem; }
    .share-dialog.at-dialog.share-as-post, .share-dialog.at-dialog.share-as-message {
      width: 32rem; }
      .share-dialog.at-dialog.share-as-post .link-box, .share-dialog.at-dialog.share-as-message .link-box {
        max-width: 85%;
        width: 24rem; }
  .share-dialog.at-dialog.share-as-post div#messageArea {
    display: none; }
  .share-dialog.at-dialog.share-as-message div#socialMediaArea {
    display: none; }

.rugshare-title {
  height: 2em;
  margin: auto;
  width: 7em;
  font-size: 2em;
  position: absolute;
  top: -2em;
  left: 0;
  right: 0;
  z-index: 500;
  background: red;
  background: #ad201c;
  border-radius: 8px 8px 0px 0px;
  line-height: 2em;
  color: white; }

.share-area {
  position: relative;
  text-align: center;
  max-height: 60rem;
  vertical-align: middle;
  font-size: 0.8125rem; }
  .share-area > img {
    vertical-align: middle;
    border-style: none; }

.options-section {
  margin-left: 0;
  margin-right: 0;
  display: flex; }

.section {
  max-width: 32rem;
  display: inline-block;
  padding: 1em 2em;
  border-right: 2px solid #EEF0F1;
  border-right: 2px solid var(--bg-secondary);
  vertical-align: top;
  min-height: 30rem; }

#hoverInfo {
  position: absolute;
  background-color: #EEF0F1;
  background-color: var(--bg-secondary);
  width: 29em;
  left: auto;
  right: 0;
  font-weight: normal;
  text-transform: none;
  padding: 0.5em;
  line-height: 1.5;
  cursor: pointer; }

div#socialMediaArea {
  border-right: none; }
  div#socialMediaArea .preview-title-main {
    color: #1d2129;
    color: #30363B;
    color: var(--text-primary-active);
    font-weight: bold;
    text-decoration: none;
    font-weight: bold;
    font-family: Segoe UI; }

div#printArea {
  border-top: 2px solid #89bd9e;
  display: none; }

.section-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #30363B;
  color: var(--text-primary-active);
  position: relative; }
  .section-title > h5 {
    font-size: 1em; }
  .section-title > * {
    display: inline-block; }

span#appsInfo {
  margin: 0 0.5em;
  cursor: pointer; }
  span#appsInfo svg {
    height: 1.5em;
    vertical-align: sub; }

.section-desc {
  font-weight: normal;
  text-align: center;
  line-height: 1.5em;
  color: #30363B;
  color: var(--text-primary-active); }

.platforms-area {
  margin: 1em 0; }

.platform {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.6em;
  cursor: pointer; }
  .platform.selected > .platform-icon-area {
    box-shadow: 0px 6px 24px rgba(76, 51, 255, 0.5); }
  .platform.selected > .platform-title {
    font-weight: bold; }
  .platform > .platform-icon-area {
    border-radius: 8px;
    padding: 0.5em;
    width: 3em;
    height: 3em;
    margin: auto;
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); }
    .platform > .platform-icon-area > img {
      width: 100%; }
  .platform > .platform-title {
    font-size: 0.8em;
    margin: 0.5em 0; }

.preview-box {
  width: 19em;
  margin: auto; }

.platform-preview-box {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #E5E7E9;
  background-color: var(--bg-primary-active);
  border: 2px solid #ccc;
  box-sizing: border-box;
  border-radius: 0px 10px 8px 8px;
  position: relative;
  z-index: 1; }
  .platform-preview-box > .preview-thumb {
    height: 11em;
    overflow: hidden;
    border-radius: 0px 8px 0px 0px;
    position: relative;
    z-index: 21;
    background-color: #EEF0F1;
    background-color: var(--bg-secondary); }
    .platform-preview-box > .preview-thumb > img {
      width: 100%;
      margin-top: -15%; }
  .platform-preview-box > .preview-title {
    text-align: left;
    padding: 0.5em; }
    .platform-preview-box > .preview-title > .preview-title-main {
      font-size: 0.85em;
      line-height: 1.3em;
      -webkit-text-decoration-line: underline;
              text-decoration-line: underline;
      color: #2b95f0; }
    .platform-preview-box > .preview-title > .preview-subtitle {
      font-size: 0.7em;
      line-height: 1.2em;
      color: #999; }
    .platform-preview-box > .preview-title > .preview-time {
      text-align: right;
      color: #999999;
      font-size: 0.7em;
      line-height: 1em; }
  .platform-preview-box > .preview-link {
    display: block;
    font-size: 0.8em;
    color: #a7b3bd;
    text-align: left;
    margin: 0.3em 0.5em 0.5em; }
  .platform-preview-box > .preview-link.fbPreviewLink {
    color: #616770;
    text-transform: uppercase; }
  .platform-preview-box > .box-arrow {
    position: absolute;
    width: 16px;
    height: 16px;
    left: calc(8%);
    top: -8px;
    border-radius: 2px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: -1; }

.platform-preview-title {
  font-weight: bold;
  text-align: center;
  font-size: 0.82em;
  line-height: 1.8em;
  margin: 0.5em 0 1em 0;
  color: #808080; }

.rugshare-sec-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;
  border: 2px solid #ad201c;
  box-sizing: border-box;
  border-radius: 8px;
  color: black;
  color: #585858;
  color: var(--text-primary);
  width: 10em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 0.2em 0.5em;
  text-decoration: none;
  display: inline-block;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .rugshare-sec-btn:hover {
    background-color: #ad201c;
    cursor: pointer;
    color: white; }
  .rugshare-sec-btn > a {
    color: black;
    color: #30363B;
    color: var(--text-primary-active);
    text-decoration: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .rugshare-sec-btn:hover > a {
    color: white; }
  .rugshare-sec-btn > span {
    vertical-align: middle; }

.share-url {
  display: inline-block;
  width: calc(100% - 8em);
  border: 2px solid #EEF0F1;
  border: 2px solid var(--bg-secondary); }
  .share-url > input {
    padding-right: 0 !important; }

.link-section {
  background-color: #E5E7E9;
  background-color: var(--bg-primary-active);
  padding: 0.5rem; }

.link-box {
  display: inline-block;
  max-width: 48%;
  margin: 0.5rem;
  color: #7f7f7f;
  color: #30363B;
  color: var(--text-primary-active);
  vertical-align: top;
  text-align: left;
  width: 26em;
  position: relative;
  min-height: 8em; }

#qrArea > div {
  display: inline-block;
  width: calc(100% - 7.5em);
  padding-left: 1em; }
  #qrArea > div > div {
    font-size: 0.9em; }

#qrArea label {
  display: inline-block;
  margin-left: 1em; }

.qr-checkbox {
  vertical-align: top;
  box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.15);
  margin: 0 0.5em; }

.QR-checkbox-area {
  display: none; }

.text-overlay {
  position: absolute;
  width: 32px;
  height: 30px;
  background: linear-gradient(270deg, #EEF0F1 0%, rgba(196, 196, 196, 0) 100%);
  background: var(--utility-input-fade);
  right: 0;
  top: 0; }

#copy-link-area {
  vertical-align: middle;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto; }
  #copy-link-area > div {
    margin: 10px 0; }
  #copy-link-area .at-button {
    text-transform: capitalize;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.1); }
  #copy-link-area .at-button:hover {
    background: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: white; }

.qr-code-image {
  width: 7.5em;
  display: inline-block;
  vertical-align: top;
  outline: 1px solid white; }

.QRtext {
  font-size: 0.9em;
  padding-bottom: 0.8em; }

div#WhatsAppPreview {
  border-radius: 5px;
  text-align: left;
  background: #e2ffc7; }

#WhatsAppPreview > .preview-title {
  width: calc(100% - 5em);
  background: #daf7c1;
  height: 5em; }

div#WhatsAppPreview .preview-title-main {
  font-weight: bold;
  color: #333;
  text-decoration: none; }

#WhatsAppPreview .preview-link {
  display: block;
  font-size: 0.8em;
  color: #18a0ae;
  line-height: 2em; }

#WhatsAppPreview .preview-time {
  position: absolute;
  bottom: 5px;
  right: 1em; }

#WhatsAppPreview > div {
  display: inline-block;
  vertical-align: top; }

#WhatsAppPreview > .preview-thumb {
  width: 5em;
  height: 5em;
  border-radius: 4px 0 0 4px; }

@media screen and (max-width: 1500px) {
  .link-section > div {
    width: 85%;
    margin: auto; } }

@media screen and (max-width: 900px) {
  .section {
    min-height: auto; }
  .share-dialog-wrapper {
    max-height: 80vh;
    overflow: auto; }
  .at-dialog.share-dialog {
    width: 32rem; }
  .options-section .col-md {
    width: 100%;
    display: block; }
  .share-area {
    min-width: 30em; }
  .link-section > div {
    width: 80%; }
  .preview-box,
  div#printArea,
  div#qrArea {
    display: none; }
  .link-box {
    width: 100%;
    max-width: 100%; }
  div.share-url {
    width: calc(100% - 8em); }
  .share-url .bp3-icon {
    width: 71%; }
  .share-dialog {
    width: auto; }
  .options-section {
    flex-direction: column; } }

.at-catalog-header {
  margin: 0.5rem; }

.at-printing-layout {
  width: 100%;
  height: 20em;
  min-width: 15em;
  position: relative;
  padding: 2em 1em;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); }

.at-catalog-image {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  overflow: hidden; }

.at-catalog-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0; }
  .at-catalog-nav > div {
    cursor: pointer; }

.at-close-rugshare-button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 25; }

.lds-roller {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px; }

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px; }

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s; }

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px; }

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s; }

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px; }

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s; }

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px; }

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s; }

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px; }

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s; }

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px; }

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s; }

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px; }

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s; }

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px; }

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s; }

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px; }

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.at-ordersheet-dims-area,
.at-ordersheet-rugresize-area {
  display: flex;
  align-items: top;
  flex-wrap: wrap;
  margin: 0.5rem 0; }
  .at-ordersheet-dims-area > div,
  .at-ordersheet-rugresize-area > div {
    flex: 1 1 auto;
    margin: 0.25rem; }
  .at-ordersheet-dims-area > .at-units-input > p,
  .at-ordersheet-rugresize-area > .at-units-input > p {
    margin-bottom: 0.25rem; }
  .at-ordersheet-dims-area .bp3-editable-text,
  .at-ordersheet-rugresize-area .bp3-editable-text {
    box-shadow: inset 0 0 0 2px #EEF0F1;
    box-shadow: inset 0 0 0 2px var(--bg-secondary);
    padding: 0.5rem; }
  .at-ordersheet-dims-area.no-input,
  .at-ordersheet-rugresize-area.no-input {
    pointer-events: none;
    opacity: 0.5; }

.at-ordersheet-area-superscript {
  font-size: 1rem; }

@media only screen and (max-width: 1200px) {
  .at-ordersheet-dims-area,
  .at-ordersheet-rugresize-area {
    display: block; }
    .at-ordersheet-dims-area > div,
    .at-ordersheet-rugresize-area > div {
      display: inline-grid; } }

.at-ordersheet-price-area {
  margin: 0.5rem 0;
  display: flex;
  align-items: baseline; }

.at-ordersheet-pua {
  margin-left: 0.5rem;
  color: #939A9F; }

.at-ordersheet-max-area-message {
  color: red; }

#davisRugs-OrdersheetPreviewButton {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem; }

.at-ordersheet-preview {
  max-height: 85vh;
  border-radius: 0.5rem;
  display: flex;
  overflow: auto;
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  min-height: 70vh; }
  .at-ordersheet-preview__view-section {
    background-color: #EEF0F1;
    background-color: var(--bg-secondary);
    position: relative;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%; }
  .at-ordersheet-preview__form-section {
    padding: 1rem;
    color: #585858;
    color: var(--text-primary);
    background-color: #F7F8F8;
    background-color: var(--bg-primary);
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }

.at-ordersheet-dialog-buttons {
  text-align: right; }

.at-ordersheet-form-area {
  padding: 1rem; }

.at-section-title {
  margin-bottom: 1rem; }

.at-ordersheet-form-area-message {
  margin-top: 1rem; }

.at-ordersheet-buttons-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .at-ordersheet-buttons-area > button {
    margin: 0.5rem 0.25rem; }

#selectedKnotText {
  margin-top: 10px; }

.at-ordersheet-infotext {
  text-align: center;
  display: none; }

.at-ordersheet__wrapper {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

#at-ordersheet-area {
  display: flex;
  flex-direction: column;
  height: 100%; }
  #at-ordersheet-area > div {
    display: flex;
    flex-direction: column;
    height: 100%; }

.at-ordersheet__container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .at-ordersheet__container > div {
    flex: 0 0 auto; }
  .at-ordersheet__container > .at-ordersheet__content {
    overflow: auto;
    display: flex;
    flex-direction: column; }
    .at-ordersheet__container > .at-ordersheet__content > .outside {
      flex: 1 1 auto; }

.at-ordersheet__title > .at-button {
  display: none; }

.at-parentMessage-buttons > div {
  margin: auto;
  margin-top: 1rem; }

.at-addedField {
  flex-direction: row; }

.at-addedField__label {
  margin-left: 0.5rem; }

#at-formdate {
  margin-top: 5px;
  font-size: 18px;
  color: gray; }

.at-addedFieldSelect__dropdown {
  margin-bottom: 0.5rem; }

.sidebar {
  height: 100%;
  box-shadow: 0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  padding: 1.5rem;
  position: fixed;
  width: 25%; }
  .sidebar.left {
    left: 0; }
  .sidebar.right {
    right: 0px; }
  .sidebar.panel-hidden {
    z-index: 17; }
    .sidebar.panel-hidden.left {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
    .sidebar.panel-hidden.right {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }

#at-sidebar__footer__infoInFooter {
  text-align: center;
  margin-bottom: 2rem; }

.at-sidebar__footer-infotextHTML {
  display: none;
  width: 100%; }

.at-sidebar__togglebtn {
  position: absolute;
  top: 1rem; }
  .at-sidebar__togglebtn:focus {
    outline: none !important; }
  .at-sidebar__togglebtn.togglebtn-left {
    left: 99%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }
  .at-sidebar__togglebtn.togglebtn-right {
    right: 99%;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }

.at-sidebar__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1 1 auto; }
  .at-sidebar__content > * {
    flex: 0 0 auto; }
  .at-sidebar__content__logo {
    height: 5rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    flex: 0 0 auto;
    margin-bottom: 1rem; }
    .at-sidebar__content__logo.at-rightsidebar__logo {
      flex-grow: 1;
      max-width: 100%;
      display: none; }
      .at-sidebar__content__logo.at-rightsidebar__logo > img {
        max-width: 50%;
        height: auto;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-transform: none;
                transform: none; }
    .at-sidebar__content__logo > .logo-image {
      max-height: calc(100% - 0.5rem);
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      cursor: pointer;
      max-width: 100%;
      height: auto; }
    .at-sidebar__content__logo > .logo-tagline {
      position: absolute;
      bottom: 0;
      height: 1rem;
      text-align: center;
      left: 0;
      margin: auto;
      right: 0;
      margin-top: 0.5rem;
      display: none; }
  .at-sidebar__content__tabs {
    height: 100%; }

.at-sidebar__container {
  flex-direction: column;
  height: calc(100% - 4rem);
  flex: 1 1 auto;
  display: flex; }

.at-sidebar__footer {
  flex: 1 1 auto;
  height: -webkit-fit-content;
  height: fit-content;
  height: -moz-fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .at-sidebar__footer > * {
    margin: 0.25rem; }

.at-sidebar__action-btns {
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  position: absolute;
  right: calc(100% + 3rem);
  top: 1rem;
  box-shadow: 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1); }
  .at-sidebar__action-btns > .at-back-to-design {
    min-width: 9rem;
    border-right: 2px solid #EEF0F1;
    border-right: 2px solid var(--bg-secondary); }

#at-sidebar__footer__saveAsImage {
  display: none; }

.at-sidebar__footer-infotext {
  display: none; }
  .at-sidebar__footer-infotext.shown {
    display: block; }

.at-sidebar-stepper.sidebar.stepper-tab-layout .at-vis-thumbs .thumb-container {
  grid-template-columns: 50% 50%; }

.at-sidebar-stepper.sidebar.stepper-tab-layout .bp3-tree-node .thumb-container {
  grid-template-columns: 33% 33% 33%; }

.at-sidebar-stepper.sidebar {
  padding: 0; }
  .at-sidebar-stepper.sidebar > .at-sidebar-area {
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column; }
  .at-sidebar-stepper.sidebar.stepper-tab-layout {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__content {
      height: auto;
      overflow: unset;
      border-top: none; }
    .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__footer {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      background-color: #F7F8F8;
      background-color: var(--bg-primary);
      grid-template-rows: auto auto; }
      .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__footer > * {
        margin: 0; }
      .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__footer > .copyright-text {
        grid-row-start: 2;
        padding-bottom: 0;
        grid-column-start: 1;
        grid-column-end: 3;
        padding-top: 0.25rem; }
        .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__footer > .copyright-text > .copyright-description {
          display: none; }
      .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__footer > .at-footer-buttons-area {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: 3;
        justify-content: center; }
    .at-sidebar-stepper.sidebar.stepper-tab-layout .at-sidebar__step-container__title {
      display: none; }
    .at-sidebar-stepper.sidebar.stepper-tab-layout #at-ordersheet-area {
      width: 100%; }
  .at-sidebar-stepper.sidebar .at-sidebar__content {
    border-top: 2px #EEF0F1 solid;
    border-top: 2px var(--bg-secondary) solid;
    flex-direction: row; }
    .at-sidebar-stepper.sidebar .at-sidebar__content > .bp3-tabs {
      width: 100%;
      min-height: calc(100% + 5rem); }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .bp3-tabs > .bp3-tab-list {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #F7F8F8;
        background-color: var(--bg-primary);
        border-bottom: 2px #EEF0F1 solid;
        border-bottom: 2px var(--bg-secondary) solid; }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .bp3-tabs > .bp3-tab-panel {
        height: auto;
        overflow: unset; }
    .at-sidebar-stepper.sidebar .at-sidebar__content__logo {
      margin-bottom: 0; }
      .at-sidebar-stepper.sidebar .at-sidebar__content__logo > .logo-image {
        padding: 1rem;
        max-width: 100%;
        height: auto;
        max-height: calc(100% - 0.5rem); }
    .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container {
      display: flex;
      flex-direction: column;
      width: 33.33%;
      padding: 0 1rem; }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container.dimmed {
        opacity: 0.5;
        pointer-events: none; }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container > .at-sidebar__step-container__title {
        line-height: 3rem;
        height: 3rem;
        flex: 0 0 auto; }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container:not(:last-child) {
        border-right: 2px #EEF0F1 solid;
        border-right: 2px var(--bg-secondary) solid; }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container > .at-section-container {
        flex: 1 1 auto;
        overflow: auto; }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container .at-vis-thumbs .thumb-container {
        grid-template-columns: 100%; }
        @media only screen and (max-width: 992px) {
          .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container .at-vis-thumbs .thumb-container {
            grid-template-columns: 33% 33% 33%; } }
      .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container .bp3-tree-node .thumb-container {
        grid-template-columns: 50% 50%; }
        @media only screen and (max-width: 992px) {
          .at-sidebar-stepper.sidebar .at-sidebar__content > .at-sidebar__step-container .bp3-tree-node .thumb-container {
            grid-template-columns: 50% 50%; } }
    .at-sidebar-stepper.sidebar .at-sidebar__content .area-swatch {
      width: 100%; }
    .at-sidebar-stepper.sidebar .at-sidebar__content .at-des-details__title__heading {
      width: 100%; }
    .at-sidebar-stepper.sidebar .at-sidebar__content > .at-customize-area {
      border-top: 2px #EEF0F1 solid;
      border-top: 2px var(--bg-secondary) solid; }
    .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-variations {
      border-bottom: 2px #EEF0F1 solid;
      border-bottom: 2px var(--bg-secondary) solid; }
    .at-sidebar-stepper.sidebar .at-sidebar__content .at-color-schemes {
      grid-template-columns: 33% 33% 33%; }
    .at-sidebar-stepper.sidebar .at-sidebar__content #at-des-details__title-saveImageIcon {
      display: none; }
    .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-color-options {
      width: 100%; }
    .at-sidebar-stepper.sidebar .at-sidebar__content .at-ordersheet__title {
      display: none; }
    .at-sidebar-stepper.sidebar .at-sidebar__content #at-des-details__title-favorite {
      display: none; }
  .at-sidebar-stepper.sidebar .at-sidebar__footer {
    border-top: 2px #EEF0F1 solid;
    border-top: 2px var(--bg-secondary) solid;
    display: grid;
    grid-template-columns: 66.67% 33.33%;
    flex: 0 0 auto; }
    .at-sidebar-stepper.sidebar .at-sidebar__footer > .copyright-text {
      align-self: end;
      padding-left: 0.5rem;
      padding-bottom: 0.5rem; }
    .at-sidebar-stepper.sidebar .at-sidebar__footer > .at-footer-buttons-area {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      padding: 0.25rem; }
      .at-sidebar-stepper.sidebar .at-sidebar__footer > .at-footer-buttons-area > .at-button {
        margin: 0.25rem; }

.has-ordersheet-outside #at-ordersheet-designName {
  display: none; }

.has-ordersheet-outside #at_ordersheet_buttons_cancel {
  display: none; }

.at-price-est-area {
  padding: 0.5rem; }
  .at-price-est-area__pin-inp {
    display: flex; }
    .at-price-est-area__pin-inp__input {
      margin: 0.5rem 0; }
      .at-price-est-area__pin-inp__input > .bp3-input {
        box-shadow: inset 0 0 0 1px #C7CBCE;
        box-shadow: inset 0 0 0 1px var(--text-primary-muted); }
  .at-price-est-area .bp3-callout {
    margin: 0.5rem 0; }

.at-sendToApp-area {
  padding: 0.4em 1em;
  width: auto;
  display: block; }
  .at-sendToApp-area .at-sendToApp-area-userIdInput,
  .at-sendToApp-area .at-button {
    margin: 0.5rem 0; }
  .at-sendToApp-area > .userIdText {
    display: inline-block;
    margin: auto; }
  .at-sendToApp-area__inp {
    display: inline-block;
    margin-left: 0.7rem;
    width: 6rem; }

button#ReturnToAppBtn {
  width: 10rem;
  text-align: center; }

.at-sidebar__container.hide-color p.at-section-allowcolortext {
  color: #00D092;
  color: var(--brand3); }

.at-sidebar__container.hide-color .at-design-color-options,
.at-sidebar__container.hide-color #color-area,
.at-sidebar__container.hide-color .at-summary {
  display: none; }

.at-sidebar__container.hide-size #at-ordersheet-area,
.at-sidebar__container.hide-size .at-sidebar__container__shape-example,
.at-sidebar__container.hide-size .at-summary, .at-sidebar__container.hide-size .at-des-details__title__controls {
  display: none; }

.at-sidebar__container__steps {
  display: flex;
  justify-content: space-between; }
  .at-sidebar__container__steps .stepwise-buttons {
    text-align: center; }
    .at-sidebar__container__steps .stepwise-buttons-button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; }
      .at-sidebar__container__steps .stepwise-buttons-button p {
        position: absolute;
        margin: auto; }

.at-sidebar__container__shape-example {
  text-align: left; }
  .at-sidebar__container__shape-example .shape {
    border: 1px solid black;
    width: 9rem;
    height: 12rem;
    display: flex;
    position: relative;
    text-align: center;
    transition: height 300ms linear, border-radius 300ms linear; }
    .at-sidebar__container__shape-example .shape.Square {
      height: 9rem; }
    .at-sidebar__container__shape-example .shape.Round {
      border-radius: 9rem;
      height: 9rem; }
    .at-sidebar__container__shape-example .shape .shape-example-text {
      position: absolute; }
    .at-sidebar__container__shape-example .shape .shape-example-text.text-height {
      -webkit-transform: rotateZ(-90deg);
              transform: rotateZ(-90deg);
      align-self: center;
      margin-left: -0.7rem; }
    .at-sidebar__container__shape-example .shape .shape-example-text.text-width {
      justify-self: center;
      position: absolute;
      bottom: 0;
      margin-left: 3rem; }

.at-sidebar__container .at-summary-content {
  margin: 1rem 0; }

.at-sidebar__container .at-summary-title {
  font-weight: bold; }

.at-sidebar__container.summary .at-sidebar__container__shape-example,
.at-sidebar__container.summary p.at-section-allowcolortext,
.at-sidebar__container.summary .at-design-color-options {
  display: none; }

.at-sidebar__container.summary .at-ordersheet__container .at-section-wrapper {
  display: none; }

.at-sidebar__container.summary-title {
  font-weight: bold; }

.at-sidebar__container.summary-content {
  margin: 1rem 0; }

.camera-button {
  width: 72px;
  height: 72px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .camera-button--disabled {
    cursor: not-allowed;
    -webkit-animation: 2s rotateanim infinite;
            animation: 2s rotateanim infinite;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='4' stroke-dasharray='50%25%2c 13%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
    width: 74px;
    height: 74px; }
  .camera-button--nopointer {
    cursor: not-allowed; }
    .camera-button--nopointer .camera-button-clicker:active {
      width: 64px;
      height: 64px; }

@-webkit-keyframes rotateanim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotateanim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.camera-button-clicker {
  width: 64px;
  height: 64px;
  background: white;
  border-radius: 50%; }
  .camera-button-clicker:active {
    width: 60px;
    height: 60px; }

.at-scrolling-horizbar {
  display: flex;
  width: 100%;
  color: white;
  justify-content: center;
  margin: 0.5rem 0; }

.rear-placeholders, .front-placeholders {
  pointer-events: none;
  visibility: hidden; }

.input-warning {
  color: #A82A2A; }

.data-control-settings-dialog {
  background: #F7F8F8;
  background: var(--bg-primary);
  padding: 1rem; }

.data-control-settings-area {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  .data-control-settings-area > section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
  .data-control-settings-area .data-control-right-section {
    justify-content: space-between; }

.data-control-close {
  position: absolute;
  right: 0;
  cursor: pointer; }

.at-cinematic-bottompanel {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
  background-color: transparent !important; }

.bottompanel-wrapper {
  padding: 1rem 1rem 0.5rem 1rem;
  height: auto;
  background: #F7F8F8;
  background: var(--bg-primary);
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  opacity: 0.8;
  min-width: 60%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: grid;
  grid-template-columns: 45% 10% 45%; }
  .bottompanel-wrapper.hide {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  .bottompanel-wrapper__left-area {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
  .bottompanel-wrapper__center-area {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center; }
  .bottompanel-wrapper__right-area {
    grid-column-start: 3;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end; }

.play-btn-circular {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  border: none;
  cursor: pointer;
  background-color: transparent !important;
  opacity: 0.8;
  color: rgba(158, 150, 150, 0.5);
  margin: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #585858;
  border: 2px solid var(--text-primary); }

.play-btn {
  border: 0;
  padding: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 24px;
  border-color: transparent transparent transparent #585858;
  border-color: transparent transparent transparent var(--text-primary);
  transition: 100ms all ease;
  cursor: pointer;
  border-style: double;
  border-width: 0px 0 0px 20px; }
  .play-btn.btn-paused {
    border-style: solid;
    border-width: 11px 0 11px 20px; }
  .play-btn:hover {
    border-color: transparent transparent transparent #30363B;
    border-color: transparent transparent transparent var(--text-primary-active); }

.middle-placeholder {
  display: inline-flex;
  align-items: center;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  flex-direction: row-reverse;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-left: 6.5rem; }

.animation-duration-slider {
  width: auto;
  height: 16px;
  margin: 0.5rem 0 0 0;
  min-width: 200px; }
  .animation-duration-slider .bp3-slider-progress.bp3-intent-primary {
    background-color: #00D092;
    background-color: var(--brand3); }
  .animation-duration-slider .bp3-slider-progress {
    background: #C7CBCE;
    background: var(--text-primary-muted); }
  .animation-duration-slider .bp3-slider-handle {
    background: #C7CBCE;
    background: var(--text-primary-muted); }

.sliderBlock {
  margin: 0 1rem; }
  .sliderBlock .bp3-heading {
    display: inline-block; }
  .sliderBlock span.right {
    float: right; }
  .sliderBlock span.left {
    float: left; }
  .sliderBlock span.sub {
    font-size: 0.8rem; }

.logo-checkbox > span {
  margin-left: 0.5rem; }

.ellipseDiv.exit:before {
  -webkit-animation: hideBubble 1.5s forwards;
          animation: hideBubble 1.5s forwards; }

@-webkit-keyframes hideBubble {
  0% {
    opacity: 0.8;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%); }
  100% {
    opacity: 0;
    -webkit-clip-path: circle(49% at 48% 52%);
            clip-path: circle(49% at 48% 52%); } }

@keyframes hideBubble {
  0% {
    opacity: 0.8;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%); }
  100% {
    opacity: 0;
    -webkit-clip-path: circle(49% at 48% 52%);
            clip-path: circle(49% at 48% 52%); } }

.ellipseDiv.entry:before {
  -webkit-animation: showBubble 1.5s forwards;
          animation: showBubble 1.5s forwards; }

@-webkit-keyframes showBubble {
  0% {
    opacity: 0;
    -webkit-clip-path: circle(49% at 48% 52%);
            clip-path: circle(49% at 48% 52%); }
  100% {
    opacity: 0.8;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%); } }

@keyframes showBubble {
  0% {
    opacity: 0;
    -webkit-clip-path: circle(49% at 48% 52%);
            clip-path: circle(49% at 48% 52%); }
  100% {
    opacity: 0.8;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%); } }

.checkbox-area {
  display: block; }

.checkbox-area-inline {
  display: inline-block; }

.checkbox {
  box-shadow: none;
  vertical-align: middle; }

.cinematic-main {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: black; }
  .cinematic-main.isIdle .hideOnIdle {
    opacity: 0;
    transition: opacity 0.4s;
    pointer-events: none; }

.topPanel {
  position: absolute;
  background: #F7F8F8;
  background: var(--bg-primary);
  z-index: 60;
  right: 0;
  margin: 0.5rem;
  top: -3rem;
  transition: top 0.4s; }
  .topPanel.show {
    top: 0; }

.at-cine-music-slider {
  opacity: 0.8; }

.at-volume-slider {
  background-color: #F7F8F8;
  background-color: var(--bg-primary);
  width: 10rem;
  height: 2rem;
  /* top: 6.5rem;
  left: calc(100% - 7.05rem); */
  opacity: 0.8;
  -webkit-animation: fadeIn 0.5s forwards;
          animation: fadeIn 0.5s forwards;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  visibility: visible; }
  .at-volume-slider.hidden {
    visibility: hidden; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.ui {
  z-index: 18; }

.dialog {
  z-index: 19; }

.popover {
  z-index: 20; }

.overlay {
  z-index: 21; }

.center-absolute {
  position: absolute;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.at-section-wrapper {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column; }

.at-section-heading-area {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 2.5rem; }

.at-section-label {
  cursor: pointer;
  flex: 1 1 auto;
  height: 1.5rem;
  display: flex;
  align-items: center; }

body {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0px;
  overflow: hidden;
  background: #F7F8F8;
  background: var(--bg-primary);
  color: #585858;
  color: var(--text-primary);
  font-size: 0.875rem; }

button {
  font-family: "IBM Plex Sans"; }

p {
  margin-bottom: 0.25rem; }

.busyState * {
  cursor: wait !important; }

.at-idle-fadeout {
  transition: opacity 800ms;
  opacity: 0;
  pointer-events: none; }

.at-scroll-on-hover, .at-sidebar__container {
  overflow: auto;
  scrollbar-width: thin; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

:root {
  font-size: 16px; }

h1.bp3-heading, .bp3-running-text h1 {
  line-height: 2.5rem;
  font-size: 2.25rem; }

h2.bp3-heading, .bp3-running-text h2 {
  line-height: 2rem;
  font-size: 1.75rem; }

h3.bp3-heading, .bp3-running-text h3 {
  line-height: 1.5625rem;
  font-size: 1.375rem; }

h4.bp3-heading, .bp3-running-text h4 {
  line-height: 1.3125rem;
  font-size: 1.125rem; }

h5.bp3-heading, .at-sidebar-stepper.sidebar .at-sidebar__content .at-des-details__price-area__price, .bp3-running-text h5 {
  line-height: 1.1875rem;
  font-size: 1rem; }

h6.bp3-heading, .at-sidebar-stepper.sidebar .at-sidebar__content .at-section-label > .bp3-heading, .bp3-running-text h6 {
  line-height: 1rem;
  font-size: 0.875rem; }

.bp3-text-large {
  font-size: 1rem; }

.bp3-text-small, .bp3-form-group > label.bp3-label, .at-sidebar-stepper.sidebar .at-sidebar__content .thumb-title, .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-color-options > .at-button {
  font-size: 0.75rem; }

.marginAuto {
  margin: auto; }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  background: rgba(206, 212, 218, 0.5);
  border-radius: 0.5rem; }
  ::-webkit-scrollbar-thumb:hover {
    background: gray; }

::-webkit-scrollbar-track {
  background: transparent; }

body {
  scrollbar-face-color: rgba(206, 212, 218, 0.5);
  scrollbar-track-color: rgba(0, 0, 0, 0.05); }

@media (hover: none) {
  .sidebar.fadeout {
    opacity: 1 !important; } }

@media only screen and (orientation: landscape) {
  .sidebar {
    width: 50%; } }

@media only screen and (orientation: portrait) {
  .sidebar {
    width: 70%; } }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .copyright-text.bp3-text-small, .bp3-form-group > label.copyright-text.bp3-label, .at-sidebar-stepper.sidebar .at-sidebar__content .copyright-text.thumb-title, .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-color-options > .copyright-text.at-button {
    font-size: 0.6rem; }
  .at-dialog {
    width: 80%; }
  .copyright-text > span {
    display: none; }
  :root {
    font-size: 14px; }
  .at-promotionals {
    display: none; }
  .login-field {
    flex-grow: 1; }
  .bottompanel-wrapper {
    width: 100%; }
    .bottompanel-wrapper__left-area {
      display: none; }
    .bottompanel-wrapper__center-area {
      grid-column-start: 1; } }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .copyright-text.bp3-text-small, .bp3-form-group > label.copyright-text.bp3-label, .at-sidebar-stepper.sidebar .at-sidebar__content .copyright-text.thumb-title, .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-color-options > .copyright-text.at-button {
    font-size: 0.6rem; }
  :root {
    font-size: 14px; }
  .sidebar {
    padding: 0.5rem 1rem; }
  .at-sidebar__content__logo {
    margin-bottom: 0.5rem; }
  .at-dialog {
    width: 80%; }
  .bp3-button-text {
    font-size: 0.9rem; }
  .at-promotionals {
    display: none; }
  .login-field {
    flex-grow: 1; }
  .at-design-variations-heading > .at-button {
    display: inherit; }
    .at-design-variations-heading > .at-button.has-3-children:not(.has-morethan-3-children) {
      display: none; }
  .bottompanel-wrapper {
    width: 100%; }
    .bottompanel-wrapper__left-area {
      display: none; }
    .bottompanel-wrapper__center-area {
      grid-column-start: 1; } }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sidebar {
    width: 35%; }
  .at-dialog {
    width: 80%; }
  .thumb-container {
    grid-template-columns: 50% 50%; }
  .selectSizeButton {
    display: flex;
    justify-content: center;
    align-items: center; }
  .at-design-variations-heading > .at-button {
    display: inherit; }
    .at-design-variations-heading > .at-button.has-3-children:not(.has-morethan-3-children) {
      display: none; }
  .at-color-schemes {
    grid-template-columns: 33% 33% 33%; }
    .at-color-schemes > .thumb-item:nth-child(n + 4) {
      display: inherit; }
    .at-color-schemes.collapsed > .thumb-item:nth-child(n + 4) {
      display: none; }
  .copyright-description {
    display: none; }
  .bottompanel-wrapper {
    width: 100%; }
    .bottompanel-wrapper__left-area {
      display: none; }
    .bottompanel-wrapper__center-area {
      grid-column-start: 1; } }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sidebar {
    width: 30%; }
  .thumb-container {
    grid-template-columns: 50% 50%; }
  .copyright-description {
    display: block; }
  .at-promotionals {
    display: flex; }
  .login-field {
    flex-grow: 0; }
  .bottompanel-wrapper {
    width: 80%; }
    .bottompanel-wrapper__left-area {
      display: flex; }
    .bottompanel-wrapper__center-area {
      grid-column-start: 2; } }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sidebar {
    width: 30%;
    padding: 1rem; }
  .copyright-text.bp3-text-small, .bp3-form-group > label.copyright-text.bp3-label, .at-sidebar-stepper.sidebar .at-sidebar__content .copyright-text.thumb-title, .at-sidebar-stepper.sidebar .at-sidebar__content .at-design-color-options > .copyright-text.at-button {
    font-size: 0.75rem; }
  .bp3-form-group {
    margin: 0 0 1rem; }
    .bp3-form-group > label.bp3-label {
      margin-bottom: 0.5rem; }
  :root {
    font-size: 14px; }
  .bottompanel-wrapper {
    width: 60%; } }

.leaflet-container {
  cursor: default;
  background: transparent; }

img.leaflet-tile {
  mix-blend-mode: color; }

@media only screen and (min-width: 1700px) {
  .sidebar {
    width: 25%; }
  .thumb-container {
    grid-template-columns: 33% 33% 33%; }
  .at-design-variations-heading > .at-button.has-morethan-3-children,
  .at-design-variations-heading > .at-button .has-3-children {
    display: none; }
  .at-color-schemes {
    grid-template-columns: 20% 20% 20% 20% 20%; }
    .at-color-schemes > .thumb-item:nth-child(-n + 5) {
      display: inherit; }
    .at-color-schemes > .thumb-item:nth-child(n + 6) {
      display: inherit; }
    .at-color-schemes.collapsed > .thumb-item:nth-child(n + 6) {
      display: none; }
    .at-color-schemes.collapsed > .thumb-item:nth-child(-n + 5) {
      display: inherit; }
  .color-area-container .area-swatch {
    width: 50%; }
  :root {
    font-size: 16px; } }

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 17; }

.hidden {
  display: none; }

.test-canvas {
  position: absolute;
  z-index: 1000;
  -webkit-transform: scale(0.1);
          transform: scale(0.1);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  display: none; }

.no-custom * {
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.no-custom .bp3-icon-caret-down {
  display: none !important; }

.thumb-hidden {
  display: none; }

#at-error-page {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F7F8F8;
  background-color: var(--bg-primary); }
  #at-error-page > .error-title {
    margin: 0.25rem; }
  #at-error-page > .error-body {
    margin: 0.25rem;
    text-align: center; }
  #at-error-page > .error-button {
    margin: 1rem; }

.at-search-container {
  display: flex; }
  .at-search-container > .at-input-group {
    flex-grow: 1; }

.at-collapse-button:focus {
  outline: none; }

:focus {
  outline: gray auto 1px;
  outline-offset: 0; }

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important; }
  .disabled > * {
    pointer-events: none; }
  .disabled .color-area-popover {
    pointer-events: none !important; }

.custom-dialog-toggle {
  display: none; }

.justroom div#left-sidebar {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.justroom div#right-sidebar {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.justroom button.at-button.icon.at-sidebar__togglebtn {
  display: none; }

.closeuponly div#left-sidebar {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.closeuponly div#right-sidebar {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.closeuponly button.at-button.icon.at-sidebar__togglebtn {
  display: none; }

#myroom-container > canvas {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  touch-action: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

#myroom-container > .overlay-canvas {
  touch-action: pinch-zoom; }

#mask-canvas {
  pointer-events: none; }

.myroom-controls {
  position: absolute;
  box-shadow: -3px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);
  background-color: var(--bg-primary); }
  .myroom-controls.topLeft {
    flex-direction: column;
    left: calc(100% + 1rem);
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .myroom-controls.middleRight {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    clip: rect(0 2.5em 5rem -2em);
    right: calc(100% - 2.5em); }
    .myroom-controls.middleRight--expanded {
      box-shadow: none; }
  .myroom-controls.bottomBar {
    bottom: 0;
    left: 100%;
    width: 100vw;
    box-shadow: none;
    border-radius: 0; }
  .myroom-controls.camera-options {
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    height: 5rem; }
    .myroom-controls.camera-options > .camera-options__button {
      width: 11rem;
      text-align: left;
      height: 2.5rem;
      align-items: center;
      justify-content: left; }
    .myroom-controls.camera-options h6 {
      margin: auto;
      text-align: left;
      margin-left: 0; }

label.camera-options__button > button {
  width: 10rem;
  height: 100%;
  text-align: left;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: 0; }

.middleRight {
  vertical-align: middle;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 3em; }

#myroomCamera {
  width: 5em;
  height: 100%;
  border-radius: 50%;
  justify-content: flex-start;
  outline: none; }

.rightBarPortal {
  bottom: 0;
  z-index: -1; }

#left-sidebar .bp3-portal {
  bottom: 0;
  z-index: -1; }

.vertSeparator {
  width: 1px;
  height: 1.5rem;
  margin: 0.5rem 0.3rem;
  border: 1px solid #c7cbce; }

.at-webcam {
  position: absolute;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-primary-hover); }
  .at-webcam > #MyRoomvideo {
    position: absolute;
    z-index: 500;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%; }
  .at-webcam--controls {
    position: absolute;
    top: 50%;
    right: 1rem;
    z-index: 500;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.at-myroomicebreaker-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  display: flex;
  align-items: center;
  justify-content: center; }

.at-myroomicebreaker-image {
  position: absolute;
  object-fit: cover; }

.at-myroomicebreaker-contents {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  overflow: auto;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  position: absolute;
  padding: 2rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box; }
  .at-myroomicebreaker-contents * {
    margin: 0;
    margin-right: 0 !important;
    max-width: clamp(200px, 15vw, 25rem); }
  .at-myroomicebreaker-contents > label {
    display: flex;
    flex-direction: column; }
  .at-myroomicebreaker-contents > label .bp3-card {
    margin-right: 0;
    flex-basis: 100%; }
  .at-myroomicebreaker-contents .scan-wrapper {
    width: 100%;
    top: 100%; }
    .at-myroomicebreaker-contents .scan-wrapper > svg {
      width: 50%; }
  .at-myroomicebreaker-contents .cardimg {
    max-width: 100%;
    border-radius: 3px; }
  .at-myroomicebreaker-contents .bp3-card {
    padding: 0;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-primary); }
  .at-myroomicebreaker-contents .bp3-button.bp3-minimal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.5rem; }
  .at-myroomicebreaker-contents .at-button.at-minimal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.5rem;
    min-height: 3rem; }
  .at-myroomicebreaker-contents .bp3-button .at-icon {
    color: inherit;
    padding: 0.5rem; }
  .at-myroomicebreaker-contents .bp3-button-text {
    font-weight: bold;
    font-size: 0.95rem; }
  .at-myroomicebreaker-contents .scan-text {
    padding-left: 0.5rem;
    color: #30363b;
    font-size: 0.875rem;
    line-height: 1.125rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: calc(100% - 80px);
    word-break: break-word;
    text-align: left; }
  .at-myroomicebreaker-contents .scan-qr {
    width: 80px;
    height: 80px;
    min-width: 80px;
    background: #c2c2c2; }
  .at-myroomicebreaker-contents .scan-wrapper {
    background: #f7f8f8;
    border-radius: 0px 0px 4px 4px;
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    z-index: -1;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    display: none; }
  .at-myroomicebreaker-contents .at-ar-card {
    max-height: unset; }
    .at-myroomicebreaker-contents .at-ar-card > .bp3-card {
      border-radius: 3px 3px 0 0; }
    .at-myroomicebreaker-contents .at-ar-card .scan-wrapper {
      z-index: 1;
      display: flex; }
      .at-myroomicebreaker-contents .at-ar-card .scan-wrapper .qr-code {
        width: 80px; }
        .at-myroomicebreaker-contents .at-ar-card .scan-wrapper .qr-code canvas {
          width: 100% !important;
          height: auto !important; }
    .at-myroomicebreaker-contents .at-ar-card .scan-qr-wrapper {
      flex: 0 1;
      display: block;
      width: 100%;
      position: absolute;
      top: 100%;
      text-align: center;
      background: #f7f8f8;
      margin-top: -1rem;
      padding: 1rem;
      height: 9rem; }
      .at-myroomicebreaker-contents .at-ar-card .scan-qr-wrapper.hidden {
        display: none; }
      .at-myroomicebreaker-contents .at-ar-card .scan-qr-wrapper canvas {
        height: 80% !important;
        width: 100% !important;
        object-fit: contain; }
    .at-myroomicebreaker-contents .at-ar-card .ar-play-store,
    .at-myroomicebreaker-contents .at-ar-card .ar-app-store {
      cursor: pointer;
      text-decoration: underline; }
    .at-myroomicebreaker-contents .at-ar-card .ar-app-store-area {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0.5rem 0; }
      .at-myroomicebreaker-contents .at-ar-card .ar-app-store-area a {
        padding: 1rem 0; }
  .at-myroomicebreaker-contents .card-image-wrapper {
    display: flex;
    flex-direction: column; }
  .at-myroomicebreaker-contents .opt-1-wrapper {
    margin-right: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column; }
    .at-myroomicebreaker-contents .opt-1-wrapper.disabled {
      opacity: 0.8;
      cursor: not-allowed; }
  .at-myroomicebreaker-contents .opt-1-wrapper .bp3-card {
    margin-right: 0;
    flex-basis: 100%; }
  .at-myroomicebreaker-contents .hiddeninput {
    display: none; }

.at-dialog.at-myroom-Message-dialog {
  width: 25rem; }
  .at-dialog.at-myroom-Message-dialog .bp3-form-helper-text {
    margin-bottom: 1rem; }

.ar-stores-separator {
  flex: 0 0 1.6rem;
  align-items: center;
  justify-content: center;
  display: flex; }

@media only screen and (min-width: 500px) and (max-width: 1600px) {
  .at-myroomicebreaker-contents {
    height: auto;
    width: auto;
    text-align: center;
    overflow: visible; }
    .at-myroomicebreaker-contents > * {
      width: clamp(200px, 15vw, 25rem);
      margin: 0;
      justify-content: center; } }

@media only screen and (max-width: 1400px) {
  .at-myroomicebreaker-contents .at-ar-card .scan-wrapper {
    display: none; } }

@media only screen and (max-height: 700px) {
  .at-myroomicebreaker-contents .at-ar-card .scan-wrapper {
    display: none; } }

@media only screen and (max-height: 500px) {
  .at-myroomicebreaker-container {
    overflow: auto;
    align-items: flex-start; } }

@media only screen and (max-width: 800px) {
  .at-myroomicebreaker-contents .bp3-button-text {
    font-size: 0.75rem;
    line-height: 1rem; }
  .at-myroomicebreaker-contents .bp3-button.bp3-minimal {
    padding: 0; }
  .at-myroomicebreaker-contents .scan-wrapper {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; }
  .at-myroomicebreaker-contents .at-ar-card .scan-wrapper {
    position: unset; }
  .at-myroomicebreaker-contents .scan-text {
    width: 100%;
    font-size: 0.75em;
    line-height: 0.8rem;
    padding-left: 0; } }

@media only screen and (max-height: 500px) {
  .at-myroomicebreaker-contents .bp3-button-text {
    font-size: 0.75rem;
    line-height: 1rem; }
  .at-myroomicebreaker-contents .bp3-button.bp3-minimal {
    padding: 0; }
  .at-myroomicebreaker-contents .scan-wrapper {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; }
  .at-myroomicebreaker-contents .at-ar-card .scan-wrapper {
    position: unset; }
  .at-myroomicebreaker-contents .scan-text {
    font-size: 0.75em;
    line-height: 0.8rem;
    padding-left: 0; } }

@media only screen and (max-width: 500px) {
  .at-myroomicebreaker-contents {
    overflow: auto;
    flex-direction: column !important;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .at-myroomicebreaker-contents > * {
      margin-top: unset; }
    .at-myroomicebreaker-contents > div,
    .at-myroomicebreaker-contents > label {
      max-width: 70%; }
    .at-myroomicebreaker-contents .card-image-wrapper {
      overflow: hidden;
      position: relative; }
    .at-myroomicebreaker-contents .cardimg {
      width: auto;
      height: auto;
      max-width: unset !important;
      position: relative; }
    .at-myroomicebreaker-contents .scan-wrapper {
      flex-direction: row; }
    .at-myroomicebreaker-contents .scan-text {
      font-size: 0.9em;
      line-height: 1rem;
      padding-left: 0.5rem; } }

@media only screen and (max-width: 400px) {
  .at-myroomicebreaker-contents .at-ar-card .ar-app-store-area {
    flex-direction: column;
    align-items: center; } }

.day-state-btn {
  width: 3rem;
  height: 3rem;
  background: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 15;
  align-self: flex-start;
  margin-top: 1rem;
  cursor: pointer;
}
.day-state-btn__image {
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s;
  stroke:var(--text-primary);
  fill:var(--text-primary);
  pointer-events: none;
  width: 2rem;
}
.day-state-btn__image--active {
  opacity: 1;
  pointer-events: all;
}

.day-state-btn:hover {
  background-color: var(--bg-primary-hover)  
}
.animatesubjectimg {
  opacity: 1;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto; }

.animatesubjectimg.backup,
.animateddiv.backup {
  display: none; }

.animateddiv {
  opacity: 0; }

.animateddiv.active {
  z-index: 5;
  opacity: 1; }

.animateddiv.standby {
  z-index: 4;
  opacity: 1; }

.animatesubjectimg.active {
  opacity: 1;
  z-index: 5; }

.animateddiv .blur {
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  width: 100%;
  height: 100%; }

.animatesubjectimg.standby {
  z-index: 4;
  opacity: 1; }

.animation-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden; }

.animation-wrapper.paused .active {
  /* display: block !important; */
  opacity: 1 !important;
  -webkit-animation: none !important;
          animation: none !important; }

#eMotion .myRugToast {
  background-color: #ff5100;
  color: white; }

#loadingDiv {
  background: white;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center; }

#loadingIcon {
  width: 25%; }

#app-main-studioEntry {
  width: 100%;
  display: grid;
  grid-template-areas: "cyr-detailView cyr-roomViewContainer" "cyr-copyright cyr-copyright" "illustration-wrapper  illustration-wrapper";
  grid-template-columns: 50% 50%;
  overflow: hidden; }
  #app-main-studioEntry #cyr-detailView {
    grid-area: cyr-detailView; }
  #app-main-studioEntry .cyr-roomViewContainer {
    grid-area: cyr-roomViewContainer; }
  #app-main-studioEntry .sliderContainer.hasRuler {
    display: none; }
  #app-main-studioEntry .stage-wrapper.deepzoom {
    position: relative;
    max-width: 100%;
    height: 100dvh;
    min-height: 25rem; }
  #app-main-studioEntry #stage_container {
    width: 50vw; }
  #app-main-studioEntry .rendered1xDesign1 {
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    height: 100dvh;
    width: 50vw;
    position: absolute;
    right: 0;
    left: 0;
    object-fit: cover;
    object-position: 0% 100%; }
  #app-main-studioEntry .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 104%;
    left: -20px; }
  #app-main-studioEntry .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent; }
  #app-main-studioEntry .studioButtonPausePlay:hover .tooltiptext {
    visibility: visible; }
  #app-main-studioEntry .fade-out {
    opacity: 1;
    -webkit-animation-name: fadeout;
            animation-name: fadeout;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  #app-main-studioEntry .fade-in {
    opacity: 0;
    -webkit-animation-name: fadein;
            animation-name: fadein;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-transform: scale(1);
            transform: scale(1);
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out; }
  #app-main-studioEntry .animateRun {
    -webkit-animation: run;
    animation: run;
    -webkit-animation-duration: 5s;
            animation-duration: 5s; }

@-webkit-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0.1; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0.1; } }

@-webkit-keyframes fadein {
  0% {
    opacity: 0.1; }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.01);
            transform: scale(1.01); }
  75% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes fadein {
  0% {
    opacity: 0.1; }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.01);
            transform: scale(1.01); }
  75% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }
  #app-main-studioEntry .illustration-wrapper, #app-main-studioEntry .cyr-copyright {
    display: none; }
  #app-main-studioEntry #leafletCanvas, #app-main-studioEntry #DesignCanvasImage {
    -webkit-filter: blur(5px);
            filter: blur(5px); }
  #app-main-studioEntry .at-sidebar__action-btns {
    right: 10px !important;
    background-color: #454545;
    z-index: 99999; }
  #app-main-studioEntry :focus {
    outline: gray auto 0px !important; }
  #app-main-studioEntry .navigator-arrows {
    position: absolute;
    margin: auto;
    height: 5rem;
    width: 5rem;
    z-index: 8;
    cursor: pointer; }
    #app-main-studioEntry .navigator-arrows.left {
      top: 0;
      bottom: 0;
      left: 1rem; }
    #app-main-studioEntry .navigator-arrows.right {
      top: 0;
      bottom: 0;
      right: 1rem; }
    #app-main-studioEntry .navigator-arrows.up {
      left: 0;
      right: 0;
      top: 1rem; }
    #app-main-studioEntry .navigator-arrows.down {
      left: 0;
      right: 0;
      bottom: 1rem; }
    #app-main-studioEntry .navigator-arrows > svg {
      width: 100%;
      height: 100%;
      stroke-width: 0.5px;
      stroke: #eee;
      -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
              filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
      fill: #F05223; }
      #app-main-studioEntry .navigator-arrows > svg:hover {
        -webkit-filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.8));
                filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.8));
        cursor: pointer;
        stroke: white; }

@media (max-width: 1025px) and (orientation: portrait) {
  #loadingIcon {
    width: 50%; }
  #app-main-studioEntry {
    width: 100%;
    display: flex;
    flex-direction: column-reverse; }
    #app-main-studioEntry .at-spinner-overlay {
      height: 50vh; }
    #app-main-studioEntry #studioFullScreenButton {
      display: none; }
    #app-main-studioEntry #stage_container {
      width: 100vw; }
    #app-main-studioEntry .stage-wrapper.deepzoom {
      position: relative;
      width: Min(100vw, 100%);
      height: 50dvh; }
    #app-main-studioEntry .rendered1xDesign1 {
      width: 100vw;
      height: 50dvh;
      margin-top: 0.2rem;
      padding-right: 0rem;
      padding-bottom: 0rem; }
    #app-main-studioEntry .navigator-arrows.left {
      left: 0rem; }
    #app-main-studioEntry .navigator-arrows.right {
      right: 0rem; }
    #app-main-studioEntry .navigator-arrows.up {
      top: 0rem; }
    #app-main-studioEntry .navigator-arrows.down {
      bottom: 0rem; } }

@media (max-width: 500px) {
  .navigator-arrows {
    height: 2rem !important;
    width: 2rem !important; }
  #app-main-studioEntry .cyr-roomViewContainer {
    min-height: 0 !important; } }

@media (max-width: 1000px) and (orientation: landscape) {
  .navigator-arrows {
    height: 2rem !important;
    width: 2rem !important; }
  .stage-wrapper.deepzoom {
    min-height: 0 !important; } }

