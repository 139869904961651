$at-size: 1rem;
$pt-border-radius: 0.25rem;
$pt-button-height: 2 * $at-size;
$button-border-width: 1px;
$pt-icon-size-standard: 1rem;
$button-padding: ($at-size / 2) $at-size;
$button-padding-small: 0 ($pt-grid-size * 0.7) !default;
$button-padding-large: ($pt-grid-size / 2) ($pt-grid-size * 1.5) !default;
$button-icon-spacing: ($pt-button-height - $pt-icon-size-standard) / 2 !default;
$button-icon-spacing-large: ($pt-button-height-large - $pt-icon-size-large) / 2 !default;
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
$button-box-shadow: inset 0 0 0 $button-border-width var(--text-primary),
  inset 0 (-$button-border-width) 0 var(--text-primary);

$transparent: #00000000;

// "intent": (default, hover, active, muted)
$at-button-intents: (
  "primary": (
    var(--brand3),
    var(--brand2),
    var(--brand1)
  ),
  "success": (
    $success3,
    $success2,
    $success1
  ),
  "warning": (
    $warning3,
    $warning2,
    $warning1
  ),
  "danger": (
    $danger3,
    $danger2,
    $danger1
  )
);

@mixin pt-button-base() {
  @include pt-flex-container(row, $button-icon-spacing, $inline: inline);
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: $pt-border-radius;
  cursor: pointer;
  padding: $button-padding;
  vertical-align: middle;
  text-align: left;
  font-size: $pt-font-size;
}

@mixin pt-button-height($height) {
  min-width: $height;
  min-height: $height;
}

@mixin pt-button-height-large() {
  @include pt-button-height($pt-button-height-large);
  @include pt-flex-margin(row, $button-icon-spacing-large);
  padding: $button-padding-large;
  font-size: $pt-font-size-large;
}

@mixin pt-button-height-default() {
  @include pt-button-height($pt-button-height);
  padding: $button-padding;
}

@mixin pt-button-height-small() {
  @include pt-button-height($pt-button-height-small);
  font-size: 0.875rem;
  padding: $button-padding-small;
}

@mixin pt-button() {
  box-shadow: $button-box-shadow;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  &:hover {
    background-color: var(--bg-primary-hover);
    color: var(--text-primary-hover);
  }

  &:active,
  &.#{$ns1}-active {
    background-color: var(--bg-primary-active);
    color: var(--text-primary-active);
  }

  &:disabled,
  &.#{$ns1}-disabled {
    @include pt-button-disabled();
  }
}

@mixin pt-button-disabled() {
  outline: none;
  box-shadow: none;
  cursor: not-allowed;
  color: var(--text-primary-muted);
}

@mixin pt-button-intent($default-color, $hover-color, $active-color) {
  $intent-box-shadow: inset 0 0 0 $button-border-width $default-color, $layer-1-raised;
  $intent-box-shadow-hover: inset 0 0 0 $button-border-width $hover-color, $layer-1-raised;
  $intent-box-shadow-active: inset 0 0 0 $button-border-width $active-color $layer-2-raised;
  box-shadow: $intent-box-shadow;
  background-color: $default-color;
  color: $white;
  & > .at-button-text {
    color: $white;
    opacity: 1;
  }

  &:hover,
  &:active,
  &.#{$ns1}-active {
    color: $white;
    & > .at-button-text {
      color: $white;
    }
  }

  &:hover {
    box-shadow: $intent-box-shadow-hover;
    background-color: $hover-color;
  }

  &:active,
  &.#{$ns1}-active {
    box-shadow: $intent-box-shadow-active;
    background-color: $active-color;
  }

  &:disabled,
  &.#{$ns1}-disabled {
    border-color: transparent;
    box-shadow: none;
    opacity: 0.5;
    // background-color: $muted-color;
    &:hover {
      box-shadow: $intent-box-shadow;
      background-color: $default-color;
    }
  }
  &.#{$ns1}-tertiary {
    @include pt-button-tertiary($default-color, $hover-color, $active-color);
  }
}
@mixin pt-button-tertiary($default-color, $hover-color, $active-color) {
  $intent-box-shadow: inset 0 0 0 $button-border-width $default-color;
  $intent-box-shadow-hover: inset 0 0 0 $button-border-width $hover-color, $layer-1-raised;
  $intent-box-shadow-active: inset 0 0 0 $button-border-width $active-color, $layer-2-raised;
  box-shadow: $intent-box-shadow;
  background-color: transparent;
  color: $default-color;
  & > .at-button-text {
    color: $default-color;
  }

  &:active,
  &.#{$ns1}-active {
    box-shadow: $intent-box-shadow-active;
    background-color: $active-color;
  }
  &:hover {
    box-shadow: $intent-box-shadow-hover;
    background-color: $hover-color;
    color: $white;
    & > .at-button-text {
      color: $white;
    }
  }
}
@mixin pt-button-minimal() {
  box-shadow: none;
  color: var(--text-primary);
  background-color: transparent;
  &:hover {
    background-color: var(--bg-primary-hover);
    color: var(--text-primary-hover);
    box-shadow: none;
    text-decoration: none;
  }

  &:active,
  &.#{$ns1}-active {
    background-color: var(--bg-primary-active);
    color: var(--text-primary-active);
  }

  &:disabled,
  &:disabled:hover,
  &.#{$ns1}-disabled,
  &.#{$ns1}-disabled:hover {
    background: none;
    cursor: not-allowed;
    color: var(--text-primary-muted);
    &.#{$ns1}-active {
      background-color: var(--bg-primary-active);
      color: var(--text-primary-active);
    }
  }
  & > .bp3-heading {
    opacity: 1;
  }

  @each $intent, $colors in $at-button-intents {
    &.#{$ns1}-intent-#{$intent} {
      @include pt-button-minimal-intent($colors...);
    }
  }
}

@mixin pt-button-minimal-intent($default-color, $hover-color, $active-color) {
  color: $default-color;
  &:hover,
  &:active,
  &.#{$ns1}-active {
    box-shadow: none;
    background: none;
    color: $default-color;
  }

  &:hover {
    color: $hover-color;
    background-color: var(--bg-primary-hover);
  }

  &:active,
  &.#{$ns1}-active {
    color: $active-color;
    background-color: var(--bg-primary-active);
  }

  &:disabled,
  &.#{$ns1}-disabled {
    background: none;
  }

  .#{$ns1}-button-spinner .#{$ns1}-spinner-head {
    stroke: $default-color;
  }
}

@mixin pt-button-minimal-divider() {
  $divider-height: $pt-button-height * 2;

  margin: ($pt-button-height - $divider-height) / 2;
  background: $pt-divider-black;
  width: $minimal-button-divider-width;
}

/*
Button

Markup:
<a role="button" class="#{$ns}-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="#{$ns}-button #{$ns}-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.#{$ns}-active - Active appearance
.#{$ns}-disabled - Disabled appearance
.#{$ns}-intent-primary - Primary intent
.#{$ns}-intent-success - Success intent
.#{$ns}-intent-warning - Warning intent
.#{$ns}-intent-danger - Danger intent
.#{$ns}-minimal - More subtle appearance
.#{$ns}-large - Larger size
.#{$ns}-small - Smaller size
.#{$ns}-fill - Fill parent container

Styleguide button
*/
$ns1: at;
.#{$ns1}-button {
  @include pt-button-base();
  @include pt-button-height($pt-button-height);

  &:empty {
    // override padding from other modifiers (for CSS icon support)
    // stylelint-disable-next-line declaration-no-important
    padding: 0 !important;
  }

  &:disabled,
  &.#{$ns1}-disabled {
    cursor: not-allowed;
  }

  &.#{$ns1}-fill {
    display: flex;
    width: 100%;
  }

  &.#{$ns1}-align-right,
  .#{$ns1}-align-right & {
    text-align: right;
  }

  &.#{$ns1}-align-left,
  .#{$ns1}-align-left & {
    text-align: left;
  }

  // default styles
  &:not([class*="#{$ns1}-intent-"]) {
    background-color: inherit;
    @include pt-button();
  }

  // intents
  @each $intent, $colors in $at-button-intents {
    &.#{$ns1}-intent-#{$intent} {
      @include pt-button-intent($colors...);
    }
  }

  &[class*="#{$ns1}-intent-"] .#{$ns1}-button-spinner .#{$ns1}-spinner-head {
    stroke: $white;
  }

  // size modifiers
  &.#{$ns1}-large,
  .#{$ns1}-large & {
    @include pt-button-height-large();
  }

  &.#{$ns1}-small,
  .#{$ns1}-small & {
    @include pt-button-height-small();
  }

  // loading state
  &.#{$ns1}-loading {
    position: relative;

    &[class*="#{$ns}-icon-"]::before {
      visibility: hidden;
    }

    .#{$ns1}-button-spinner {
      // spinner appears centered within button
      position: absolute;
      margin: 0;
    }

    > :not(.#{$ns1}-button-spinner) {
      visibility: hidden;
    }
  }

  // #{$icon-classes} {
  //   &.#{$ns1}-align-right {
  //     margin-left: $button-icon-spacing;
  //   }
  // }

  // button with SVG icon only (no text or children)
  .#{$ns1}-icon:first-child:last-child,
  // if loading, then it contains exactly [spinner, icon]
  .#{$ns1}-spinner + .#{$ns1}-icon:last-child {
    // center icon horizontally. this works for large buttons too.
    margin: 0 (-($pt-button-height - $pt-icon-size-standard) / 2);
  }

  // disabled and intent button icon should use same color as text
  // &:disabled,
  // &.#{$ns1}-disabled,
  // &[class*="#{$ns}-intent-"] {
  //   &::before,
  //   #{$icon-classes} {
  //     // stylelint-disable-next-line declaration-no-important
  //     color: inherit !important;
  //   }
  // }

  // minimal must come last to override all default styles
  &.#{$ns1}-minimal {
    @include pt-button-minimal();
  }
}

a.#{$ns1}-button {
  text-align: center;
  text-decoration: none;
  transition: none;

  &,
  &:hover,
  &:active {
    // override global 'a' styles
    color: $pt-text-color;
  }

  &.#{$ns1}-disabled {
    // color: $button-color-disabled;
  }
}

.#{$ns1}-button-text {
  // default: don't grow to fill but allow shrinking as necessary
  flex: 0 1 auto;
}

// when alignment is set, grow to fill and inherit `text-align` set on `.#{$ns}-button`
.#{$ns1}-button,
.#{$ns1}-button-group {
  &.#{$ns1}-align-left,
  &.#{$ns1}-align-right {
    .#{$ns1}-button-text {
      flex: 1 1 auto;
    }
  }
}
// .#{$ns1}-button{
//   &>.#{$ns1}-icon{
//     margin-right:0.25rem !important;
//   }
// }

.disable {
  pointer-events: none;
}
.at-input-btn-label {
  position: relative;
  padding: 0 $at-size;
  & > button {
    padding: 0;
  }
  &:after {
    cursor: pointer;
    position: absolute;
    content: "";
    // width: 100%;
    // height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:hover {
    background-color: var(--bg-primary-hover);
    color: var(--text-primary-hover);
    box-shadow: none;
    text-decoration: none;
    border-radius: 0.25rem;
  }
  &:active {
    background-color: var(--bg-primary-active);
    color: var(--text-primary-active);
  }

  &:disabled,
  &:disabled:hover {
    background: none;
    cursor: not-allowed;
    color: var(--text-primary-muted);
  }
}
