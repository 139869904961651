.opening-image-area{
  display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
}
img.opening-image {
  width: 100%;
  margin: auto;
  display: flex;
}
.opening-image-container {
  margin: auto;
}
