.sidebar {
  height: 100%;
  box-shadow: $layer-2-raised;
  transition: transform 0.2s;
  background-color: var(--bg-primary);
  padding: 1.5rem;
  position: fixed;
  width: 25%;
  &.left {
    left: 0;
  }
  &.right {
    right: 0px;
  }
  &.panel-hidden {
    z-index: 17;
    &.left {
      transform: translateX(-100%);
    }
    &.right {
      transform: translateX(100%);
    }
  }
}

#at-sidebar__footer__infoInFooter {
  text-align: center;
  margin-bottom: 2rem;
}
.at-sidebar__footer-infotextHTML {
  display: none;
  width: 100%;
}
