.day-state-btn {
  width: 3rem;
  height: 3rem;
  background: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 15;
  align-self: flex-start;
  margin-top: 1rem;
  cursor: pointer;
}
.day-state-btn__image {
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s;
  stroke:var(--text-primary);
  fill:var(--text-primary);
  pointer-events: none;
  width: 2rem;
}
.day-state-btn__image--active {
  opacity: 1;
  pointer-events: all;
}

.day-state-btn:hover {
  background-color: var(--bg-primary-hover)  
}