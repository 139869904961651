.newTag {
  background: var(--new-tag-color);
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15);
  border-radius: 4px;
  position: absolute;
  color: var(--bg-primary-active);
  padding: 0.2rem 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
}

#designslist .variations, #designslist  .variationThumb {
  display: none;
}
.variationThumb {
  width: 15%;
  min-width: 2rem;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  background: var(--bg-secondary);
  margin: 0.25rem;
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px rgba(16, 22, 26, 0.1), 0px 1px 1px rgba(16, 22, 26, 0.2);
  border: 2px solid transparent;

  max-height: 4rem;
  display: flex;
  &.fixedSize{
    width: 3rem;
    height: 3rem;
  }
  
  &.active,
  &:hover {
    border: 2px solid var(--brand3);
  }
}
.thumb-item .active{
  border: 2px solid var(--brand3);
}
img.variationImgThumb {
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}
.mainThumbArea {
  background-color: var(--bg-primary);
  border-radius: 4px;
  position: relative;
}
.hideOnHover {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.mainThumbArea:hover ~ .hideOnHover{
  opacity: 0;
}
// .hideOnHover:hover {
//   visibility: visible;
//}
.design-browser__thumbs-area__container.templateGrid {
  grid-template-columns: 25% 25% 25% 25%;
  display: grid;
}

@media only screen and (max-width: 1200px) {
  .variationThumb {
    width: 20%;
    min-width: 1.5rem;
    padding: 0.2rem;
  }
  .design-browser__thumbs-area__container.templateGrid {
    grid-template-columns: 33% 33% 33%;
    display: grid;
  }
}

.variations {
  display: inline-flex;
  width: 100%;
}
.variationThumbHolder{
  width: 100%;
  height: 100%;
}
.designThumb-template1 {
  & .imageThumb {
    background: var(--bg-secondary);
    padding: 0.3rem 1.5rem;
    position: relative;
  }
  .newTag {
    top: 0.5rem;
    right: 0.5rem;
  }
  &-designName {
    font-size: 1.375rem;
    font-weight: bold;
    color: var(--text-primary);
  }
  .variations {
    justify-content: center;
  }
}

.designThumb-template2 {
  position: relative;
  width: 86%;
  margin-left: 7%;
  display: inline-block;
  margin: auto;
  .mainThumbArea {
    padding: 0;
  }
  .imageThumb {
    padding: 0.25rem;
  }
  .newTag {
    top: 0.5rem;
    left: 0.5rem;
  }
  .detailsContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    background-color: #252a2e;
    color: var(--text-primary-active);
    padding: 0.5rem 0 0.5rem 0.75rem;
    text-align: left;
    margin: auto;
  }
  .designName {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
    text-transform: uppercase;
  }

  .sizeInfo {
    font-size: 0.75rem;
    font-family: IBM Plex sans;
    font-weight: 400;
    color: var(--text-primary-active);
  }
  .variations {
    position: absolute;
    bottom: 3.5rem;
    width: 100%;
    background-color: rgba(28, 31, 34, 0.75);
    text-align: left;
    padding-left: 0.75rem;
    left: 0.25rem;
  }

  .variationThumb {
    background-color: #1c1f22;
    text-align: center;
    box-sizing: border-box;
  }
}


.designThumb-template3 {
  position: relative;
  width: 92%;
  margin-left: 4%;
  display: inline-block;
  margin: auto;
  margin-bottom: 1rem;
  .mainThumbArea {
    padding: 0;
  }
  .imageThumb {
    padding: 0.25rem;
    margin: 0.25rem;
  }
  .newTag {
    top: 0.5rem;
    left: 0.5rem;
  }
  .detailsContainer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 0.4rem);
    height: 3.5rem;
    background-color: #252a2e;
    color: var(--text-primary-active);
    padding: 0.5rem 0 0.5rem 0.75rem;
    text-align: left;
    margin: auto;
    width:100%;
  }
  .designName {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
    text-transform: uppercase;
  }

  .sizeInfo {
    font-size: 0.75rem;
    font-family: IBM Plex sans;
    font-weight: 400;
    color: var(--text-primary-active);
  }
  .variations {
    bottom: 3.5rem;
    width: 100%;
    justify-content: center;
    padding: 0;
  }
  .variationThumb {
    background-color: var(--bg-secondary);
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
    
  }
 
  .mainThumbArea:hover .hideOnHover {
    opacity: 0;
  }
}

.designThumb-template4 {
  position: relative;
  width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
  display: inline-block;
  margin: auto;
  .mainThumbArea {
    padding: 0.5rem 1.5rem;
    background-color: var(--bg-secondary);
  }
  .imageThumb {
    padding: 0.25rem;
  }
  .newTag {
    top: 0.5rem;
    left: 0.5rem;
  }
  .detailsContainer {
    bottom: 0;
    height: 3.5rem;
    color: var(--text-primary);
    text-align: left;
    justify-content: space-between;
    display: flex;
    margin: 1rem 0.5rem;
}
  .designName {
    font-size: 1.375rem;
    line-height: 1.5rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
    text-transform: uppercase;
    color: var(--text-primary-active);
  }

  .sizeInfo {
    font-size: 0.875rem;
    color: var(--text-primary-active);
    font-family: IBM Plex sans;
    font-weight: 400;
    line-height: 1.5rem;
  }

}
#left-sidebar .designThumb-template4 {
  .detailsContainer{
    display: block;
  } 
  .designName{
    font-size: 0.875rem;
  }
  .sizeInfo{
    font-size: 0.75rem;
  } 
} 



