.at-ordersheet-dims-area,
.at-ordersheet-rugresize-area {
  display: flex;
  align-items: top;
  flex-wrap: wrap;
  & > div {
    flex: 1 1 auto;
    margin: 0.25rem;
  }

  & > .at-units-input {
    & > p {
      margin-bottom: 0.25rem;
    }
    &____qualitytext {
      // display: none;
    }
  }
  & .bp3-editable-text {
    box-shadow: inset 0 0 0 2px var(--bg-secondary);
    padding: 0.5rem;
  }
  margin: 0.5rem 0;
  &.no-input {
    pointer-events: none;
    opacity: 0.5;
  }
}
.at-ordersheet-area-superscript {
  font-size: 1rem;
}
@media only screen and (max-width: 1200px) {
  .at-ordersheet-dims-area,
  .at-ordersheet-rugresize-area {
    display: block;

    & > div {
      display: inline-grid;
    }
  }
}

.at-ordersheet-price-area {
  margin: 0.5rem 0;
  display: flex;
  align-items: baseline;
}

.at-ordersheet-pua {
  margin-left: 0.5rem;
  color: $gray3;
}

.at-ordersheet-max-area-message{
  color: red;
}

#davisRugs-OrdersheetPreviewButton{
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.at-ordersheet-preview {
  max-height: 85vh;
  border-radius: 0.5rem;
  display: flex;
  overflow: auto;
  background-color: var(--bg-primary);
  min-height: 70vh;
  &__view-section {
    background-color: var(--bg-secondary);
    position: relative;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
  }

  &__form-section {
    padding: 1rem;
    color: var(--text-primary);

    background-color: var(--bg-primary);

    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.at-ordersheet-dialog-buttons {
  text-align: right;
}

.at-ordersheet-form-area {
  padding: 1rem;
}

.at-section-title {
  margin-bottom: 1rem;
}

.at-ordersheet-form-area-message {
  margin-top: 1rem;
}

.at-ordersheet-buttons-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  & > button {
    margin: 0.5rem 0.25rem;
  }
}

#selectedKnotText{
  margin-top: 10px;
}

.at-ordersheet-infotext {
  text-align: center;
  display: none;
}

.at-ordersheet {
  &__wrapper {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

#at-ordersheet-area {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  height: 100%;
}

.at-ordersheet__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  &  > div {
    flex: 0 0 auto;
  }
  &> .at-ordersheet__content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    & > .outside {
      flex: 1 1 auto;
    }
  }
}
.at-ordersheet__title > .at-button {
  display: none;
}

.at-parentMessage-buttons>div {
  margin: auto;
  margin-top: 1rem;
}