.at-file-input-dialog {
  .at-dialog-area.at-file-input-dialog-area {
    min-height: 20rem;
    display: grid;
    grid-template-rows: 2rem calc(100% - 2rem);
  }
  .at-dialog-content {
    justify-content: center;
  }
  .palette-from-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.5rem;
  }
  .uploaded-image,
  .palette-colors {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uploaded-image img {
    max-width: 90%;
  }
  div#imageDropContainer {
    height: 100%;
    position: unset;
    display: grid;
    grid-row: 1/-1;
    width: 100%;
    &:hover {
      border-color: var(--brand3);
    }
  }

  h5.bp3-heading.at-dialog-heading {
    margin: 0;
    height: 100%;
    line-height: 2rem;
  }

  label.custom-file-label {
    display: grid;
    height: 60%;
    margin-top: 1rem;
    width: 80%;
  }
  .image-input-url {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    label.bp3-label {
      margin: 0;
      margin-right: 1rem;
      flex: 0 0 9rem;
    }
    .bp3-input-group {
      width: calc(100% - 9rem);
    }
  }
  .after-image-load-area {
    grid-row: 1/-1;
    display: grid;
  }
  .image-palette-wrapper {
    grid-row: 1/-1;
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0.5rem 0;
  }
  .image-palette-footer-area {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
}
