.at-radiogroup {
  margin: 0.75rem 0;
}
.at-radio input[type="radio"] {
  display: none;
}
.radio-icon {
  &--checked {
    color: var(--brand3);
  }
}
