.at-addedField {
    & {
        flex-direction: row;
    }

    &__label {
        margin-left: 0.5rem;
    }

}

#at-formdate{
    margin-top: 5px;
    font-size: 18px;
    color: gray;
}

.at-addedFieldSelect__dropdown {
    margin-bottom: 0.5rem;
}