.input-warning {
  color: $danger1;
}
.data-control-settings-dialog {
  background: var(--bg-primary);
  padding: 1rem;
}
.data-control-settings-area {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  & > section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  & .data-control-right-section {
    justify-content: space-between;
  }
}
.data-control-settings-subsection {
  &.shuffle-section {
  }
  &.showwhat-section {
    // display: none;
  }
}
.data-control-close {
  position: absolute;
  right: 0;
  cursor: pointer;
}
