.explorug-controller {
  --bg-color: #f2b824;
  --header-height: calc(9 * 100vw / 16 + 5rem);
  background: var(--bg-color, #f2b824);
  .app-main {
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  .exp-controller-header {
    position: relative;
    width: 100%;
    height: var(--header-height);
    background: #f2b824;
    .exp-controller-title {
      font-family: "IBM Plex Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      margin: 1rem;
    }
    #room-view-container {
      position: relative;
      width: 90%;
      height: calc(100% - 4rem);
      margin: auto;
      .stage-container {
        height: calc(9 * 100vw / 16);
        bottom: 0;
        right: 0;
        margin: auto;
        width: 100%;
        .illustration-container > div {
          background: var(--bg-color, #f2b824);
        }
      }
    }
  }
  .at-sidebar-tabs {
    // height: calc(100% - var(--header-height));
  }
  .at-sidebar__content__tabs {
    background: white;
    .bp3-tab-list {
      background: var(--bg-color, #f2b824);
      .bp3-tab {
        font-weight: 500;
        padding: 1rem 0.5rem;
        font-size: 1.2rem;
      }
      .bp3-tab[aria-selected="true"] {
        color: var(--text-primary-active);
        background: #fafafa !important;
        border-radius: 0.25rem;
        margin-bottom: -1px;
      }
      .bp3-tab-indicator {
        background-color: white;
      }
    }
  }
  #cyr-collection .cyr-heading {
    display: none;
  }
  .cyr-section {
    background: white;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .thumb-title {
    font-size: 1rem;
  }
  ul.tree-content-folders-container {
    display: flex;
    flex: 0 0 auto;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    gap: 0.5rem;
    padding: 1rem;
    &::-webkit-scrollbar {
      display: none;
    }
    .tree-content-folders {
      padding: 0.5rem 1rem;
      line-height: 1.5rem;
      background: #f0f0f0;
      border-radius: 4px;
      font-size: 1rem;
      &.selected {
        background: var(--bg-color, #f2b824);
        color: #000;
        border: none;
      }
    }
  }
  .cyr-thumbs-container {
    position: relative;
    max-height: 100%;
    overflow: auto;
    flex: 1 1 auto;
  }
  .exp-controller-design-wrapper {
    max-height: 100%;
    display: flex;
    justify-content: center;
    & > img {
      object-fit: contain;
    }
  }

  .design-controller__thumbs-area__container {
    // display: flex;
    // gap: 2rem;
    height: 100%;
    max-width: 100%;
    scroll-behavior: smooth;
    align-items: flex-end;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: 50% 50%;
    & > div {
      flex: 0 0 Min(15%, 20rem);
      min-width: var(--thumb-min-width, 12rem);
      height: 100%;
      .thumb-item {
        height: 100%;
      }
    }
    // & > :first-child {
    //   margin-left: auto;
    // }
    // & > :last-child {
    //   margin-right: auto;
    // }
  }
  .at-vis-thumbs {
    overflow: auto;
    overflow-x: hidden;
    .thumb-item {
      width: Min(100%, 40rem);
      margin: auto;
    }
  }
  .at-designviews.at-vis-thumbs .thumb-item {
    width: Min(100%, 30rem);
    & .thumb-image-container > img {
      width: 40%;
    }
  }
  .at-section-heading-area {
    display: none;
  }
  .thumb-container {
    grid-template-columns: 100%;
    justify-items: center;
  }
  .stage-wrapper.framePictureWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      position: absolute;
      max-width: Min(80%, 35rem);
      right: 0;
      left: 0;
      margin: auto;
      margin-top: -5rem;
      display: flex;
      justify-content: center;
      align-content: center;
      align-self: center;
      justify-self: center;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
@media only screen and (min-width: 700px) {
  .explorug-controller {
    .design-controller__thumbs-area__container {
      grid-template-columns: 33% 33% 33%;
    }
  }
}
