.popover-container{
  &>.bp3-popover-target{
    width:100%;
    padding-left:2rem;
    padding-right:2rem;
    height:3rem;
  }
}
.dropdown-container{
  display: inline-flex;
  width:20rem;
  border: 2px solid #ccc;
  border-radius: 0.25rem;
  justify-content: space-between;
  align-items: center;
}
.dropdown-menu{
  width:20rem;
}
.bp3-select::after {
  display: none;
}
.bp3-select{
  color: var(--text-primary);

  &.at-select{
    width:100%;
    &>.at-icon{
      top: 0;
      bottom: 0;
      margin: auto;
      height: fit-content;
      color: inherit;
    }
  }
  &.minimal{
    &>select{
      box-shadow: none;
    }  
  }
  &>select{
    box-shadow: $inset-box-shodow;
    background-color: transparent;
    color: var(--text-primary);
    background-image: none;

    &:hover{
      opacity: 1;
      background-color: var(--bg-primary-hover);
      box-shadow: 0 0 0 2px var(--bg-primary-hover);
      background-image: none;
    }
    &:active{
      opacity: 1;
      background-color: var(--bg-primary-active);
      box-shadow: 0 0 0 2px var(--bg-primary-active);
      background-image: none;
    
    }
    &>option{
      background-color: transparent;
    }
  }
}
.at-option{
  color: var(--text-primary);
}