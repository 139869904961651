
$panel-height: 2.5em;
.panel-btn-grp{
  position: absolute;
  top: 50%;
  display: inline-flex;
  transform-origin: center;
  box-shadow: $layer-2-raised-panel-btn;
  border-radius: 0.5rem 0.5rem 0 0;
  &.left{
    left: calc(100% + #{$panel-height/2} - 1px);
    transform: translate(-50%, -50%) rotateZ(90deg);

  }
  &.right{
    right: calc(100% + #{$panel-height/2} - 1px);
    transform: translate(50%,-50%) rotateZ(-90deg);
  }
  & span:first-child{
    border-top-left-radius: $btn-border-radius;
  }
  & span:last-child{
    border-top-right-radius: $btn-border-radius;
  }
}
.panel-btn {
  height: $panel-height;
  cursor: pointer;
  width: max-content;
  display: inline-flex;
  // margin-right: $grid-gutter-width/4;
  // margin-left: $grid-gutter-width/4;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.04);
  background-color: var(--bg-secondary);
  &.active {
    background-color: var(--bg-primary);
    color:$pt-intent-primary
  }
}