#at-design-color-options-autocolor{
  min-width: fit-content;
}
.bp3-popover.bp3-minimal.autocolor-popover {
  width: 11rem;
  border: none;
  margin-top: 0.25rem !important;
}
ul.bp3-menu.autocolor-menu {
  padding: 0;
  color: white;
  line-height: 2rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-shadow: 0px 0px 0px rgba(16, 22, 26, 0.32), 0px 1px 1px rgba(16, 22, 26, 0.32);
  
&>li:hover {
  transform: rotateY(180deg);
  div {transform: rotateY(180deg);}
}

}

ul.bp3-menu.autocolor-menu a {
  line-height: 2rem;
  font-size: 0.875rem;
  border-radius: 0;
}

ul.bp3-menu.autocolor-menu li:first-child a {
  border-radius: 0.25rem 0.25rem 0 0;
}

ul.bp3-menu.autocolor-menu li:last-child a {
  border-radius: 0 0 0.25rem 0.25rem;
}
