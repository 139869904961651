.three-canvas-loading{
  animation: fadeOut 2s;
  animation-fill-mode: forwards; 
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.studioButton{
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 5px;
}

.studioButtonPausePlay{
  z-index: 99;
  height: 38px;
  width: 38px;
  border: 1px solid white;
  box-shadow: none;
  background-color: #454545;
  cursor: pointer;
  position: relative;
}
