.QRDialogTopDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: center;
}

.QRpara {
    padding: 0rem 2.5rem;
    font-size: 1rem;
    line-height: 1.125rem;
    word-break: break-word;
    text-align: center;
    margin-bottom: 1rem;
}

.QRTopRightDiv {
    margin-bottom: 1rem;
    cursor: pointer;
    border: 1px solid #C7CBCE;
    border-radius: 0.25rem;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cyr-qrTextDiv{
    display: flex;
    justify-content: space-around;
    width: 100%;
}