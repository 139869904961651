.camera-button {
  width: 72px;
  height: 72px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
    animation: 2s rotateanim infinite;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='4' stroke-dasharray='50%25%2c 13%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
    width: 74px;
    height: 74px;
  }

  &--nopointer {
    cursor: not-allowed;
    .camera-button-clicker:active {
      width: 64px;
      height: 64px;
    }
  }

  @keyframes rotateanim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.camera-button-clicker {
  width: 64px;
  height: 64px;
  background: white;
  border-radius: 50%;

  &:active {
    width: 60px;
    height: 60px;
  }
}
