.branding{
  width:100%;
  height:4rem;
  position: absolute;
 
  box-shadow: $layer-1-raised;
  &.fill{
    height:100vh
  }
}
.logo-area{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.brand-progress-bar{
  width: 18rem;
  &>.bp3-progress-meter{
    background: linear-gradient(90deg, #0BAADE 0%, #00D092 100%) !important;
  }
}