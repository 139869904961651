
.promotionalModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.promotionalContainer {
  width: 46.875rem;
  height: 90vh;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 65465;
  overflow: hidden;
  padding: 1.25;
  display: grid;
  grid-template-rows: auto;
  justify-content: center;

  .list {
    height: 19.25rem;
  }
  
  .list.isMobile {
    height: 257px;
  }
  
  .list li {
    list-style-type: none;
  
    width: 27.938rem;
    height: 19.25rem;
    position: absolute;
    left: 50%;
    margin-left: -13.938rem;
    border-radius: 10px;
  }
  
  .list.isMobile li {
    width: 209px;
    height: 257px;
    margin-left: 104px;
  }
  
  .list li {
    transition: transform 1s;
  }
  
  .list li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .hide {
    transform: translateX(-100%);
    border-radius: 10px;
    opacity: 0;
  }
  
  .prev {
    transform: translateX(-23.438rem) scale(0.525);
  }
  
  .next {
    transform: translateX(23.438rem) scale(0.525);
  }
  
  .act {
    z-index: 1;
  }
  
  .new-next {
    transform: translateX(39.375rem) scale(0.2512);
    opacity: 0;
  }
  
  .old-prev {
    transform: translateX(-39.375rem) scale(0.2512);
    opacity: 0;
  }
  .carousel {
    /* margin-top: 3.5rem; */
    position: relative;
    width: 100%;
    align-self: center;
  }
  
  .carousel::before {
    content: "";
    display: block;
    width: 100%;
    height: max-content;
  }
  
  .controller-button {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
  
    transform: translate(0, -50%);
    width: 100%;
    z-index: 2;
  }
  
  .controller-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.875rem;
    height: rem;
    border-radius: 50%;
    border: 2px solid white;
  
    background-color: rgba(217, 217, 217, 0.2);
    cursor: pointer;
  }
  
  .logo {
    width: 31.813rem;
    justify-self: center;
  }
  
  .logo.isMobile {
    width: 14.875rem;
    justify-self: center;
  }
  
  .logo img {
    width: 100%;
    height: 100%;
  }
  
  .about {
    /* margin-top: 6rem; */
    font-weight: 600;
    font-size: 2.125rem;
    line-height: 2.563rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  
  .about.isMobile {
    width: 100%;
    height: fit-content;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
  
  .description {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
  }
  
  .contact {
    /* margin-top: 3.5rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 0.8rem;
  }
  
  .contact.isMobile {
    width: 100%;
  }
  
  .upgrade {
    font-weight: 600;
    font-size: 26px;
    line-height: 15px;
    text-align: center;
    align-self: self-end;
  }
  
  .upgrade.isMobile {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.625rem;
    align-self: center;
  }
  
  .contact button {
    /* margin-top: 1.2rem; */
    background-color: #f05223;
    width: 32.938rem;
    height: 3.688rem;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;
    cursor: pointer;
  }
  
  .contact.isMobile button {
    width: 245px;
    height: 49px;
  }
  
  .contact div {
    /* margin-top: 1rem; */
    color: #f05223;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
  }

  .contact div u{
    cursor: pointer;
  }
  
  .contact.isMobile div {
    font-size: 12px;
  }
  
  .icon-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    font-size: 30px;
    color: black;
  }
}

.promotionalContainer.isMobile {
  width: 95%;
  height: 75vh;
}


.at-login-screen {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.at-promotionals {
  flex-basis: 0;
  flex-grow: 1;
  background: url(login.jpg);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;

  & > .bp3-card {
    position: absolute;
    bottom: 7rem;
    transform: translateX(-50%);
    left: 50%;
    width: 30rem;
    color: var(--text-primary-active);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: $layer-3-temp-nav;

    & > p {
      margin-top: 1rem;
      text-align: center;
    }

    & > .at-button {
      margin-top: 2rem;
    }
  }
}

.at-login-input .bp3-input {
  box-shadow: inset 0 0 0 2px var(--text-primary-muted);
  background-color: var(--bg-secondary);
}

.login-field {
  flex: 0 0 42%;
  height: auto;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-primary-active);
}

.signing-spinner {
  display: inline-flex;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  vertical-align: bottom;
  opacity: 0;
  transition: opacity 0.4s;
}

.signing-spinner.show {
  opacity: 1;
}

.signin-span-text {
  margin-left: -1rem;
  transition: 0.5s;
}

.signin-span-text.shift-right {
  margin-left: 0;
}

.at-login-screen.separate-loginpage {
  .at-promotionals {
    display: none;
  }
  .login-field {
    flex: auto;
  }
}

.privacy-policy {
  position: absolute;
  z-index: 10;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
  color: var(--text-primary-active);
  font-size: 0.8rem;
  & > a {
    display: block;
    color: var(--text-primary-active);
  }
}
