.at-des-details {
  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &__languageDropdown {
      & .bp3-heading {
        font-size: 1.1rem;
        padding: 0.5rem;
      }
      & .bp3-menu.langoptions-menu {
        min-width: 6rem;
      }
    }
    &__heading {
      flex: 1 1 auto;
      max-width: 100%;
      word-break: break-word;
    }

    &__controls {
      margin-left: -0.6rem;

      &.fullWidth {
        width: 100%;
      }
    }
  }

  &____tex-sel {
    margin: 1rem 0;
  }

  &__price-area {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    height: 1.5rem;

    &__dims {
      flex: 1 0 auto;
      line-break: strict;
    }
  }

  &__size-area {
    display: flex;
    margin: 0.5rem 0;
  }

  &__design-info {
    margin-bottom: 0.5rem;
  }
 
}
button#at-des-details__title-saveImageIcon img {
  width: 1.2rem;
}

.at-des-details__title__heading__linebreak{
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

