// $tree-row-height: $pt-grid-size * 3;
.bp3-tree-node-content {
  height: 2.5rem !important;
  &:hover {
    background-color: var(--bg-primary-hover);
  }
  &.active{
    background-color: var(--bg-primary-active);
  }
}
.tree-node-content-inside{
  .at-folder-story-icon{
    opacity: 0;
  }
  &.active {
    &>span{
      color:var(--text-primary-active);
      &>h5{
        color:var(--text-primary-active);
      }
    }
  }
  &:hover{
    &>.#{$ns}-tree-node-caret{  
      opacity:1
    }
    &>.at-folder-story-icon{  
      opacity:1
    }
  }
}


@for $i from 0 through 20 {
  .#{$ns}-tree-node-content-#{$i} {
    padding-left: 0.75rem+0.75rem* $i !important;
    .tree-node-content-inside{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      height: 100%;
      @if $i ==0 {
        // border-left: 1.5px solid #abacad;
      // &>.#{$ns}-tree-node-caret{
      //   display: block;
      // } 
      }
  }
  }
}

.#{$ns}-tree-node-caret {
  opacity:0;
  
  @include pt-icon-colors();
  transform: rotate(0deg);
  cursor: pointer;
  padding: $tree-icon-spacing;
  margin-right: 0.25rem;
  transition: transform ($pt-transition-duration * 2) $pt-transition-ease;

  &.#{$ns}-tree-node-caret-open {
    transform: rotate(-180deg);
  }

  // CSS API support
  &.#{$ns}-icon-standard::before {
    content: $pt-icon-chevron-up;
  }
}

// $tree-icon-spacing: ($tree-row-height - $pt-icon-size-standard) / 4;
