.checkbox-area {
  display: block;
}
.checkbox-area-inline {
  display: inline-block;
}
.checkbox {
  box-shadow: none;
  vertical-align: middle;
}
