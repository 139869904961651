.favorites-area {
  margin-bottom: 0.5rem
}

.lonely-area {
  // height:6.5rem;
  text-align: center;
  padding: 1rem;
  color: var(--bg-text-primary-muted);
  background-color: var(--bg-secondary);

  &>div {
    margin-bottom: 0.5rem;
  }
}

.at-dialog.at-fav-dialog,
.at-dialog.at-confirmation-dialog {
  min-width: 17rem;
}
.at-dialog.at-dialog-xs.at-confirmation-dialog.info-dialog {
  width: 15%;
}
// .at-confirmation-dialog.info-dialog{
//   .at-close-dialog-button{
//     display: none;
//   }
// }

.at-fav-dialog-area,
.at-confirmation-dialog-area {
  &.at-dialog-area {
    &>.bp3-heading {
      text-align: left;
    }

    &>.at-dialog-content {
      align-items: flex-start;

      &>.at-dialog-content-text {
        margin-bottom: 1rem;
      }

      &>.bp3-form-group {
        width: calc(100% - 1rem)
      }
    }
  }

}

.at-close-dialog-button {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 25;
}

.at-fav-btns-area,
.at-confirmation-btns-area {
  &>button {
    margin-right: 0.25rem
  }
}