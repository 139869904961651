.animation-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.animation-wrapper.paused .active {
  /* display: block !important; */
  opacity: 1 !important;
  animation: none !important;
}
