$qr-code-width: 10rem;
.at-arcarpet-dialog {
  width: 25% !important;
  min-width: 30rem;
}
.ar-dialog-content {
  .scan-text {
    padding-left: 1.5rem;
    font-size: 1rem;
    line-height: 1.125rem;
    height: fit-content;
    width: calc(100% - #{$qr-code-width});
    word-break: break-word;
    text-align: left;
    align-self: center;
  }
  .no-ar-app {
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
    width: 100%;
  }
  .ar-app-store-area {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.5rem 0;
    text-align: center;
    font-size: 1rem;
    a {
      padding: 1rem 0;
      line-height: 2;
    }
  }
  .no-ar-app-title {
    color: var(--text-primary);
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    &>.bp3-icon{
      margin-left: 0.5rem;
    }
  }

  .ar-app-store canvas,
  .ar-play-store canvas {
    width: 8rem !important;
    height: auto !important;
    display: block;
  }
  .no-ar-app-content {
    height: 0;
    opacity: 0;
    text-align: center;
    overflow: hidden;
    transition: all 1s ease;
    transition-property: opacity;
    transition-duration: 0.25s;
    &.show {
       height: auto;
      opacity: 1;
    }
  }
}
.ar-scan-wrapper {
  width: 100%;
  top: 100%;
  background: #f7f8f8;
  border-radius: 0px 0px 4px 4px;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  display: none;
  z-index: 1;
  display: flex;
  .qr-code {
    width: $qr-code-width;
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  & > svg {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .ar-scan-wrapper {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: unset;
  }
  .ar-dialog-content .scan-text {
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0;
    margin: 0.5rem 0;
    text-align: center;
  }
}

@media only screen and (max-height: 500px) {
  .ar-scan-wrapper {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: unset;
  }
  .ar-dialog-content .scan-text {
    padding-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .scan-wrapper {
    flex-direction: row;
  }
  .scan-text {
    padding-left: 0.5rem;
  }
  .at-arcarpet-dialog {
    min-width: 22rem;
  }
}
