.at-dialog-area{
  color: var(--text-primary-active);
  background-color:var(--bg-primary);

  padding: 1rem;
  position: relative;
  &>.bp3-heading{
    margin-bottom: 1rem;
    text-align: center;
  }
  &>.at-close-dialog-button{
    position: absolute;
    top:0.25rem;
    right:0.25rem;
    z-index: 25;
  }
  &>.at-dialog-content{
    display: flex;
    flex-direction: column; 
    align-items: center;
  }
}
.at-qr-description{
  text-align: center;
}
.at-vr-qrcode{
  width: 8rem;
  height: 8rem;
  margin: 0.5rem;
  cursor: pointer;
  outline: 1px solid white;
}