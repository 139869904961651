$input-height:2.25rem;

.at-input-group {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .at-input {
    position: relative;
    width: 100%;
    height:2.25rem;
    // add space if there's something before or after the input
    &:not(:first-child) {
      padding-left: $input-height;
    }

    &:not(:last-child) {
      padding-right: $input-height;
    }
  }

  .at-input-action,
  > .at-button,
  > .at-icon {
    position: absolute;

    // glue it to the end it appears on
    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }

  // // direct descendant to exclude icons in buttons
  > .#{$ns}-icon {
    // bump icon up so it sits above input
    z-index: 1;
    color: var(--text-primary);
  }

}
