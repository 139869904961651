#loadingDiv{
  background: white;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadingIcon{
  width: 25%;
}

#app-main-studioEntry{
  width: 100%;
    display: grid;
    grid-template-areas:
      "cyr-detailView cyr-roomViewContainer"
      "cyr-copyright cyr-copyright"
      "illustration-wrapper  illustration-wrapper";
    
      grid-template-columns:50% 50%;
      overflow: hidden;
  #cyr-detailView{
    grid-area: cyr-detailView;
  }

  .cyr-roomViewContainer{
    grid-area: cyr-roomViewContainer;
  }

  .sliderContainer.hasRuler{
    display: none;
  }

  .stage-wrapper.deepzoom{
    position: relative;
    // width: Min(100vw, 100%);
    max-width: 100%;
    height: 100dvh;
    min-height: 25rem;
  }

  #stage_container{
    width: 50vw;
  }

  .rendered1xDesign1{
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    height: 100dvh;
    width: 50vw;
    position: absolute;
    right: 0;
    left: 0;
    object-fit: cover;
    object-position: 0% 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 104%;
    left: -20px;
  }
  
   .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  .studioButtonPausePlay:hover .tooltiptext {
    visibility: visible;
  }
  
  .fade-out {
    opacity: 1;
    animation-name: fadeout;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }
  
  .fade-in {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    transform: scale(1);
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  .animateRun {
    -webkit-animation: run ;
    -moz-animation: run ;
    animation: run ;
    animation-duration: 5s
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.1;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0.1;
    }
    25% {
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.01);
    }
    75% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .illustration-wrapper,.cyr-copyright{
    display: none;
  }

  #leafletCanvas,#DesignCanvasImage{
    filter: blur(5px);
  }
  .at-sidebar__action-btns{
    right: 10px !important;
    background-color: #454545;
    z-index: 99999;
  }
  :focus{
    outline: gray auto 0px !important;
  }

.navigator-arrows {
    position: absolute;
    margin: auto;
    height: 5rem;
    width: 5rem;
    z-index: 8;
    cursor: pointer;

    &.left{
      top: 0;
      bottom: 0;
      left: 1rem;
    }

    &.right {
      top: 0;
      bottom: 0;
      right: 1rem;
    }
    &.up {
      left: 0;
      right: 0;
      top: 1rem;
    }
    &.down {
      left: 0;
      right: 0;
      bottom: 1rem;
    }

    &>svg {
      width: 100%;
      height: 100%;
      stroke-width: 0.5px;
      stroke: #eee;
      filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.6));
      fill: #F05223;

      &:hover {
        filter: drop-shadow(1px 1px 10px rgba(255, 255, 255, 0.8));
        cursor: pointer;
        stroke: white;
      }
    }
  }
}

@media (max-width: 1025px) and (orientation:portrait) {
  #loadingIcon{
    width: 50%;
  }
  
  #app-main-studioEntry{
    width: 100%;
    display: flex;
      flex-direction: column-reverse;
      // grid-template-areas:
      //   "cyr-detailView"
      //   "cyr-roomViewContainer"
      //   "cyr-copyright"
      //   "illustration-wrapper";
      
      //   // grid-template-columns:100%;
      //   grid-template-rows:40% 40% auto auto;
      .at-spinner-overlay{
        height: 50vh;
      }

      #studioFullScreenButton{
        display: none;
      }

      #stage_container{
        width: 100vw;
      }

      // #cyr-detailView, #cyr-roomViewContainer{
      //   height: 50vh;
      // }

      .stage-wrapper.deepzoom{
        position: relative;
        width: Min(100vw, 100%);
        height: 50dvh;
        // min-height: 25rem;
      }

      // .cyr-arrows-container{
      //   display: none;
      // }

      .rendered1xDesign1{
        width: 100vw;
        height: 50dvh;
        margin-top: 0.2rem;
        padding-right: 0rem;
        padding-bottom: 0rem;
      }

      .navigator-arrows {
    
        &.left{
          left: 0rem;
        }
    
        &.right {
          right: 0rem;
        }
        &.up {
          top: 0rem;
        }
        &.down {
          bottom: 0rem;
        }
      }
     
  }
}

@media (max-width: 500px){
  // .cyr-arrows-container{
  //   display: none;
  // }

  .navigator-arrows{
    height: 2rem !important;
    width: 2rem !important;
  }

  #app-main-studioEntry .cyr-roomViewContainer {
    min-height: 0 !important;
  }
}

@media (max-width: 1000px) and (orientation:landscape){
  // .cyr-arrows-container{
  //   display: none;
  // }

  .navigator-arrows{
    height: 2rem !important;
    width: 2rem !important;
  }

  .stage-wrapper.deepzoom{
    min-height: 0 !important;
  }
}
