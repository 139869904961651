.at-cinematic-bottompanel {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
  background-color: transparent !important;
}
.bottompanel-wrapper {
  padding: 1rem 1rem 0.5rem 1rem;
  height: auto;
  background: var(--bg-primary);
  transform: translateY(0);
  transition: transform 0.4s;
  opacity: 0.8;
  min-width: 60%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: grid;
  grid-template-columns: 45% 10% 45%;
  &.hide {
    // display: none;
    transform: translateY(100%);
  }
  &__left-area {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  &__center-area {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__right-area {
    grid-column-start: 3;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.play-btn-circular {
  width: 4rem;
  height: 4rem;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  border: none;
  cursor: pointer;
  background-color: transparent !important;
  opacity: 0.8;
  color: rgba(158, 150, 150, 0.5);
  margin: 0 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--text-primary);
}
.play-btn {
  border: 0;
  padding: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 24px;

  border-color: transparent transparent transparent var(--text-primary);
  transition: 100ms all ease;
  cursor: pointer;

  // play state
  border-style: double;
  border-width: 0px 0 0px 20px;

  &.btn-paused {
    border-style: solid;
    border-width: 11px 0 11px 20px;
  }

  &:hover {
    border-color: transparent transparent transparent var(--text-primary-active);
  }
}

.middle-placeholder {
  display: inline-flex;
  align-items: center;
  transform: translateX(50%);
  flex-direction: row-reverse;
  transform: translateX(-50%);
  margin-left: 6.5rem;
}
.animation-duration-slider {
  width: auto;
  height: 16px;
  margin: 0.5rem 0 0 0;
  min-width: 200px;
  .bp3-slider-progress.bp3-intent-primary {
    background-color: var(--brand3);
  }
  .bp3-slider-progress {
    background: var(--text-primary-muted);
  }
  .bp3-slider-handle {
    background: var(--text-primary-muted);
  }
}
.sliderBlock {
  margin: 0 1rem;
  & .bp3-heading {
    display: inline-block;
  }
  & span.right {
    float: right;
  }
  & span.left {
    float: left;
  }
  & span.sub {
    font-size: 0.8rem;
  }
}

.logo-checkbox > span {
  margin-left: 0.5rem;
}
