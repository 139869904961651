.cyr-socialmediashare {
  display: grid;
  grid-template-columns: Repeat(4, 1fr);
  text-align: center;
  grid-gap: 1rem;
  .cyr-heading {
    font-size: 1.75rem;
  }
  .cyr-social-media {
    cursor: pointer;
  }
  & span.bp3-icon.at-icon svg {
    width: 100%;
    height: 100%;
  }

  span.bp3-icon.at-icon {
    width: 100%;
    height: 3rem;
  }
}
