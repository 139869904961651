.color-selection-box {
  // position: absolute;
  // right:calc(100% - 1rem);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  min-width: 20rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  box-shadow: $layer-4-popout;
  overflow: auto;
  position: relative;
  &.dialog {
    & .color-pie {
      pointer-events: none;
    }
  }
  & > div {
    margin: 0.5rem 1rem;
  }
  & .at-color-search-area {
    margin: 0.5rem 2rem 0.5rem 1rem;
  }

  & > .color-palette-dropdown {
    margin-left: 0;
    margin-right: 0;
    padding: 0.25rem 1rem;
    margin-bottom: 0;
    background-color: var(--bg-secondary);
    box-shadow: $layer-2-raised;
  }

  & > .color-palette-area {
    // overflow: auto;
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: row;
    }

    & .color-palette-custom-select {
      text-align: center;
      margin-top: 0.5rem;
    }
  }
}
.at-close-button {
  position: absolute;
  top: 0.6rem;
  right: 0.25rem;
  z-index: 25;
}

.color-palette-area-colorLabel {
  text-align: center;
  display: none;

  &.shown {
    display: flex;
    flex-direction: column;
  }
}

.color-palette-area-colorLabels {
  padding: 0.15rem;
}

.labelTemplate-flipo {
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 3rem;
  margin: 0.15rem;

  &__titletext {
    font-size: 0.8rem;
    border-top: 1px solid #cccccc;
  }

  &__text {
    font-size: 1rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.at-color-palette-options {
  display: flex;
  align-items: flex-end;
}

.at-sorting-options {
  flex: 1 1 auto;
  align-self: center;
}

.color-swatch {
  $border-width: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--bg-primary);
  box-shadow: 0px 0px 0 2px var(--bg-primary);
  cursor: pointer;
  &.no-background {
    background-color: transparent !important;
    border: none !important;
  }
  &.hide-color-pom{
    img{
      visibility: hidden;
    }
  }

  &.active {
    outline: 1px solid var(--bg-primary);
    box-shadow: 0px 0px 0 3px var(--text-primary-active);
    z-index: 1;
  }
  &--disabled {
    pointer-events: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    cursor: default;

    &.active {
      box-shadow: none;
      outline: none;
      background-color: transparent;
    }
  }

  & > span {
    background-color: var(--bg-primary);
    font-size: 8px;
    text-align: center;
    width: 100%;
    height: 10px;
    line-height: 10px;
    color: var(--text-primary-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.pauligSwatchBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 1rem -1rem 0rem;
}

.pauligSwatchBoxText{
  font-size: 9.5px;
  margin-top: 4px;
  text-transform: capitalize;
}

.at-similar-color-content {
  display: flex;
  overflow: auto;
  margin-top: 0.5rem;
}

.at-texture-sample-area {
  $box-size: 5rem;
  $box-pad: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $layer-4-popout;
  padding: $box-pad;
  width: $box-size + 2 * $box-pad;
  position: absolute;

  color: var(--text-primary);
  background-color: var(--bg-primary);

  & > .at-texture-sample-box {
    width: $box-size;
    height: $box-size;
    margin-bottom: $box-pad;
  }

  & > .at-texture-sample-colorname {
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    width: 100%;
  }
}
